/* design library 2.1 */
enum DisignLibraryColors {
  baseSecondary = 'var(--c-base-secondary)',
  baseSub = 'var(--c-base-sub)',
  baseNormal = 'var(--c-base-normal)',
  baseTertiary = 'var(--c-base-tertiary)',
  baseSemilight = 'var(--c-base-semilight)',
  baseExtralight = 'var(--c-base-extralight)',
  baseLight = 'var(--c-base-light)',
  accent1Normal = 'var(--c-accent1-normal)',
  accent2Normal = 'var(--c-accent2-normal)',
  accent4Normal = 'var(--c-accent4-normal)',
  accent1Secondary = 'var(--c-accent1-secondary)',
  accent2Secondary = 'var(--c-accent2-secondary)',
  accent1Tertiary = 'var(--c-accent1-tertiary)',
  accent2Tertialy = 'var(--c-accent2-tertiary)',
  accent2Dark = 'var(--c-accent2-dark)',
  accent2Light = 'var(--c-accent2-light)',
  accent1Light = 'var(--c-accent1-light)',
  accent1Dark = 'var(--c-accent1-dark)',
  accent1Semilight = 'var(--c-accent1-semilight)',
  accent1Text = 'var(--c-accent1-text)',
  accent2Text = 'var(--c-accent2-text)',
  accent3Text = 'var(--c-accent3-text)',
  accent3Normal = 'var(--c-accent3-normal)',
  accent3Dark = 'var(--c-accent3-dark)',
  accent4Light = 'var(--c-accent4-light)',
  accent4Semilight = 'var(--c-accent4-semilight)',
  accent4Secondary = 'var(--c-accent4-secondary)',
  accent4Dark = 'var(--c-accent4-dark)',
  accent4Tertialy = 'var(--c-accent4-tertiary)',
  accent4Text = 'var(--c-accent4-text)',
  purpleText = 'var(--c-purple-text)',
  purpleLight = 'var(--c-purple-light)',
}

enum OldColors {
  blueLight = 'var(--blueLight)',
  blueLight30 = 'var(--blueLight30)',
  blue = 'var(--blue)',
  blueDark10 = 'var(--blueDark10)',
  blueDark20 = 'var(--blueDark20)',
  cobalt = 'var(--cobalt)',

  greenLight = 'var(--greenLight)',
  green = 'var(--green)',
  greenDark10 = 'var(--greenDark10)',
  greenDark20 = 'var(--greenDark20)',

  redLight = 'var(--redLight)',
  red = 'var(--red)',
  redDark10 = 'var(--redDark10)',
  redDark20 = 'var(--redDark20)',

  yellowLight = 'var(--yellowLight)',
  yellow = 'var(--yellow)',
  yellowDark = 'var(--yellowDark)',
  yellowDark10 = 'var(--yellowDark10)',
  yellowDark20 = 'var(--yellowDark20)',

  white = 'var(--white)',
  greyLight30 = 'var(--greyLight30)',
  greyLight20 = 'var(--greyLight20)',
  greyLight10 = 'var(--greyLight10)',
  grey = 'var(--grey)',
  greyDark = 'var(--greyDark)',
  black = 'var(--black)',
  darkNavy = 'var(--darkNavy)',

  pistachio = 'var(--pistachio)',
  mauve = 'var(--mauve)',
  azure = 'var(--azure)',
  violet = 'var(--violet)',
  fuchsia = 'var(--fuchsia)',
  antiqueWhite = 'var(--antiqueWhite)',
  alloyOrange = 'var(--alloyOrange)',
  orange = 'var(--orange)',

  instagramGradient = 'var(--instagram-gradient)',
  chatfuelGradient = 'var(--chatfuel-gradient)',

  /* New ui kit colors https://www.figma.com/file/P0qAC86lncns2dO8jOgiFG/Design-Library-2.1?node-id=6%3A24 */
  /* Cobalt */

  cobaltSub = 'var(--cobaltSub)',
  cobaltTertiary = 'var(--cobaltTertiary)',
  cobaltSemilight = 'var(--cobaltSemilight)',
  cobaltExtralight = 'var(--cobaltExtralight)',

  /* Blue */

  blueSecondary = 'var(--blueSecondary)',

  /* Purple */

  /* Magenta */

  /* Green */

  /* Yellow */

  /* Oragne */

  orangeNormal = 'var(--orangeNormal)',
  accent3Orange = 'var(--accent3Orange)',
  accent3OrangeSecondary = 'var(--accent3OrangeSecondary)',

  /* Red */

  redNormal = 'var(--redNormal)',
  redSecondary = 'var(--redSecondary)',
  redSemilight = 'var(--redSemilight)',

  /* Gradients */
}

export const Color = { ...OldColors, ...DisignLibraryColors };
export type Color = typeof Color[keyof typeof Color];

export const ColorObject = Object.entries(Color).reduce(
  (acc, item) => Object.assign(acc, { [item[0]]: item[1] }),
  {},
);

export const ColorKeyObject = Object.keys(Color).reduce(
  (acc, item) => Object.assign(acc, { [item]: item }),
  {},
);

export type ColorKey = keyof typeof Color;

export enum HEXColors {
  black = '#000000',
  white = '#FFFFFF',

  blueLight = '#88BEEE',
  blue = '#208EF0',
  blueDark10 = '#2086e2',
  facebookColor = '#4B76CF',

  violet = '#6358DE',

  red = '#DE614D',

  greenShopify = '#9EBD59',
  greenLight = '#94D4BE',

  greyLight10 = '#E9E8E8',
  greyLight11 = '#E6E5E5',
  greyLight12 = '#D8D8D8',
  greyLight20 = '#F3F2F2',
  greyLight30 = '#f9f8f8',
  grey = '#C6C6C6',
  greyDark = '#747474',

  baseLight = '#EFF0F2',
  baseSecondary = '#5B677D',

  cobalt = '#152442',
  cobaltTertiary = '#8A92A0',

  yellowLight = '#F4E99D',
  yellow = '#F6E049',
  yellowDark20 = '#CABD72',

  mustardDark10 = '#FBEDB6',
  mustardDark40 = '#6B5619',

  orange = '#FD9426',

  darkOrange = '#F17E59',

  azure = '#22AEDA',

  magenta = '#BE60EF',

  green = '#45c195',

  fuchsia = '#ce426d',

  antiqueWhite = '#ffebd6',

  alloyOrange = '#ad6418',

  accent1Normal = '#208EF0',

  accent2Normal = '#3FB289',
  accent2Dark = '#028F61',

  accent3Dark = '#DA720B',

  accent4Normal = '#FA5740',
  accent4Dark = '#F03C23',

  accent4Secondary = '#FF806E',

  baseExtraLight = '#F5F6F7',

  calloutsAliceBlue = '#F3F8FD',

  // TODO Все цвета ниже не из библиотеки и не подразумеваются для использования.
  // Дизайнеры планируют причесывать библиотеку цветов, убрать эти цвета когда все будет готово
  // https://chatfuel.slack.com/archives/CR4PBGR5F/p1651147414489679
  _a6d2f9 = '#a6d2f9',
  _ECECEC = '#ECECEC',
  _ff0000 = '#ff0000',
  _428fe9 = '#428fe9',
  _f7f2d4 = '#f7f2d4',
  _666666 = '#666666',
  _79BBF6 = '#79BBF6',
  _999999 = '#999999',
  _00ff00 = '#00ff00',
  _2ABBC5 = '#2ABBC5',
  _f4f4f4 = '#f4f4f4',
  _e4e4e4 = '#e4e4e4',
  _e8e8e8 = '#e8e8e8',
  _ece3ab = '#ece3ab',
  _EBB8B0 = '#EBB8B0',
}
