import React, { useLayoutEffect, useRef } from 'react';
import {
  CupertinoPane,
  CupertinoPane as CupertinoPaneBase /* CupertinoSettings */,
} from 'cupertino-pane';
import { usePreventEvents } from '@utils/Event/usePreventEvents';

const containerStyles: React.CSSProperties = {
  overflowY: 'auto',
  overflowX: 'hidden',
};

const heightRatio = {
  top: 0.92,
  middle: 0.7,
  bottom: 0.42,
};

interface DialogBreaks {
  top?: number;
  middle?: number;
  bottom?: number;
}

export type DialogBreaksFn = (w: Window) => DialogBreaks;

const defaultBreaksFn: DialogBreaksFn = (window: Window) => ({
  top: Math.round(window.innerHeight * heightRatio.top),
  middle: Math.round(window.innerHeight * heightRatio.middle),
  bottom: Math.round(window.innerHeight * heightRatio.bottom),
});

interface MobileDialogProps {
  className?: string;
  backdropOpacity?: number;
  backdrop?: boolean;
  show: boolean;
  breaksFn?: DialogBreaksFn;
  initialBreak?: keyof DialogBreaks;
  fitHeight?: boolean;
  onClose?(): void;
  hideCloseButton?: boolean;
}

export const MobileDialog: React.FC<MobileDialogProps> = ({
  children,
  className,
  backdropOpacity,
  backdrop,
  breaksFn = defaultBreaksFn,
  fitHeight = false,
  initialBreak = 'top',
  show,
  onClose,
  hideCloseButton = false,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<CupertinoPane | null>(null);
  const preventEventsRef = usePreventEvents([
    'mousedown',
    'touchstart',
    'mouseup',
  ]);

  useLayoutEffect(() => {
    if (rootRef.current) {
      const setContentWrapperHeight = () => {
        if (contentWrapperRef.current)
          contentWrapperRef.current.style.height = `${
            window.innerHeight -
            (drawerRef.current?.getPanelTransformY() ?? 0) -
            contentWrapperRef.current.offsetTop
          }px`;
      };

      const breaks: DialogBreaks = breaksFn(window);

      drawerRef.current = new CupertinoPaneBase(rootRef.current, {
        fitHeight,
        initialBreak,
        breaks: {
          top: {
            enabled: breaks?.top !== undefined,
            height: breaks?.top,
          },
          middle: {
            enabled: breaks?.middle !== undefined,
            height: breaks?.middle,
          },
          bottom: {
            enabled: breaks?.bottom !== undefined,
            height: breaks?.bottom,
          },
        },
        backdropOpacity,
        backdrop,
        passiveListeners: false,
        touchMoveStopPropagation: true,
        dragBy: ['.draggable'],
        cssClass: className,
        onBackdropTap: () => {
          onClose?.();
        },
        onDidDismiss: () => {
          onClose?.();
        },
        onDidPresent: setContentWrapperHeight,
        onTransitionEnd: setContentWrapperHeight,
        onDrag: (event) => {
          event?.preventDefault();
          event?.stopPropagation();
          setContentWrapperHeight();
        },
        buttonDestroy: !hideCloseButton,
      });

      preventEventsRef(drawerRef.current.el);
    }
    return () => {
      drawerRef.current?.destroyResets();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (!drawerRef.current) return;
    if (show) drawerRef.current.present({ animate: true });
    else drawerRef.current.destroy({ animate: true });
  }, [show]);

  return (
    <div ref={rootRef}>
      <div ref={contentWrapperRef} style={containerStyles}>
        {children}
      </div>
    </div>
  );
};
