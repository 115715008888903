import React from 'react';
import cx from 'classnames';
import { getComponentWithRefAndName } from '@utils/withRef';
import { logDeprecatedComponent } from '@utils/logDeprecated';
import * as css from './ButtonUnstyled.css';

const componentName = 'ButtonUnstyled';
export const ButtonUnstyled = logDeprecatedComponent(
  componentName,
  getComponentWithRefAndName<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement>
  >(componentName, ({ className, ...props }, ref) => (
    <button
      ref={ref}
      className={cx(css.buttonUnstyled, className)}
      {...props}
    />
  )),
);
