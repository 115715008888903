import escapeStringRegexp from 'escape-string-regexp';

export const ATTR_SIGN = {
  start: '{{',
  end: '}}',
};

const ATTR_SIGN_ESCAPED = {
  start: escapeStringRegexp(ATTR_SIGN.start),
  end: escapeStringRegexp(ATTR_SIGN.end),
};

// export const ATTR_SIGN_REG = new RegExp(
//   `${ATTR_SIGN_ESCAPED.start}((?!${escapeStringRegexp(ATTR_SIGN.start[0])}).)*?${ATTR_SIGN_ESCAPED.end}`,
//   'g',
// );

export const ATTR_SIGN_REG = new RegExp(
  `${ATTR_SIGN_ESCAPED.start}[^${escapeStringRegexp(ATTR_SIGN.start[0])}]*?${
    ATTR_SIGN_ESCAPED.end
  }`,
  'g',
);

export const CLEAR_QUERY_REQ_EXP = new RegExp(
  `^${ATTR_SIGN_ESCAPED.start}|${ATTR_SIGN_ESCAPED.end}$`,
  'g',
);

export const ATTR_MARK_TYPE = 'attr';

export enum EditorModeEnum {
  view = 'view',
  edit = 'edit',
}
