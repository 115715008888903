import React from 'react';
import cn from 'classnames';
import * as css from './ScrollBox.css';

export interface IScrollBox extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  fullHeight?: boolean;
  onScroll?: React.UIEventHandler;
  innerRef?: React.Ref<HTMLDivElement>;
  elId?: string;
  className?: string;
}

const ScrollBox = ({
  children,
  onScroll,
  innerRef,
  fullHeight,
  elId,
  className,
  ...props
}: IScrollBox) => {
  return (
    <div
      className={cn(css.layout, className, {
        [css.fullHeight]: fullHeight,
      })}
      onScroll={onScroll}
      ref={innerRef}
      id={elId}
      {...props}
    >
      {children}
    </div>
  );
};

export default ScrollBox;
