import React from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { createFlattenTree, getChildrenIds } from './utils';
import {
  TreeType,
  TreeStateItem,
  RenderItemProps,
  DefaultHandlers,
  DefaultState,
  FlatTreeItemTypes,
} from './types';

export interface TreeProps<T> {
  treeData: TreeType<T>[];
  itemSize: (item: TreeStateItem<T>) => number;
  renderItem: (
    props: RenderItemProps<T, DefaultHandlers, DefaultState>,
  ) => JSX.Element;
  renderLoader: () => JSX.Element | null;
  height: number;
  threshold: number;
  isItemLoaded: (currentIndex: number, flatTreeLength: number) => boolean;
  loadMoreItems: () => Promise<any>;
  onExpandedChange: (expandedIds: string[]) => void;
  expanded: string[];
}

interface VariableSizeListRef {
  resetAfterIndex(index: number, shouldForceUpdate: boolean): void;
}

export function Tree<T>({
  treeData,
  renderItem,
  height,
  loadMoreItems,
  isItemLoaded,
  threshold,
  itemSize,
  renderLoader,
  onExpandedChange,
  expanded,
}: TreeProps<T>) {
  const listRef = React.useRef<VariableSizeListRef | null>(null);
  const [items, setItems] = React.useState(
    createFlattenTree(treeData, expanded),
  );

  React.useEffect(() => {
    setItems(createFlattenTree(treeData, expanded));
  }, [treeData, expanded]);

  const expandHandler = React.useCallback(
    (expandedItem: TreeType<T>) => {
      const alreadyExpanded = expanded.some(
        (expandedId) => expandedId === expandedItem.id,
      );

      if (alreadyExpanded) {
        const idsToFold = getChildrenIds(expandedItem);

        return expanded.filter((expandedId) => !idsToFold.includes(expandedId));
      }

      return [...expanded, expandedItem.id];
    },
    [expanded], // eslint-disable-line react-hooks/exhaustive-deps
  );

  listRef.current?.resetAfterIndex(0, false);

  const renderItems: Array<TreeStateItem<T>> = items.filter(
    ({ visible }) => visible,
  );
  const loaderElement = renderLoader();

  if (loaderElement) {
    renderItems.push({ type: FlatTreeItemTypes.loader });
  }

  renderItems.push({ type: FlatTreeItemTypes.spacer });

  return (
    <InfiniteLoader
      itemCount={renderItems.length + 1}
      threshold={threshold}
      isItemLoaded={(index) =>
        isItemLoaded(index, renderItems.length - 2 - (loaderElement ? 1 : 0))
      }
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <VariableSizeList
          ref={(reference) => {
            ref(reference);
            listRef.current = reference;
          }}
          onItemsRendered={onItemsRendered}
          height={height}
          width="100%"
          itemSize={(index) => itemSize(renderItems[index])}
          itemCount={renderItems.length}
        >
          {({ index, style }) => {
            const curr = renderItems[index];

            if (curr.type === FlatTreeItemTypes.loader) {
              return <div style={style}>{loaderElement}</div>;
            }

            if (curr.type === FlatTreeItemTypes.spacer) {
              return <div style={style} />;
            }

            const handlers = {
              onExpandedChange: () =>
                onExpandedChange(expandHandler(curr.tree)),
            };

            const state = {
              expanded: expanded.includes(curr.id),
            };

            return (
              <div style={style}>
                {renderItem({
                  data: curr.tree,
                  handlers,
                  level: curr.depth,
                  state,
                })}
              </div>
            );
          }}
        </VariableSizeList>
      )}
    </InfiniteLoader>
  );
}
