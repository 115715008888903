import React from 'react';
import cn from 'classnames';
import { ButtonType } from '../Base';
import * as css from './ButtonUnstyled.css';

export const ButtonUnstyled = React.forwardRef<
  HTMLButtonElement,
  React.HTMLProps<HTMLButtonElement>
>(({ className, type = 'button', ...props }, ref) => (
  <button
    {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    type={type as ButtonType}
    ref={ref}
    className={cn(css.unstyledButton, className)}
  />
));
