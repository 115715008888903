import React from 'react';
import cx from 'classnames';
import { Input } from './Input';
import * as css from './TextArea.css';

export interface ITextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  renderIcon?: () => React.ReactNode;
  renderIconEnd?: () => React.ReactNode;
  containerClassName?: string;
  error?: boolean | string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      renderIcon,
      renderIconEnd,
      error,
      className,
      containerClassName,
      ...htmlProps
    },
    ref,
  ) => (
    <Input
      renderIcon={renderIcon}
      renderIconEnd={renderIconEnd}
      error={error}
      containerClassName={containerClassName}
      render={({ getInputProps }) => (
        <textarea
          {...getInputProps({
            ...htmlProps,
            className: cx(css.textarea, className),
          })}
          ref={ref}
        />
      )}
    />
  ),
);
