import { MutableRefObject, useState } from 'react';
import { useEventListener } from 'cf-common/src/hooks/useEventListener';

export function useHover<T extends MutableRefObject<HTMLElement | null>>(
  containerRef: T,
) {
  const [hovered, setHovered] = useState(false);

  const config = {
    htmlElementRef: containerRef,
  };

  useEventListener('mouseenter', () => setHovered(true), config);
  useEventListener('mouseleave', () => setHovered(false), config);

  return { hovered };
}
