import { pick } from 'ramda';
import { DatePickerShortcut, PickedDate, PickedRange } from '../types';
import { makeDateRangeByShortcut } from './constants';

export const getDateByShortcut = (
  shortcut: DatePickerShortcut,
): [PickedDate, PickedDate] => {
  // eslint-disable-next-line default-case
  const DATE_RANGE_BY_SHORTCUT = makeDateRangeByShortcut();

  switch (shortcut) {
    case DatePickerShortcut.today:
    case DatePickerShortcut.yesterday:
    case DatePickerShortcut.thisWeek:
    case DatePickerShortcut.thisMonth:
    case DatePickerShortcut.last3Months:
      return DATE_RANGE_BY_SHORTCUT[shortcut];
    default:
      return [null, null];
  }
};

const isRangesAreEqual = (
  [lhsWiderRangeDate, rhsWiderRangeDate]: PickedRange,
  [lhsDate, rhsDate]: PickedRange,
) => {
  if (!(lhsWiderRangeDate && rhsWiderRangeDate && lhsDate && rhsDate)) {
    return false;
  }
  const lhsDateIncluded = lhsWiderRangeDate.isSame(lhsDate, 'day');
  const rhsDateIncluded = rhsWiderRangeDate.isSame(rhsDate, 'day');
  return lhsDateIncluded && rhsDateIncluded;
};

export const getExactRangeShortcutByDate = (
  range: PickedRange,
  ranges: Record<string, PickedRange>,
): DatePickerShortcut => {
  const DATE_RANGE_BY_SHORTCUT = makeDateRangeByShortcut();

  let minRangeDays = Infinity;
  let result = DatePickerShortcut.custom;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const shortcut of Object.keys(ranges)) {
    const shortcutRange =
      DATE_RANGE_BY_SHORTCUT[shortcut as DatePickerShortcut];
    const equal = isRangesAreEqual(shortcutRange, range);
    if (equal) {
      const daysInRange = shortcutRange[1]!.diff(shortcutRange[0]!, 'day');
      if (daysInRange < minRangeDays) {
        minRangeDays = daysInRange;
        result = shortcut as DatePickerShortcut;
      }
    }
  }
  return result;
};

export const getAutoSelectedRange = (range: PickedRange) => {
  const DATE_RANGE_BY_SHORTCUT = makeDateRangeByShortcut();

  const autoDisplayableRanges = pick(
    [
      DatePickerShortcut.today,
      DatePickerShortcut.yesterday,
      DatePickerShortcut.thisWeek,
    ],
    DATE_RANGE_BY_SHORTCUT,
  );
  return getExactRangeShortcutByDate(range, autoDisplayableRanges);
};
