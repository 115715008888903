import React from 'react';
import { ReactComponent as BotDefaultLogo } from '@ui/images/bot-default-logo.svg';
import cn from 'classnames';
import Maybe from 'graphql/tsutils/Maybe';
import css from './BotImage.css';

export interface BotImageProps {
  src: Maybe<string>;
  className?: string;
}

export const BotImage: React.FC<BotImageProps> = ({ src, className }) => (
  <div
    className={cn(css.botImage, className)}
    data-testid="bot-list__list-item__bot_image"
  >
    {src ? (
      <img alt="bot" src={src} className={css.image} />
    ) : (
      <BotDefaultLogo />
    )}
  </div>
);
