// tslint:disable jsx-no-lambda
import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { getComponentWithRefAndName } from '@utils/withRef';
import {
  ControlDecorator,
  ControlDecoratorProps,
  RenderFn,
} from './ControlDecorator';
import * as css from './Input.module.css';
import { combineRefs } from '@utils/combineRefs';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type WithoutDivProps = Pick<
  ControlDecoratorProps,
  Exclude<keyof ControlDecoratorProps, keyof React.HTMLProps<HTMLDivElement>>
>;

type WithoutDivAndRender = Omit<WithoutDivProps, 'render'>;

export interface InputProps
  extends WithoutDivAndRender,
    React.InputHTMLAttributes<HTMLInputElement> {
  render?: RenderFn;
  renderLabel?: () => React.ReactNode;
  borderless?: boolean;
  containerClassName?: string;
  onControlDecoratorClick?(): void;
  autoSelect?: boolean;
  readOnly?: boolean;
  inputContainerId?: string;
}

export const Input = getComponentWithRefAndName<HTMLInputElement, InputProps>(
  'Input',
  (
    {
      renderLabel,
      renderIcon,
      renderIconEnd,
      render,
      colorWay,
      error,
      renderErrorText,
      autoFocus,
      borderless,
      disabled,
      containerClassName,
      onControlDecoratorClick,
      iconEndStyle,
      autoSelect,
      readOnly,
      inputContainerId,
      ...htmlInputProps
    },
    ref,
  ) => {
    if (ref && render) {
      console.error(
        'Error: both `ref` and `render` props detected! ' +
          'When using the `render` prop, pass `ref` to the rendered component instead.',
      );
    }

    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      if (autoSelect && inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, [autoSelect]);

    const input = (
      <ControlDecorator
        id={inputContainerId}
        className={containerClassName}
        renderIcon={renderIcon}
        renderIconEnd={renderIconEnd}
        renderErrorText={renderErrorText}
        error={error}
        colorWay={colorWay}
        borderless={borderless}
        disabled={disabled}
        readOnly={readOnly}
        onClick={onControlDecoratorClick}
        iconEndStyle={iconEndStyle}
        render={({ getInputProps, getFocusHandlers }) => {
          if (render) {
            return render({ getInputProps, getFocusHandlers });
          }
          return (
            <input
              type="text"
              autoFocus={autoFocus}
              disabled={disabled}
              readOnly={readOnly}
              {...getInputProps({
                ...htmlInputProps,
                ref: combineRefs([inputRef, ref]),
                className: cx(css.input, htmlInputProps.className, {
                  [css.padStart]: !renderIcon,
                  [css.padEnd]: !renderIconEnd,
                  [css.readonly]: readOnly,
                }),
              })}
            />
          );
        }}
      />
    );
    return renderLabel ? (
      // eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control
      <label className={css.label}>
        <div className={css.labelContent}>{renderLabel()}</div>
        {input}
      </label>
    ) : (
      input
    );
  },
);

Input.displayName = 'Input';
