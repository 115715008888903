import * as PopperJS from 'popper.js';

export const DEFAULT_POPPER_MODIFIERS: PopperJS.Modifiers = {
  flip: {
    boundariesElement: 'viewport',
    enabled: true,
  },
  preventOverflow: {
    boundariesElement: 'viewport',
    enabled: true,
  },
};
