import React, { ReactChild, ReactFragment } from 'react';
import cn from 'classnames';
import * as css from './Stepper.css';

interface StepperStep {
  title: ReactChild | ReactFragment;
  content?: React.ReactNode;
  label?: string;
  done?: boolean;
  active?: boolean;
}

interface StepperProps extends React.HTMLAttributes<HTMLOListElement> {
  steps: StepperStep[];
}

export const Stepper: React.FC<StepperProps> = ({
  className,
  steps,
  ...props
}) => (
  <ol className={cn(css.steps, className)} {...props}>
    {steps.map(({ label, title, done, active, content }, index) => (
      <li
        className={cn({
          [css.active]: active,
          [css.done]: done,
        })}
        data-label={label}
        key={label || index}
      >
        <div className={css.stepTitle}>{title}</div>
        {content}
      </li>
    ))}
  </ol>
);
