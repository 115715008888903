import React, { CSSProperties } from 'react';
import cx from 'classnames';
import * as css from './BubbleValue.css';
import { Tooltip2 } from '../../Tooltip2';
import { ValidationResult } from '../BubbleEditor';

interface Props extends TestLocator {
  attributes: any;
  validation?: ValidationResult;
  children: React.ReactNode;
  isFocused: boolean;
  style?: CSSProperties;
  node: {
    text: string;
  };
}

export const BubbleValue: React.SFC<Props> = ({
  validation,
  isFocused,
  node: { text },
  style,
  ...props
}) => {
  return (
    <Tooltip2
      content={validation?.errorMessage}
      positionFixed
      placement="top-start"
      hoverable
      disabled={isFocused || validation?.isValid || !validation?.errorMessage}
    >
      {(ref, bind) => (
        <div className={css.root} ref={ref} {...bind} {...props.attributes}>
          <div
            data-testid={props['data-testid']}
            className={cx(css.value, {
              [css.empty]: !text,
              [css.error]: !validation?.isValid && !isFocused,
              [css.valid]: validation?.isValid || isFocused,
            })}
            style={style}
          >
            {props.children}
          </div>
        </div>
      )}
    </Tooltip2>
  );
};
