import React from 'react';
import cn from 'classnames';
import { Color } from '@ui/_common/colors';
import * as css from './CircleLoader.css';

interface CircleLoaderProps {
  className?: string;
  size?: number;
  opacity?: number;
  color?: keyof typeof Color;
}

const DEFAULT_LOADER_SIZE = 16;
const DEFAULT_LOADER_OPACITY = 0.4;

export const CircleLoader: React.FC<CircleLoaderProps> = ({
  className,
  size = DEFAULT_LOADER_SIZE,
  opacity = DEFAULT_LOADER_OPACITY,
  color = 'white',
}) => (
  <svg
    className={cn(css.circleLoader, className)}
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1626 2.02193C20.5369 1.84509 23.8805 2.74101 26.7142 4.58127C29.548 6.42154 31.7264 9.11167 32.9373 12.2661C34.1482 15.4206 34.3293 18.8774 33.4548 22.1411C32.5803 25.4048 30.695 28.3079 28.0691 30.4343C25.4432 32.5607 22.2116 33.8012 18.8374 33.9781C15.4631 34.1549 12.1195 33.259 9.28577 31.4187C6.45202 29.5785 4.27359 26.8883 3.06271 23.7339C1.85183 20.5794 1.67067 17.1226 2.54519 13.8589"
      stroke={Color[color]}
      opacity={opacity}
      strokeWidth="4"
    />
  </svg>
);
