import React from 'react';
import noop from 'lodash-es/noop';
import { ButtonUnstyled } from '@ui/Button';
import { AnchorParams } from '@ui/CollapsibleSection';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
// This is not good when UI kit have dependency of regular components, PluginHeaderView should be in ui kit actually
import { PluginHeaderView } from '@components/FlowBuilder/EditorPanel/components/common/PluginHeader';
import * as css from './Anchor.css';

export interface AnchorProps extends AnchorParams {
  disabled?: boolean;
}

export const Anchor: React.FC<AnchorProps> = ({
  changeExtended,
  extended,
  disabled,
  children,
}) => (
  <ButtonUnstyled
    onClick={changeExtended}
    disabled={disabled}
    data-testid="collapsible-section-anchor"
    className={css.button}
  >
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className={css.wrapper}
    >
      {children}

      <Spacer horizontalFactor={3} />

      <Icon
        icon="arrow_right"
        size="24px"
        style={{
          transform: extended ? 'rotate(90deg)' : 'none',
        }}
      />
    </Flex>
  </ButtonUnstyled>
);

export interface AnchorTitleProps extends AnchorProps {
  title: React.ReactNode;
  tooltip?: React.ReactNode;
}

export const AnchorWithTitle: React.FC<AnchorTitleProps> = ({
  title,
  tooltip,
  ...anchorProps
}) => (
  <Anchor {...anchorProps}>
    <PluginHeaderView
      containerClassName={css.header}
      showTooltip={Boolean(tooltip)}
      tooltip={tooltip}
      title={title}
      small
    />
  </Anchor>
);

export const AnchorLoading: React.FC = () => (
  <Anchor disabled extended={false} changeExtended={noop}>
    <LoadingPlaceholder color="baseTertiary" width={124} height={8} />
  </Anchor>
);
