import React, { useRef, useState, FC } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import cn from 'classnames';
import { ChromePicker } from 'react-color';
import { Manager, Popper, Reference } from 'react-popper';
import { ButtonUnstyled } from '../Button/Unstyled';
import * as css from './ColorPicker.css';

interface ColorPickerProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'onChange' | 'value'> {
  initialValue?: string;
  onChange?: (value: string) => void;
  onDone?: (value: string) => void;
}

export const ColorPicker: FC<ColorPickerProps> = ({
  className,
  style,
  initialValue,
  onChange,
  onDone,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue || '');
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => {
    if (show) {
      onDone?.(value);
    }
    setShow(false);
  });

  return (
    <div ref={ref} className={css.box}>
      <Manager>
        <Reference>
          {(bind) => (
            <ButtonUnstyled
              className={cn(css.button, className)}
              style={{
                ...style,
                backgroundColor: value,
              }}
              onClick={() => {
                setShow(true);
              }}
              {...props}
              {...bind}
            />
          )}
        </Reference>
        {show && (
          <Popper
            placement="bottom-start"
            modifiers={{
              offset: {
                offset: '0, 4',
              },
            }}
            positionFixed
          >
            {({ style, ref }) => (
              <div className={css.pickerBox} style={style} ref={ref}>
                <ChromePicker
                  color={value}
                  onChange={({ hex }) => {
                    setValue(hex);
                    onChange?.(hex);
                  }}
                  disableAlpha
                />
              </div>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  );
};
