import React, { useState } from 'react';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import { ShadowBox } from '@ui/ShadowBox/ShadowBox';
import { AnchorWithTitle, AnchorLoading, AnchorTitleProps } from './Anchor';

interface SectionViewProps
  extends Omit<AnchorTitleProps, 'extended' | 'changeExtended'> {
  active: boolean;
  onChangeExtended: () => void;
  loading?: boolean;
}

const CollapsibleSectionWithShadowView: React.FC<SectionViewProps> = ({
  children,
  title,
  tooltip,
  active,
  onChangeExtended,
  loading,
}) => {
  return (
    <ShadowBox active={active} data-testid="collapsible-section">
      <CollapsibleSection
        defaultExtended={active}
        anchor={({ extended, changeExtended }) =>
          loading ? (
            <AnchorLoading />
          ) : (
            <AnchorWithTitle
              tooltip={tooltip}
              title={title}
              extended={extended}
              changeExtended={() => {
                changeExtended();
                onChangeExtended();
              }}
            />
          )
        }
      >
        {({ bind }) => <div {...bind}>{children}</div>}
      </CollapsibleSection>
    </ShadowBox>
  );
};

export interface CollapsibleSectionWithShadowProps
  extends Omit<SectionViewProps, 'active' | 'onChangeExtended'> {
  initialValue?: boolean;
}

export const CollapsibleSectionWithShadow: React.FC<CollapsibleSectionWithShadowProps> =
  (props) => {
    const [active, setActive] = useState(props.initialValue || false);

    return (
      <CollapsibleSectionWithShadowView
        {...props}
        active={active}
        onChangeExtended={() => setActive(!active)}
      />
    );
  };
