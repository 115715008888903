import { Flex } from '../Flex';
import { LoadingPlaceholder } from './LoadingPlaceholder';
import React from 'react';
import { Button, ButtonProps } from '../Button';

interface ButtonLoadingPlaceholderProps extends ButtonProps {}

export const ButtonLoadingPlaceholder: React.FC<ButtonLoadingPlaceholderProps> =
  ({ style, ...props }) => (
    <Button
      style={{
        padding: '0 12px',
        pointerEvents: 'none',
        ...style,
      }}
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        style={{
          height: '100%',
          marginTop: 2,
        }}
      >
        <LoadingPlaceholder height={6} width="80%" />
      </Flex>
    </Button>
  );
