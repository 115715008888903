import React from 'react';
import cn from 'classnames';
import * as css from './Toaster.css';
import { Flex } from '../Flex';
import { Spacer } from '../Spacer';
import { Button, ButtonUnstyled } from '../Button';
import { ReactComponent as CloseIcon } from '../_deprecated/Icon/icons/ic_close_2.svg';
import { Type } from '../Type';
import { ColorKey } from '../_common/colors';

export enum ToasterType {
  success = 'Success',
  info = 'Info',
  error = 'Error',
  warning = 'Warning',
}

export type ToasterTypeKey = keyof typeof ToasterType;

export interface ToasterConfig {
  type: ToasterTypeKey;
  leftIcon?: JSX.Element;
  content?: JSX.Element | string;
  button?: JSX.Element;
  buttonText?: string;
  onButtonClick?: () => void;
  timeout?: number;
  showCloseButton?: boolean;
  closeButton?: boolean;
  onDismiss?: () => void;
  fullWidth?: boolean;
  offset?: number;
  closeByClick?: boolean;
}

export interface ToasterProps extends ToasterConfig {
  id: string;
  hiding: boolean;
}

export interface ToasterInnerProps extends ToasterProps {
  index: number;
}

export const Toaster: React.FC<ToasterInnerProps> = ({
  hiding,
  type,
  index,
  leftIcon,
  content,
  button,
  buttonText,
  onButtonClick,
  onDismiss,
  closeButton,
  fullWidth,
  offset,
  closeByClick
}) => {
  const toasterType = ToasterType[type];

  let buttonTextColor = 'black' as ColorKey;

  switch (type) {
    case 'error':
      buttonTextColor = 'red';
      break;
    default:
      break;
  }

  return (
    <Flex className={css.toasterWrapper} justifyContent="center">
      <Flex
        className={cn(css.toaster, css[`toasterType${toasterType}`], {
          [css.toasterHiding]: hiding,
          [css.fullWidth]: fullWidth,
        })}
        alignItems="center"
        style={{
          transform: `translateY(-${52 * (index + 1)}px)`,
          zIndex: 1000 - index,
          marginBottom: -(offset || 0),
        }}
        onMouseDown={()=> {
          if (closeByClick) {
            onDismiss?.();
          }
        }}
      >
        {leftIcon}
        {leftIcon && <Spacer horizontalFactor={2} />}
        {content && (
          <>
            <Type color="white" as="div" size="15px_DEPRECATED">
              {content}
            </Type>
            <Spacer horizontalFactor={2} />
          </>
        )}
        {(button || buttonText) && <Spacer horizontalFactor={2} />}
        {button ||
          (buttonText && (
            <Button intent="secondary" onClick={onButtonClick} size="s">
              <Type color={buttonTextColor} size="15px_DEPRECATED">
                {buttonText}
              </Type>
            </Button>
          ))}
        {(button || buttonText) && <Spacer horizontalFactor={2} />}
        {closeButton && (
          <>
            <Spacer horizontalFactor={2} />
            <ButtonUnstyled onClick={onDismiss} style={{ outline: 'none' }}>
              <CloseIcon className={css.closeIcon} />
            </ButtonUnstyled>
          </>
        )}
      </Flex>
    </Flex>
  );
};
