import React from 'react';
import { SwiperProps, Swiper } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

interface SwiperSliderWrapperProps extends SwiperProps {
  children: React.ReactNode[];
}

export const SwiperSliderWrapper = ({
  children,
  ...props
}: SwiperSliderWrapperProps) => {
  return (
    <Swiper modules={[Autoplay]} {...props}>
      {children}
    </Swiper>
  );
};
