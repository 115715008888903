import React from 'react';
import cx from 'classnames';
import { getComponentWithRefAndName } from '@utils/withRef';
import * as s from './Flex.css';
import { BASE_UNIT } from '../Spacer/Spacer';

export interface FlexProps extends React.HTMLProps<HTMLDivElement> {
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'flex-end'
    | 'center'
    | 'flex-start';
  alignItems?:
    | 'baseline'
    | 'center'
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'normal';
  flexWrap?: boolean;
  className?: string;
  inline?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  gapFactor?: number;
}

export const Flex = getComponentWithRefAndName<HTMLDivElement, FlexProps>(
  'Flex',
  (
    {
      flexDirection,
      justifyContent,
      alignItems,
      flexWrap,
      className,
      inline,
      fullHeight,
      fullWidth,
      style,
      gapFactor,
      ...htmlProps
    }: FlexProps,
    ref,
  ) => {
    const gapStyle: React.CSSProperties =
      !style?.gap && gapFactor ? { gap: gapFactor * BASE_UNIT } : {};

    return (
      <div
        className={cx(s.Flex, className, {
          [s.rowReverse]: flexDirection === 'row-reverse',
          [s.column]: flexDirection === 'column',
          [s.columnReverse]: flexDirection === 'column-reverse',
          [s.spaceBetween]: justifyContent === 'space-between',
          [s.center]: justifyContent === 'center',
          [s.justifyFlexEnd]: justifyContent === 'flex-end',
          [s.justifyCenter]: justifyContent === 'center',
          [s.justifyFlexStart]: justifyContent === 'flex-start',
          [s.alignBaseline]: alignItems === 'baseline',
          [s.alignCenter]: alignItems === 'center',
          [s.alignFlexStart]: alignItems === 'flex-start',
          [s.alignFlexEnd]: alignItems === 'flex-end',
          [s.flexWrap]: flexWrap,
          [s.inline]: inline,
          [s.fullHeight]: fullHeight,
          [s.fullWidth]: fullWidth,
        })}
        ref={ref}
        style={{
          ...style,
          ...gapStyle,
        }}
        {...htmlProps}
      />
    );
  },
);
