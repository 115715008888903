import React from 'react';
import { Type } from '@ui/Type';

import * as css from './InputErrorBox.css';

interface InputErrorBoxProps extends TestLocator {
  errorText?: React.ReactNode;
}

export const InputErrorBox: React.FC<InputErrorBoxProps> = ({
  errorText,
  'data-testid': dataTestId,
}) => {
  return (
    <div className={css.errorBox}>
      <Type
        as="div"
        size="12px"
        weight="medium"
        color="red"
        data-testid={dataTestId}
      >
        {errorText}
      </Type>
    </div>
  );
};
