import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import cn from 'classnames';
import { Focus } from 'react-powerplug';

import { logDeprecatedComponent } from '@utils/logDeprecated';

import { Icon, IconColor } from '../Icon';

import * as s from './Input.css';

function getRowsCount(count?: number) {
  const MIN_ROWS_COUNT = 3;
  const MAX_ROWS_COUNT = 8;

  const countForDynamicSize = {
    minRows: MIN_ROWS_COUNT,
    maxRows: MAX_ROWS_COUNT,
  };

  if (!count) {
    return countForDynamicSize;
  }

  const correctCount = Math.min(
    Math.max(count, MIN_ROWS_COUNT),
    MAX_ROWS_COUNT,
  );

  return {
    minRows: correctCount,
    maxRows: correctCount,
  };
}

interface ITextareaProps {
  rows?: number;
  inputRef?: (node: HTMLElement) => void;
  style?: {};
  className?: string;
}

function Textarea(props: ITextareaProps) {
  const rowsCount = getRowsCount(props.rows);
  return <TextareaAutosize {...props} {...rowsCount} />;
}

interface ICommonProps extends TestLocator {
  invalid?: boolean;
  disabled?: boolean;
  placeholderIcon?: React.ReactType<void>;
  buttonIcon?: React.ReactType<void>;
  onIconClick?: () => void;
  textarea?: boolean;
  noPadding?: boolean;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  placeholder?: string;
  value?: string | boolean;
  className?: string;
  onKeyDown?: React.KeyboardEventHandler;
  onPaste?: React.ClipboardEventHandler;
  innerRef?: (ref: HTMLElement | null) => void;
}

type RenderPropsType = {
  textarea?: boolean;
  value?: string;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onIconClick?: React.FormEventHandler;
  showIcon?: boolean;
  getRef: (ref: HTMLElement | null) => void;
  childrenProps: {};
};

interface IInputContainerProps extends ICommonProps {
  children: (data: RenderPropsType) => React.ReactNode;
  borderless?: boolean;
  className?: string;
}

export const InputContainer: React.FC<IInputContainerProps> = logDeprecatedComponent(
  'InputContainer',
  ({
    children,
    invalid,
    disabled,
    onIconClick,
    textarea,
    placeholderIcon,
    className,
    buttonIcon,
    innerRef,
    borderless,
    ...props
  }) => {
    let inputRef: HTMLElement | null;

    function getRef(node: HTMLElement | null) {
      inputRef = node;

      if (innerRef) {
        innerRef(node);
      }
    }

    function focusOnInput() {
      if (inputRef) {
        inputRef.focus();
      }
    }

    const dataForChildren: RenderPropsType = {
      textarea,
      getRef,
      childrenProps: {
        ...props,
        disabled,
      },
    };

    const testid = props['data-testid'];

    return (
      <Focus>
        {({ bind, focused }) => {
          const states = {
            [s.normal]: !focused && !invalid && !disabled,
            [s.focused]: focused && !invalid && !disabled,
            [s.invalid]: invalid,
            [s['focused-and-invalid']]: focused && invalid,
            [s.disabled]: disabled,
          };

          const containerStyleName = cn({
            [s.container]: true,
            [s.borderless]: borderless,
            [s.noPadding]: props.noPadding,
            ...states,
          });

          Object.assign(dataForChildren.childrenProps, {
            onFocus: (e: React.FocusEvent) => {
              bind.onFocus();

              if (props.onFocus) {
                props.onFocus(e);
              }
            },
            onBlur: (e: React.FocusEvent) => {
              bind.onBlur();

              if (props.onBlur) {
                props.onBlur(e);
              }
            },
          });

          const iconButtonStyleName = focused
            ? s['icon-button-focused']
            : s['icon-button'];
          const placeholderStyleName = textarea
            ? s['placeholder-textarea']
            : s['placeholder-input'];

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              data-testid={testid}
              className={cn(containerStyleName, className)}
              onClick={focusOnInput}
            >
              {placeholderIcon && !textarea && (
                <Icon
                  onClick={focusOnInput}
                  className={s['icon-placeholder']}
                  svg={placeholderIcon}
                  color={IconColor.gray}
                />
              )}
              {children(dataForChildren)}
              {buttonIcon && !textarea && props.value && (
                <Icon
                  onClick={onIconClick}
                  className={cn(iconButtonStyleName, 'test-input-icon')}
                  svg={buttonIcon}
                  color={IconColor.black}
                />
              )}
              {props.placeholder && !props.value && !focused && (
                <div className={placeholderStyleName}>{props.placeholder}</div>
              )}
            </div>
          );
        }}
      </Focus>
    );
  },
);

export interface InputProps extends ICommonProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  rows?: number;
  className?: string;
}

export const Input: React.FC<InputProps> = logDeprecatedComponent(
  'Input',
  (props) => {
    return (
      <InputContainer {...props}>
        {(opts) =>
          props.textarea ? (
            <Textarea
              inputRef={opts.getRef}
              {...opts.childrenProps}
              rows={props.rows}
              className={s.input}
            />
          ) : (
            <input
              ref={opts.getRef}
              {...opts.childrenProps}
              className={cn(s.input, props.className)}
            />
          )
        }
      </InputContainer>
    );
  },
);
