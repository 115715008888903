import React from 'react';

function setRef(ref: React.Ref<any>, componentInstance: any) {
  if (!ref) {
    return;
  }
  if (typeof ref === 'function') {
    ref(componentInstance);
  } else {
    (ref as any).current = componentInstance; // eslint-disable-line no-param-reassign
  }
}

type RefSetter = (n: HTMLElement | null) => void;

type AutofocusRenderFn = (options: {
  bind: { ref: RefSetter };
  getProps: <T>(props?: React.Props<T>) => T & { ref: RefSetter };
}) => React.ReactNode;

type AutofocusProps = {
  shouldFocus?: boolean;
  selectAll?: boolean;
} & (
  | { render: AutofocusRenderFn; children: never }
  | { children: AutofocusRenderFn; render: never }
);

export class Autofocus extends React.Component<AutofocusProps, any> {
  el?: HTMLElement | null;

  static defaultProps: Partial<AutofocusProps> = {
    shouldFocus: true,
    selectAll: false,
  };

  constructor(props: any) {
    super(props);
    this.mount = this.mount.bind(this);
    this.getProps = this.getProps.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldFocus) {
      this.setFocus();
    }
  }

  componentDidUpdate(prevProps: AutofocusProps) {
    if (!prevProps.shouldFocus && this.props.shouldFocus) {
      this.setFocus();
    }
  }

  mount(n: HTMLElement | null) {
    this.el = n;
  }

  setFocus() {
    const { selectAll } = this.props;
    if (this.el) {
      this.el.focus();

      if (selectAll) {
        (this.el as HTMLInputElement | HTMLTextAreaElement).select();
      }
    }
  }

  getProps(props?: any) {
    const ref = (n: HTMLElement | null) => {
      setRef(this.mount, n);
      setRef(props && props.ref, n);
    };
    return {
      ...props,
      ref,
    };
  }

  render() {
    const { render, children } = this.props;
    return (render ?? children)({
      bind: { ref: this.mount },
      getProps: this.getProps,
    });
  }
}
