import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import { VariablesType } from '@globals';
import { ATTR_SIGN } from './TextWithAttributesEditorConsts';

interface AttributeParams {
  id?: string;
  name: string;
  type?: VariablesType;
  count?: number;
}

export class FlowBuilderAttribute
  implements AttributesQuery_bot_variableSuggest {
  __typename = 'BotUserVariable' as const;

  id: string;
  name: string;
  type: VariablesType | null;
  count: number | null;

  constructor({ id, name, type, count }: AttributeParams) {
    this.name = name;
    this.type = type || VariablesType.system;
    this.id = id || `${name}_${type}`;
    this.count = count || null;
  }

  toString() {
    return `${ATTR_SIGN.start}${this.name}${ATTR_SIGN.end}`;
  }
}
