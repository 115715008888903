import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Type } from '@ui/Type';
import {
  ComboboxTriangleButton,
  ComboboxTriangleButtonProps,
} from '../components/ComboboxTriangleButton';
import { MenuCombobox, MenuComboboxProps } from './MenuCombobox';
import { BaseComboboxItem } from './types';
import { VisuallyDisabled } from '@ui/VisuallyDisabled';

export const DefaultButtonChildren = (item: Maybe<BaseComboboxItem>) => {
  return <Type size="15px">{item?.title ?? ''}</Type>;
};

interface ComboboxWithTriangleButtonProps<T>
  extends Omit<MenuComboboxProps<T>, 'renderInput'>,
    Omit<ComboboxTriangleButtonProps, 'children'> {
  buttonChildren?: (item: Maybe<T>) => React.ReactNode;
}

export function ComboboxWithTriangleButton<T extends BaseComboboxItem>({
  buttonChildren = DefaultButtonChildren,
  selectedItem,
  itemToString,
  items,
  'data-testid': dataTestId,
  onClick,
  ...props
}: ComboboxWithTriangleButtonProps<T>) {
  return (
    <MenuCombobox
      itemToString={itemToString}
      items={items}
      {...props}
      renderInput={({ popperReference, downshift }) => (
        <ComboboxTriangleButton
          ref={popperReference.ref}
          {...downshift.getToggleButtonProps({
            disabled: props.disabled,
            onClick,
          })}
          {...props}
          data-testid={dataTestId}
        >
          <VisuallyDisabled disable={Boolean(props.disabled)}>
            {buttonChildren(selectedItem)}
          </VisuallyDisabled>
        </ComboboxTriangleButton>
      )}
    />
  );
}
