import React from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '../Button';
import * as css from './Toggle.css';

export interface ToggleProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'value' | 'onChange'> {
  value: boolean;
  onChange?: (newValue: boolean) => void;
  className?: string;
  small?: boolean;
  disabled?: boolean;
}

export const Toggle = React.forwardRef<HTMLButtonElement, ToggleProps>(
  ({ value, onChange, className, small, disabled, ...props }, ref) => {
    const classes = cn(className, css.dot, css.layout, {
      [css.on]: value,
      [css.dotSmall]: small,
      [css.layoutSmall]: small,
      [css.onSmall]: small && value,
      [css.disabled]: disabled,
    });
    const onClick = () => !disabled && onChange?.(!value);

    return (
      <ButtonUnstyled
        {...props}
        onClick={onClick}
        className={classes}
        ref={ref}
      />
    );
  },
);
