import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import * as css from './LoadingError.css';
import { Type } from '../Type';
import { ReactComponent as InfoIcon } from '../_deprecated/Icon/icons/error-info.svg';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../_deprecated/Button';

interface LoadingErrorProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'kind'> {
  onTryAgainClick?: () => void;
}

export const LoadingError: React.FC<LoadingErrorProps> = ({
  className,
  onTryAgainClick,
  ...props
}) => (
  <div className={cn(css.loadingError, className)} {...props}>
    <div>
      <Type color="red" size="15px_DEPRECATED">
        <InfoIcon className={css.infoIcon} />
        Something went wrong
      </Type>
      {onTryAgainClick && (
        <Button
          intent={ButtonIntent.primary}
          colorWay={ButtonColorWay.white}
          size={ButtonSize.s}
          className={cn(css.tryAgain, css.buttonRight)}
          onClick={() => {
            onTryAgainClick();
          }}
        >
          Try Again
        </Button>
      )}
    </div>
  </div>
);

export const LoadingErrorWithMessage: React.FC<LoadingErrorProps> = ({
  className,
  onTryAgainClick,
  children,
  ...props
}) => (
  <div className={cn(css.loadingError, className)} {...props}>
    <Flex alignItems="center" flexDirection="column" style={{ maxWidth: 600 }}>
      {children}
      {onTryAgainClick && (
        <Button
          intent={ButtonIntent.primary}
          colorWay={ButtonColorWay.white}
          size={ButtonSize.s}
          className={cn(css.tryAgain, css.buttonBottom)}
          onClick={() => {
            onTryAgainClick();
          }}
        >
          Try Again
        </Button>
      )}
    </Flex>
  </div>
);
