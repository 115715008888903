import React, { useRef } from 'react';
import cn from 'classnames';
import nanoid from 'nanoid';
import { getComponentWithRefAndName } from '@utils/withRef';
import { Color, ColorKey } from '../_common/colors';
import { IconNames, Icons } from './icons';
import * as css from './Icon.css';

export interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  icon: IconNames;
  size?:
    | '8px'
    | '10px'
    | '12px'
    | '14px'
    | '16px'
    | '20px'
    | '24px'
    | '28px'
    | '30px'
    | '32px'
    | '52px'
    | '60px'
    | '120px';
  color?: ColorKey;
  noColor?: boolean;
  filled?: boolean;
}

export const Icon = getComponentWithRefAndName<HTMLSpanElement, IconProps>(
  'Icon',
  (
    {
      icon,
      size = '24px',
      color = 'black',
      noColor = false,
      filled,
      className,
      style,
      ...props
    },
    ref,
  ) => {
    const id = useRef(`icon_${nanoid()}`);
    const iconName = `${icon}${filled ? '_filled' : ''}` as IconNames;
    const InnerIcon = Icons[iconName];

    if (!InnerIcon) {
      throw new Error(`Cannot find icon ${iconName}`);
    }

    return (
      <span
        ref={ref}
        className={cn(css[`icon_${size}`], css.icon_container, className)}
        style={{
          color: !noColor ? Color[color] : undefined,
          ...style,
        }}
        {...props}
      >
        <InnerIcon id={id.current} className={css[`icon_${size}`]} />
      </span>
    );
  },
);
