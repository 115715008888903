import { Flex } from '@ui/Flex';
import React from 'react';
import cn from 'classnames';
import css from './BotListItem.css';
import { BotImage } from './BotImage';
import { BotTitle, BotTitleProps } from './BotTitle';
import {
  BotAction,
  BotActionProps,
  BotActionType,
} from './BotAction/BotAction';
import { Spacer } from '@ui/Spacer';
import { NestedMenuItem } from '@ui/NestedMenu/types';
import { BotTitleInput } from './BotTitleInput/BotTitleInput';
import { PopperMenu, PopperMenuProps } from '@ui/NestedMenu';
import { IconButton } from '@ui/IconButton';
import { Platform } from '@globals';

export interface BotListItemProps<MenuItemType extends NestedMenuItem>
  extends BotTitleProps {
  disabled?: boolean;
  onRename?(): void;
  sections:
    | [React.ReactNode, React.ReactNode, React.ReactNode]
    | [React.ReactNode]
    | null;
  className?: string;
  actionType: BotActionProps['actionType'];
  menuItems: PopperMenuProps<MenuItemType>['items'];
  onMenuItemSelect: PopperMenuProps<MenuItemType>['onItemSelect'];
  titleEditing: boolean;
  onTitleChange(newTitle: string): void;
  onActionClick: VoidFunction;
  onBotSelect: VoidFunction;
  onMenuClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  menuLoading?: boolean;
  loadingMenuBoxClassName?: string;
  platforms?: Platform[];
}

export const BotListItem = <MenuItemType extends NestedMenuItem>({
  disabled,
  connectedPageInfo,
  sections,
  botTitle,
  isBotPro,
  className,
  actionType,
  menuItems,
  onMenuItemSelect,
  titleEditing,
  onTitleChange,
  onActionClick,
  onBotSelect,
  onMenuClick,
  menuLoading,
  loadingMenuBoxClassName,
  platforms,
}: BotListItemProps<MenuItemType>) => {
  const menuItemsEmpty = !menuItems.length;
  const lastColumn =
    actionType === BotActionType.none && menuItemsEmpty ? (
      <Spacer horizontalFactor={58} factor={0} />
    ) : (
      <Flex className={css.lastColumn}>
        <BotAction
          className={css.botAction}
          actionType={actionType}
          onActionClick={onActionClick}
        />
        <Spacer horizontalFactor={1.5} factor={0} />
        {menuItemsEmpty ? (
          <Spacer horizontalFactor={9} />
        ) : (
          <PopperMenu
            placement="left-start"
            menuStopPropagation
            items={menuItems}
            className={css.popperMenu}
            onItemSelect={onMenuItemSelect}
            menuLoading={menuLoading}
            loadingMenuBoxClassName={loadingMenuBoxClassName}
          >
            {(ref, { setOpen }) => (
              <IconButton
                onClick={(e) => {
                  onMenuClick?.(e);
                  e.stopPropagation();
                  setOpen(true);
                }}
                icon="more"
                ref={ref}
                className={css.moreButton}
                data-testid="bot_list_item__bot_menu_button"
              />
            )}
          </PopperMenu>
        )}
      </Flex>
    );

  return (
    <Flex
      data-testid="bot-list__list-item"
      className={cn(
        css.botListItem,
        disabled && css.disabled,
        titleEditing && css.editing,
        className,
      )}
      onClick={titleEditing ? undefined : onBotSelect}
    >
      <BotImage className={css.image} src={connectedPageInfo?.picture} />
      {titleEditing ? (
        <BotTitleInput
          onTitleChange={onTitleChange}
          title={botTitle}
          className={css.titleInput}
        />
      ) : (
        <>
          <BotTitle
            className={css.title}
            botTitle={botTitle}
            isBotPro={isBotPro}
            connectedPageInfo={connectedPageInfo}
            platforms={platforms}
          />
          {sections}
          {lastColumn}
        </>
      )}
    </Flex>
  );
};
