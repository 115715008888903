import React from 'react';
import cn from 'classnames';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import * as css from './Modal.css';
import { usePreventWheel } from '../../utils/Event/usePreventWheel';
import { Flex } from '../Flex';
import { usePreventEvents } from '@utils/Event/usePreventEvents';
import { combineRefs } from '@utils/combineRefs';
import { ModalProps } from './types';

export const Modal: React.FC<ModalProps> = ({
  onDismiss,
  isOpen,
  initialFocusRef,
  overlayClassName,
  preventEvents,
  ...props
}) => {
  const scrollPreventRef = usePreventWheel();
  const preventEventsRef = usePreventEvents([
    'mousedown',
    'touchstart',
    'mouseup',
  ]);

  return (
    <DialogOverlay
      className={cn(css.overlay, overlayClassName)}
      onDismiss={() => {
        onDismiss?.();
      }}
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
      ref={combineRefs(
        preventEvents
          ? [scrollPreventRef, preventEventsRef]
          : [scrollPreventRef],
      )}
    >
      <Flex
        className={css.scrollPrevent}
        justifyContent="center"
        alignItems="center"
      >
        <DialogContent {...props} className={css.dialog} />
      </Flex>
    </DialogOverlay>
  );
};
