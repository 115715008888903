import React from 'react';
import { AdditionalProps, ComboboxChildrenProps } from './types';
import { ScrollBox } from '@ui/ScrollBox';
import {
  FilterableInputMenuCombobox,
  FilterableInputMenuComboboxProps,
} from './FilterableInputMenuCombobox';

export interface InputMenuComboboxProps<T>
  extends Omit<FilterableInputMenuComboboxProps<T>, 'children'> {
  children: (
    props: ComboboxChildrenProps<T> & { props: AdditionalProps<T> },
  ) => React.ReactNode;
}

export function InputMenuCombobox<T>({
  children,
  ...props
}: InputMenuComboboxProps<T>) {
  return (
    <FilterableInputMenuCombobox {...props}>
      {({ popper, downshift, props }) => {
        return (
          <ScrollBox>
            {props.currentItems.map((item, index) =>
              children({ popper, downshift, props: { item, index, ...props } }),
            )}
          </ScrollBox>
        );
      }}
    </FilterableInputMenuCombobox>
  );
}
