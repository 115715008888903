/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import i18next from 'i18next';
import { Portal } from 'react-portal';
import { VariableSizeList as List } from 'react-window';
import debounce from 'lodash-es/debounce';
import noop from 'lodash-es/noop';
import { compose, curry, isNil, last, test } from 'ramda';
import memoize from 'lodash-es/memoize';
import { Manager, Reference, Popper } from 'react-popper';
import { codes as keycodes } from 'keycode';
import escapeStringRegexp from 'escape-string-regexp';
import cn from 'classnames';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import AutosizeInput from 'react-input-autosize';
import OutsideClick from 'react-outsideclick';
import Maybe from 'graphql/tsutils/Maybe';

import { AutomateEnabled } from '@utils/Data/Admin/Automate';
import { Platform } from '@globals';

import { InputContainer } from '../_deprecated/Input';

import * as inputStyles from '../_deprecated/Input/Input.css';
import {
  HighlightedString,
  HighlightOccurrenceType,
  IHighlightedStringProps,
} from '../HighlightedString';

import { MenuItem } from '../Menu';
import ScrollBox from '../ScrollBox/ScrollBox';
import { TagItem } from '../TagItem';
import { MenuItemCreate } from './MenuItemCreate';
import { MenuItemEmpty } from './MenuItemEmpty';
import * as suggestStyles from './Suggest.css';
import { Flex } from '../Flex';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';

type itemType = 'group' | 'item' | '_separator' | 'title';

// TODO: for refactoring - if there is only "Add block item" then it have to be highlighted

export interface ISuggestProps<T> extends TestLocator {
  items: T[];
  selectedItem?: T;
  selectedItems?: T[];
  displaySelected?: boolean;
  placeholder?: string;
  onChange: (value: string, selectedItem: T) => void;
  onDeselectItem?: (value: string) => void;
  onInputChange?: (value: string) => void;
  onTagDoubleClick?: (item?: T) => void;
  getItemType?: (item: T) => itemType;
  itemToString?: (item?: T) => string;
  itemsFilter?: (value: string, itemString: string) => boolean;
  highlightOccurrence?: HighlightOccurrenceType;
  onAddNewBlock?: (value: string) => void;
  onAddNewFlow?: (value: string) => void;
  borderless?: boolean;
  fullWidth?: boolean;
  showAddBlockPanel?: boolean;
  showAddFlowPanel?: boolean;
  mainColor?: boolean;
  autoCloseDropdown?: boolean;
  className?: string;
  inputWithSelectedBlocksClassName?: string;
  onInputFocus?: React.FocusEventHandler;
  onInputBlur?: React.FocusEventHandler;
  onInputKeyDown?: React.KeyboardEventHandler;
  autofocus?: boolean;
  autofocusItem?: boolean;
  readonly?: boolean;
  invalid?: boolean;
  emptyInputPlaceholder?: string;
  platform?: Platform | null;
  disableLinkPad?: boolean;
  enablePortal?: boolean;
  dropdownRef?: (el: any) => void;
  disableDropdown?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  rightElement?: React.ReactNode;
  useButtonForMobile?: boolean;
}

interface ISuggestState<T> {
  indexOfHighlightedItem?: number;
  filteredItems: { item: T; index: number }[];
}

const USE_VIRTUAL_LIST_LIMIT = 500;

function defaultItemToString<T extends {}>(item: T | undefined) {
  return item?.toString() || '';
}

const defaultGetType = (): itemType => 'item';
const defaultItemsFilter = (value: string, itemString: string) =>
  test(new RegExp(escapeStringRegexp(value), 'i'), itemString);
const onInputKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  closeMenu: Function,
) => event.keyCode === keycodes.enter && closeMenu();

const getTitle = memoize(
  (params: IHighlightedStringProps) => <HighlightedString {...params} />,
  ({ str, matchValue }) => `${str}__${matchValue}`,
);

const getPlaceholderText = (
  platform: Maybe<Platform>,
  isAutomateEnabled?: boolean,
): string => {
  if (platform === Platform.instagram) {
    return i18next.t('modernUi.Suggest.chooseFlow');
  }
  if (!isAutomateEnabled) {
    return i18next.t('modernUi.Suggest.chooseCreateFlow');
  }
  return i18next.t('modernUi.Suggest.chooseCreateFlowBlock');
};

const convertToStateItems = <T extends any>(
  items: T[],
  props: ISuggestProps<T>,
) => {
  const { showAddBlockPanel, getItemType = defaultGetType } = props;

  let startIndex = showAddBlockPanel ? 1 : 0;

  const addRealItemIndex = (item: T) => {
    const isItem = getItemType(item) === 'item';
    if (isItem) {
      startIndex += 1;
    }
    return {
      item,
      index: isItem ? startIndex : -1,
    };
  };

  return items
    .filter(
      (item, index, items) =>
        getItemType(item) === 'item' ||
        (getItemType(item) === 'group' &&
          index !== items.length - 1 &&
          getItemType(items[index + 1]) === 'item') ||
        (getItemType(item) === '_separator' &&
          index !== items.length - 1 &&
          items[index + 2] &&
          getItemType(items[index + 2]) === 'item'),
    )
    .map(addRealItemIndex);
};

// deprecated
export class SuggestUi<T> extends React.PureComponent<
  ISuggestProps<T>,
  ISuggestState<T>
> {
  inputRef: HTMLInputElement | null = null;

  listRef = React.createRef<List>();

  updatePopper = noop;

  isButtonMode = isMobileDevice() && this.props.useButtonForMobile;

  filterItems = debounce((selectedItemString?: string) => {
    const {
      items,
      itemsFilter = defaultItemsFilter,
      getItemType = defaultGetType,
      itemToString = defaultItemToString,
    } = this.props;

    const filteredItems =
      selectedItemString && !this.isButtonMode
        ? items.filter(
            (item) =>
              getItemType(item) === 'group' ||
              getItemType(item) === '_separator' ||
              (
                compose(
                  curry(itemsFilter)(selectedItemString),
                  itemToString as any,
                ) as any
              )(item),
          )
        : items;

    this.setState({
      filteredItems: convertToStateItems(filteredItems, this.props),
    });

    if (this.listRef.current) {
      this.listRef.current.resetAfterIndex(0, false);
    }

    this.updatePopper();
  }, 100);

  constructor(props: ISuggestProps<T>) {
    super(props);
    this.state = {
      indexOfHighlightedItem: undefined,
      filteredItems: convertToStateItems(props.items, props),
    };
  }

  componentDidMount() {
    const { autofocus } = this.props;
    if (autofocus && this.inputRef) {
      this.inputRef.focus();
    }

    this.filterItems();
  }

  componentDidUpdate(prevProps: Readonly<ISuggestProps<T>>): void {
    const { items, autofocusItem, selectedItems } = this.props;
    const { indexOfHighlightedItem } = this.state;
    if (prevProps.items.length !== items.length) {
      this.filterItems();
    }
    if (
      autofocusItem &&
      indexOfHighlightedItem === undefined &&
      !!selectedItems?.length &&
      document.activeElement === this.inputRef
    ) {
      this.highlightItemByIndex(0);
    }
  }

  setInputRef(inp: HTMLInputElement) {
    this.inputRef = inp;
    if (this.props.inputRef) {
      (
        this.props.inputRef as React.MutableRefObject<HTMLInputElement>
      ).current = inp;
    }
  }

  // eslint-disable-next-line
  getHighlitedIndex(): number {
    return 0;
  }

  getItemSizeByType = (item: T) =>
    (this.props.getItemType || defaultGetType)(item) === 'item' ? 32 : 24;

  highlightItemByIndex = (index?: number) => {
    this.setState({
      indexOfHighlightedItem: index,
    });
  };

  stateReducer = (state: DownshiftState<T>, changes: StateChangeOptions<T>) => {
    const { items = [] } = this.props;
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: state.isOpen,
          highlightedIndex: 0,
        };
      case Downshift.stateChangeTypes.changeInput:
        return {
          ...changes,
          isOpen: true,
          highlightedIndex:
            this.props.showAddBlockPanel && items.length > 0 ? 1 : 0,
        };
      default:
        return changes;
    }
  };

  resetSelection = () => this.highlightItemByIndex(undefined);

  handleKeyDown = (event: React.KeyboardEvent): void => {
    const { displaySelected } = this.props;

    if (!displaySelected) {
      return;
    }

    const functionalKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'];

    if (functionalKeys.indexOf(event.key) > -1) {
      this.handleFunctionalKey(event);
      return;
    }

    this.updatePopper();
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  handleFunctionalKey(event: React.KeyboardEvent): void {
    const {
      selectedItem: inputValue,
      onDeselectItem,
      selectedItems = [],
      itemToString = defaultItemToString,
      readonly,
    } = this.props;

    if (!onDeselectItem) {
      return;
    }

    const currentIndex = this.state.indexOfHighlightedItem || 0;

    if (event.key === 'Delete' && !readonly) {
      if (this.isHighlighted()) {
        onDeselectItem(itemToString(selectedItems[currentIndex]));
        if (currentIndex >= selectedItems.length - 1 && this.inputRef) {
          this.inputRef.focus();
        }
        this.filterItems();
      }
    }

    if (event.key === 'Backspace' && !readonly) {
      if (this.isHighlighted()) {
        onDeselectItem(itemToString(selectedItems[currentIndex]));
        if (currentIndex > 0) {
          this.highlightItemByIndex(currentIndex - 1);
        }
        if (currentIndex >= selectedItems.length - 1 && this.inputRef) {
          this.inputRef.focus();
        }
        this.filterItems();
      } else if (itemToString(inputValue) === '') {
        if (last(selectedItems)) {
          this.highlightItemByIndex(selectedItems.length - 1);
        }
      }
    }

    if (event.key === 'ArrowLeft') {
      if (currentIndex > 0) {
        this.highlightItemByIndex(currentIndex - 1);
      } else if (
        this.inputRef &&
        this.inputRef.selectionStart === 0 &&
        !this.isHighlighted()
      ) {
        this.highlightItemByIndex(selectedItems.length - 1);
      }
    }

    if (event.key === 'ArrowRight') {
      if (currentIndex < selectedItems.length - 1 && this.isHighlighted()) {
        this.highlightItemByIndex(currentIndex + 1);
      } else {
        this.resetSelection();
        if (this.inputRef) {
          this.inputRef.focus();
        }
      }
    }

    this.updatePopper();
  }

  isHighlighted() {
    // tslint:disable-next-line:strict-type-predicates
    return this.state.indexOfHighlightedItem !== undefined;
  }

  getHighlightedItem(selectedItems: any[]) {
    const index = this.state.indexOfHighlightedItem || 0;
    return selectedItems[index];
  }

  getPlaceholder(selectedItems: any[], isAutomateEnabled: boolean) {
    if (selectedItems.length === 0) {
      return (
        this.props.emptyInputPlaceholder ||
        getPlaceholderText(this.props.platform, isAutomateEnabled)
      );
    }
    return '';
  }

  render() {
    const {
      items,
      selectedItem,
      selectedItems = [],
      displaySelected = false,
      onChange,
      onInputChange,
      getItemType = defaultGetType,
      placeholder = '',
      itemToString = defaultItemToString,
      highlightOccurrence,
      autoCloseDropdown = true,
      className,
      onAddNewBlock = noop,
      onAddNewFlow = noop,
      showAddBlockPanel,
      showAddFlowPanel,
      onDeselectItem = noop,
      onTagDoubleClick = noop,
      onInputBlur: onBlur,
      onInputFocus: onFocus,
      onInputKeyDown: _onKeyDown,
      borderless,
      readonly,
      invalid,
      enablePortal,
      dropdownRef,
      disableDropdown,
      autofocusItem,
      platform,
      rightElement,
    } = this.props;
    const testId = this.props['data-testid'];

    const { filteredItems } = this.state;

    const selectedItemString: string = itemToString(selectedItem);
    const getItemId = (item: T) =>
      item ? (item as unknown as { id: string }).id || itemToString(item) : '';

    const wrapper = (node: React.ReactNode) =>
      enablePortal ? <Portal>{node}</Portal> : node;

    return (
      <OutsideClick onClickOutside={this.resetSelection}>
        <div
          className={suggestStyles['suggest-container']}
          onClick={() => {
            if (this.inputRef) {
              this.inputRef.focus();
            }
          }}
        >
          <Manager>
            <Downshift
              selectedItem={selectedItem}
              onChange={(selectedItem) => {
                onChange(
                  typeof selectedItem === 'string'
                    ? selectedItem
                    : itemToString(selectedItem),
                  selectedItem,
                );
                this.filterItems(selectedItemString);
              }}
              itemToString={itemToString}
              stateReducer={autoCloseDropdown ? undefined : this.stateReducer}
            >
              {({
                isOpen,
                getInputProps,
                getItemProps,
                closeMenu,
                openMenu,
                highlightedIndex,
                selectedItem: dsSelectedItem,
              }) => {
                const addOnInput = () => {
                  if (showAddFlowPanel && highlightedIndex === 0) {
                    onAddNewFlow(selectedItemString);
                  }
                  if (
                    showAddBlockPanel &&
                    highlightedIndex === (showAddFlowPanel ? 1 : 0)
                  ) {
                    onAddNewBlock(selectedItemString);
                  }
                  if (autoCloseDropdown) {
                    closeMenu();
                  }
                };
                return (
                  <div
                    onKeyDown={this.handleKeyDown}
                    className={suggestStyles['action-item-input-layout']}
                  >
                    <Reference>
                      {({ ref }) => {
                        return (
                          <div ref={ref}>
                            <InputContainer
                              data-testid={testId}
                              className={className}
                              borderless={borderless}
                              invalid={invalid}
                              {...getInputProps({
                                placeholder,
                                onBlur,
                                onFocus,
                                value: selectedItemString,
                                onChange: ({
                                  currentTarget: { value },
                                }: React.ChangeEvent<HTMLInputElement>) => {
                                  if (this.isHighlighted()) {
                                    this.resetSelection();
                                  }
                                  if (onInputChange) {
                                    onInputChange(value);
                                  }
                                  this.filterItems(value);
                                },
                                onKeyDown: (event) => {
                                  onInputKeyDown(event, addOnInput);
                                  if (_onKeyDown) {
                                    _onKeyDown(event);
                                  }
                                },
                                onClick: () => {
                                  this.resetSelection();
                                  openMenu();
                                },
                              })}
                            >
                              {(data: any) => (
                                <React.Fragment>
                                  {displaySelected &&
                                    selectedItems.map((item, index) => {
                                      const onClick = (
                                        event: React.MouseEvent,
                                      ) => {
                                        event.stopPropagation();
                                        this.highlightItemByIndex(index);
                                        if (autoCloseDropdown) {
                                          closeMenu();
                                        }
                                      };
                                      return (
                                        <TagItem
                                          data-testid={`${testId}__tag`}
                                          key={itemToString(item)}
                                          onClick={onClick}
                                          onDoubleClick={() =>
                                            onTagDoubleClick(item)
                                          }
                                          isActive={
                                            this.state
                                              .indexOfHighlightedItem === index
                                          }
                                          onRemoveRequest={() => {
                                            onDeselectItem(itemToString(item));
                                            this.highlightItemByIndex(
                                              undefined,
                                            );
                                            this.filterItems();
                                            setTimeout(() => {
                                              this.inputRef?.focus();
                                            });
                                          }}
                                        >
                                          {itemToString(item)}
                                        </TagItem>
                                      );
                                    })}
                                  <div className={suggestStyles['input-pad']}>
                                    <AutomateEnabled>
                                      {({ isAutomateEnabled }) => (
                                        <AutosizeInput
                                          {...data.childrenProps}
                                          readOnly={this.isButtonMode}
                                          disabled={readonly}
                                          placeholderIsMinWidth
                                          placeholder={this.getPlaceholder(
                                            selectedItems,
                                            isAutomateEnabled,
                                          )}
                                          inputStyle={{
                                            border: 'none',
                                            padding: 0,
                                            maxWidth: 300,
                                            backgroundColor: 'inherit',
                                          }}
                                          inputRef={(inp: HTMLInputElement) => {
                                            data.getRef(inp);
                                            this.setInputRef(inp);
                                          }}
                                          className={cn(
                                            inputStyles.input,
                                            this.props
                                              .inputWithSelectedBlocksClassName,
                                            {
                                              [suggestStyles[
                                                'input-with-selected-blocks'
                                              ]]: displaySelected,
                                            },
                                          )}
                                          onClick={() => {
                                            if (this.isButtonMode) {
                                              openMenu();
                                            }
                                          }}
                                          onFocus={(
                                            event: React.FocusEvent<HTMLInputElement>,
                                          ) => {
                                            data.childrenProps.onFocus(event);
                                            openMenu();
                                            this.resetSelection();
                                            if (
                                              autofocusItem &&
                                              this.state
                                                .indexOfHighlightedItem ===
                                                undefined
                                            ) {
                                              this.highlightItemByIndex(0);
                                            }
                                            if (onFocus) {
                                              onFocus(event);
                                            }
                                          }}
                                        />
                                      )}
                                    </AutomateEnabled>
                                  </div>

                                  {rightElement && (
                                    <Flex
                                      justifyContent="flex-end"
                                      className={suggestStyles.rightElementBox}
                                    >
                                      {rightElement}
                                    </Flex>
                                  )}
                                </React.Fragment>
                              )}
                            </InputContainer>
                            {!this.props.disableLinkPad &&
                              this.isHighlighted() && (
                                <div className={suggestStyles['link-pad']}>
                                  <div
                                    className={suggestStyles['click-item']}
                                    onClick={() => {
                                      const index =
                                        this.state.indexOfHighlightedItem || 0;
                                      onTagDoubleClick(selectedItems[index]);
                                    }}
                                  >
                                    <span className={suggestStyles.link}>
                                      Go to{' '}
                                      {this.getHighlightedItem(selectedItems)
                                        ?.is_flow
                                        ? 'flow'
                                        : 'block'}
                                    </span>
                                    <span> (Double click)</span>
                                  </div>
                                  {!readonly && (
                                    <div
                                      className={suggestStyles['click-item']}
                                      onClick={() => {
                                        const currentIndex =
                                          this.state.indexOfHighlightedItem ||
                                          0;
                                        onDeselectItem(
                                          itemToString(
                                            selectedItems[currentIndex],
                                          ),
                                        );
                                        if (currentIndex > 0) {
                                          this.highlightItemByIndex(
                                            currentIndex - 1,
                                          );
                                        }
                                      }}
                                    >
                                      ,&nbsp;
                                      <span className={suggestStyles.link}>
                                        Delete
                                      </span>
                                      <span> (Backspace)</span>
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        );
                      }}
                    </Reference>
                    {isOpen &&
                      !this.isHighlighted() &&
                      !disableDropdown &&
                      (selectedItemString ||
                        filteredItems.length > 0 ||
                        items.length === 0 ||
                        platform === Platform.instagram) &&
                      wrapper(
                        <Popper placement="bottom-start">
                          {({ ref, style, scheduleUpdate }) => {
                            const extStyle = {
                              ...style,
                              marginBottom: 4,
                              width: enablePortal ? 240 : undefined,
                              cursor: 'default',
                            };

                            this.updatePopper = scheduleUpdate;
                            const addBlock = () => {
                              onAddNewBlock(selectedItemString);
                            };
                            const addFlow = () => {
                              onAddNewFlow(selectedItemString);
                            };
                            const alreadyAdded =
                              selectedItems
                                .map(itemToString)
                                .indexOf(selectedItemString) > -1;
                            const emptyList =
                              !showAddBlockPanel && items.length === 0;

                            return (
                              <div
                                ref={(el) => {
                                  (ref as (el: any) => void)(el);
                                  dropdownRef?.(el);
                                }}
                                style={extStyle}
                                className={suggestStyles.box_makeup}
                                onClick={(event) => {
                                  this.updatePopper();
                                  event.stopPropagation();
                                  event.preventDefault();
                                }}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                }}
                              >
                                {filteredItems.length >
                                USE_VIRTUAL_LIST_LIMIT ? (
                                  <List
                                    ref={this.listRef}
                                    height={300}
                                    width="100%"
                                    estimatedItemSize={32}
                                    itemCount={filteredItems.length}
                                    itemSize={(index) =>
                                      this.getItemSizeByType(
                                        (filteredItems[index] as any).item,
                                      )
                                    }
                                    direction="vertical"
                                  >
                                    {({ index, style }) => {
                                      const { item } = filteredItems[index];
                                      const type = getItemType(item);
                                      const itemString = itemToString(item);
                                      const itemProps =
                                        type === 'item'
                                          ? getItemProps({
                                              index,
                                              item,
                                            })
                                          : {
                                              title: itemString,
                                            };

                                      const customItemProps =
                                        type === 'item'
                                          ? {
                                              active: isNil(highlightedIndex)
                                                ? item === dsSelectedItem
                                                : highlightedIndex === index,
                                              title: itemString,
                                            }
                                          : {};

                                      return (
                                        <MenuItem
                                          {...itemProps}
                                          {...customItemProps}
                                          tabIndex={index}
                                          type={type}
                                          style={style}
                                        />
                                      );
                                    }}
                                  </List>
                                ) : (
                                  <ScrollBox>
                                    <div
                                      className={suggestStyles.scrollBoxSpacing}
                                    >
                                      {showAddFlowPanel && (
                                        <MenuItemCreate
                                          addItem={addFlow}
                                          {...getItemProps({
                                            item: {
                                              id: 'add flow',
                                              title: selectedItemString,
                                            },
                                          })}
                                          active={highlightedIndex === 0}
                                          title={selectedItemString}
                                          text={i18next.t(
                                            'modernUi.BlocksSelector.CreateFlow',
                                          )}
                                        />
                                      )}
                                      {showAddBlockPanel && (
                                        <MenuItemCreate
                                          addItem={addBlock}
                                          {...getItemProps({
                                            item: {
                                              id: 'add block',
                                              title: selectedItemString,
                                            },
                                          })}
                                          active={
                                            highlightedIndex ===
                                            (showAddFlowPanel ? 1 : 0)
                                          }
                                          title={selectedItemString}
                                          text={i18next.t(
                                            'modernUi.BlocksSelector.CreateBlock',
                                          )}
                                        />
                                      )}
                                      {alreadyAdded && (
                                        <MenuItem
                                          title={selectedItemString}
                                          subtitle=" — Already added"
                                        />
                                      )}
                                      {emptyList &&
                                        platform === Platform.facebook && (
                                          <MenuItemEmpty
                                            title={i18next.t(
                                              'modernUi.BlocksSelector.InputTextToCreateBlock',
                                            )}
                                          />
                                        )}
                                      {!filteredItems.length &&
                                        !selectedItemString &&
                                        platform === Platform.instagram && (
                                          <>
                                            <MenuItem
                                              title={i18next.t(
                                                'modernUi.BlocksSelector.InstagramFlows',
                                              )}
                                              type="_separator"
                                            />
                                            <MenuItemEmpty
                                              title={i18next.t(
                                                'modernUi.BlocksSelector.NoInstagramFlowsYet',
                                              )}
                                            />
                                          </>
                                        )}
                                      {!filteredItems.length &&
                                        selectedItemString &&
                                        platform === Platform.instagram && (
                                          <>
                                            <MenuItem
                                              title={i18next.t(
                                                'modernUi.BlocksSelector.InstagramFlows',
                                              )}
                                              type="_separator"
                                            />
                                            <MenuItemEmpty
                                              title={i18next.t(
                                                'modernUi.BlocksSelector.NothingMatchesYourSearch',
                                              )}
                                            />
                                          </>
                                        )}
                                      {filteredItems.map(({ item, index }) => {
                                        const type = getItemType(item);
                                        const itemString = itemToString(item);

                                        const itemProps =
                                          type === 'item'
                                            ? getItemProps({
                                                index,
                                                item,
                                              })
                                            : {
                                                title: itemString,
                                              };

                                        const customItemProps =
                                          type === 'item'
                                            ? {
                                                active: isNil(highlightedIndex)
                                                  ? item === dsSelectedItem
                                                  : highlightedIndex === index,
                                                title: getTitle({
                                                  highlightOccurrence,
                                                  matchValue:
                                                    selectedItemString,
                                                  str: itemString,
                                                }),
                                                attrTitle: itemString,
                                              }
                                            : {};
                                        return (
                                          <MenuItem
                                            {...itemProps}
                                            {...customItemProps}
                                            key={getItemId(item)}
                                            tabIndex={index}
                                            type={type}
                                          />
                                        );
                                      })}
                                    </div>
                                  </ScrollBox>
                                )}
                              </div>
                            );
                          }}
                        </Popper>,
                      )}
                  </div>
                );
              }}
            </Downshift>
          </Manager>
        </div>
      </OutsideClick>
    );
  }
}

// deprecated
export default SuggestUi;
// deprecated
export const SuggestStringsUi = SuggestUi as new () => SuggestUi<string>;
