import ng from 'angular';
import { react2angular } from 'react2angular';
import DropdownUi from './Dropdown';

export const ngDropdownUi = ng
  .module('app.modernUi.dropdownUi', [])
  .component(
    'dropdownUi',
    react2angular(DropdownUi, ['items', 'selectedItem', 'onChange'], []),
  ).name;
