import ng from 'angular';
import { react2angular } from 'react2angular';
import React from 'react';
import { SectionHeader, ISectionHeaderProps } from './SectionHeader';

interface IModernHeaderProps extends ISectionHeaderProps {
  label: string;
}

const ModernSectionHeader: React.SFC<IModernHeaderProps> = ({
  label,
  intent = 'section',
}) => <SectionHeader intent={intent}>{label}</SectionHeader>;

export const ngSectionHeader = ng
  .module('app.components.modernSectionHeader', [])
  .component(
    'modernSectionHeader',
    react2angular(ModernSectionHeader, ['intent', 'label'], []),
  ).name;
