import React from 'react';
import cn from 'classnames';
import * as css from './MenuItem.css';

export interface IMenuItemProps {
  children: React.ReactNode;
  selected?: boolean;
}

export function MenuItem({
  children,
  selected = false,
  ...props
}: IMenuItemProps) {
  return (
    <div
      className={cn(css.menuItem, {
        [css.selected]: selected,
      })}
      {...props}
    >
      {children}
    </div>
  );
}
