import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { logIfDeprecatedPropsPassed } from '@utils/logDeprecated';
import { isUrlExternal, isAbsoluteCFDomainUrl } from '@utils/UrlUtils';
import { getComponentWithRefAndName } from '@utils/withRef';

import { ReactComponent as ExternalLinkIcon } from '@ui/Icon/icons/actions/External_Link.svg';

import { LocationState } from 'history';
import * as css from './Anchor.css';

enum AnchorIntents {
  external = 'external', // external links
  internal = 'internal', // inner links
  tooltip = 'tooltip', // tooltip
  subtitle = 'subtitle', // subtitles
  unstyled = 'unstyled', // no styling
}

enum AnchorSizes {
  small = 'small',
  regular = 'regular',
}

export interface AnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  intent?: keyof typeof AnchorIntents;
  size?: keyof typeof AnchorSizes;
  disabled?: boolean;
  hideArrow?: boolean;
  forceArrow?: boolean;
  noUnderline?: boolean;
  colorfulBg?: boolean;
  ref?: any;
  saveLocationState?: boolean;
  saveGetParams?: boolean;
  state?: LocationState;
  externalLinkClassName?: string;

  href?: string; // to make this props available inside of storybook
}

export const Anchor = getComponentWithRefAndName<
  HTMLAnchorElement,
  AnchorProps
>(
  'Anchor',
  (
    {
      className,
      externalLinkClassName,
      children,
      intent = AnchorIntents.unstyled,
      size,
      disabled,
      href = '',
      noUnderline,
      hideArrow,
      forceArrow,
      colorfulBg,
      saveLocationState,
      saveGetParams,
      state,
      ...props
    },
    ref,
  ) => {
    logIfDeprecatedPropsPassed('Anchor', ['size'], { size }, { href });

    const isExternal = isUrlExternal(href);
    const isSameDomainAbsolutePath = href && isAbsoluteCFDomainUrl(href);
    const isMailto = href?.includes('mailto:');

    const isUnstyled = intent === AnchorIntents.unstyled;
    const anchorProps = {
      className: cn(className, css[intent], {
        [css.anchor]: !isUnstyled,
        [css.disabled]: !isUnstyled && disabled,
        [css.noUnderline]: noUnderline,
        [css[size!]]: !!size,
        [css.colorfulBg]: colorfulBg,
      }),
      href,
      ...props,
    };

    // External
    if (forceArrow || isExternal || isSameDomainAbsolutePath || isMailto) {
      return (
        <a ref={ref} {...anchorProps}>
          {!props.dangerouslySetInnerHTML ? (
            <>
              {children}
              {(forceArrow ||
                ((isExternal || isSameDomainAbsolutePath) && !hideArrow)) && (
                <ExternalLinkIcon
                  className={cn(css.externalLinkIcon, externalLinkClassName, {
                    [css.externalLinkIcon_external]:
                      intent === AnchorIntents.external,
                    [css.externalLinkIcon_tooltip]:
                      intent === AnchorIntents.tooltip,
                    [css.externalLinkIcon_small]: size === AnchorSizes.small,
                    [css.externalLinkIcon_regular]:
                      size === AnchorSizes.regular,
                  })}
                />
              )}
            </>
          ) : undefined}
        </a>
      );
    }

    // Inner
    if (href) {
      const { pathname, search, hash } = new URL(href, document.baseURI);
      return (
        <Link
          to={({ state: currentState, search: currentSearch }) => {
            return {
              pathname,
              search: saveGetParams
                ? new URLSearchParams([
                    ...new URLSearchParams(search).entries(),
                    ...new URLSearchParams(currentSearch).entries(),
                  ]).toString()
                : search,
              hash,
              state: saveLocationState
                ? {
                    ...currentState,
                    ...state,
                  }
                : state,
            };
          }}
          innerRef={ref}
          {...anchorProps}
        >
          {!props.dangerouslySetInnerHTML ? children : undefined}
        </Link>
      );
    }

    // Visual
    return (
      <span ref={ref} {...anchorProps}>
        {!props.dangerouslySetInnerHTML ? children : undefined}
      </span>
    );
  },
);
