import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { isInclusivelyAfterDay } from 'react-dates';
import noop from 'lodash-es/noop';
import moment from 'moment';
import { Hover } from 'react-powerplug';
import useOnClickOutside from 'use-onclickoutside';
import React, { useEffect, useRef, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { DATE_PICKER_LOCALISATION_BY_SHORTCUT } from './constants';
import { getAutoSelectedRange, getDateByShortcut } from './helpers';
import { DatePickerShortcut, PickedDate, PickedRange } from '../types';
import {
  DateRangePicker,
  DateRangePickerProps,
  DateRangePickerApi,
} from '../DateRangePicker';
import { getRangeText } from '../utils/rangeTextFormat';
import * as css from './DateRangePickerField.css';
import * as datePickerCss from '../DatePicker.css';

export interface DateRangePickerFieldProps
  extends Pick<DateRangePickerProps, 'onCancel' | 'defaultValue'> {
  onShowMenu(): void;
  onChange(dates: PickedRange, range: DatePickerShortcut): void;
}

export const DateRangePickerField: React.FC<DateRangePickerFieldProps> = ({
  defaultValue,
  onCancel,
  onChange,
  onShowMenu,
}) => {
  const { t } = useSafeTranslation();
  const defaultRange = defaultValue ?? [null, null];
  const [dates, setDates] = useState<[PickedDate, PickedDate]>(defaultRange);
  const menuRef = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const pickerCompRef = useRef<DateRangePickerApi>(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const [pickerOpened, setPickerOpened] = useState(false);
  const [selectedShortcut, setSelectedShortcut] =
    useState<DatePickerShortcut | null>(getAutoSelectedRange(defaultRange));

  const closePicker = () => {
    setMenuOpened(false);
    setPickerOpened(false);
  };

  const updateDates = (
    dates: [PickedDate, PickedDate],
    range: DatePickerShortcut,
  ) => {
    setDates(dates);
    onChange(dates, range);
    setMenuOpened(false);
  };

  useEffect(() => {
    if (!menuOpened && selectedShortcut === DatePickerShortcut.custom) {
      setPickerOpened(false);
    }
  }, [selectedShortcut, menuOpened]);

  useOnClickOutside(containerRef, closePicker);

  const [startDate, endDate] = dates;
  return (
    <div ref={containerRef}>
      <SimpleCombobox<{ id: DatePickerShortcut }>
        downshiftContainerClassName={css.dateRangePickerCombobox}
        downshiftMenuRef={menuRef}
        position="bottom-start"
        isOpen={menuOpened}
        onChange={(item) => {
          if (item?.id && item.id !== selectedShortcut) {
            setSelectedShortcut(item.id);
            if (item.id !== DatePickerShortcut.custom) {
              updateDates(getDateByShortcut(item.id), item.id);
            }
          }
        }}
        renderInput={({ ref, getToggleButtonProps }) => (
          <Button
            {...getToggleButtonProps()}
            ref={ref}
            intent="secondary"
            iconRight={<Icon icon="triangle" />}
            data-testid="date-range-picker-field_platform-selector-button"
            onClick={() => {
              onShowMenu();
              setMenuOpened((prevState) => !prevState);
            }}
          >
            <Type size="15px_DEPRECATED" weight="medium">
              {getRangeText(startDate, endDate)}
            </Type>
          </Button>
        )}
        items={[
          { id: DatePickerShortcut.today },
          { id: DatePickerShortcut.yesterday },
          { id: DatePickerShortcut.thisWeek },
          { id: DatePickerShortcut.thisMonth },
          { id: DatePickerShortcut.last3Months },
          { id: DatePickerShortcut.custom },
        ]}
        renderItem={({ getItemProps, item, index, highlightedIndex }) => {
          const active = index === highlightedIndex;
          const selected = selectedShortcut === item.id;
          const commonProps = {
            'data-testid': `date-range-picker-field_${item.id}-button`,
            ...getItemProps({ item }),
            key: item.id,
            title: t(DATE_PICKER_LOCALISATION_BY_SHORTCUT[item.id]),
            active,
          };

          if (item.id === DatePickerShortcut.custom) {
            return (
              <MenuItem
                {...commonProps}
                onMouseEnter={() => {
                  setPickerOpened(true);
                }}
                onClick={noop}
                rightElement={
                  <Icon
                    icon="triangle"
                    color={active ? 'white' : 'black'}
                    className={datePickerCss.rightArrow}
                  />
                }
              />
            );
          }

          return (
            <MenuItem
              {...commonProps}
              onMouseEnter={() => {
                if (
                  selectedShortcut === DatePickerShortcut.custom &&
                  item.id !== DatePickerShortcut.custom
                ) {
                  setPickerOpened(false);
                }
              }}
              rightElement={
                selected && (
                  <Icon icon="check" color={active ? 'white' : 'blue'} />
                )
              }
            />
          );
        }}
      />
      {pickerOpened && (
        <div
          ref={pickerRef}
          style={{
            position: 'fixed',
            zIndex: 'var(--zindex-popover-next)' as any, // the same as combobox menu
            top: menuRef.current?.getBoundingClientRect().top!,
            left: menuRef.current?.getBoundingClientRect().right! + 4,
          }}
        >
          <DateRangePicker
            className={css.dateRangePicker}
            dateRangePickerRef={pickerCompRef}
            defaultValue={dates}
            onChange={(dates) => {
              const range = getAutoSelectedRange(dates);
              updateDates(dates, range);
              setSelectedShortcut(range);
              setPickerOpened(false);
            }}
            isOutsideRange={(day) =>
              isInclusivelyAfterDay(day, moment().add(1, 'day') as any)
            }
            onCancel={() => {
              onCancel?.();
              closePicker();
            }}
            renderMonthElement={({ month }: any) => {
              const baseStyle: React.CSSProperties = {
                padding: 4,
                position: 'relative',
                bottom: 4,
              };
              return (
                <Flex justifyContent="center">
                  <Hover>
                    {({ hovered, bind }) => (
                      <ButtonUnstyled
                        {...bind}
                        onClick={() => {
                          pickerCompRef.current?.setStartDate(
                            month.clone().startOf('month'),
                          );
                          const selectingSameMonth = month
                            .clone()
                            .isSame(moment(), 'month');
                          const endDate = selectingSameMonth
                            ? moment().endOf('day')
                            : month.clone().endOf('month');
                          pickerCompRef.current?.setEndDate(endDate);
                        }}
                        disabled={month.clone().isAfter(moment(), 'month')}
                        style={
                          hovered
                            ? {
                                ...baseStyle,
                                backgroundColor: 'var(--blue)',
                                borderRadius: 4,
                              }
                            : baseStyle
                        }
                      >
                        <Type
                          weight="medium"
                          color={hovered ? 'white' : 'black'}
                          size="15px_DEPRECATED"
                        >
                          {moment(month).format('MMMM YYYY')}
                        </Type>
                      </ButtonUnstyled>
                    )}
                  </Hover>
                </Flex>
              );
            }}
            withConfirmation
          />
        </div>
      )}
    </div>
  );
};
