/**
 * File for registering icons in set
 *
 * DO NOT FORGET TO MANUALLY UPDATE ICONS WITH currentColor
 */
import { PluginType } from '@components/Plugins/common/PluginTypes';

import { ReactComponent as TabsAI } from './tabs/AI.svg';
import { ReactComponent as TabsAnalyze } from './tabs/Analyze.svg';
import { ReactComponent as TabsAutomate } from './tabs/Automate.svg';
import { ReactComponent as TabsConfigure } from './tabs/Configure.svg';
import { ReactComponent as TabsHome } from './tabs/Home.svg';
import { ReactComponent as TabsFlows } from './tabs/Flows.svg';
import { ReactComponent as TabsLiveChat } from './tabs/LiveChat.svg';
import { ReactComponent as TabsPeople } from './tabs/People.svg';
import { ReactComponent as TabsReEngage } from './tabs/ReEngage.svg';
import { ReactComponent as TabsUpgrade } from './tabs/Upgrade.svg';
import { ReactComponent as MessagingTemplateIcon } from './tabs/MessagingTemplate.svg';

import { ReactComponent as EpFacebookShops } from './entryPoints/Facebook_shops.svg';
import { ReactComponent as EpAutoReply } from './entryPoints/Auto_reply.svg';
import { ReactComponent as EpCustomerChat } from './entryPoints/Customer_chat.svg';
import { ReactComponent as EpFbAdsCtm } from './entryPoints/Fb_ads_ctm.svg';
import { ReactComponent as EpInstagramAdsCtm } from './entryPoints/instagram_ads_ctm.svg';
import { ReactComponent as EpFbAdsSm } from './entryPoints/Fb_ads_sm.svg';
import { ReactComponent as EpGetStarted } from './entryPoints/Get_started.svg';
import { ReactComponent as EpMsgButton } from './entryPoints/Msg_button.svg';
import { ReactComponent as EpMsgCheckbox } from './entryPoints/Msg_checkbox.svg';
import { ReactComponent as EpPersistentMenu } from './entryPoints/Persistent_menu.svg';
import { ReactComponent as EpRef } from './entryPoints/Ref.svg';
import { ReactComponent as EpZapier } from './entryPoints/Zapier.svg';
import { ReactComponent as EpShopifyEvent } from './entryPoints/Abandoned_card.svg';
import { ReactComponent as EpShopifyEventFilled_filled } from './entryPoints/Abandoned_card_filled.svg';
import { ReactComponent as OrderConfirmation_filled } from './entryPoints/OrderConfirmation_filled.svg';
import { ReactComponent as ProductVisit_filled } from './entryPoints/ProductVisit_filled.svg';
import { ReactComponent as ShopifyBackInStock_filled } from './entryPoints/BackInStock_filled.svg';
import { ReactComponent as OrderStatusUpdate_filled } from './entryPoints/OrderStatusUpdate_filled.svg';
import { ReactComponent as EpPopup } from './entryPoints/Popup.svg';
import { ReactComponent as EpInstagramDirect } from './entryPoints/Instagram_direct.svg';
import { ReactComponent as EpInstagramStoryMention } from './entryPoints/Instagram_story_mention.svg';
import { ReactComponent as EpInstagramStoryReply } from './entryPoints/Instagram_story_reply.svg';

import { ReactComponent as EpFacebookShops_filled } from './entryPoints/Facebook_shops_filled.svg';
import { ReactComponent as EpAutoReply_filled } from './entryPoints/Auto_reply_filled.svg';
import { ReactComponent as EpCustomerChat_filled } from './entryPoints/Customer_chat_filled.svg';
import { ReactComponent as EpFbAdsCtm_filled } from './entryPoints/Fb_ads_ctm_filled.svg';
import { ReactComponent as EpInstagramAdsCtm_filled } from './entryPoints/instagram_ads_ctm_filled.svg';
import { ReactComponent as EpFbAdsSm_filled } from './entryPoints/Fb_ads_sm_filled.svg';
import { ReactComponent as EpGetStarted_filled } from './entryPoints/Get_started_filled.svg';
import { ReactComponent as EpMsgButton_filled } from './entryPoints/Msg_button_filled.svg';
import { ReactComponent as EpMsgCheckbox_filled } from './entryPoints/Msg_checkbox_filled.svg';
import { ReactComponent as EpPersistentMenu_filled } from './entryPoints/Persistent_menu_filled.svg';
import { ReactComponent as EpRef_filled } from './entryPoints/Ref_filled.svg';
import { ReactComponent as EpInstagramDirect_filled } from './entryPoints/Instagram_direct_filled.svg';
import { ReactComponent as EpInstagramStoryMention_filled } from './entryPoints/Instagram_story_mention_filled.svg';
import { ReactComponent as EpInstagramStoryReply_filled } from './entryPoints/Instagram_story_reply_filled.svg';

import { ReactComponent as EpFacebookShops_left_column } from './entryPoints/Facebook_shops_left_column.svg';
import { ReactComponent as EpAutoReply_left_column } from './entryPoints/Auto_reply_left_column.svg';
import { ReactComponent as EpCustomerChat_left_column } from './entryPoints/Customer_chat_left_column.svg';
import { ReactComponent as EpFbAdsCtm_left_column } from './entryPoints/Fb_ads_ctm_left_column.svg';
import { ReactComponent as EpInstagramAdsCtm_left_column } from './entryPoints/instagram_ads_ctm_left_column.svg';
import { ReactComponent as EpFbAdsSm_left_column } from './entryPoints/Fb_ads_sm_left_column.svg';
import { ReactComponent as EpGetStarted_left_column } from './entryPoints/Get_started_left_column.svg';
import { ReactComponent as EpMsgButton_left_column } from './entryPoints/Msg_button_left_column.svg';
import { ReactComponent as EpPersistentMenu_left_column } from './entryPoints/Persistent_menu_left_column.svg';
import { ReactComponent as EpRef_left_column } from './entryPoints/Ref_left_column.svg';
import { ReactComponent as EpZapier_left_column } from './entryPoints/Zapier_left_column.svg';
import { ReactComponent as EpInstagramDirect_left_column } from './entryPoints/Instagram_direct_left_column.svg';
import { ReactComponent as EpInstagramStoryMention_left_column } from './entryPoints/Instagram_story_mention_left_column.svg';
import { ReactComponent as EpInstagramStoryReply_left_column } from './entryPoints/Instagram_story_reply_left_column.svg';

import { ReactComponent as ArrowBackIcon } from './arrow-back.svg';
import { ReactComponent as BellIcon } from './bell.svg';
import { ReactComponent as BellPlus } from './bellPlus.svg';
import { ReactComponent as BellBadgeIcon } from './bell_badge.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Microphone } from './microphone.svg';
import { ReactComponent as ArrowRightIcon } from './actions/Arrow_right.svg';
import { ReactComponent as ArrowLeftIcon } from './actions/Arrow_left.svg';
import { ReactComponent as AssignIcon } from './actions/Assign.svg';
import { ReactComponent as AddImage } from './actions/Add_Image.svg';
import { ReactComponent as AttachmentIcon } from './actions/Attachment.svg';
import { ReactComponent as Attachment2Icon } from './actions/Attachment2.svg';
import { ReactComponent as AttributeIcon } from './actions/Attribute.svg';
import { ReactComponent as CheckIcon } from './actions/Check.svg';
import { ReactComponent as CheckInCircleIcon } from './actions/CheckInCircle.svg';
import { ReactComponent as CheckInCircle2Icon } from './actions/CheckInCircle2.svg';
import { ReactComponent as BadIcon } from './actions/Bad.svg';
import { ReactComponent as CloseIcon } from './actions/Close.svg';
import { ReactComponent as CopyIcon } from './actions/Copy.svg';
import { ReactComponent as CropIcon } from './actions/Crop.svg';
import { ReactComponent as DeleteIcon } from './actions/Delete.svg';
import { ReactComponent as DragIcon } from './actions/Drag.svg';
import { ReactComponent as DialogIcon } from './actions/Dialog.svg';
import { ReactComponent as EditIcon } from './actions/Edit.svg';
import { ReactComponent as EmojiIcon } from './actions/Emoji.svg';
import { ReactComponent as EmptyPhotoIcon } from './actions/Empty_Photo.svg';
import { ReactComponent as FilterIcon } from './actions/Filter.svg';
import { ReactComponent as FlowIcon } from './actions/Flow.svg';
import { ReactComponent as FolderIcon } from './actions/Folder.svg';
import { ReactComponent as GalleryLandscapeIcon } from './actions/Gallery_Landscape.svg';
import { ReactComponent as GallerySquareIcon } from './actions/Gallery_Square.svg';
import { ReactComponent as HeartIcon } from './actions/Heart.svg';
import { ReactComponent as HeartIcon_filled } from './actions/Heart_filled.svg';
import { ReactComponent as ImageIcon } from './actions/Image.svg';
import { ReactComponent as ImagePlaceholderIcon } from './image-placeholder.svg';
import { ReactComponent as VideoPlaceholderIcon } from './video-placeholder.svg';
import { ReactComponent as AudioPlaceholderIcon } from './audio-placeholder.svg';
import { ReactComponent as DocumentPlaceholderIcon } from './document-placeholder.svg';
import { ReactComponent as InboxActiveIcon } from './actions/Inbox_Active.svg';
import { ReactComponent as InboxAllIcon } from './actions/Inbox_All.svg';
import { ReactComponent as InboxDoneIcon } from './actions/Inbox_Done.svg';
import { ReactComponent as MessengerIcon } from './actions/Messenger.svg';
import { ReactComponent as ChatIcon } from './actions/Chat.svg';
import { ReactComponent as ChatSimpleIcon } from './actions/ChatSimple.svg';
import { ReactComponent as MinusIcon } from './actions/Minus.svg';
import { ReactComponent as MoreIcon } from './actions/More.svg';
import { ReactComponent as MoreSmallIcon } from './actions/More_small.svg';
import { ReactComponent as NoteIcon } from './actions/Note.svg';
import { ReactComponent as PauseIcon } from './actions/Pause.svg';
import { ReactComponent as TransferPlanIcon } from './actions/TransferPlan.svg';
import { ReactComponent as PhotoIcon } from './actions/Photo.svg';
import { ReactComponent as PlayIcon } from './actions/Play.svg';
import { ReactComponent as PlusIcon } from './actions/Plus.svg';
import { ReactComponent as ProcessIcon } from './actions/Process.svg';
import { ReactComponent as RestartIcon } from './actions/Restart.svg';
import { ReactComponent as SearchIcon } from './actions/Search.svg';
import { ReactComponent as SendIcon } from './actions/Send.svg';
import { ReactComponent as SharedIcon } from './actions/Shared.svg';
import { ReactComponent as SortIcon } from './actions/Sort.svg';
import { ReactComponent as TagIcon } from './actions/Tag.svg';
import { ReactComponent as TemplateIcon } from './actions/Template.svg';
import { ReactComponent as MagicIcon } from './actions/Magic.svg';
import { ReactComponent as TriangleIcon } from './actions/Triangle.svg';
import { ReactComponent as UndoIcon } from './actions/Undo.svg';
import { ReactComponent as VideoIcon } from './actions/Video.svg';
import { ReactComponent as Video2Icon } from './actions/Video2.svg';
import { ReactComponent as SubscribeIcon } from './actions/Subscribe.svg';
import { ReactComponent as UnsubscribeIcon } from './actions/Unsubscribe.svg';
import { ReactComponent as BagIcon } from './actions/Bag.svg';
import { ReactComponent as HamburgerIcon } from './actions/Hamburger.svg';
import { ReactComponent as DirectIcon } from './actions/Direct.svg';
import { ReactComponent as MessengerCircleIcon } from './actions/Messenger2.svg';
import { ReactComponent as ExpandIcon } from './actions/Expand.svg';
import { ReactComponent as UnexpandIcon } from './actions/Unexpand.svg';
import { ReactComponent as ThunderboltIcon } from './actions/Thunderbolt.svg';
import { ReactComponent as YoutubeIcon } from './actions/Youtube.svg';
import { ReactComponent as QuestionIcon } from './actions/Question.svg';
import { ReactComponent as HelpIcon } from './actions/Help.svg';
import { ReactComponent as BurgerIcon } from './actions/Burger.svg';
import { ReactComponent as ConnectBlock } from './actions/Connect_Block.svg';
import { ReactComponent as LeftArrow } from './actions/Left_Arrow.svg';
import { ReactComponent as RightArrow } from './actions/Right_Arrow.svg';
import { ReactComponent as RedirectIcon } from './actions/Redirect.svg';
import { ReactComponent as SettingsIcon } from './actions/Settings.svg';
import { ReactComponent as DownloadIcon } from './actions/Download.svg';
import { ReactComponent as DownloadIcon2 } from './actions/Download2.svg';
import { ReactComponent as OpenIcon } from './actions/Open.svg';
import { ReactComponent as CopyBotLink } from './actions/CopyBotLink.svg';
import { ReactComponent as VisiblePasswordOn } from './actions/Visible_Password_On.svg';
import { ReactComponent as VisiblePasswordOff } from './actions/Visible_Password_Off.svg';
import { ReactComponent as RefreshIcon } from './actions/Refresh.svg';
import { ReactComponent as RefreshIcon2 } from './actions/Refresh2.svg';
import { ReactComponent as LogOutIcon } from './actions/Log_out.svg';
import { ReactComponent as PlusInCircle } from './actions/PlusInCircle.svg';
import { ReactComponent as StarIcon } from './actions/Star.svg';
import { ReactComponent as ChevronUp } from './actions/ChevronUp.svg';
import { ReactComponent as ChevronDown } from './actions/ChevronDown.svg';
import { ReactComponent as Phone } from './actions/Phone.svg';
import { ReactComponent as ListView } from './actions/ListView.svg';
import { ReactComponent as LinkIcon } from './actions/Link.svg';
import { ReactComponent as CallIcon } from './actions/Call.svg';
import { ReactComponent as AiIcon } from './ai.svg';

import { ReactComponent as InfoIcon } from './alerts/Info.svg';
import { ReactComponent as WarningIcon } from './alerts/Warning.svg';
import { ReactComponent as WarningRedIcon } from './alerts/WarningRed.svg';
import { ReactComponent as WarningCircle } from './alerts/CircleWarning.svg';
import { ReactComponent as ExternalLinkIcon } from './actions/External_Link.svg';
import { ReactComponent as TriangleAlertIcon } from './alerts/Triangle.svg';
import { ReactComponent as Checked_Folder } from './actions/Checked_Folder.svg';
import { ReactComponent as ActiveCircle } from './actions/ActiveCircle.svg';
import { ReactComponent as ActiveCircle2 } from './actions/ActiveCircle2.svg';
import { ReactComponent as InactiveCircle } from './actions/InactiveCircle.svg';
import { ReactComponent as Disconnect } from './actions/Disconnect.svg';
import { ReactComponent as Duplicate } from './actions/Duplicate.svg';
import { ReactComponent as Sync } from './actions/Sync.svg';

import { ReactComponent as GoogleIcon } from './social/Google.svg';
import { ReactComponent as MetaCircleIcon } from './social/MetaCircle.svg';
import { ReactComponent as InstagramIcon } from './social/Instagram.svg';
import { ReactComponent as InstagramFilledIcon } from './social/Instagram_filled.svg';
import { ReactComponent as InstagramFillableIcon } from './social/Instagram_fillable.svg';
import { ReactComponent as FacebookIcon } from './social/Facebook.svg';
import { ReactComponent as FacebookFillableIcon } from './social/Facebook_fillable.svg';
import { ReactComponent as FacebookFilledIcon } from './social/Facebook_filled.svg';
import { ReactComponent as FacebookButtonIcon } from './social/FacebookButton.svg';
import { ReactComponent as FacebookLogoInv } from './social/FacebookLogoInv.svg';
import { ReactComponent as MessengerLogoIcon } from './social/Messenger.svg';
import { ReactComponent as IntercomLogoIcon } from './social/Intercom.svg';
import { ReactComponent as WebsiteLogo } from './social/Website.svg';
import { ReactComponent as ShopifyLogo } from './social/Shopify.svg';
import { ReactComponent as ShopifyBagColorfulIcon } from './social/ShopifyBagColorful.svg';
import { ReactComponent as ShopifyBagIcon } from './social/ShopifyBag.svg';
import { ReactComponent as DirectLogoIcon } from './social/Direct.svg';
import { ReactComponent as WhatsappLogo } from './social/Whatsapp.svg';
import { ReactComponent as WhatsappRectangleIcon } from './social/WhatsappRectangle.svg';
import { ReactComponent as WhatsappBotIcon } from './social/WhatsappBot.svg';
import { ReactComponent as FbIgBotIcon } from './social/FbIgBot.svg';
import { ReactComponent as NewWebsite } from './social/NewWebsite.svg';
import { ReactComponent as SentIcon } from './livechat/sent.svg';
import { ReactComponent as DeliveredIcon } from './livechat/delivered.svg';
import { ReactComponent as ClockIcon } from './livechat/clock.svg';
import { ReactComponent as LocationIcon } from './location.svg';

import { ReactComponent as BlocksChannel } from './channels/Blocks.svg';

import { ReactComponent as StrikethroughIcon } from './editor/strikethrough.svg';
import { ReactComponent as BoldIcon } from './editor/bold.svg';
import { ReactComponent as ItalicIcon } from './editor/italic.svg';

import { ReactComponent as TextIcon } from './text.svg';
import { ReactComponent as ClipIcon } from './clip.svg';
import { ReactComponent as ReplyIcon } from './reply.svg';

export const Icons = {
  tabs_home: TabsHome,
  tabs_flows: TabsFlows,
  tabs_automate: TabsAutomate,
  tabs_liveChat: TabsLiveChat,
  tabs_aiTab: TabsAI,
  tabs_people: TabsPeople,
  tabs_reEngage: TabsReEngage,
  tabs_configure: TabsConfigure,
  tabs_analyze: TabsAnalyze,
  tabs_upgrade: TabsUpgrade,

  // toolbar_question_mark,
  // toolbar_add_flow,
  // toolbar_align_flow,
  // toolbar_fitScreen,
  // toolbar_gridView,
  // toolbar_listView,
  //
  // plugins_sendContent,
  // plugins_action,
  // plugins_condition,
  // plugins_delay,
  // plugins_redirect,
  //
  // plugins_text,
  // plugins_typing,
  // plugins_image,
  // plugins_gallery,
  // plugins_video,
  // plugins_audio,
  // plugins_quickReply,
  // plugins_email,
  // plugins_phone,
  // plugins_userInput,
  // plugins_userAttribute,
  // plugins_otn,
  // plugins_note,
  //
  // plugins_link,
  // plugins_call,
  // plugins_addTag,
  // plugins_removeTag,
  // plugins_addAttribute,
  // plugins_removeAttribute,
  // plugins_analytics,
  // plugins_json,
  // plugins_gSheets,
  // plugins_zapier,
  // plugins_sendEmail,
  // plugins_liveChat,
  // plugins_handover,

  [PluginType.ref_link_entry_point]: EpRef,
  [PluginType.send_to_messenger_entry_point]: EpMsgButton,
  [PluginType.customer_chat_entry_point]: EpCustomerChat,
  [PluginType.shopify_customer_chat_entry_point]: EpCustomerChat,
  [PluginType.persistent_menu_entry_point]: EpPersistentMenu,
  [PluginType.instagram_persistent_menu_entry_point]: EpPersistentMenu,
  [PluginType.fb_page_entry_point]: EpGetStarted,
  ep_stmCheckbox: EpMsgCheckbox,
  [PluginType.ads_manager_ctm_entry_point]: EpFbAdsCtm,
  [PluginType.instagram_ads_manager_ctm_entry_point]: EpInstagramAdsCtm,
  [PluginType.ads_manager_sm_entry_point]: EpFbAdsSm,
  [PluginType.comments_autoreply_entry_point]: EpAutoReply,
  [PluginType.facebook_shops_entry_point]: EpFacebookShops,
  [PluginType.external_integration_entry_point]: EpZapier,
  [PluginType.shopify_event_entry_point]: EpShopifyEvent,
  [`${PluginType.order_confirmation_shortcut}`]: OrderConfirmation_filled,
  [`${PluginType.product_visit_shortcut}`]: ProductVisit_filled,
  [`${PluginType.order_status_update_shortcut}`]: OrderStatusUpdate_filled,
  [PluginType.popup_entry_point]: EpPopup,
  [PluginType.instagram_direct_entry_point]: EpInstagramDirect,
  [PluginType.instagram_comments_autoreply_entry_point]: EpAutoReply,
  [PluginType.instagram_story_mention_entry_point]: EpInstagramStoryMention,
  [PluginType.instagram_story_reply_entry_point]: EpInstagramStoryReply,

  [`${PluginType.ref_link_entry_point}_filled`]: EpRef_filled,
  [`${PluginType.send_to_messenger_entry_point}_filled`]: EpMsgButton_filled,
  [`${PluginType.customer_chat_entry_point}_filled`]: EpCustomerChat_filled,
  [`${PluginType.shopify_customer_chat_entry_point}_filled`]:
    EpCustomerChat_filled,
  [`${PluginType.persistent_menu_entry_point}_filled`]: EpPersistentMenu_filled,
  [`${PluginType.instagram_persistent_menu_entry_point}_filled`]:
    EpPersistentMenu_filled,
  [`${PluginType.fb_page_entry_point}_filled`]: EpGetStarted_filled,
  ep_stmCheckbox_filled: EpMsgCheckbox_filled,
  [`${PluginType.ads_manager_ctm_entry_point}_filled`]: EpFbAdsCtm_filled,
  [`${PluginType.instagram_ads_manager_ctm_entry_point}_filled`]:
    EpInstagramAdsCtm_filled,
  [`${PluginType.ads_manager_sm_entry_point}_filled`]: EpFbAdsSm_filled,
  [`${PluginType.comments_autoreply_entry_point}_filled`]: EpAutoReply_filled,
  [`${PluginType.facebook_shops_entry_point}_filled`]: EpFacebookShops_filled,
  [`${PluginType.external_integration_entry_point}_filled`]: EpZapier,
  [`${PluginType.instagram_direct_entry_point}_filled`]:
    EpInstagramDirect_filled,
  [`${PluginType.popup_entry_point}_filled`]: EpPopup,
  [`${PluginType.shopify_event_entry_point}_filled`]:
    EpShopifyEventFilled_filled,
  [`${PluginType.order_confirmation_shortcut}_filled`]:
    OrderConfirmation_filled,
  [`${PluginType.product_visit_shortcut}_filled`]: ProductVisit_filled,
  [`${PluginType.shopify_back_in_stock}_filled`]: ShopifyBackInStock_filled,
  [`${PluginType.order_status_update_shortcut}_filled`]:
    OrderStatusUpdate_filled,
  [`${PluginType.instagram_comments_autoreply_entry_point}_filled`]:
    EpAutoReply_filled,
  [`${PluginType.instagram_story_mention_entry_point}_filled`]:
    EpInstagramStoryMention_filled,
  [`${PluginType.instagram_story_reply_entry_point}_filled`]:
    EpInstagramStoryReply_filled,

  [`${PluginType.ref_link_entry_point}_left_column`]: EpRef_left_column,
  [`${PluginType.send_to_messenger_entry_point}_left_column`]:
    EpMsgButton_left_column,
  [`${PluginType.customer_chat_entry_point}_left_column`]:
    EpCustomerChat_left_column,
  [`${PluginType.shopify_customer_chat_entry_point}_left_column`]:
    EpCustomerChat_left_column,
  [`${PluginType.persistent_menu_entry_point}_left_column`]:
    EpPersistentMenu_left_column,
  [`${PluginType.fb_page_entry_point}_left_column`]: EpGetStarted_left_column,
  [`${PluginType.ads_manager_ctm_entry_point}_left_column`]:
    EpFbAdsCtm_left_column,
  [`${PluginType.ads_manager_ctm_json_entry_point}_left_column`]:
    EpFbAdsCtm_left_column,
  [`${PluginType.instagram_ads_manager_ctm_entry_point}_left_column`]:
    EpInstagramAdsCtm_left_column,
  [`${PluginType.ads_manager_sm_entry_point}_left_column`]:
    EpFbAdsSm_left_column,
  [`${PluginType.comments_autoreply_entry_point}_left_column`]:
    EpAutoReply_left_column,
  [`${PluginType.facebook_shops_entry_point}_left_column`]:
    EpFacebookShops_left_column,
  [`${PluginType.external_integration_entry_point}_left_column`]:
    EpZapier_left_column,
  [`${PluginType.popup_entry_point}_left_column`]: EpPopup,
  [`${PluginType.shopify_event_entry_point}_left_column`]: EpShopifyEvent,
  [`${PluginType.shopify_back_in_stock}_left_column`]:
    ShopifyBackInStock_filled,
  [`${PluginType.instagram_direct_entry_point}_left_column`]:
    EpInstagramDirect_left_column,
  [`${PluginType.instagram_comments_autoreply_entry_point}_left_column`]:
    EpAutoReply_left_column,
  [`${PluginType.instagram_story_mention_entry_point}_left_column`]:
    EpInstagramStoryMention_left_column,
  [`${PluginType.instagram_story_reply_entry_point}_left_column`]:
    EpInstagramStoryReply_left_column,

  messenger: MessengerIcon,
  chat: ChatIcon,
  chatSimple: ChatSimpleIcon,
  plus: PlusIcon,
  minus: MinusIcon,
  close: CloseIcon,
  check: CheckIcon,
  check_small: CheckIcon,
  check_in_circle: CheckInCircleIcon,
  check_in_circle2: CheckInCircle2Icon,
  bad: BadIcon,
  undo: UndoIcon,
  search: SearchIcon,
  more: MoreIcon,
  more_small: MoreSmallIcon,
  drag: DragIcon,
  copy: CopyIcon,
  delete: DeleteIcon,
  edit: EditIcon,
  flow: FlowIcon,
  filter: FilterIcon,
  folder: FolderIcon,
  sort: SortIcon,
  send: SendIcon,
  play: PlayIcon,
  pause: PauseIcon,
  transferPlan: TransferPlanIcon,
  restart: RestartIcon,
  process: ProcessIcon,
  emoji: EmojiIcon,
  crop: CropIcon,
  /**
   * @deprecated do not use it in new code (it has wrong margins), use `help` isntead
   */
  question: QuestionIcon,
  help: HelpIcon,
  photo: PhotoIcon,
  bell: BellIcon,
  bell_badge: BellBadgeIcon,
  gallerySquare: GallerySquareIcon,
  galleryLandscape: GalleryLandscapeIcon,
  attribute: AttributeIcon,
  tag: TagIcon,
  template: TemplateIcon,
  messagingTempalte: MessagingTemplateIcon,
  magic: MagicIcon,
  video: VideoIcon,
  videoFill: Video2Icon,
  note: NoteIcon,
  emptyPhoto: EmptyPhotoIcon,
  triangle: TriangleIcon,
  shared: SharedIcon,
  subscribe: SubscribeIcon,
  unsubscribe: UnsubscribeIcon,
  expand: ExpandIcon,
  unexpand: UnexpandIcon,
  thunderbolt: ThunderboltIcon,
  youtube: YoutubeIcon,
  info: InfoIcon,
  warning: WarningIcon,
  warningRed: WarningRedIcon,
  circleWarning: WarningCircle,
  externalLink: ExternalLinkIcon,
  triangleAlert: TriangleAlertIcon,
  bag: BagIcon,
  assign: AssignIcon,
  attachment: AttachmentIcon,
  attachment2: Attachment2Icon,
  inboxActive: InboxActiveIcon,
  inboxAll: InboxAllIcon,
  inboxDone: InboxDoneIcon,
  dialog: DialogIcon,
  arrow_right: ArrowRightIcon,
  arrow_left: ArrowLeftIcon,
  arrowBack: ArrowBackIcon,
  checkedFolder: Checked_Folder,
  heart: HeartIcon,
  heart_filled: HeartIcon_filled,
  hamburger: HamburgerIcon,
  direct: DirectIcon,
  messengerCircle: MessengerCircleIcon,
  burger: BurgerIcon,
  activeCircle: ActiveCircle,
  activeCircle2: ActiveCircle2,
  inactiveCircle: InactiveCircle,
  addImage: AddImage,
  settings: SettingsIcon,
  refresh: RefreshIcon,
  refresh2: RefreshIcon2,
  image: ImageIcon,
  imagePlaceholder: ImagePlaceholderIcon,
  documentPlaceholder: DocumentPlaceholderIcon,
  audioPlaceholder: AudioPlaceholderIcon,
  videoPlaceholder: VideoPlaceholderIcon,
  instagram: InstagramIcon,
  instagramFilled: InstagramFilledIcon,
  instagramFillable: InstagramFillableIcon,
  facebookFilled: FacebookFilledIcon,
  facebookFillable: FacebookFillableIcon,
  facebook: FacebookIcon,
  connectBlock: ConnectBlock,
  rightArrow: RightArrow,
  leftArrow: LeftArrow,
  redirect: RedirectIcon,
  disconnect: Disconnect,
  download: DownloadIcon,
  download2: DownloadIcon2,
  open: OpenIcon,
  logOut: LogOutIcon,
  star: StarIcon,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  phone: Phone,
  listView: ListView,
  call: CallIcon,
  link: LinkIcon,
  ai: AiIcon,

  whatsapp: WhatsappRectangleIcon,
  fbIgBot: FbIgBotIcon,
  whatsappBot: WhatsappBotIcon,
  newWebsite: NewWebsite,

  google: GoogleIcon,
  metaCircle: MetaCircleIcon,
  messengerLogo: MessengerLogoIcon,
  directLogo: DirectLogoIcon,
  websiteLogo: WebsiteLogo,
  shopifyLogo: ShopifyLogo,
  shopifyBagColorful: ShopifyBagColorfulIcon,
  shopifyBag: ShopifyBagIcon,
  whatsappLogo: WhatsappLogo,
  facebookLogo: FacebookButtonIcon,
  facebookLogoInv: FacebookLogoInv,
  intercomLogo: IntercomLogoIcon,
  duplicate: Duplicate,
  sync: Sync,
  copyBotLink: CopyBotLink,
  blocksChannel: BlocksChannel,
  flowChannel: TabsFlows,
  reengageChannel: TabsReEngage,
  visiblePasswordOn: VisiblePasswordOn,
  visiblePasswordOff: VisiblePasswordOff,
  plusInCircle: PlusInCircle,
  bellPlus: BellPlus,
  calendar: Calendar,
  microphone: Microphone,

  sent: SentIcon,
  delivered: DeliveredIcon,
  clock: ClockIcon,
  location: LocationIcon,

  italic: ItalicIcon,
  bold: BoldIcon,
  strikethrough: StrikethroughIcon,

  text: TextIcon,
  clip: ClipIcon,
  reply: ReplyIcon,
};

export type IconNames = keyof typeof Icons;
