import React from 'react';
import cn from 'classnames';

import {
  Button,
  ButtonIntent,
  ButtonProps,
  ButtonSize,
} from '@ui/_deprecated/Button';
import { Loader } from '@ui/Loader';
import { Type } from '@ui/Type';

import * as css from './Dialog.css';
import { Icon } from '../Icon';

const DialogComponent: React.FC<React.HTMLProps<HTMLDivElement>> = (
  props,
  ref,
) => (
  <div
    {...props}
    data-testid="dialog"
    className={cn(css.dialog, props.className)}
    ref={ref}
  />
);

export const Dialog = React.forwardRef(DialogComponent);

export const DialogContent: React.FC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => (
  <div
    {...props}
    data-testid="dialog__content"
    className={cn(css.dialogContent, props.className)}
  />
);

export const DialogHeading: React.FC<React.HTMLProps<HTMLHeadingElement>> = ({
  children,
  ...props
}) => (
  <div
    {...props}
    data-testid="dialog__header"
    className={cn(css.dialogHeading, props.className)}
  >
    <Type as="h3" color="black" size="24px">
      {children}
    </Type>
  </div>
);

export const DialogDivider: React.FC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => (
  <div
    {...props}
    data-testid="dialog__divider"
    className={cn(css.dialogDivider, props.className)}
  />
);

export const DialogLoader: React.FC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => (
  <div
    {...props}
    data-testid="dialog__loader"
    className={cn(css.dialogLoader, props.className)}
  >
    <Loader />
    {props.children}
  </div>
);

export const DialogFooter: React.SFC<
  React.HTMLProps<HTMLDivElement> & { isAdaptive?: boolean }
> = ({ isAdaptive = false, className, ...props }) => {
  return (
    <div
      data-testid="dialog__footer"
      {...props}
      className={cn(
        isAdaptive ? css.dialogFooterAdaptive : css.dialogFooter,
        className,
      )}
    />
  );
};

export const DialogCloseButton: React.FC<ButtonProps> = (props) => (
  <Button
    intent={ButtonIntent.toggle}
    data-testid="dialog__close-button"
    {...props}
    className={cn(
      css.dialogCloseButton,
      props.className,
      'test-close-dialog-button',
    )}
    size={ButtonSize.s}
    renderIcon={() => <Icon icon="close" noColor />}
    tall
  />
);
