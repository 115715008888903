import React from 'react';
import cn from 'classnames';
import * as css from './CheckBox.css';
import { ReactComponent as Checked } from './images/checked.svg';

export enum CheckboxTheme {
  dark = 'dark',
}

export interface CheckBoxProps extends React.HTMLProps<HTMLInputElement> {
  theme?: CheckboxTheme;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  className,
  theme,
  ...props
}) => {
  const { checked, disabled } = props;
  return (
    <div className={cn(css.checkBox, className)}>
      {!disabled && (
        <input {...props} type="checkbox" className={css.checkBoxInput} />
      )}
      <div
        className={cn(
          css.decorator,
          {
            [css.checked]: checked,
            [css.disabled]: disabled,
            [css.disabledChecked]: checked && disabled,
          },
          theme ? css[theme] : undefined,
        )}
      >
        {checked && <Checked className={css.icon} />}
      </div>
    </div>
  );
};
