import { Modifiers } from 'popper.js';
import { identity, propOr } from 'ramda';
import React from 'react';
import { Menubox } from '../../Menu';
import { ComboboxBase, ComboboxBaseProps } from './ComboboxBase';
import { RenderMenuProps } from './types';

export interface MenuComboboxBaseProps<T>
  extends ComboboxBaseProps<T>,
    RenderMenuProps<T> {
  autoSelectFirstItem?: boolean;
  menuBoxClassName?: string;
  mapPopperModifiers?: (defaultModifiers: Modifiers) => Modifiers;
}

// TODO move to constants / utils
const DEFAULT_MODIFIERS: Modifiers = {
  offset: {
    offset: '0, 4',
  },
};

export const defaultItemToString = propOr<string>('', 'title');

export function MenuComboboxBase<T>({
  children,
  renderFooterItem,
  renderMenuHead,
  autoSelectFirstItem,
  menuBoxClassName,
  mapPopperModifiers = identity,
  itemToString = defaultItemToString,
  ...props
}: MenuComboboxBaseProps<T>) {
  return (
    <ComboboxBase
      placement="bottom-start"
      modifiers={mapPopperModifiers(DEFAULT_MODIFIERS)}
      itemToString={itemToString}
      {...props}
    >
      {({ popper, downshift }) => {
        const { isOpen, highlightedIndex, setHighlightedIndex } = downshift;

        if (autoSelectFirstItem && isOpen && highlightedIndex === null) {
          setHighlightedIndex(0);
        }

        return (
          <Menubox
            ref={popper.ref}
            style={popper.style}
            className={menuBoxClassName}
          >
            {renderMenuHead?.({ popper, downshift })}
            {children({ popper, downshift })}
            {renderFooterItem?.({ popper, downshift })}
          </Menubox>
        );
      }}
    </ComboboxBase>
  );
}
