import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { Icon } from '@ui/Icon';

import * as css from './MenuItem.css';
import { Tooltip2, Position, TooltipType, TooltipPureProps } from '../Tooltip2';
import { combineRefs } from '@utils/combineRefs';
import { MENU_Z_INDEX } from '@components/FlowBuilder/FlowBuilderOverlay/overlays/Menu/consts';

export type MenuItemType =
  | 'group'
  | 'item'
  | 'divider'
  | 'title'
  | 'button'
  | 'node'
  | '_separator'; // _separator is deprecated type

export interface MenuItemProps
  extends React.HTMLProps<HTMLDivElement>,
    TestLocator {
  title?: string;
  titleElement?: React.ReactNode;
  subtitleElement?: React.ReactNode;
  subtitle?: string;
  comment?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  wrapContent?: boolean;
  type?: MenuItemType;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  innerRef?: React.Ref<HTMLDivElement>;
  attrTitle?: string;
  tooltip?: React.ReactNode;
  placement?: Position;
  tooltipType?: TooltipType;
  tooltipBoundariesElement?: TooltipPureProps['boundariesElement'];
  titleStyle?: CSSProperties;
  rightMenuElement?: React.ReactNode;
}

const RENDER_SENSITIVE_PROPS: (keyof MenuItemProps)[] = [
  'id',
  'role',
  'aria-selected',
  'active',
  'title',
  'tabIndex',
  'type',
  'attrTitle',
  'leftElement',
  'rightElement',
  'rightMenuElement',
  'tooltip',
  'titleElement',
];

const propsAreEqual = (prevProps: MenuItemProps, nextProps: MenuItemProps) =>
  RENDER_SENSITIVE_PROPS.every((key) => prevProps[key] === nextProps[key]);

const TOOLTIP_Z_INDEX = MENU_Z_INDEX + 1;

export const MenuItem: React.FC<MenuItemProps> = React.memo(
  ({
    title = '',
    titleElement,
    subtitle = '',
    subtitleElement,
    active = false,
    disabled = false,
    wrapContent = false,
    type = 'item',
    leftElement,
    rightElement,
    comment,
    innerRef,
    attrTitle,
    tooltip,
    tooltipBoundariesElement,
    placement,
    tooltipType,
    titleStyle,
    onMouseEnter,
    onMouseLeave,
    rightMenuElement,
    ...props
  }) => {
    const isItem = type === 'item';
    const isSeparator = type === '_separator';
    const isDivider = type === 'divider';
    const isTitle = type === 'title';
    const isButton = type === 'button';
    const isNode = type === 'node';

    return (
      <Tooltip2
        hoverable
        boundariesElement={tooltipBoundariesElement}
        content={tooltip}
        type={tooltipType}
        placement={placement || 'right'}
        disabled={!tooltip}
        style={{ zIndex: TOOLTIP_Z_INDEX }}
      >
        {(ref, bind) => (
          <div
            data-testid={props['data-testid'] || 'menu-item'}
            {...props}
            {...bind}
            data-active={(isItem || isNode) && active}
            style={
              isNode || rightMenuElement
                ? {
                    display: 'flex',
                    justifyContent: 'space-between',
                    ...props.style,
                  }
                : props.style
            }
            className={cn(
              {
                [css.wrapper]: isItem || isTitle || isButton || isNode,
                [css.active]: (isItem || isNode) && active,
                [css.group]: !isItem && !isTitle && !isButton && !isNode,
                [css.separator]: isSeparator,
                [css.disabled]: disabled,
                [css.divider]: isDivider,
                [css.blockTitle]: isTitle,
                [css.button]: isButton,
              },
              props.className,
              'test-menu-item',
            )}
            ref={combineRefs<HTMLDivElement>([ref, innerRef])}
            title={tooltip ? attrTitle : attrTitle || title}
            onMouseEnter={(event) => {
              onMouseEnter?.(event);
              bind.onMouseEnter?.();
            }}
            onMouseLeave={(event) => {
              onMouseLeave?.(event);
              bind.onMouseLeave?.();
            }}
          >
            <div
              className={cn({
                [css.makeup]: isItem || isTitle || isButton || isNode,
                [css.wrap]: wrapContent,
              })}
            >
              {leftElement && (
                <div
                  className={cn(css.leftElement, { [css.disabled]: disabled })}
                >
                  {leftElement}
                </div>
              )}
              {!isDivider && (
                <div
                  className={cn(css.title, {
                    [css.forRightElement]: rightElement,
                  })}
                  style={titleStyle}
                >
                  {titleElement || title}
                </div>
              )}
              {isItem && (
                <div className={css.subtitle}>
                  {subtitleElement || subtitle}
                </div>
              )}
              {isItem && comment && (
                <div className={css.comment}>{comment}</div>
              )}
              {rightElement && (
                <div className={css.rightElement}>{rightElement}</div>
              )}
            </div>
            {isNode && (
              <Icon
                size="20px"
                icon="triangle"
                style={{
                  transform: 'rotate(-90deg) translate(0px, 10px)',
                  color: active ? 'white' : 'black',
                }}
              />
            )}
            {rightMenuElement && <div>{rightMenuElement}</div>}
          </div>
        )}
      </Tooltip2>
    );
  },
  propsAreEqual,
);
