import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as css from './CollapsibleSection.css';

export interface AnchorParams {
  extended: boolean;
  changeExtended: () => void;
}

interface CollapsibleSectionProps {
  anchor: (params: AnchorParams) => React.ReactNode;
  initialState?: boolean; // deprecated
  defaultExtended?: boolean;
  animationDuration?: number;
  disableAnimation?: boolean;
  unmountHiddenSection?: boolean;
  controllable?: boolean;
  ignoreTransitionClasses?: boolean;
  children: (params: {
    extended: boolean;
    bind: { ref: React.MutableRefObject<HTMLDivElement | null> };
  }) => React.ReactNode;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  anchor,
  defaultExtended,
  animationDuration = 300,
  disableAnimation,
  unmountHiddenSection = true,
  controllable,
  children,
  ignoreTransitionClasses,
  ...props
}) => {
  // props.initialState is deprecated prop
  const [extended, setExtended] = useState(
    defaultExtended || props.initialState,
  );
  const [hidden, setHidden] = useState(!defaultExtended);
  const [show, setShow] = useState(defaultExtended);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const sectionInnerRef = useRef<HTMLDivElement | null>(null);
  const setShowTimeoutRef = useRef(setTimeout(() => null, 0));
  const setCollapsedTimeoutRef = useRef(setTimeout(() => null, 0));

  useEffect(() => {
    if (!controllable) {
      return;
    }

    if (extended !== defaultExtended) {
      setExtended(defaultExtended);
    }
  }, [controllable, extended, setExtended, defaultExtended]);

  useEffect(() => {
    if (extended) {
      setShowTimeoutRef.current = setTimeout(
        () => setShow(true),
        animationDuration,
      );
      setHidden(false);
    } else {
      setCollapsedTimeoutRef.current = setTimeout(
        () => setHidden(true),
        animationDuration,
      );
      setShow(false);
    }
    return () => {
      clearTimeout(setCollapsedTimeoutRef.current);
      clearTimeout(setShowTimeoutRef.current);
    };
  }, [extended, animationDuration]);

  useEffect(() => {
    if (extended && !show) {
      if (sectionRef.current) {
        sectionRef.current.style.maxHeight = '0';
        setTimeout(() => {
          if (sectionRef.current && sectionInnerRef.current) {
            sectionRef.current.style.maxHeight = `${sectionInnerRef.current.clientHeight}px`;
          }
        }, 20);
        setTimeout(() => {
          if (sectionRef.current) {
            sectionRef.current.style.removeProperty('max-height');
          }
        }, 350);
      }
    }
  }, [extended, show]);

  useEffect(() => {
    if (!extended && !hidden) {
      if (sectionRef.current && sectionInnerRef.current) {
        sectionRef.current.style.maxHeight = `${sectionInnerRef.current.clientHeight}px`;
        setTimeout(() => {
          if (sectionRef.current) {
            sectionRef.current.style.maxHeight = '0';
          }
        }, 20);
        setTimeout(() => {
          if (sectionRef.current) {
            sectionRef.current.style.removeProperty('max-height');
          }
        }, 350);
      }
    }
  }, [extended, hidden]);

  return (
    <>
      {anchor({
        changeExtended: () => setExtended(!extended),
        extended: !!extended,
      })}
      <div
        className={cn(css.section, {
          [css.extending]: !ignoreTransitionClasses && extended && !show,
          [css.collapsing]: !ignoreTransitionClasses && !extended && !hidden,
          [css.collapsed]: hidden,
        })}
        ref={sectionRef}
        style={{
          transitionDuration: `${animationDuration}ms`,
        }}
      >
        {!(hidden && unmountHiddenSection) &&
          children({
            extended: !!extended,
            bind: { ref: sectionInnerRef },
          })}
      </div>
    </>
  );
};
