import React from 'react';
import cx from 'classnames';
import * as css from './ServiceMessage.css';
import { Icon } from '../Icon';
import { Spacer } from '../Spacer';

export enum ServiceMessageType {
  default = 'default',
  error = 'error',
}

export enum ServiceMessageIntent {
  popup = 'popup',
  onpage = 'onpage',
}

interface IServiceMessageProps extends React.HTMLProps<HTMLDivElement> {
  renderContentEnd?: () => React.ReactNode;
  type?: ServiceMessageType;
  intent?: ServiceMessageIntent;
  hideErrorIcon?: Boolean;
}

export const ServiceMessage: React.SFC<IServiceMessageProps> = ({
  renderContentEnd,
  type,
  children,
  intent = ServiceMessageIntent.popup,
  hideErrorIcon = false,
  ...props
}) => {
  const hasExtraContent = Boolean(renderContentEnd);
  const fullWidth = intent === ServiceMessageIntent.onpage;
  return (
    <div
      data-testid="service_message"
      className={cx(css.wrapper, {
        [css.longer]: hasExtraContent && !fullWidth,
        [css.fullWidth]: fullWidth,
        [css.error]: type === ServiceMessageType.error,
      })}
      {...props}
    >
      <div className={css.message}>
        {type === ServiceMessageType.error && !hideErrorIcon && (
          <>
            <Icon icon="warningRed" />
            <Spacer factor={1} horizontalFactor={2} />
          </>
        )}
        {children}
      </div>
      {renderContentEnd ? <div>{renderContentEnd()}</div> : null}
    </div>
  );
};
