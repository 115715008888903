import { checkWLDomain } from 'cf-common/src/whitelabel';
import React from 'react';
import { seoStrings } from '../../../seo.config';

interface IPageHeaderProps {
  dialogsCounter: number;
}

export const PageTitle: React.FC<IPageHeaderProps> = ({ dialogsCounter }) => {
  const defaultTitle = checkWLDomain(window.location.hostname)
    ? 'Bot Dashboard'
    : seoStrings.title;
  document.title =
    dialogsCounter > 0 ? `✉ (${dialogsCounter}) ${defaultTitle}` : defaultTitle;

  return null;
};
