import React from 'react';
import { Button, ButtonIntent, ButtonSize, ButtonColorWay } from '../_deprecated/Button';
import * as css from './ServiceMessage.css';

interface IServiceMessageProps {
  message?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  onClose?: () => void;
}

export const ServiceMessage: React.SFC<IServiceMessageProps> = ({
  message = '',
  buttonLabel,
  onButtonClick,
  ...props
}) => {
  return (
    <div className={css.wrapper} {...props}>
      <div className={css.message}>{message}</div>
      {buttonLabel &&
        onButtonClick && (
          <Button
            intent={ButtonIntent.primary}
            size={ButtonSize.s}
            colorWay={ButtonColorWay.white}
            onClick={onButtonClick}
          >
            {buttonLabel}
          </Button>
        )}
    </div>
  );
};
