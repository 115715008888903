import { ReactComponent as WorriedFaceComponent } from './em_worried-face.svg';
import { ReactComponent as MonocleFaceComponent } from './em_face-with-monocle.svg';
import { ReactComponent as MouthAndSweatFaceComponent } from './em_face-with-open-mouth-and-cold-sweat.svg';
import { ReactComponent as GrinWithStarsFaceComponent } from './em_grinning-face-with-star-eyes.svg';
import { ReactComponent as CryingFaceComponent } from './em_crying-face.svg';
import { ReactComponent as FaceWithSunglassesComponent } from './em_face-with-sunglasses.svg';

export const WorriedFace = WorriedFaceComponent;
export const MonocleFace = MonocleFaceComponent;
export const MouthAndSweatFace = MouthAndSweatFaceComponent;
export const GrinWithStarsFace = GrinWithStarsFaceComponent;
export const CryingFace = CryingFaceComponent;
export const FaceWithSunglasses = FaceWithSunglassesComponent;
