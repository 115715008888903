import { MouseEventHandler } from 'react';

export enum IconColor {
  black = 'black',
  white = 'white',
  gray = 'gray',
  blue = 'blue',
  none = 'none',
}

export type IconProps = {
  color?: IconColor;
  className?: string;
  svg?: React.ReactType<any>;
  onClick?: MouseEventHandler;
  size?: IconSize;
  url?: string;
  rounded?: boolean;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
};

export enum IconSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  slarge = 'slarge',
}
