import React from 'react';
import ng from 'angular';
import { react2angular } from 'react2angular';
import { ButtonColorWay, ButtonSize, ButtonIntent, Button } from './Button';
import { ReactComponent as PlusIcon } from './Icon/icons/ic_narrow_add.svg';

interface IButtonWrapperProps {
  onClick(): void;
  intent: string;
  label: string;
  size: string;
  colorWay: string;
  icon: string;
}

const intentPropsMap: { [intent: string]: ButtonIntent } = {
  primary: ButtonIntent.primary,
  secondary: ButtonIntent.secondary,
};

const colorPropsMap: { [colorWay: string]: ButtonColorWay } = {
  blue: ButtonColorWay.blue,
  white: ButtonColorWay.white,
};

const sizePropsMap: { [size: string]: ButtonSize } = {
  s: ButtonSize.s,
  m: ButtonSize.m,
};

const iconPropsMap: { [size: string]: () => React.ReactNode } = {
  plus: () => <PlusIcon />,
};

const ModernButtonAdapter: React.SFC<IButtonWrapperProps> = ({
  onClick,
  intent = 'primary',
  label,
  size = 'm',
  colorWay = 'white',
  icon,
}) => {
  return (
    <Button
      onClick={onClick}
      intent={intentPropsMap[intent]}
      colorWay={colorPropsMap[colorWay]}
      size={sizePropsMap[size]}
      renderIcon={iconPropsMap[icon]}
    >
      {label}
    </Button>
  );
};

export const ngButtonAdapter = ng
  .module('app.modernUi.modernButton', [])
  .component(
    'modernButton',
    react2angular(
      ModernButtonAdapter,
      ['onClick', 'intent', 'label', 'size', 'colorWay', 'icon'],
      [],
    ),
  ).name;
