import React, { useEffect } from 'react';

interface KeyDownHandlerProps {
  focused: boolean;
  onKeyDown(event: KeyboardEvent): any;
}

export const KeyDownHandler: React.FC<KeyDownHandlerProps> = ({
  focused,
  onKeyDown,
}) => {
  useEffect(() => {
    if (focused) {
      document.documentElement!.addEventListener('keydown', onKeyDown);
    }
    return () => {
      document.documentElement!.removeEventListener('keydown', onKeyDown);
    };
  }, [focused, onKeyDown]);
  return null;
};
