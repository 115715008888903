import React from 'react';
import cn from 'classnames';
import * as css from './ShimmerText.css';

interface IShimmerTextProps extends React.HTMLProps<HTMLDivElement> {
  play?: boolean;
}

export const ShimmerText: React.FC<IShimmerTextProps> = ({
  play,
  children,
  ...htmlProps
}) => {
  return (
    <div {...htmlProps}>
      <div className={cn({ [css.shimmer]: play })}>{children}</div>
    </div>
  );
};
