import ng from 'angular';
import { react2angular } from 'react2angular';
import { TimeoutInput } from './TimeoutInput';

export const ngTimeoutInput = ng
  .module('app.modernUi.timeoutInput', [])
  .component(
    'timeoutInput',
    react2angular(TimeoutInput, ['value', 'interval', 'onChange'], []),
  ).name;
