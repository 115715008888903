import moment from 'moment';
import memoize from 'lodash-es/memoize';
import { Translation } from '@translations';
import { DatePickerShortcut, PickedRange } from '../types';

export const DATE_PICKER_LOCALISATION_BY_SHORTCUT: Record<
  DatePickerShortcut,
  Translation
> = {
  [DatePickerShortcut.today]:
    'modernUi.DatePicker.RangePickerField.shortcuts.today',
  [DatePickerShortcut.yesterday]:
    'modernUi.DatePicker.RangePickerField.shortcuts.yesterday',
  [DatePickerShortcut.thisWeek]:
    'modernUi.DatePicker.RangePickerField.shortcuts.thisWeek',
  [DatePickerShortcut.thisMonth]:
    'modernUi.DatePicker.RangePickerField.shortcuts.thisMonth',
  [DatePickerShortcut.last3Months]:
    'modernUi.DatePicker.RangePickerField.shortcuts.last3Months',
  [DatePickerShortcut.custom]:
    'modernUi.DatePicker.RangePickerField.shortcuts.custom',
};

export const makeDateRangeByShortcut = memoize(
  () =>
    ({
      [DatePickerShortcut.today]: [
        moment().startOf('day'),
        moment().endOf('day'),
      ],
      [DatePickerShortcut.yesterday]: [
        moment().subtract(1, 'day').startOf('day'),
        moment().subtract(1, 'day').endOf('day'),
      ],
      [DatePickerShortcut.thisWeek]: [
        moment().startOf('week'),
        moment().endOf('day'),
      ],
      [DatePickerShortcut.thisMonth]: [
        moment().startOf('month'),
        moment().endOf('day'),
      ],
      [DatePickerShortcut.last3Months]: [
        moment().subtract(3, 'month').startOf('day'),
        moment().endOf('day'),
      ],
    } as Record<DatePickerShortcut, PickedRange>),
);
