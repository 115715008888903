import React from 'react';
import cn from 'classnames';
import { Button, ButtonSize, ButtonIntent } from '../_deprecated/Button';
import { ReactComponent as Add } from '../_deprecated/Icon/icons/ic_narrow_add.svg';
import { ITab, Tab } from './Tab';
import * as css from './TabsSet.css';

export interface ITabsSetProps {
  tabs: ITab[];
  activeTabIndex: number;
  onTabClick: (tabIndex: number, evt: React.MouseEvent) => void;
  onCopyTo?: (tabIndex: number) => void;
  removeTab?: (tabIndex: number) => void;
  createNewTab: () => void;
  renameTab?: (tabIndex: number, newName: string) => void;
  cloneTab?: () => void;
  validate?: (name: string, tabIndex?: number) => boolean;
  getErrorMessage?: (name: string, tabIndex?: number) => string | undefined;
  allowCreateNewTabs: boolean;
  allowEditTabs: boolean;
}

interface ITabsSetState {
  editMode: boolean;
}

export class TabsSet extends React.Component<ITabsSetProps, ITabsSetState> {
  state = {
    editMode: false,
  };

  componentWillReceiveProps(nextProps: ITabsSetProps) {
    if (this.props.tabs.length === nextProps.tabs.length - 1) {
      this.setState({
        editMode: true,
      });
    }
  }

  shouldComponentUpdate(_: ITabsSetProps, nextState: ITabsSetState) {
    if (this.state.editMode && nextState.editMode) {
      return false;
    }
    return true;
  }

  getClickHandler(tabIndex: number) {
    return (evt: React.MouseEvent) => {
      this.props.onTabClick(tabIndex, evt);
    };
  }

  getRenameHandler(tabIndex: number) {
    return (newName: string) => {
      this.setState({
        editMode: false,
      });

      if (this.props.renameTab) {
        this.props.renameTab(tabIndex, newName);
      }
    };
  }

  setEditMode = () => {
    this.setState({
      editMode: true,
    });
  };

  render() {
    const {
      activeTabIndex,
      tabs,
      getErrorMessage,
      validate = () => true,
      allowCreateNewTabs,
      allowEditTabs,
    } = this.props;
    const { editMode } = this.state;

    return (
      <div data-testid="tabs-set" className={css['tabs-set']}>
        {tabs.map((tab, index) => (
          <Tab
            data-testid="tabs-set__tab"
            tab={tab}
            key={tab.id}
            isActive={index === activeTabIndex}
            isFaded={tab.faded || (editMode && activeTabIndex !== index)}
            validate={(name: string) => validate && validate(name, index)}
            getErrorMessage={(name: string) =>
              getErrorMessage && getErrorMessage(name, index)
            }
            onClick={this.getClickHandler(index)}
            onStartEditing={() => this.setEditMode()}
            editMode={editMode && index === activeTabIndex}
            onClone={this.props.cloneTab}
            onRename={
              this.props.renameTab ? this.getRenameHandler(index) : undefined
            }
            onRemove={
              this.props.removeTab
                ? () => this.props.removeTab && this.props.removeTab(index)
                : undefined
            }
            onCopyTo={
              this.props.onCopyTo
                ? () => this.props.onCopyTo && this.props.onCopyTo(index)
                : undefined
            }
            className="test-tab"
            allowEdit={allowEditTabs}
            showDots={allowEditTabs}
          />
        ))}
        {allowCreateNewTabs && (
          <Button
            data-testid="tabs-set__tab-add-button"
            className={cn('test-add-tab-button', css.addButton)}
            size={ButtonSize.s}
            intent={ButtonIntent.secondary}
            renderIcon={() => <Add className={css.svgPlus} />}
            onClick={this.props.createNewTab}
            tall
          />
        )}
      </div>
    );
  }
}
