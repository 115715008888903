import React from 'react';

import cn from 'classnames';
import * as css from './Label.css';

interface ILabel extends TestLocator {
  children: React.ReactNode;
  className?: string;
}

const Label = ({ children, className, ...restProps }: ILabel) => (
  <div
    data-testid={restProps['data-testid']}
    className={cn(css.font, css.layout, className)}
  >
    {children}
  </div>
);

export default Label;
