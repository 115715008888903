import React from 'react';
import cx from 'classnames';
import * as s from './FlexItem.css';

// const propTypes = {
//   equalWidth: PropTypes.bool,
//   grow: PropTypes.bool,
//   doNotGrow: PropTypes.bool,
//   shrink: PropTypes.bool,
//   doNotShrink: PropTypes.bool,
// };

interface Props extends React.HTMLProps<HTMLDivElement> {
  equalWidth?: boolean;
  grow?: boolean;
  growHeight?: boolean;
  growWidth?: boolean;
  doNotGrow?: boolean;
  shrink?: boolean;
  doNotShrink?: boolean;
}

export const FlexItem: React.FC<Props> = ({
  equalWidth,
  grow,
  growHeight,
  growWidth,
  doNotGrow,
  shrink,
  doNotShrink,
  className,
  ...htmlProps
}) => {
  return (
    <div
      className={cx(s.FlexItem, className, {
        [s.equalWidth]: equalWidth,
        [s.grow]: grow,
        [s.growHeight]: growHeight,
        [s.growWidth]: growWidth,
        [s.doNotGrow]: doNotGrow,
        [s.shrink]: shrink,
        [s.doNotShrink]: doNotShrink,
      })}
      {...htmlProps}
    />
  );
};
