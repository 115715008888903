import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Anchor } from '@ui/Links';
import { Type } from '@ui/Type';
import React from 'react';
import { AdManagerTutorialLinks } from '../../pages/GrowPage/ClickToMessenger/components/AdManagerTutorialLinks';
import * as css from './EmptyView.css';

interface EmptyViewProps {
  title: string;
  message: string;
  linkHref: string;
  linkLabel: string;
  onLinkClick?: () => void;
  refreshButtonLabel: string;
  onRefreshClick: () => void;
  onTutorialLinkClick?: () => void;
}

export const EmptyView: React.FC<EmptyViewProps> = ({
  title,
  message,
  linkHref,
  linkLabel,
  refreshButtonLabel,
  onLinkClick,
  onRefreshClick,
  onTutorialLinkClick,
}) => (
  <Flex
    alignItems="center"
    justifyContent="space-between"
    flexDirection="column"
  >
    <div>
      <Type align="center" weight="medium" as="p" size="18px">
        {title}
      </Type>
      <Flex className={css.message} flexDirection="column" alignItems="center">
        <Type align="center" color="greyDark" size="15px_DEPRECATED">
          {message}
        </Type>
        <Anchor
          hideArrow
          intent="external"
          href={linkHref}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => onLinkClick?.()}
        >
          {linkLabel}
        </Anchor>
      </Flex>
    </div>
    <Flex alignItems="center" className={css.buttons}>
      <Button
        className={css.refreshButton}
        intent="secondary"
        onClick={onRefreshClick}
      >
        {refreshButtonLabel}
      </Button>
      <AdManagerTutorialLinks onClick={() => onTutorialLinkClick?.()} />
    </Flex>
  </Flex>
);
