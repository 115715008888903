import ng from 'angular';
import { react2angular } from 'react2angular';
import { position } from 'caret-pos';
import { BaseEmoji } from 'emoji-mart';
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import faceIcon from '!!raw-loader!./images/face.svg';
import * as css from './EmojiPicker.css';
import { EmojiPopup } from './EmojiPopup';
import { FACE_ICON_HEIGHT } from './EmojiPicker';
import { insertText } from '../../utils/documentExecCommand';

export const ngEmojiPopup = ng
  .module('app.modernUI.emojiPopup', [])
  .component(
    'emojiPopup',
    react2angular(
      EmojiPopup,
      ['onSelect', 'onRequestClose', 'referenceElement'],
      [],
    ),
  ).name;

const INPUT_SELECTOR = 'input, textarea, [contenteditable]';

class EmojiPickerController {
  shouldShowPopup: boolean = false;

  referenceElement: Element | undefined;

  $element: JQLite;

  $scope: ng.IScope;

  buttonSpaceWidth: number | undefined;

  $timeout: ng.ITimeoutService;

  onSelect: ((emoji: BaseEmoji) => void) | undefined;

  constructor(
    $scope: any,
    $element: JQLite,
    $sce: ng.ISCEService,
    $timeout: ng.ITimeoutService,
  ) {
    'ngInject';

    $scope.faceIcon = $sce.trustAsHtml(faceIcon);
    $scope.css = css;
    $scope.FACE_ICON_HEIGHT = FACE_ICON_HEIGHT;
    this.$element = $element;
    this.$scope = $scope;
    this.$timeout = $timeout;
  }

  $postLink() {
    this.referenceElement = this.$element[0].firstChild as Element;
  }

  getInputElement = () => (this.$element.find(INPUT_SELECTOR) || [])[0];

  setFocus = (focusableElement: HTMLElement | null) => {
    if (focusableElement) {
      focusableElement.focus();
      if (!position(focusableElement).pos) {
        position(focusableElement, focusableElement.innerText.length);
      }
    }
  };

  onSelectEmoji = (emoji: BaseEmoji) => {
    const focusableElement = this.getInputElement();
    if (focusableElement) {
      this.setFocus(focusableElement);
      insertText(emoji.native, focusableElement);
    }

    this.closePopup();

    this.$timeout(() => {
      if (this.onSelect) {
        this.onSelect(emoji);
      }
    }, 50); // wait few tics after eval async
  };

  onRequestClose = () => {
    this.closePopup();
  };

  closePopup = () => {
    this.$scope.$evalAsync(() => {
      this.shouldShowPopup = false;
    });
  };

  showPopup = (e: Event) => {
    e.stopImmediatePropagation();
    this.setFocus(this.getInputElement());
    this.$scope.$evalAsync(() => {
      this.shouldShowPopup = true;
    });
  };
}

export const ngEmojiPicker = ng
  .module('app.modernUI.emojiPicker', [ngEmojiPopup])
  .component('emojiPicker', {
    controllerAs: 'vm',
    transclude: true,
    template: () =>
      `<div 
         class="{{vm.hideButton? '' : css.box}}"
         ng-style="{'padding-right': !vm.hideButton ? vm.buttonSpaceWidth + 'px' : ''}"
        >
         <div ng-style="{position: 'relative'}">
            <ng-transclude></ng-transclude>
         </div>
         <div 
           ng-if="!vm.hideButton" 
           ng-class="[css.button, vm.shouldShowPopup ? css.active : undefined]"
           ng-style="{bottom: (((vm.oneLineInputHeight || FACE_ICON_HEIGHT) - FACE_ICON_HEIGHT) * 0.5)}"
           ng-click="vm.showPopup($event)"
           ng-mousedown="$event.preventDefault();"
           ng-bind-html="faceIcon"
         ></div>
        <emoji-popup
            ng-if="vm.shouldShowPopup"
            on-select="vm.onSelectEmoji"
            on-request-close="vm.onRequestClose"
            reference-element="vm.referenceElement"
        ></emoji-popup>
      </div>`,
    controller: EmojiPickerController,
    bindings: {
      hideButton: '<',
      onSelect: '<',
      buttonSpaceWidth: '<',
      oneLineInputHeight: '<',
    },
  }).name;
