import React from 'react';
import cn from 'classnames';
import * as css from './VisuallyDisabled.css';

export interface VisuallyDisabledProps {
  disable: boolean;
  className?: string;
}

export const VisuallyDisabled: React.FC<VisuallyDisabledProps> = ({
  disable,
  children,
  className,
}) => (
  <div
    className={cn(
      {
        [css.disabled]: disable,
      },
      className,
    )}
  >
    {children}
  </div>
);
