import React from 'react';

interface Props {
  render: (
    props: {
      focusedWithin: boolean;
      bind: { ref: (n: HTMLElement | null) => void };
    },
  ) => React.ReactNode;
  onFocusIn?: (event: FocusEvent) => void;
  onFocusOut?: (event: FocusEvent) => void;
}

interface State {
  focusedWithin: boolean;
}

class FocusWithin extends React.Component<Props, State> {
  container?: HTMLElement | null;

  constructor(props: Props) {
    super(props);
    this.mount = this.mount.bind(this);
    this.handleFocusIn = this.handleFocusIn.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
    this.state = {
      focusedWithin: false,
    };
  }

  componentDidMount() {
    if (this.container) {
      /**
       * Temporarilry pass `any`,
       * see https://github.com/Microsoft/TSJS-lib-generator/pull/369#issuecomment-427148407
       */
      this.container.addEventListener('focusin', this.handleFocusIn as any);
      this.container.addEventListener('focusout', this.handleFocusOut as any);
    }
  }

  componentWillUnmount() {
    if (this.container) {
      /**
       * Temporarilry pass `any`,
       * see https://github.com/Microsoft/TSJS-lib-generator/pull/369#issuecomment-427148407
       */
      this.container.removeEventListener('focusin', this.handleFocusIn as any);
      this.container.removeEventListener('focusout', this.handleFocusOut as any);
    }
  }

  mount(n: HTMLElement | null) {
    this.container = n;
  }

  handleFocusIn(event: FocusEvent) {
    if (!this.state.focusedWithin) {
      this.setState({ focusedWithin: true });
      if (this.props.onFocusIn) {
        this.props.onFocusIn(event);
      }
    }
  }

  handleFocusOut(event: FocusEvent) {
    if (!this.container) {
      return;
    }
    if (
      !event.relatedTarget ||
      !this.container.contains(event.relatedTarget as Node)
    ) {
      this.setState({ focusedWithin: false });
      if (this.props.onFocusOut) {
        this.props.onFocusOut(event);
      }
    }
  }

  render() {
    return this.props.render({
      focusedWithin: this.state.focusedWithin,
      bind: { ref: this.mount },
    });
  }
}

export { FocusWithin };
