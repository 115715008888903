import React from 'react';
import { Type } from '@ui/Type';
import {
  SegmentedCheckboxBox,
  SegmentedCheckboxItem,
  SegmentedCheckboxItemProps,
} from './SegmentedCheckbox';

export type SegmentedCheckboxOnClick<T> = (
  e: React.MouseEvent<HTMLInputElement, MouseEvent>,
  item: T,
  index: number,
) => void;

export interface SegmentedCheckboxTextProps<T> {
  items: Array<T>;
  checkedIds: Array<string>;
  disabled?: Array<string>;
  onClick: SegmentedCheckboxOnClick<T>;
}

export interface SegmentedCheckboxTextItemProps
  extends Omit<
    SegmentedCheckboxItemProps,
    'children' | 'onClick' | 'checked' | 'value'
  > {
  id: string;
}

export const SegmentedCheckboxText = ({
  items,
  onClick,
  disabled,
  checkedIds,
}: SegmentedCheckboxTextProps<SegmentedCheckboxTextItemProps>) => {
  return (
    <SegmentedCheckboxBox>
      {items.map((item, index) => (
        <SegmentedCheckboxItem
          id={item.id}
          key={item.id}
          name={item.name}
          value={item.id}
          checked={checkedIds.includes(item.id)}
          onClick={(e) => onClick(e, item, index)}
          disabled={disabled?.includes(item.id)}
          data-testid={item['data-testid']}
        >
          <Type size="12px">{item.name}</Type>
        </SegmentedCheckboxItem>
      ))}
    </SegmentedCheckboxBox>
  );
};
