import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Boundary } from 'popper.js';
import cn from 'classnames';
import { Portal } from 'react-portal';
import * as css from './Tooltip.css';

export type Position =
  | 'top-start'
  | 'bottom-start'
  | 'left'
  | 'top'
  | 'bottom'
  | 'right'
  | 'bottom-end'
  | 'top-end';

export type TooltipType = 'standart' | 'small';

export interface TooltipPureProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'content'> {
  children: (
    ref: React.Ref<any>,
    scheduleUpdateRef: MutableRefObject<(() => void) | undefined>,
  ) => React.ReactNode;
  content: React.ReactNode;
  placement?: Position;
  type?: TooltipType;
  show?: boolean;
  onTooltipMouseEnter?: () => void;
  onTooltipMouseLeave?: () => void;
  boundariesElement?: Boundary | Element;
  disableFlip?: boolean;
  positionFixed?: boolean;
  updatePositionByContent?: boolean;
}

export type Tooltip2ChildrenProps = (
  ref: React.Ref<any>,
  bind: { onMouseEnter: () => void; onMouseLeave: () => void },
  scheduleUpdateRef: MutableRefObject<(() => void) | undefined>,
) => React.ReactNode;

export interface Tooltip2Props extends Omit<TooltipPureProps, 'children'> {
  children: Tooltip2ChildrenProps;
  hoverable?: boolean;
  disabled?: boolean;
}

type Hook = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export function useTooltip(mouseOverDelay = 200, mouseOutDelay = 200): Hook {
  const [hovered, setHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const debounceHoveredSetter = useRef(setTimeout(() => true, 1000));

  useEffect(() => {
    if (hovered) {
      debounceHoveredSetter.current = setTimeout(
        () => setIsVisible(true),
        mouseOverDelay,
      );
    } else {
      debounceHoveredSetter.current = setTimeout(
        () => setIsVisible(false),
        mouseOutDelay,
      );
    }
    return () => clearTimeout(debounceHoveredSetter.current);
  }, [hovered, mouseOutDelay, mouseOverDelay]);

  return [isVisible, setHovered];
}

const SINGLE_LINE_HEIGHT = 30;

export const TooltipPure: React.FC<TooltipPureProps> = ({
  children,
  placement = 'right',
  content,
  show,
  className = '',
  positionFixed,
  type = 'standart',
  disableFlip,
  boundariesElement,
  onTooltipMouseEnter,
  onTooltipMouseLeave,
  updatePositionByContent,
  ...tooltipProps
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [multiLine, setMultiLine] = useState<boolean>(false);
  const scheduleUpdateRef = useRef<(() => void) | undefined>();

  useEffect(() => {
    setMultiLine(
      !!boxRef.current && boxRef.current.clientHeight > SINGLE_LINE_HEIGHT,
    );
  }, [content, children]);

  useEffect(() => {
    if (updatePositionByContent && content) {
      scheduleUpdateRef.current?.();
    }
  }, [updatePositionByContent, content]);

  return (
    <Manager>
      <Reference>{({ ref }) => children(ref, scheduleUpdateRef)}</Reference>
      {show ? (
        <Portal>
          <Popper
            placement={placement}
            positionFixed={positionFixed}
            modifiers={
              disableFlip
                ? {
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                    flip: {
                      enabled: false,
                    },
                  }
                : boundariesElement
                ? {
                    preventOverflow: {
                      boundariesElement,
                      enabled: true,
                    },
                    flip: {
                      boundariesElement,
                      enabled: true,
                    },
                  }
                : undefined
            }
          >
            {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
              scheduleUpdateRef.current = scheduleUpdate;
              return (
                <div
                  {...tooltipProps}
                  ref={ref}
                  style={
                    tooltipProps.style
                      ? { ...style, ...tooltipProps.style }
                      : style
                  }
                  className={cn(
                    css.tooltip,
                    css[`tooltip-${placement}`],
                    className,
                    css[`tooltip-${type}`],
                    { [css.singleLine]: !multiLine },
                  )}
                  onMouseEnter={onTooltipMouseEnter}
                  onMouseLeave={onTooltipMouseLeave}
                >
                  <div ref={boxRef}>{content}</div>
                  <div className={cn(css.tooltipNib)} {...arrowProps} />
                </div>
              );
            }}
          </Popper>
        </Portal>
      ) : null}
    </Manager>
  );
};

export const Tooltip2: React.FC<Tooltip2Props> = ({
  children,
  hoverable = false,
  disabled,
  onTooltipMouseEnter,
  onTooltipMouseLeave,
  show: initShow,
  content,
  ...tooltipProps
}) => {
  // we need mouse over delay to avoid show during just mouse moving over the element
  const [isVisible, setHovered] = useTooltip(0, hoverable ? 300 : 0);
  const [bindHovered, setBindHovered] = useTooltip(200, 200);

  const show =
    (initShow && !!content) ||
    (!disabled && ((hoverable && isVisible) || bindHovered));

  return (
    <TooltipPure
      data-testid="flowbuilder__tooltip"
      {...tooltipProps}
      onTooltipMouseEnter={() => {
        setHovered(true);
        if (onTooltipMouseEnter) {
          onTooltipMouseEnter();
        }
      }}
      onTooltipMouseLeave={() => {
        setHovered(false);
        if (onTooltipMouseLeave) {
          onTooltipMouseLeave();
        }
      }}
      content={content}
      show={show}
    >
      {(ref, scheduleUpdateRef) =>
        children(
          ref,
          {
            onMouseEnter: () => setBindHovered(true),
            onMouseLeave: () => setBindHovered(false),
          },
          scheduleUpdateRef,
        )
      }
    </TooltipPure>
  );
};
