import ng from 'angular';
import { react2angular } from 'react2angular';
import { SuggestStringsUi } from './Suggest';

export const ngSuggestStringsUi = ng
  .module('app.modernUi.suggestStringsUi', [])
  .component(
    'suggestStringsUi',
    react2angular(
      SuggestStringsUi,
      ['items', 'selectedItem', 'placeholder', 'onChange', 'onInputChange'],
      [],
    ),
  ).name;
