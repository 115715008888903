import React from 'react';
import cn from 'classnames';
import * as css from './NotificationTag.css';
import { ReactComponent as IIcon } from './images/i.svg';

export enum NotificationTagType {
  'info' = 'info',
  'success' = 'success',
  'warning' = 'warning',
  'error' = 'error',
}

interface NotificationTagProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  notificationTagType: NotificationTagType;
  innerRef?: React.Ref<HTMLButtonElement>;
}

export const NotificationTag: React.FC<NotificationTagProps> = (props) => {
  const { title, notificationTagType, innerRef, ...buttonProps } = props;

  return (
    <button
      tabIndex={-1}
      {...buttonProps}
      ref={innerRef}
      className={cn(css.notificationTag, css[notificationTagType])}
    >
      <IIcon />
    </button>
  );
};
