import { getArrayFrom0ToN } from '@utils/ArrayUtils';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { ButtonUnstyled } from '../Button';
import { Flex } from '../Flex';
import * as css from './Bullets.css';

interface BulletsProps {
  itemsNumber: number;
  activeIndex: number;
  onIndexChange(index: number): void;
}

const BULLET_SIZE = 8;
const BULLET_MARGIN = 8;
const MAX_VISIBLE_BULLETS_NUMBER = 7;
const ACTIVE_ITEM_POSITION_NUMBER = 3;

const MAX_CONTAINER_WIDTH =
  MAX_VISIBLE_BULLETS_NUMBER * BULLET_SIZE +
  (MAX_VISIBLE_BULLETS_NUMBER - 1) * BULLET_SIZE;

export const Bullets: React.FC<BulletsProps> = ({
  activeIndex,
  onIndexChange,
  itemsNumber,
}) => {
  // we need to add some math for correct visualization "many bullets" case
  // see ManyItems story for Gallery element

  const containerWidth = useMemo(() => {
    return Math.min(
      MAX_CONTAINER_WIDTH,
      itemsNumber * BULLET_SIZE + (itemsNumber - 1) * BULLET_MARGIN,
    );
  }, [itemsNumber]);

  /**
   * to display bullets in ManyItems case, we will calculate
   * active element position and place all other elements relative to it
   */
  const activeItemLeft = useMemo(() => {
    if (activeIndex < ACTIVE_ITEM_POSITION_NUMBER) {
      return activeIndex * (BULLET_SIZE + BULLET_MARGIN);
    }
    if (activeIndex + ACTIVE_ITEM_POSITION_NUMBER >= itemsNumber) {
      return (
        containerWidth +
        BULLET_SIZE -
        (itemsNumber - activeIndex) * (BULLET_SIZE + BULLET_MARGIN)
      );
    }
    return ACTIVE_ITEM_POSITION_NUMBER * (BULLET_SIZE + BULLET_MARGIN);
  }, [activeIndex, itemsNumber, containerWidth]);

  const getBulletLeft = (index: number) => {
    return (
      activeItemLeft + (index - activeIndex) * (BULLET_SIZE + BULLET_MARGIN)
    );
  };

  /**
   * to display bullets' sizes in ManyItems case, we will compute delta
   * from active element and calculate other bullets' sizes relative to it
   */
  const getBulletSize = (index: number) => {
    if (itemsNumber <= MAX_VISIBLE_BULLETS_NUMBER) {
      return BULLET_SIZE;
    }
    if (index === activeIndex) {
      return BULLET_SIZE;
    }
    if (
      getBulletLeft(index) === 0 ||
      getBulletLeft(index) === containerWidth - BULLET_SIZE
    ) {
      return BULLET_SIZE / 2;
    }
    return (3 * BULLET_SIZE) / 4;
  };

  return (
    <Flex
      alignItems="center"
      className={css.bulletContainer}
      style={{ width: `${containerWidth}px` }}
    >
      {getArrayFrom0ToN(itemsNumber).map((index) => (
        <ButtonUnstyled
          key={index}
          className={cn(
            css.bullet,
            activeIndex === index ? css.bulletActive : css.bulletInactive,
          )}
          style={{ transform: `translateX(${getBulletLeft(index)}px)` }}
          onClick={() => onIndexChange(index)}
        >
          <div
            className={css.bulletInner}
            style={{
              width: `${getBulletSize(index)}px`,
              height: `${getBulletSize(index)}px`,
            }}
          />
        </ButtonUnstyled>
      ))}
    </Flex>
  );
};
