import React, { useState, useRef } from 'react';

type Option = string;

export function useCheckboxGroup(options: Option[]) {
  const [values, setValues] = useState<any>({});

  const lastClickedCheckboxValue = useRef<string>();

  const handleShiftClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    /**
     * The logic for selecting checkboxes when "shift" is pressed is:
     *
     * 1. Find all checkboxes in range between the clicked checkbox and
     *    the previously clicked checkbox
     * 2. Set all these checkboxes to the same state that the target checkbox
     *    is going to be set to.
     *
     */
    const checkbox = event.currentTarget as HTMLInputElement;
    if (!event.shiftKey || !lastClickedCheckboxValue.current) {
      // either first shift click or not a shift-click —> cannot select range
      lastClickedCheckboxValue.current = checkbox.value;
      setValues({
        ...values,
        [checkbox.value]: checkbox.checked,
      });
      return;
    }

    const indexOfLastClickedCheckboxValue = options.findIndex(
      (value) => value === lastClickedCheckboxValue.current,
    );
    const indexOfClickedCheckboxValue = options.findIndex((value) => value === checkbox.value);

    if (
      indexOfLastClickedCheckboxValue === -1 ||
      indexOfClickedCheckboxValue === -1 ||
      indexOfLastClickedCheckboxValue === indexOfClickedCheckboxValue
    ) {
      return;
    }

    const [leftIndex, rightIndex] = [
      Math.min(indexOfLastClickedCheckboxValue, indexOfClickedCheckboxValue),
      Math.max(indexOfLastClickedCheckboxValue, indexOfClickedCheckboxValue),
    ];

    const targetCheckedState = checkbox.checked;
    const newValues = options.slice(leftIndex, rightIndex + 1).reduce((acc: any, value) => {
      acc[value] = targetCheckedState;
      return acc;
    }, {});

    setValues({
      ...values,
      ...newValues,
    });
  };

  function setSelectedTo(checked: boolean) {
    return () => {
      const allSet = options.reduce((acc, value) => Object.assign(acc, { [value]: checked }), {});
      setValues({ ...values, ...allSet });
    };
  }
  const selectAll = setSelectedTo(true);
  const deselectAll = setSelectedTo(false);

  return {
    values,
    selectAll,
    deselectAll,
    setValues, // low-level setter, perhaps should not be used by consumers
    handleClick: handleShiftClick,
  };
}
