import React from 'react';
import cn from 'classnames';
import { Flex } from '../Flex';
import * as css from './SegmentedGroupTrack.css';

export enum SegmentedGroupTrackColorWay {
  white = 'white',
  grey = 'grey',
  greyLight = 'greyLight',
  blueLight30 = 'blueLight30',
}

interface Props extends React.HTMLProps<HTMLDivElement>, TestLocator {
  colorWay?: SegmentedGroupTrackColorWay;
  /**
   * role attribute *must* be provided
   *
   * Appropriate choices for this component
   * are either "tablist" or "radiogroup",
   * but something else might be appropriate, too
   *
   * More about roles:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles
   * https://www.w3.org/WAI/PF/aria/roles
   */
  role: string;
}

export const SegmentedGroupTrack: React.FC<Props> = ({
  colorWay = SegmentedGroupTrackColorWay.white,
  role,
  children,
  className,
  style,
  ...props
}) => (
  <Flex
    data-testid={props['data-testid']}
    role={role}
    className={cn(className, css.track, {
      [css.white]: colorWay === SegmentedGroupTrackColorWay.white,
      [css.grey]: colorWay === SegmentedGroupTrackColorWay.grey,
      [css.greyLight]: colorWay === SegmentedGroupTrackColorWay.greyLight,
    })}
    style={style}
  >
    {children}
  </Flex>
);
