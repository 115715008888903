import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React, { useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  DayPickerRangeController,
  DayPickerRangeControllerShape,
  FocusedInputShape,
} from 'react-dates';
import { PickedDate } from './types';
import { getRangeText } from './utils/rangeTextFormat';
import './styles/react-dates.global.css';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Type } from '../Type';
import { Icon } from '../Icon';
import { Spacer } from '../Spacer';
import * as css from './DatePicker.css';
import { DateFormat, DateUtils } from '@utils/DateTime';

export type DateRangePickerApi = {
  setStartDate: React.Dispatch<React.SetStateAction<PickedDate>>;
  setEndDate: React.Dispatch<React.SetStateAction<PickedDate>>;
};
export interface DateRangePickerProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    'onChange' | 'defaultValue'
  > {
  onChange(date: [PickedDate, PickedDate]): void;
  defaultValue?: [PickedDate, PickedDate] | null;
  numberOfMonths?: number;
  withConfirmation?: boolean;
  onCancel?(): void;
  className?: string;
  isOutsideRange?: DayPickerRangeControllerShape['isOutsideRange'];
  renderMonthElement?: DayPickerRangeControllerShape['renderMonthElement'];
  dateRangePickerRef?: React.MutableRefObject<DateRangePickerApi | null>;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onChange,
  defaultValue = [null, null],
  numberOfMonths = 2,
  withConfirmation,
  onCancel,
  className,
  isOutsideRange,
  renderMonthElement,
  dateRangePickerRef,
}) => {
  const { t } = useSafeTranslation();
  const [startDate, setStartDate] = useState<PickedDate>(
    defaultValue?.[0] || null,
  );
  const [endDate, setEndDate] = useState<PickedDate>(defaultValue?.[1] || null);
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputShape>('startDate');

  useEffect(() => {
    if (dateRangePickerRef) {
      // eslint-disable-next-line no-param-reassign
      dateRangePickerRef.current = { setStartDate, setEndDate };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFocusChange = (focused: FocusedInputShape | null) => {
    setFocusedInput(!focused ? 'startDate' : focused);
  };

  return (
    <div className={className}>
      <DayPickerRangeController
        startDate={startDate as any}
        endDate={endDate as any}
        onDatesChange={({ startDate: sd, endDate: ed }) => {
          setStartDate(sd as PickedDate);
          setEndDate(ed as PickedDate);
          if (!withConfirmation) {
            onChange([sd as PickedDate, ed as PickedDate]);
          }
        }}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        initialVisibleMonth={
          defaultValue?.[0] ? () => defaultValue[0] as any : undefined
        }
        isOutsideRange={isOutsideRange}
        numberOfMonths={numberOfMonths}
        noBorder
        transitionDuration={0}
        minimumNights={0}
        hideKeyboardShortcutsPanel
        navPrev={
          <Button
            intent="text"
            icon={<Icon icon="triangle" className={css.leftArrow} />}
            className={css.monthLeftButton}
          />
        }
        navNext={
          <Button
            intent="text"
            icon={<Icon icon="triangle" className={css.rightArrow} />}
            className={css.monthRightButton}
          />
        }
        renderMonthElement={(props) =>
          renderMonthElement ? (
            renderMonthElement?.(props)
          ) : (
            <Flex justifyContent="center">
              <Type weight="medium" size="15px_DEPRECATED">
                {DateFormat.MMMMYYYY(props.month)}
              </Type>
            </Flex>
          )
        }
        // add ignore cause library types are bad for this prop
        // @ts-ignore
        renderWeekHeaderElement={(day) => (
          <Type size="12px-all_caps" color="grey">
            {DateUtils.mapDDtoDDD(day)}
          </Type>
        )}
        renderCalendarInfo={
          withConfirmation
            ? () => (
                <div className={css.dateRangePickerFooter}>
                  <Flex alignItems="center">
                    <Type size="15px_DEPRECATED" weight="medium">
                      {getRangeText(startDate, endDate)}
                    </Type>
                  </Flex>
                  <Flex>
                    <Button
                      intent="secondary"
                      onClick={() => {
                        onCancel?.();
                      }}
                    >
                      {t('modernUi.DatePicker.RangePicker.cancelButtonText')}
                    </Button>
                    <Spacer factor={0} horizontalFactor={4} />
                    <Button
                      onClick={() => {
                        onChange([startDate, endDate]);
                      }}
                      disabled={!startDate || !endDate}
                    >
                      {t('modernUi.DatePicker.RangePicker.applyButtonText')}
                    </Button>
                  </Flex>
                </div>
              )
            : undefined
        }
      />
    </div>
  );
};
