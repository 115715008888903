import React from 'react';
import cn from 'classnames';
import * as css from './Tabs2.css';

export interface TabItem {
  id: string;
  title?: string;
  icon?: React.ReactNode;
  hide?: boolean;
}

interface Tabs2Props {
  items: TabItem[];
  onChangeTab: (id: string) => void;
  className?: string;
  tabClassName?: string;
  active?: string;
}

export const Tabs2: React.FC<Tabs2Props> = (props) => {
  return (
    <div className={cn(css.tabs, props.className)}>
      {props.items
        .filter((item) => !item.hide)
        .map((item) => (
          <button
            key={item.id}
            className={cn(
              css.tab,
              props.tabClassName,
              item.id === props.active && css.active,
            )}
            onClick={() => props.onChangeTab(item.id)}
            onSubmit={() => props.onChangeTab(item.id)}
          >
            {item.icon || null}
            {item.title || null}
          </button>
        ))}
    </div>
  );
};
