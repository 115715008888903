import { Badge, Kind } from '@ui/Badge';
import { Flex } from '@ui/Flex';
import { Anchor } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import Maybe from 'graphql/tsutils/Maybe';
import React from 'react';
import css from './BotTitle.css';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { Platform } from '@globals';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';

export interface BotTitleProps {
  botTitle: string;
  isBotPro: boolean;
  // TODO : remove
  connectedPageInfo: Maybe<{
    id: string;
    title: string;
    picture?: string | null;
    whatsapp_business_account?: {
      name: string | null;
    } | null;
    whatsapp_phone?: {
      display_phone_number: string | null;
    } | null;
  }>;
  className?: string;
  platforms?: Platform[];
}

export const BotTitle: React.FC<BotTitleProps> = ({
  botTitle,
  isBotPro,
  connectedPageInfo,
  className,
  platforms,
}) => {
  const { t } = useSafeTranslation();
  const fbPageHref =
    connectedPageInfo?.id && `https://www.facebook.com/${connectedPageInfo.id}`;
  const isWhatsappBot =
    platforms && testAllowedPlatforms([Platform.whatsapp], platforms);

  return (
    <Flex flexDirection="column" className={className}>
      <Flex alignItems="center" className={css.title}>
        <TextEllipsis>
          <Type
            size="18px"
            weight="medium"
            data-testid="bot-list__list-item__bot_title"
          >
            {botTitle}
          </Type>
        </TextEllipsis>
        {isBotPro && (
          <>
            <Spacer horizontalFactor={1} />
            <Badge
              kind={Kind.alert}
              data-testid="bot-list__list-item__pro_badge"
            >
              {t('BotList-JSXText-8052-pro')}
            </Badge>
          </>
        )}
      </Flex>
      <TextEllipsis>
        <Type
          color="greyDark"
          size="15px"
          data-testid="bot-list__list-item__connected_page"
        >
          {connectedPageInfo ? (
            isWhatsappBot ? (
              <>
                <Type color="greyDark" as="div">
                  {t('BotList-connected-to')}{' '}
                  {truncateWithEllipses(
                    connectedPageInfo.whatsapp_business_account?.name || '',
                    50,
                  )}
                </Type>
                <Type color="greyDark" as="div">
                  {connectedPageInfo.whatsapp_phone?.display_phone_number}
                </Type>
              </>
            ) : (
              <Anchor
                target="_blank"
                rel="noopener noreferrer"
                href={fbPageHref}
                intent="external"
                hideArrow
              >
                {connectedPageInfo.title}
              </Anchor>
            )
          ) : isWhatsappBot ? (
            t('Whatsapp.connectThisBotToNumber')
          ) : (
            t('BotList-string-1985-connect-this-bot-to-a-facebook-page')
          )}
        </Type>
      </TextEllipsis>
    </Flex>
  );
};
