import React from 'react';
import type { PhoneInputProps } from './PhoneInputInner';
import { Loader } from '../Loader';
import { Flex } from '../Flex';
import * as css from './PhoneInput.css';

const PhoneInputInner = React.lazy(() => import('./PhoneInputInner'));

export const PhoneInput: React.FC<
  PhoneInputProps & { loader?: React.ReactNode }
> = ({ loader, ...props }) => {
  return (
    <React.Suspense
      fallback={
        loader ?? (
          <Flex justifyContent="center" className={css.loaderContainer}>
            <Loader />
          </Flex>
        )
      }
    >
      <PhoneInputInner {...props} />
    </React.Suspense>
  );
};
