import React from 'react';
import cn from 'classnames';
import * as css from './Range.css';

export const Range = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>(({ className, ...props }, ref) => (
  <input
    ref={ref}
    type="range"
    className={cn(css.inputRange, className)}
    {...props}
  />
));
