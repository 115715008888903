import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { getComponentWithRefAndName } from '@utils/withRef';
import { Color, ColorKey } from '../_common/colors';
import * as css from './ScrollBox.css';

export interface ScrollBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  fullHeight?: boolean;
  shadowColor?: ColorKey;
}

export const ScrollBoxWithShadow = getComponentWithRefAndName<
  HTMLDivElement,
  ScrollBoxProps
>(
  'ScrollBoxWithShadow',
  (
    {
      children,
      onScroll,
      fullHeight,
      shadowColor = 'greyLight30',
      id,
      className,
      ...props
    },
    ref,
  ) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const topShadowRef = useRef<HTMLDivElement | null>(null);
    const bottomShadowRef = useRef<HTMLDivElement | null>(null);

    const onScrollInner = () => {
      if (
        scrollRef.current &&
        topShadowRef.current &&
        bottomShadowRef.current
      ) {
        topShadowRef.current.style.opacity =
          scrollRef.current.scrollTop !== 0 ? '1' : '0.01';
        bottomShadowRef.current.style.opacity =
          scrollRef.current.scrollTop + scrollRef.current.offsetHeight <
          scrollRef.current.scrollHeight
            ? '1'
            : '0.01';
      }
    };

    useEffect(() => {
      onScrollInner();
    }, [children]);

    return (
      <div
        ref={ref}
        className={cn(css.scrollBoxContainer, className, {
          [css.fullHeight]: fullHeight,
        })}
      >
        <div
          className={cn(css.layout, className, {
            [css.fullHeight]: fullHeight,
          })}
          onScroll={(e) => {
            if (onScroll) {
              onScroll(e);
            }
            onScrollInner();
          }}
          ref={scrollRef}
          id={id}
          {...props}
        >
          {children}
        </div>
        <div
          ref={topShadowRef}
          className={css.topShadow}
          style={{
            background: `linear-gradient(to bottom, ${Color[shadowColor]} 0%, transparent 100%)`,
          }}
        />
        <div
          ref={bottomShadowRef}
          className={css.bottomShadow}
          style={{
            background: `linear-gradient(to top, ${Color[shadowColor]} 0%, transparent 100%)`,
          }}
        />
      </div>
    );
  },
);
