import React, { CSSProperties, MutableRefObject } from 'react';
import { Value, Editor as CoreEditor } from 'slate';
import { Editor, RenderNodeProps } from 'slate-react';
import { BaseEmoji } from 'emoji-mart';
import debounce from 'lodash-es/debounce';
import PlainSerializer from 'slate-plain-serializer';
import memoize from 'lodash-es/memoize';
import * as css from './BubbleEditor.css';
import { BubbleValue } from './BubbleValue';
import { RichPlaceholder } from './RichPlaceholder';
import { EmojiPicker } from '../EmojiPicker';
import { getSlateFixClassName } from '@utils/Contenteditable/getSlateFixClassName';
import cn from 'classnames';
import { PREVENT_CHROME_TRANSLATOR_CLASS } from 'cf-common/src/utils/ChromeTranslator/consts';

export function serialize(value: Value) {
  return PlainSerializer.serialize(value);
}

export function deserialize(value: string) {
  return PlainSerializer.deserialize(value);
}

const cursorIsAtStartOfParagraph = memoize(
  (change: CoreEditor) =>
    change.value.selection.isSet &&
    change.value.selection.start.isAtStartOfNode(
      change.value.startBlock.nodes.get(0),
    ),
);

export type ValidationResult = {
  isValid: boolean;
  errorMessage?: string | JSX.Element | null;
};

export interface ValidationErrorFunc {
  validationErrorFunc: (item: string) => ValidationResult;
}

export interface Props extends Partial<ValidationErrorFunc>, TestLocator {
  defaultValue?: string;
  value?: Value;

  onChange?(value: Value): void;

  onStringChange?(value: string): void;

  onFocus?: Function;
  onBlur?: Function;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  placeholder?: string | JSX.Element;
  className?: string;
  style?: CSSProperties;
  showControlsPlaceholder?: boolean;
  autoFocus?: boolean;
  readonly?: boolean;
  id?: string;
  example?: [string, string];
  showEmojiPicker?: boolean;
  preventSpaces?: boolean;
  bubbleStyle?: CSSProperties;
  shouldMoveCursorToEndOnFocus?: boolean;
  editorRef?: MutableRefObject<Editor | null>;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
}

interface State {
  editorState?: Value;
}

function renderNode(editorProps: RenderNodeProps, bubbleEditorProps: Props) {
  if (editorProps.node.object === 'block') {
    return (
      <BubbleValue
        {...editorProps}
        isFocused={editorProps.isFocused}
        validation={
          bubbleEditorProps.validationErrorFunc
            ? bubbleEditorProps.validationErrorFunc(editorProps.node.text)
            : { isValid: true }
        }
        style={bubbleEditorProps.bubbleStyle}
        data-testid={`${bubbleEditorProps['data-testid']}__text-node`}
      />
    );
  }
  return null;
}

const SPACE_REGEXP = /\s+/g;

export class BubbleEditor extends React.Component<Props, State> {
  editorInstance?: Editor;

  slateFocusTimeouts: number[] = [];

  insertEmoji = debounce((emoji: BaseEmoji) => {
    // debounce call for insert emoji after Editor component will be ready
    if (this.editorInstance) {
      const firstNode = this.editorInstance.value.document.getFirstText();
      const { selection } = this.editorInstance.value;
      if (
        firstNode &&
        firstNode.key === selection.anchor.key &&
        selection.anchor.offset === 0 &&
        selection.isBlurred
      ) {
        this.editorInstance.moveToEndOfDocument();
      }
      this.editorInstance.focus();
      this.editorInstance.insertText(emoji.native);
    }
  });

  public static defaultProps: Partial<Props> = {
    defaultValue: '',
    showControlsPlaceholder: false,
  };

  constructor(props: Props) {
    super(props);

    if (!props.value) {
      this.state = {
        editorState: PlainSerializer.deserialize(props.defaultValue as string),
      };
    } else {
      this.state = {};
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.mount = this.mount.bind(this);
  }

  componentWillUnmount() {
    this.insertEmoji.cancel();
    this.slateFocusTimeouts.forEach((timeout) => clearTimeout(timeout));
  }

  mount(editorInstance: Editor | null) {
    this.editorInstance = editorInstance || undefined;
  }

  focus() {
    if (this.editorInstance) {
      this.editorInstance.focus();
    }
  }

  handleChange({ value }: any) {
    if (!this.props.value) {
      this.setState({ editorState: value });
    }
    if (this.props.onStringChange) {
      this.props.onStringChange(serialize(value));
    }
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleKeyDown(
    event: React.KeyboardEvent<HTMLElement>,
    change: CoreEditor,
    next: () => any,
  ) {
    if (event.key === ' ' && this.props.preventSpaces) {
      event.preventDefault();
      return true;
    }

    if (
      event.key === 'Enter' &&
      (!change.value.focusBlock.text || cursorIsAtStartOfParagraph(change))
    ) {
      event.preventDefault();
      return true;
    }

    if (
      event.metaKey &&
      event.key === 'Backspace' &&
      cursorIsAtStartOfParagraph(change)
    ) {
      change.moveToStartOfPreviousBlock().focus().moveToEndOfBlock().delete();
    }

    this.props.onKeyDown?.(event);

    return next();
  }

  handlePaste = (event: Event, editor: CoreEditor, next: Function) => {
    if (!this.props.preventSpaces) {
      return next();
    }
    const { clipboardData } = event as ClipboardEvent;
    editor.insertText(
      (clipboardData?.getData('Text') || '').replace(SPACE_REGEXP, ''),
    );
    return false;
  };

  handleFocus(event: Event, editor: CoreEditor, next: () => any) {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
    if (this.props.shouldMoveCursorToEndOnFocus) {
      setTimeout(() => {
        editor?.moveToEndOfDocument();
        editor?.focus();
      });
      return undefined;
    }
    return next();
  }

  renderEditor = (
    effectiveValue: Value | undefined,
    bind?: {
      ref: React.MutableRefObject<Editor>;
      onFocus: () => void;
      onBlur: () => void;
    },
  ) => {
    const { className, readonly, id, onBlur, autoFocus, style } = this.props;
    return (
      <Editor
        ref={(editor) => {
          this.mount(editor);
          if (bind && editor) {
            // eslint-disable-next-line no-param-reassign
            bind.ref.current = editor;
          }
          if (this.props.editorRef) {
            this.props.editorRef.current = editor;
          }
        }}
        value={effectiveValue!}
        onChange={this.handleChange}
        onPaste={this.handlePaste}
        onKeyDown={this.handleKeyDown as any}
        onFocus={(...params) => {
          if (bind) {
            // @ts-ignore to skip Timeout to number conversions in tests
            this.slateFocusTimeouts.push(setTimeout(() => bind.onFocus())); // need call on next tic for correct focus work in Slate
          }
          return this.handleFocus(...params);
        }}
        onBlur={(event, __, next) => {
          if (bind) {
            // @ts-ignore to skip Timeout to number conversions in tests
            this.slateFocusTimeouts.push(setTimeout(() => bind.onBlur())); // need call on next tic for correct focus work in Slate
          }
          setTimeout(() => onBlur && onBlur(event));
          return next();
        }}
        renderNode={(props) => renderNode(props, this.props)}
        className={cn(
          className,
          getSlateFixClassName(),
          PREVENT_CHROME_TRANSLATOR_CLASS,
        )}
        style={style}
        readOnly={readonly}
        autoFocus={autoFocus}
        {...{ id }}
      />
    );
  };

  render() {
    const { value, showControlsPlaceholder, placeholder, showEmojiPicker } =
      this.props;
    const effectiveValue = value || this.state.editorState;
    const isEmpty = !effectiveValue!.document.text;
    const shouldShowPlaceholder = showControlsPlaceholder || placeholder;
    return (
      <div
        data-testid={this.props['data-testid']}
        className={css.editorContainer}
        style={{ position: 'relative' }}
        ref={this.props.containerRef}
      >
        {isEmpty && shouldShowPlaceholder ? (
          <div className={css.placeholderContainer}>
            {showControlsPlaceholder ? (
              <RichPlaceholder exampleWords={this.props.example} />
            ) : (
              placeholder
            )}
          </div>
        ) : null}
        {showEmojiPicker ? (
          <EmojiPicker oneLineInputHeight={38} onSelect={this.insertEmoji}>
            {({ bind }) => this.renderEditor(effectiveValue, bind)}
          </EmojiPicker>
        ) : (
          this.renderEditor(effectiveValue)
        )}
      </div>
    );
  }
}
