import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import React from 'react';
import cn from 'classnames';
import css from './BotListItem.css';

interface BotListItemLoaderProps {
  className?: string;
}

export const BotListItemLoader: React.FC<BotListItemLoaderProps> = ({
  className,
}) => (
  <LoadingPlaceholder
    className={cn(css.loader, className)}
    height={68}
    color="white"
  />
);
