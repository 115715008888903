import React from 'react';
import cn from 'classnames';

import { getComponentWithRefAndName } from '@utils/withRef';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';

import { Anchor } from '../Anchor';

import * as css from './TutorialLinks.css';

interface LinkItem {
  href: string;
  text: string;
  target?: string;
  maxWidth?: number;
  onClick?: () => void;
}

enum TutorialLinksVariants {
  default = 'default',
  noaccent = 'noaccent',
  card = 'card',
}

interface TutorialLinksProps extends React.HTMLAttributes<HTMLDivElement> {
  renderIcon?: () => JSX.Element;
  variant: keyof typeof TutorialLinksVariants;
  linkItems: LinkItem[];
}

export const TutorialLinks = getComponentWithRefAndName<
  HTMLDivElement,
  TutorialLinksProps
>(
  'TutorialLinks',
  ({ renderIcon, className, linkItems, children, variant, ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn(css.tutorialLinks, className, {
        [css.containerDefault]: variant === 'default',
        [css.containerNoaccent]: variant === 'noaccent',
        [css.containerCard]: variant === 'card',
      })}
    >
      {renderIcon && <div className={css.icon}>{renderIcon()}</div>}
      {children && (
        <div className={css.content}>
          <Type
            size="15px_DEPRECATED"
            weight="semibold"
            color={variant === 'card' ? 'white' : 'black'}
          >
            {children}
          </Type>
        </div>
      )}
      {linkItems.map(
        ({ text, href, target = '_blank', onClick, maxWidth }, index) => (
          <React.Fragment key={href}>
            {index > 0 && (
              <div className={css.dot}>
                <Type color="white" weight="semibold" size="15px_DEPRECATED">
                  •
                </Type>
              </div>
            )}
            <Flex
              wrap="nowrap"
              alignItems="center"
              className={cn({
                [css.linkDefault]: variant !== 'card',
                [css.linkCard]: variant === 'card',
              })}
            >
              <Anchor
                intent="unstyled"
                hideArrow
                className={css.link}
                style={{ maxWidth: maxWidth || 170 }}
                href={href}
                target={target}
                title={text}
                onClick={onClick}
              >
                {text}
              </Anchor>
              <Anchor
                intent="unstyled"
                hideArrow
                className={css.linkIconWrapper}
                href={href}
                target={target}
                title={text}
                onClick={onClick}
              >
                <Icon
                  noColor
                  icon="externalLink"
                  size="8px"
                  className={css.externalLinkIcon}
                />
              </Anchor>
            </Flex>
          </React.Fragment>
        ),
      )}
    </div>
  ),
);
