import React from 'react';
import { Flex } from '../Flex';
import { Button } from '../Button';
import { Icon } from '../Icon';
import * as css from './Pagination.css';
import { Spacer } from '../Spacer';

interface PaginationProps {
  currentPageIndex: number;
  onPageChange: (index: number) => void;
  pagesQty: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPageIndex,
  onPageChange,
  pagesQty,
}) => (
  <Flex justifyContent="center" className={css.pagination}>
    {currentPageIndex > 1 ? (
      <Button
        intent="text"
        fitToText
        onClick={() => {
          onPageChange(currentPageIndex - 1);
        }}
        data-testid="pagination__button-prev"
      >
        <Icon className={css.iconLeft} icon="triangle" />
      </Button>
    ) : (
      <Spacer horizontalFactor={9.5} />
    )}
    {Array.from({ length: pagesQty }).map((_, index) => (
      <Button
        intent={currentPageIndex - 1 === index ? 'primary' : 'text'}
        disabled={currentPageIndex - 1 === index}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        fitToText
        onClick={() => {
          onPageChange(index + 1);
        }}
        data-testid={`pagination__button-next-${index}`}
      >
        {index + 1}
      </Button>
    ))}
    {currentPageIndex < pagesQty ? (
      <Button
        fitToText
        intent="text"
        onClick={() => {
          onPageChange(currentPageIndex + 1);
        }}
        data-testid="pagination__button-next"
      >
        <Icon className={css.iconRight} icon="triangle" />
      </Button>
    ) : (
      <Spacer horizontalFactor={9.5} />
    )}
  </Flex>
);
