import React from 'react';
import cn from 'classnames';
import * as styles from './BlockOverlay.css';

type BlockOverlayProps = React.HTMLProps<HTMLDivElement>;
export const BlockOverlay: React.FC<BlockOverlayProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={cn(styles.blockOverlay, className)} {...props}>
      {children}
    </div>
  );
};
