import React, { useCallback, useRef } from 'react';
import { defaultItemToString } from '../SimpleCombobox';
import { MenuComboboxBase, MenuComboboxBaseProps } from './MenuComboboxBase';
import { RenderInputBaseProps } from './types';

interface InputProps {
  inputRef: any;
  onClick: any;
  selectInputValue: () => void;
}

export interface InputMenuComboboxBaseProps<T>
  extends Omit<MenuComboboxBaseProps<T>, 'renderInput'> {
  renderInput: (
    props: RenderInputBaseProps<T> & { props: InputProps },
  ) => React.ReactNode;
}

export function InputMenuComboboxBase<T>({
  renderInput,
  itemToString = defaultItemToString,
  ...props
}: InputMenuComboboxBaseProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null);

  const selectInputValue = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [inputRef]);

  return (
    <MenuComboboxBase
      itemToString={itemToString}
      renderInput={({ popperReference, downshift }) => {
        // fix bad focus behaviour in safari (focus passed to parent)
        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
          downshift.getToggleButtonProps().onClick(e);
        };

        const props = {
          inputRef,
          onClick,
          selectInputValue,
        };

        return renderInput({ popperReference, downshift, props });
      }}
      {...props}
    />
  );
}
