import ng from 'angular';
import { react2angular } from 'react2angular';
import { AvatarWithPlatform } from './AvatarWithPlatform';

export const ngAvatarWithPlatform = ng
  .module('app.modernUI.avatarWithPlatform', [])
  .component(
    'avatarWithPlatform',
    react2angular(AvatarWithPlatform, ['src', 'size', 'platform'], []),
  ).name;
