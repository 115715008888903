import * as PopperJS from 'popper.js';
import { PropFunc } from '@components/FlowBuilder/types';
import {
  MenuItemType,
  Tooltip,
} from '@components/FlowBuilder/views/Menu/types';
import { CSSProperties } from 'react';

export enum NestedMenuNodeDisplayMode {
  nested,
  structure,
  noTitlteStructure,
}

export interface NestedMenuItem<T = {}> {
  id: string;
  img?: React.FC;
  imageUrl?: string;
  type?: MenuItemType;
  titleElement?: React.ReactNode;
  title?: string;
  tooltip?: PropFunc<Tooltip>;
  newLabel?: boolean;
  children?: Array<T & NestedMenuItem<T>> | Group<T & NestedMenuItem<T>>;
  displayMode?: NestedMenuNodeDisplayMode;
}

type RightElementModifier<T> = ({
  item,
  index,
  active,
}: {
  item: T;
  index: number;
  active: boolean;
}) => React.ReactNode;

export interface ExtendStyles<T> {
  getItemStyles?: (item: T, index: number) => CSSProperties | undefined;
  getTitleStyles?: (item: T, index: number) => CSSProperties | undefined;
  getGroupStyles?: (
    group: Array<T>,
    index: number,
  ) => CSSProperties | undefined;
  getRightElement?: RightElementModifier<T>;
}

export interface RenderContent<T> {
  renderTooltip?: (
    tooltip: NestedMenuItem['tooltip'],
    id: string,
  ) => React.ReactElement;
  renderGroupView?: (item: T) => React.ReactElement;
}

export type Items<T> = Array<T>;
export type Group<T> = Array<Array<T>>;
export type MenuItems<T> = Array<T> | Group<T>;

export type TooltipPlacement = PopperJS.Placement;
