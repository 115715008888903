import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';

import { globalHistory } from '@utils/Routing';
import { Modal } from '@services/.';
import { modalEventEmitter, ModalEvent } from '@services/ModalService/events';
import { ModalData, ModalEventOptions } from '@services/ModalService/types';

import { Modal as ModalComponent } from './Modal';
import { MobileModal } from './MobileModal';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { MobileModalProps, ModalProps } from './types';

export const ModalContainer: React.FC = () => {
  const [activeModal, setActiveModal] = useState<ModalData | null>(null);
  const [activeModalOptions, setActiveModalOptions] = useState<
    ModalEventOptions | undefined
  >(undefined);
  const [onOverlayDismiss, setOnOverlayDismiss] = useState<
    (() => void) | null | undefined
  >(null);
  const { isSmallScreenSize } = useDeviceMedia();

  useEffect(() => {
    const unsubscribes = [
      modalEventEmitter.on<ModalData>(ModalEvent.show, (modal, options) => {
        if (
          activeModal &&
          options?.skipOverloadActive &&
          options.skipOverloadActive(activeModalOptions)
        ) {
          return;
        }

        if (activeModal && !options?.keepPreviousModal) {
          Modal.hide(activeModal.id);
        }
        setActiveModal(modal);
        setActiveModalOptions(options);

        setOnOverlayDismiss(() => options?.onOverlayDismiss);
      }),
      modalEventEmitter.on<ModalData>(ModalEvent.hide, () => {
        setActiveModal(null);
        setActiveModalOptions(undefined);
      }),
    ];
    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [activeModal, activeModalOptions]);

  const baseProps = activeModal && {
    key: activeModal.id,
    onDismiss: () => {
      onOverlayDismiss?.();
      setOnOverlayDismiss(null);
      Modal.hide(activeModal.id);
    },
  };

  const mobileModalProps: MobileModalProps = {
    ...activeModalOptions?.mobileProps,
    hideCloseButton: activeModalOptions?.preventDefaultClose,
  };

  const modalComponentProps: ModalProps = {
    overlayClassName: activeModalOptions?.overlayClassName,
  };

  return activeModal ? (
    <Router history={globalHistory}>
      {activeModalOptions?.mobileAdaptive && isSmallScreenSize ? (
        <MobileModal {...baseProps} {...mobileModalProps}>
          {activeModal.content}
        </MobileModal>
      ) : (
        <ModalComponent {...baseProps} {...modalComponentProps}>
          {activeModal.content}
        </ModalComponent>
      )}
    </Router>
  ) : null;
};
