import React, { useRef, useState, useCallback, useMemo } from 'react';
import { BaseEmoji } from 'emoji-mart';
import { Focus } from 'react-powerplug';
import cn from 'classnames';
import { InputLikeElement } from '@utils/documentExecCommand';
import { EmojiPopup } from './EmojiPopup';
import { ReactComponent as FaceIcon } from './images/face.svg';
import { ButtonUnstyled } from '../Button';
import * as css from './EmojiPicker.css';

interface EmojiIconProps {
  ref: React.MutableRefObject<HTMLButtonElement | null>;
  onClick(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
  onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>): void;
}

interface EmojiChildrenProps {
  bind: {
    ref: React.MutableRefObject<any>;
    onFocus: () => void;
    onBlur: () => void;
  };
  focused: boolean;
  isOpen?: boolean;
  emojiIconProps?: EmojiIconProps;
}

interface EmojiPickerProps {
  onSelect: (emoji: BaseEmoji, el: InputLikeElement) => void;
  oneLineInputHeight?: number;
  buttonSpaceWidth?: number;
  externalEmojiIcon?: boolean;
  children: (props: EmojiChildrenProps) => React.ReactNode;
  disabled?: boolean;
}

export const FACE_ICON_HEIGHT = 20;

export const EmojiPicker: React.FC<EmojiPickerProps> = ({
  onSelect,
  children,
  buttonSpaceWidth = 32,
  oneLineInputHeight = 36,
  externalEmojiIcon,
  disabled,
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const externalEmojiRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLElement | null>(null);
  const [showPicker, setShowPicker] = useState(false);
  const closePopup = useCallback(() => {
    setShowPicker(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const emojiIconProps: EmojiIconProps = useMemo(
    () => ({
      ref: externalEmojiRef,
      onClick: (event) => {
        event.preventDefault();
        setShowPicker(true);
      },
      onMouseDown: (event) => {
        event.preventDefault();
      },
    }),
    [],
  );

  return (
    <>
      <div
        ref={boxRef}
        style={{
          paddingRight: buttonSpaceWidth,
        }}
        className={css.box}
      >
        <Focus>
          {({ bind, focused }) => (
            <>
              {children({
                bind: { ref: inputRef, ...bind },
                focused,
                isOpen: showPicker,
                emojiIconProps,
              })}
              {!externalEmojiIcon && focused && (
                <ButtonUnstyled
                  {...emojiIconProps}
                  style={{
                    bottom: (oneLineInputHeight - FACE_ICON_HEIGHT) * 0.5,
                  }}
                  className={cn(css.button, {
                    [css.active]: showPicker,
                  })}
                  disabled={disabled}
                >
                  <FaceIcon />
                </ButtonUnstyled>
              )}
            </>
          )}
        </Focus>
      </div>
      {showPicker && (
        <EmojiPopup
          onSelect={(emoji) => {
            closePopup();
            if (inputRef.current) {
              onSelect(emoji, inputRef.current);
            }
          }}
          onRequestClose={closePopup}
          referenceElement={
            (externalEmojiIcon
              ? externalEmojiRef.current
              : boxRef.current) as Element
          }
        />
      )}
    </>
  );
};
