import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useState } from 'react';
import css from './BotTitleInput.css';

interface BotTitleInputProps {
  onTitleChange(newTitle: string): void;
  title: string;
  className?: string;
}

export const BotTitleInput: React.FC<BotTitleInputProps> = ({
  onTitleChange,
  title,
  className,
}) => {
  const [value, setValue] = useState(title);
  const { t } = useSafeTranslation();

  const handleRenameClick = () => {
    onTitleChange(value);
  };

  return (
    <Flex className={className}>
      <Input
        containerClassName={css.titleInput}
        autoFocus
        value={value}
        onChange={(e) => setValue(e.target.value)}
        data-testid="bot_title_input"
      />
      <Spacer horizontalFactor={3} />
      <Button onClick={handleRenameClick} data-testid="bot_title_rename_button">
        {t('BotList.renameButton')}
      </Button>
    </Flex>
  );
};
