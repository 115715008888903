import React from 'react';
import cn from 'classnames';
import * as css from './Radio.css';

export interface RadioButtonProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  renderLabel?: () => React.ReactNode;
  reversed?: boolean;
  verticallyCentered?: boolean;
  labelClassName?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  id,
  label,
  renderLabel,
  labelClassName,
  reversed,
  verticallyCentered,
  ...props
}) => {
  return (
    <div className={props.className} data-checked={id === value}>
      <input
        {...props}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        className={cn(css.radio, props.disabled && css.disabled)}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        htmlFor={id}
        className={cn(css.label, labelClassName, {
          [css.right]: reversed,
          [css.center]: verticallyCentered,
        })}
      >
        {label}
        {renderLabel && renderLabel()}
      </label>
    </div>
  );
};
