interface Indexable {
  index?: number;
}

/**
 * Проставляем значение поле index порядковым номером елемента в списке,
 * пропуская элементы удовлетворяющие предикату `skipIndexPredicate`
 */
export function rearrangeIndexes<T extends Indexable>(
  items: T[],
  skipIndexPredicate: (item: T) => boolean,
): T[] {
  let counter = 0;
  return items.map((item) => {
    if (skipIndexPredicate(item)) return item;
    const index = counter;
    counter++;
    return { ...item, index };
  });
}
