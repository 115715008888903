import React, { MouseEventHandler } from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '@ui/Button';
import { Icon, IconProps } from '@ui/Icon';
import { getComponentWithRefAndName } from '@utils/withRef';
import * as css from './IconButton.css';

interface IconButtonProps extends IconProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  noBackground?: boolean;
}

export const IconButton = getComponentWithRefAndName<
  HTMLButtonElement,
  IconButtonProps
>(
  'IconButton',
  (
    {
      className,
      noBackground,
      icon,
      size,
      color,
      noColor,
      filled,
      ...buttonProps
    },
    ref,
  ) => {
    const iconProps = { icon, size, color, noColor, filled };
    return (
      <ButtonUnstyled
        ref={ref}
        className={cn(css.iconButton, className, {
          [css.noBackground]: noBackground,
        })}
        {...buttonProps}
      >
        <Icon {...iconProps} />
      </ButtonUnstyled>
    );
  },
);
