import React, { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import {
  InputMenuComboboxBase,
  InputMenuComboboxBaseProps,
} from './InputMenuComboboxBase';
import {
  ComboboxFilterType,
  defaultFilterItemsToShow,
  defaultItemToString,
} from '../SimpleCombobox';
import { ComboboxChildrenProps, CurrentItems, RenderEmptyState } from './types';

export interface FilterableInputMenuComboboxProps<T>
  extends Omit<InputMenuComboboxBaseProps<T>, 'children'>,
    RenderEmptyState<T> {
  filter?: ComboboxFilterType<T>;
  items: Array<T>;
  children: (
    props: ComboboxChildrenProps<T> & { props: CurrentItems<T> },
  ) => React.ReactNode;
}

export function FilterableInputMenuCombobox<T>({
  items,
  renderEmptyState,
  children,
  filter = defaultFilterItemsToShow,
  itemToString = defaultItemToString,
  ...props
}: FilterableInputMenuComboboxProps<T>) {
  const filterMemoized = useMemo(() => memoizeOne(filter), [filter]);

  return (
    <InputMenuComboboxBase {...props}>
      {({ popper, downshift }) => {
        const currentItems = filterMemoized(
          itemToString,
          items,
          downshift.selectedItem as T,
          downshift.inputValue,
        );

        if (currentItems.length === 0) {
          return renderEmptyState({ popper, downshift });
        }

        return children({ popper, downshift, props: { currentItems } });
      }}
    </InputMenuComboboxBase>
  );
}
