import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Portal } from 'react-portal';
import { Manager, Popper, Reference } from 'react-popper';
import Downshift from 'downshift';
import { Hover } from 'react-powerplug';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  anyPass,
  isEmpty,
  always,
  contains,
  pipe,
  prop,
  toLower,
  defaultTo,
  equals,
  propOr,
} from 'ramda';
import { Modal } from '@services/index';
import { CurrentFlowAttributesDialog } from '@components/GlobalAttributesDialog';
import { VariablesType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { getAttributeDescription } from '@utils/AttributesUtils/AttributesDescription';
import { usePreventWheel } from '@utils/Event/usePreventWheel';
import { sendEvent } from '@utils/Analytics';
import {
  ATTR_SIGN,
  CLEAR_QUERY_REQ_EXP,
  EditorModeEnum,
} from './TextWithAttributesEditorConsts';
import ScrollBox from '../ScrollBox/ScrollBox';
import { Menubox, MenuItem, Divider } from '../Menu';
import { AttributesQuery_bot_variableSuggest as IAttribute } from '../../utils/AttributesUtils/@types/AttributesQuery';
import { ReactComponent as AddIcon } from '../_deprecated/Icon/icons/ic_add_small.svg';
import { TooltipPure } from '../Tooltip2';
import { KeyDownHandler } from './KeyDownHandler/KeyDownHandler';
import * as css from './Attribute.css';
import { ModalChainable } from '@services/ModalService/types';

enum ActionType {
  manageAttributes = 'manageAttributes',
}

interface AttributeMenuItem {
  name: string;
  action?: ActionType;
}

export interface ContextProps {
  modal: ModalChainable<unknown, void> | null;
}

export interface AttributeProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {
  mode: EditorModeEnum;
  children: React.ReactElement;
  onSuggestSelect: (value: string, start: number, offset: number) => void;
  onManageAttributesClick?(context: ContextProps): void;
  attributes: IAttribute[];
  start: number;
  caretOffset?: number;
  highlightFullAttributesOnly?: boolean;
  hasManageAttributes?: boolean;
}

export const itemToString = prop('name');
export const itemsFilter = (query: string) =>
  anyPass([
    always(isEmpty(query)),
    pipe(defaultTo({} as any), prop('name'), toLower as any, contains(query)),
  ]) as any;

export const nameMatches = (name: string) =>
  pipe(propOr('', 'name'), toLower as any, equals(toLower(name)));

export const Attribute: React.FC<AttributeProps> = ({
  className,
  children,
  caretOffset,
  start,
  mode,
  attributes,
  highlightFullAttributesOnly,
  hasManageAttributes,
  onManageAttributesClick,
  onSuggestSelect,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const text =
    typeof children.props.children === 'string' ? children.props.children : '';
  const focused = caretOffset !== undefined;
  const query = text
    .substr(0, caretOffset)
    .replace(CLEAR_QUERY_REQ_EXP, '')
    .replace(/}$/, '');
  const queryLowerCase = query.toLowerCase();
  const offsetRef = useRef(0);
  const startRef = useRef(0);
  const scheduleUpdateRef = useRef<undefined | (() => void)>();
  const isAttrHasEndSign = text.slice(-2) === ATTR_SIGN.end;
  startRef.current = start;
  offsetRef.current =
    !isAttrHasEndSign && caretOffset !== undefined ? caretOffset : text.length;

  const handleSelectItem = (item: AttributeMenuItem) => {
    if (item.action === ActionType.manageAttributes) {
      sendEvent({
        category: 'flows',
        action: 'manage attributes',
        label: 'block menu',
      });
      const modal = Modal.show(({ close }) => (
        <CurrentFlowAttributesDialog onDismiss={close} />
      ));
      onManageAttributesClick?.({ modal });
    } else {
      onSuggestSelect(item.name, startRef.current, offsetRef.current);
    }
  };

  useEffect(() => scheduleUpdateRef.current?.(), [children, caretOffset]);

  const dropdownBoxRef = usePreventWheel();

  const clearAttrText = text.replace(CLEAR_QUERY_REQ_EXP, '');
  const description = getAttributeDescription(clearAttrText);
  const needHighlight =
    !highlightFullAttributesOnly ||
    attributes.some(({ name }) => name === clearAttrText);

  return mode === EditorModeEnum.view ? (
    isEmpty(clearAttrText) ? (
      text
    ) : (
      <Hover>
        {({ hovered, bind }) => (
          <TooltipPure
            content={description}
            show={hovered && description}
            type="small"
            placement="top-start"
            disableFlip
          >
            {(ref) => (
              <div
                title={clearAttrText}
                className={cn(
                  css.Attribute,
                  {
                    [css.attributeHighlight]: needHighlight,
                    [css.viewMode]: needHighlight,
                  },
                  className,
                )}
                ref={ref}
                {...bind}
              >
                <span className={cn(css.attrSign, css.start)}>
                  {ATTR_SIGN.start}
                </span>
                <span className={css.attrText}>{clearAttrText}</span>
                <span className={cn(css.attrSign, css.end)}>
                  {ATTR_SIGN.end}
                </span>
              </div>
            )}
          </TooltipPure>
        )}
      </Hover>
    )
  ) : (
    <Downshift
      itemToString={itemToString}
      onSelect={handleSelectItem}
      initialIsOpen
      initialHighlightedIndex={0}
    >
      {({ getItemProps, highlightedIndex, getInputProps }) => {
        const items = attributes.filter(itemsFilter(queryLowerCase));
        const addNewItem: AttributeMenuItem = {
          name: query,
        };
        const manageAttributesItem: AttributeMenuItem = {
          name: '',
          action: ActionType.manageAttributes,
        };

        const shouldShowAddButton =
          queryLowerCase.length > 0 &&
          !items.some(nameMatches(queryLowerCase)) &&
          !highlightFullAttributesOnly;

        const highlightedIndexOffset = shouldShowAddButton ? 1 : 0;

        const lastIndex = items.length + highlightedIndexOffset;

        return (
          <div
            className={cn(
              css.Attribute,
              { [css.attributeHighlight]: needHighlight },
              className,
            )}
          >
            <KeyDownHandler
              focused={focused}
              onKeyDown={(event) => {
                getInputProps().onKeyDown(event);
                if (event.key === 'Enter') {
                  event.stopPropagation();
                }
              }}
            />
            <Manager>
              <Reference>
                {({ ref }) => (
                  <span {...props} ref={ref}>
                    {children}
                  </span>
                )}
              </Reference>
              {focused && (items.length || shouldShowAddButton) ? (
                <Portal>
                  <div ref={dropdownBoxRef}>
                    <Popper
                      placement="bottom-start"
                      positionFixed
                      modifiers={{
                        preventOverflow: {
                          enabled: true,
                          boundariesElement: 'viewport',
                          escapeWithReference: false,
                        },
                        flip: {
                          enabled: true,
                          boundariesElement: 'viewport',
                        },
                        hide: {
                          enabled: true,
                          fn: (data) => {
                            const { left, top } = data.offsets.popper;
                            /**
                             *  get boundaries of real editor container for limit left
                             *  position of suggest (for other limits we use viewport boundaries in preventOverflow and flip modifiers)
                             */
                            const editorInstance = (
                              data.instance as any
                            ).reference.closest('div[contenteditable]');
                            if (!editorInstance) {
                              return data;
                            }
                            const editorContainerLeft =
                              editorInstance.parentElement.getBoundingClientRect()
                                .left;
                            // eslint-disable-next-line no-param-reassign
                            data.styles.transform = `translate3d(${Math.round(
                              Math.max(editorContainerLeft, left),
                            )}px, ${Math.round(top)}px, 0)`;
                            return data;
                          },
                        },
                      }}
                    >
                      {({ ref, style, scheduleUpdate }) => {
                        scheduleUpdateRef.current = scheduleUpdate;
                        return (
                          <div className={css.menu} ref={ref} style={style}>
                            <Menubox>
                              <ScrollBox>
                                {shouldShowAddButton && (
                                  <MenuItem
                                    {...getItemProps({
                                      item: addNewItem,
                                      index: 0,
                                    })}
                                    title={query}
                                    active={highlightedIndex === 0}
                                    className={css.menuItem}
                                    leftElement={
                                      <AddIcon className={css.addIcon} />
                                    }
                                  />
                                )}
                                {items.map((item, i) => (
                                  <Hover key={item.id}>
                                    {({ hovered, bind }) => {
                                      const description =
                                        getAttributeDescription(item.name);
                                      const actualIndex =
                                        highlightedIndexOffset + i;
                                      return (
                                        <TooltipPure
                                          content={description}
                                          show={hovered && description}
                                          type="small"
                                          placement="right"
                                          disableFlip={false}
                                          boundariesElement="viewport"
                                        >
                                          {(ref) => (
                                            <MenuItem
                                              {...getItemProps({
                                                item,
                                                index: actualIndex,
                                                ...bind,
                                              })}
                                              title={item.name}
                                              subtitle={
                                                item.type ===
                                                VariablesType.custom
                                                  ? item.type
                                                  : undefined
                                              }
                                              active={
                                                highlightedIndex === actualIndex
                                              }
                                              className={css.menuItem}
                                              innerRef={ref}
                                            />
                                          )}
                                        </TooltipPure>
                                      );
                                    }}
                                  </Hover>
                                ))}
                              </ScrollBox>
                              {hasManageAttributes && (
                                <>
                                  <Divider />
                                  <MenuItem
                                    {...getItemProps({
                                      item: manageAttributesItem,
                                      index: lastIndex,
                                    })}
                                    title={t(
                                      'modernUi.TextWithAttributesEditor.Attribute.manageAttributes',
                                    )}
                                    className={css.menuItem}
                                    active={highlightedIndex === lastIndex}
                                  />
                                </>
                              )}
                            </Menubox>
                          </div>
                        );
                      }}
                    </Popper>
                  </div>
                </Portal>
              ) : null}
            </Manager>
          </div>
        );
      }}
    </Downshift>
  );
};
