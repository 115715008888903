import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import css from './Stepper2.css';

interface Stepper2Props {
  current: number;
  count: number;
}

export const Stepper2: React.FC<Stepper2Props> = ({ current, count }) => {
  return (
    <Flex alignItems="center" className={css.box}>
      {Array.from({ length: count }).map((_, step) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={step}>
          <Flex
            alignItems="center"
            justifyContent="center"
            className={cn(css.step, step < current + 1 && css.active)}
          >
            <Type size="18px" color="white" weight="bold">
              {step + 1}
            </Type>
          </Flex>
          {step + 1 < count && (
            <div className={cn(css.line, step < current && css.active)} />
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};
