import React, { FC } from 'react';
import copyToClipboard from 'clipboard-copy';
import { ITextAreaProps, TextArea } from '../Input/TextArea';
import { removeExtraSpaces } from '../../utils/String/removeExraSpaces';
import { useCopiedToaster } from './useCopiedToaster';

interface CodeTextAreaProps extends ITextAreaProps {
  onCopy?: () => void;
}

export const CodeTextArea: FC<CodeTextAreaProps> = ({
  ref,
  onCopy,
  ...props
}) => {
  const showCopiedToaster = useCopiedToaster();
  const miniCode = removeExtraSpaces((props.value || '').toString(), ' ');
  return (
    <TextArea
      {...props}
      readOnly
      onDoubleClick={() => {
        copyToClipboard(miniCode);
        showCopiedToaster();
        onCopy?.();
      }}
      onCopy={(event) => {
        event.clipboardData.setData('text/plain', miniCode);
        event.preventDefault();
      }}
    />
  );
};
