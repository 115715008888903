import React from 'react';
import noop from 'lodash-es/noop';
import { Icon } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button/Unstyled';
import cn from 'classnames';
import * as css from './ClosableButton.css';
import { Type } from '../../Type';

interface ClosableButtonProps {
  text: string;
  onAction(): void;
  onClose(): void;
  dark?: boolean;
}

export const ClosableButton = React.memo<ClosableButtonProps>(
  ({ text, onAction, onClose, dark }) => (
    <div className={cn(css.mainContainer, { [css.dark]: dark })}>
      <div className={css.actionBlock} onClick={onAction} onKeyDown={noop}>
        <ButtonUnstyled className={css.playIconWraper}>
          <Icon icon="play" size="28px" noColor />
        </ButtonUnstyled>
        <Type
          className={css.text}
          size="15px"
          weight="medium"
          whiteSpace="nowrap"
        >
          {text}
        </Type>
      </div>
      <ButtonUnstyled className={css.closeIconWraper} onClick={onClose}>
        <Icon icon="close" size="20px" noColor />
      </ButtonUnstyled>
    </div>
  ),
);
