/**
 * Autogenerated file
 * DONT CHANGE
 */

import { Emojis } from './Emoji.types';

import Emoji_1f62c from './images/1f62c.png';
import Emoji_1f923 from './images/1f923.png';
import Emoji_1f929 from './images/1f929.png';
import Emoji_1f64f from './images/1f64f.png';
import Emoji_1f60b from './images/1f60b.png';
import Emoji_1f973 from './images/1f973.png';
import Emoji_1f606 from './images/1f606.png';
import Emoji_1f60e from './images/1f60e.png';
import Emoji_1f607 from './images/1f607.png';
import Emoji_1f62d from './images/1f62d.png';
import Emoji_1f468_200d_1f469_200d_1f467_200d_1f466 from './images/1f468-200d-1f469-200d-1f467-200d-1f466.png';
import Emoji_1f3af from './images/1f3af.png';
import Emoji_1f4f2 from './images/1f4f2.png';
import Emoji_23f1_fe0f from './images/23f1-fe0f.png';
import Emoji_1f4ac from './images/1f4ac.png';
import Emoji_1f389 from './images/1f389.png';
import Emoji_1f634 from './images/1f634.png';
import Emoji_1f4b5 from './images/1f4b5.png';
import Emoji_1f525 from './images/1f525.png';
import Emoji_1f91d from './images/1f91d.png';
import Emoji_1f61e from './images/1f61e.png';
import Emoji_2764_fe0f from './images/2764-fe0f.png';
import Emoji_2699_fe0f from './images/2699-fe0f.png';
import Emoji_26a0_fe0f from './images/26a0-fe0f.png';
import Emoji_2728 from './images/2728.png';
import Emoji_1f64c from './images/1f64c.png';
import Emoji_1f47b from './images/1f47b.png';
import Emoji_1f4a1 from './images/1f4a1.png';
import Emoji_1f680 from './images/1f680.png';
import Emoji_1f6cd_fe0f from './images/1f6cd-fe0f.png';
import Emoji_1f4c8 from './images/1f4c8.png';
import Emoji_1f58c_fe0f from './images/1f58c-fe0f.png';
import Emoji_1f527 from './images/1f527.png';
import Emoji_1f1ec_1f1e7 from './images/1f1ec-1f1e7.png';
import Emoji_1f1fa_1f1f8 from './images/1f1fa-1f1f8.png';
import Emoji_1f1e8_1f1e6 from './images/1f1e8-1f1e6.png';
import Emoji_1f1f2_1f1fe from './images/1f1f2-1f1fe.png';
import Emoji_1f1e6_1f1f9 from './images/1f1e6-1f1f9.png';
import Emoji_1f1e7_1f1ea from './images/1f1e7-1f1ea.png';
import Emoji_1f1e7_1f1f7 from './images/1f1e7-1f1f7.png';
import Emoji_1f1ea_1f1f8 from './images/1f1ea-1f1f8.png';
import Emoji_1f1f9_1f1ed from './images/1f1f9-1f1ed.png';
import Emoji_1f1ee_1f1e9 from './images/1f1ee-1f1e9.png';

export const EmojisPaths: Record<Emojis, string> = {
  '😬': Emoji_1f62c,
  '🤣': Emoji_1f923,
  '🤩': Emoji_1f929,
  '🙏': Emoji_1f64f,
  '😋': Emoji_1f60b,
  '🥳': Emoji_1f973,
  '😆': Emoji_1f606,
  '😎': Emoji_1f60e,
  '😇': Emoji_1f607,
  '😭': Emoji_1f62d,
  '👨‍👩‍👧‍👦': Emoji_1f468_200d_1f469_200d_1f467_200d_1f466,
  '🎯': Emoji_1f3af,
  '📲': Emoji_1f4f2,
  '⏱': Emoji_23f1_fe0f,
  '💬': Emoji_1f4ac,
  '🎉': Emoji_1f389,
  '😴': Emoji_1f634,
  '💵': Emoji_1f4b5,
  '🔥': Emoji_1f525,
  '🤝': Emoji_1f91d,
  '😞': Emoji_1f61e,
  '❤️': Emoji_2764_fe0f,
  '⚙️': Emoji_2699_fe0f,
  '⚠️': Emoji_26a0_fe0f,
  '✨': Emoji_2728,
  '🙌': Emoji_1f64c,
  '👻': Emoji_1f47b,
  '💡': Emoji_1f4a1,
  '🚀': Emoji_1f680,
  '🛍': Emoji_1f6cd_fe0f,
  '📈': Emoji_1f4c8,
  '🖌': Emoji_1f58c_fe0f,
  '🔧': Emoji_1f527,
  '🇬🇧': Emoji_1f1ec_1f1e7,
  '🇲🇾': Emoji_1f1f2_1f1fe,
  '🇧🇷': Emoji_1f1e7_1f1f7,
  '🇪🇸': Emoji_1f1ea_1f1f8,
  '🇹🇭': Emoji_1f1f9_1f1ed,
  '🇮🇩': Emoji_1f1ee_1f1e9,
  '🇺🇸': Emoji_1f1fa_1f1f8,
  '🇨🇦': Emoji_1f1e8_1f1e6,
  '🇦🇹': Emoji_1f1e6_1f1f9,
  '🇧🇪': Emoji_1f1e7_1f1ea,
};
