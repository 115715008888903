import { ControlDecorator } from '../Input/ControlDecorator';
import { Flex } from '../Flex';
import {
  LoadingPlaceholder,
  LoadingPlaceholderProps,
} from './LoadingPlaceholder';
import React from 'react';

interface InputLoadingPlaceholderProps extends LoadingPlaceholderProps {}

export const InputLoadingPlaceholder: React.FC<InputLoadingPlaceholderProps> =
  ({ width = '100%', height = 36, color = 'grey', style, ...props }) => (
    <ControlDecorator
      style={{
        width,
        height,
        padding: '0 12px',
        ...style,
      }}
      render={() => (
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          style={{
            height,
          }}
        >
          <LoadingPlaceholder height={6} width="80%" color={color} {...props} />
        </Flex>
      )}
    />
  );
