import React from 'react';

export enum ButtonIntent {
  primary = 'primary',
  secondary = 'secondary',
  default = 'default',
  highlighted = 'highlighted',
  tab = 'tab',
  displayContent = 'displayContent',
  toggle = 'toggle',
  primaryLanding = 'primaryLanding',
}

export enum ButtonTextColorWay {
  black = 'black',
  red = 'red',
}

export enum ButtonColorWay {
  blue = 'blue',
  white = 'white',
  toggleWhiteHover = 'toggleWhiteHover',
  red = 'red',
  toggleWhiteOnly = 'toggleWhiteOnly',
  toggleBlueOnly = 'toggleBlueOnly',
}

export enum ButtonSize {
  m = 'm',
  s = 's',
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  intent?: ButtonIntent;
  colorWay?: ButtonColorWay;
  textColorWay?: ButtonTextColorWay;
  size?: ButtonSize;
  tall?: boolean;
  wide?: boolean;
  textNormalWeight?: boolean;
  iconClassName?: string;
  rightIconClassName?: string;
  children?: React.ReactNode;
  innerRef?: React.Ref<HTMLButtonElement>;
  renderIcon?: () => React.ReactNode;
  renderIconRight?: () => React.ReactNode;
  // TODO: Make this field required when every button has data-testid
  'data-testid'?: string;
}
