import React from 'react';
import cn from 'classnames';
import { Flex } from '../../Flex';
import * as css from './Loader.css';
import { ReactComponent as LoaderSvg } from '../../_deprecated/Icon/icons/loader.svg';

export enum LoaderSize {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
}

export type LoaderSizeKey = keyof typeof LoaderSize;

export interface LoaderProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: LoaderSizeKey;
}

export const Loader: React.FC<LoaderProps> = ({
  style,
  size = 'm',
  ...props
}) => {
  const sizeValue = LoaderSize[size];
  let sizeInPx;

  switch (sizeValue) {
    case LoaderSize.xl:
      sizeInPx = 70;
      break;
    case LoaderSize.l:
      sizeInPx = 48;
      break;
    case LoaderSize.m:
      sizeInPx = 32;
      break;
    case LoaderSize.s:
      sizeInPx = 24;
      break;
    case LoaderSize.xs:
      sizeInPx = 16;
      break;
    default:
      sizeInPx = 0;
  }

  return (
    <LoaderSvg
      style={{
        width: sizeInPx,
        height: sizeInPx,
        ...style,
      }}
      alt="loading"
      {...props}
    />
  );
};

interface CenteredLoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: LoaderSizeKey;
}

export const CenteredLoader: React.FC<CenteredLoaderProps> = ({
  className,
  size,
  ...props
}) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className={cn(css.loaderContainer, className)}
    {...props}
  >
    <Loader size={size} />
  </Flex>
);
