import React, { useMemo } from 'react';
import { SimpleCombobox, Item } from '@ui/SimpleCombobox';
import { IconButton } from '@ui/IconButton';
import { MenuItem } from '@ui/Menu';
import { Placement } from 'popper.js';

export interface MoreMenuItem extends TestLocator {
  id?: string;
  title: string;
  onClick(): void;
  disabled?: boolean;
  className?: string;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  tooltipElement?: React.ReactNode;
}

interface MoreMenuProps {
  items: MoreMenuItem[];
  className?: string;
  position?: Placement;
  'data-testid'?: string;
  button?: React.ReactNode;
}

type MoreMenuComboboxItem = Item & MoreMenuItem;

export const MoreMenu: React.FC<MoreMenuProps> = ({
  items,
  position,
  className,
  button,
  'data-testid': dataTestId,
}) => {
  const comboboxItems: MoreMenuComboboxItem[] = useMemo(
    () =>
      items.map((item, index) => {
        if (item.id) {
          return item as MoreMenuComboboxItem;
        }

        return { ...item, id: index.toString() };
      }),
    [items],
  );

  return (
    <div className={className}>
      <SimpleCombobox
        position={position}
        unsaveSelectedOption
        items={comboboxItems}
        onChange={(item) => {
          item?.onClick();
        }}
        renderInput={({ getToggleButtonProps, ref }) =>
          button ? (
            <div {...getToggleButtonProps()} ref={ref} data-testid={dataTestId}>
              {button}
            </div>
          ) : (
            <IconButton
              {...getToggleButtonProps()}
              ref={ref}
              icon="more"
              data-testid={dataTestId}
            />
          )
        }
        renderItem={({ item, index, highlightedIndex, getItemProps }) => {
          return (
            <MenuItem
              key={item.id}
              {...getItemProps({ item })}
              data-testid={dataTestId || `menu-item-${item.id}`}
              title={item.title}
              leftElement={item.leftElement}
              rightElement={item.rightElement}
              active={index === highlightedIndex}
              className={item.className}
              disabled={item.disabled}
              tooltip={item.tooltipElement}
              tooltipBoundariesElement="viewport"
            />
          );
        }}
      />
    </div>
  );
};
