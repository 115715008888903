import React from 'react';
import cn from 'classnames';
import * as css from './SymbolsLimitBubble.css';

interface SymbolsLimitBubbleProps extends React.HTMLProps<HTMLDivElement> {
  value: number;
  showAsRedValueMinLimit?: number;
}

export const SymbolsLimitBubble: React.FC<SymbolsLimitBubbleProps> = ({
  value,
  className,
  showAsRedValueMinLimit = 6,
  ...props
}) => {
  return (
    <div
      className={cn(css.SymbolsLimitBubble, className, {
        [css.red]: value < showAsRedValueMinLimit,
      })}
      {...props}
    >
      {value}
    </div>
  );
};
