import React from 'react';
import cn from 'classnames';
import * as css from './Anchor.css';

export const AnchorUnstyled: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ className, children, ...props }) => (
  <a {...props} className={cn(css.noUnderline, className)}>
    {children}
  </a>
);
