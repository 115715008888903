import * as PopperJS from 'popper.js';
import { Position } from '@ui/Tooltip2';
import { UseNestedMenuHandlers } from './useNestedMenu';
import {
  Group,
  Items,
  NestedMenuItem,
  NestedMenuNodeDisplayMode,
} from './types';

interface HandleEventParams<T> extends UseNestedMenuHandlers<T> {
  isNode: boolean;
  allItems: Array<T>;
  parentsSetOpenedMenuIndex?: (index: number) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
}

export const isStructureNode = <T extends NestedMenuItem>(item: T): Boolean =>
  [
    NestedMenuNodeDisplayMode.structure,
    NestedMenuNodeDisplayMode.noTitlteStructure,
  ].includes(item.displayMode as NestedMenuNodeDisplayMode) && !!item.children;

export const countAllItemsDeep = <T extends NestedMenuItem>(
  items: T[] = [],
): number =>
  items.reduce(
    (count, item) =>
      count +
      (isStructureNode(item)
        ? countAllItemsDeep(item.children as NestedMenuItem[])
        : 1),
    0,
  );

export function createKeyboardEventHandler<T extends NestedMenuItem>({
  isNode,
  isRoot,
  openedMenuIndex,
  allItems,
  highlightedIndex,
  setHighlightedIndex,
  setOpenedMenuIndex,
  parentsSetOpenedMenuIndex,
  onKeyDown,
  onChange,
}: HandleEventParams<T>) {
  return (event: KeyboardEvent) => {
    const allItemsLength = countAllItemsDeep(allItems);
    switch (event.code) {
      case 'ArrowDown': {
        const next = (highlightedIndex + 1) % allItemsLength;
        setHighlightedIndex(next);
        return;
      }
      case 'ArrowRight': {
        if (isNode) {
          setOpenedMenuIndex(highlightedIndex);
        }
        return;
      }
      case 'ArrowUp': {
        const next = (highlightedIndex - 1 + allItemsLength) % allItemsLength;
        setHighlightedIndex(next);
        return;
      }
      case 'ArrowLeft': {
        if (openedMenuIndex !== -1) {
          setOpenedMenuIndex(-1);
        }

        if (!isRoot) {
          parentsSetOpenedMenuIndex?.(-1);
        }
        return;
      }
      case 'Escape': {
        if (isRoot) {
          onKeyDown?.(event);
        } else {
          parentsSetOpenedMenuIndex?.(-1);
        }
        return;
      }
      case 'Enter': {
        if (isNode) {
          setOpenedMenuIndex(highlightedIndex);
          return;
        }

        const selectedItem = allItems[highlightedIndex];
        if (selectedItem) {
          onChange(selectedItem);
        }
        break;
      }
      default:
        break;
    }
  };
}

export const mapPlacement = (
  placement: PopperJS.Placement | undefined,
): Position | undefined => {
  if (!placement) {
    return undefined;
  }

  switch (placement) {
    case 'right-start':
      return 'right';
    case 'left-start':
      return 'left';
    default:
      throw new Error(`Unexpected placement ${placement}`);
  }
};

export const isGroup = <T>(items: Items<T> | Group<T>): items is Group<T> =>
  Array.isArray(items[0]);
