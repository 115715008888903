export type Node<T> = T & { id: string; children: Array<TreeType<T>> };
export type Leaf<T> = T & { id: string };
export type TreeType<T> = Node<T> | Leaf<T>;

export type ItemState = {
  visible: boolean;
  checked: boolean;
  error: boolean;
  expanded: boolean;
};

export type CommonTreeProps = ItemState & {
  id: string;
  type: FlatTreeItemTypes;
  parentId: string;
  depth: number;
  path: string[];
};

export type FlatNode<T> = CommonTreeProps & {
  expanded: boolean;
  tree: Node<T>;
};
export type FlatLeaf<T> = CommonTreeProps & { tree: Leaf<T> };
export type FlatTreeItem<T> = FlatNode<T> | FlatLeaf<T>;

export enum FlatTreeItemTypes {
  item = 'item',
  spacer = 'spacer',
  loader = 'loader',
}

export type TreeStateItem<T> =
  | FlatTreeItem<T>
  | { type: FlatTreeItemTypes.spacer }
  | { type: FlatTreeItemTypes.loader };

export type DefaultHandlers = {
  onExpandedChange: () => void;
};

export type DefaultState = {
  expanded: boolean;
};

export type RenderItemProps<T, H, S> = {
  data: TreeType<T>;
  handlers: H;
  level: number;
  state: S;
};

export type CheckTreeConfig = {
  autoCheckParent?: boolean;
};
