import { PickedDate } from '../types';

export const getRangeText = (startDate: PickedDate, endDate: PickedDate) => {
  const getDefaultRangeText = () =>
    [startDate, endDate].map((d) => d?.format('ll')).join(' - ');
  if (startDate && endDate) {
    const isSameDate = startDate.isSame(endDate, 'day');
    return isSameDate ? startDate.format('ll') : getDefaultRangeText();
  }
  if (!startDate || !endDate) {
    return getDefaultRangeText();
  }
  return '';
};
