import React, { CSSProperties } from 'react';
import noop from 'lodash-es/noop';
import { compose } from 'ramda';
import { Focus } from 'react-powerplug';
import cx from 'classnames';
import { InputErrorBox } from './InputErrorBox';
import * as css from './ControlDecorator.module.css';

type GetInputPropsOptions = {
  onFocus?: (event: React.FocusEvent<any>) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
  colorWay?: InputColorWay;
  className?: string;
} & React.HTMLProps<HTMLElement>;

type GenericRenderFn<T> = (value: T) => React.ReactNode;

export type RenderFn = GenericRenderFn<{
  getInputProps(options: GetInputPropsOptions): any;
  getFocusHandlers(): { onFocus: () => void; onBlur: () => void };
}>;

export enum InputColorWay {
  light = 'light',
  dark = 'dark',
  darkest = 'darkest',
}

export interface ControlDecoratorProps extends React.HTMLProps<HTMLDivElement> {
  renderIcon?: () => React.ReactNode;
  renderIconEnd?: () => React.ReactNode;
  iconEndStyle?: CSSProperties;
  render: RenderFn;
  error?: boolean | string;
  renderErrorText?: () => React.ReactNode;
  borderless?: boolean;
  colorWay?: InputColorWay;
  readOnly?: boolean;
}

export const ControlDecorator = ({
  render,
  renderIcon,
  renderIconEnd,
  renderErrorText,
  error,
  colorWay,
  borderless,
  className,
  disabled,
  readOnly,
  iconEndStyle,
  ...htmlProps
}: ControlDecoratorProps) => {
  const iconEnd = renderIconEnd?.();
  return (
    <>
      <Focus
        // tslint:disable-next-line jsx-no-lambda
        render={({ focused, bind }) => (
          <div
            className={cx(css.ControlDecorator, className, {
              [css.focused]: focused,
              [css.error]: error,
              [css.borderless]: borderless,
              [css.darkMode]: colorWay === InputColorWay.dark,
              [css.darkest]: colorWay === InputColorWay.darkest,
              [css.disabled]: disabled,
              [css.readonly]: readOnly,
            })}
            {...htmlProps}
          >
            {renderIcon && (
              <span className={css.iconContainer}>{renderIcon()}</span>
            )}
            <div className={css.value}>
              {render({
                getInputProps: ({
                  onFocus,
                  onBlur,
                  className,
                  colorWay,
                  ...rest
                }) => ({
                  ...rest,
                  className: cx(css.input, className),
                  onFocus: compose(bind.onFocus, onFocus || noop),
                  onBlur: compose(bind.onBlur, onBlur || noop),
                }),
                getFocusHandlers: () => bind,
              })}
            </div>
            {iconEnd && (
              <span className={css.iconContainer} style={iconEndStyle}>
                {iconEnd}
              </span>
            )}
          </div>
        )}
      />
      {((error && renderErrorText) || typeof error === 'string') && (
        <InputErrorBox errorText={renderErrorText?.() || error} />
      )}
    </>
  );
};
