import ng from 'angular';
import { react2angular } from 'react2angular';
import { DateRangePickerField } from './DateRangePickerField';

export const ngDateRangePickerField = ng
  .module('app.modernUi.dateRangePickerField', [])
  .component(
    'dateRangePickerField',
    react2angular(
      DateRangePickerField,
      ['defaultValue', 'onChange', 'onCancel', 'onShowMenu'],
      [],
    ),
  ).name;
