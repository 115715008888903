import { SimpleCombobox } from '@ui/SimpleCombobox';
import { getFormattedTime } from '@utils/dateUtils';
import { DateFormat } from '@utils/DateTime';
import cn from 'classnames';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { ButtonBase } from '../Button/Base';
import { Flex } from '../Flex';
import * as css from './TimePicker.css';

interface InputElementProps {
  hours?: boolean;
  value: string;
  onChange: (value: string | undefined) => void;
  textStyle?: CSSProperties;
  className?: string;
}

const TimeItemDropdownInput: React.FC<InputElementProps> = ({
  hours,
  value,
  onChange,
  textStyle,
  className,
}) => {
  const numberToTimeItem = useCallback(
    (item: number | string) => ({
      id: `${item}`,
      title: `${item}`.padStart(2, '0'),
    }),
    [],
  );

  const timeItems = useMemo(() => {
    return [...Array(hours ? 24 : 60).keys()].map(numberToTimeItem);
  }, [hours, numberToTimeItem]);

  return (
    <SimpleCombobox
      selectedItem={numberToTimeItem(value)}
      onChange={(item) => onChange(item?.id)}
      defaultHighlightedIndex={0}
      scrollboxStyle={{ overflowX: 'hidden' }}
      renderInput={({ getToggleButtonProps, isOpen }) => (
        <ButtonBase
          {...getToggleButtonProps()}
          className={cn(css.inputItem, isOpen && css.open, className)}
          style={textStyle}
        >
          {value.padStart(2, '0')}
        </ButtonBase>
      )}
      items={timeItems}
    />
  );
};

export interface TimePickerProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'defaultValue'> {
  /**
   * @param value формат HH:MM, может быть string или null
   * string возвращается в случае если время валидно.
   * null возвращается в случае если время не заполнено до конца
   * (юзер начал стирать время итд)
   *
   * Даже при вызове preventDefault у onKeyDown value может быть null
   * тк на 12ти часовом формате времени при вводе двух нулей в секцию
   * с часами часы сбрасываются и устанавливаются в значение 12
   * @returns
   */
  onChange: (value: string | null) => void;
  defaultValue?: string;
  textStyle?: CSSProperties;
  error?: boolean;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  onChange,
  defaultValue,
  textStyle,
  className,
  error,
  ...props
}) => {
  const value = defaultValue || DateFormat.HHmm(Date.now());
  const [defaultHours, defaultMinutes] = value.split(':');
  const [minutes, setMinutes] = useState<string>(defaultMinutes);
  const [hours, setHours] = useState<string>(defaultHours);

  const supportTimeInput = useMemo(() => {
    const testElement = document.createElement('input');
    testElement.type = 'time';
    return testElement.type === 'time';
  }, []);

  return supportTimeInput ? (
    <input
      type="time"
      className={cn(css.timePicker, className, {
        [css.timePickerError]: error,
      })}
      onChange={(e) => {
        onChange(e.target.value || null);
      }}
      defaultValue={value}
      style={textStyle}
      onKeyDown={(event) => {
        if (event.key === 'Backspace') {
          event.preventDefault();
        }
      }}
      {...props}
    />
  ) : (
    <Flex
      alignItems="center"
      className={cn({
        [css.timePickerError]: error,
      })}
    >
      <TimeItemDropdownInput
        hours
        value={hours}
        onChange={(hour = '0') => {
          setHours(hour);
          onChange(getFormattedTime(hour, minutes));
        }}
        className={className}
        textStyle={textStyle}
      />
      <span className={className} style={textStyle}>
        :
      </span>
      <TimeItemDropdownInput
        value={minutes}
        onChange={(minute = '0') => {
          setMinutes(minute);
          onChange(getFormattedTime(hours, minute));
        }}
        className={className}
        textStyle={textStyle}
      />
    </Flex>
  );
};
