import React from 'react';
import cn from 'classnames';
import { FontName, FontNameKey, FontSizeKey, SIZES_CONFIG } from './sizes';
import { Color, ColorKey } from '../_common/colors';
import * as css from './Type.css';

export enum FontWeight {
  light = 300,
  regular = 400,
  medium = 500,
  semibold = 600,
  bold = 700,
  extrabold = 800,
}

export type FontWeightKey = keyof typeof FontWeight;

export type AsType =
  | HTMLHeadingElement
  | HTMLDivElement
  | HTMLParagraphElement
  | HTMLSpanElement;

export interface TypeProps<ElementType extends AsType = AsType>
  extends TestLocator {
  align?: React.CSSProperties['textAlign'];
  wordBreak?: React.CSSProperties['wordBreak'];
  whiteSpace?: React.CSSProperties['whiteSpace'];
  decoration?: 'blink' | 'line-through' | 'none' | 'overline' | 'underline';
  as?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'div'
    | 'p'
    | 'span'
    | 'figcaption'
    | 'li'
    | 'td';
  color?: ColorKey;
  rawColor?: Color;
  size?: FontSizeKey;
  weight?: FontWeightKey;
  noWrap?: boolean;
  lineClamp?: number;
  innerRef?: React.RefObject<ElementType>;
  id?: string;
  className?: string;
  noColor?: boolean;
  fontName?: FontNameKey;
}

export const Type = <ElementType extends AsType>({
  children,
  align: textAlign,
  wordBreak,
  decoration: textDecorationLine,
  whiteSpace,
  as = 'span',
  color = 'black',
  rawColor,
  noColor,
  size = '15px',
  weight = 'regular',
  noWrap = false,
  lineClamp,
  innerRef,
  className,
  id,
  fontName,
  ...restProps
}: React.PropsWithChildren<TypeProps<ElementType>>) => {
  if (noWrap && as === 'span') {
    throw new Error(
      '"noWrap" property can be applicable only for block components!',
    );
  }

  const Component = as as unknown as React.HTMLFactory<ElementType>;
  const fontWeight = FontWeight[weight];
  const { fontSize, letterSpacing, lineHeight, textTransform } =
    SIZES_CONFIG[size];

  return (
    <Component
      id={id}
      ref={innerRef}
      data-testid={restProps['data-testid']}
      className={cn(className, {
        [css.nowrap]: noWrap,
        [css.lineClamp]: lineClamp,
      })}
      style={{
        color: noColor ? 'inherit' : rawColor || Color[color],
        fontSize,
        fontWeight,
        lineHeight,
        letterSpacing,
        wordBreak,
        whiteSpace,
        textAlign,
        textTransform,
        textDecorationLine,
        margin: 0,
        WebkitLineClamp: lineClamp,
        fontFamily: fontName && FontName[fontName],
      }}
    >
      {children}
    </Component>
  );
};
