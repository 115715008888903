import React from 'react';
import cn from 'classnames';
import * as css from './SegmentedCheckbox.css';

export interface SegmentedCheckboxItemProps extends TestLocator {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  onClick: React.MouseEventHandler<HTMLInputElement>;
  checkboxClassName?: string;
  children: React.ReactNode;
  disabled?: boolean;
}

export const SegmentedCheckboxItem = React.forwardRef<
  HTMLInputElement,
  SegmentedCheckboxItemProps
>(
  (
    {
      id,
      name,
      value,
      checked,
      onClick,
      children,
      checkboxClassName,
      disabled,
      ...props
    },
    ref,
  ) => {
    return (
      <label htmlFor={`segmented-checkbox-${id}`} className={css.label}>
        <input
          id={`segmented-checkbox-${id}`}
          ref={ref}
          name={name}
          type="checkbox"
          value={value}
          disabled={disabled}
          checked={checked}
          onClick={onClick}
          className={css.checkboxInput}
          data-testid={props['data-testid']}
        />
        <div className={cn(css.checkbox, checkboxClassName)}>{children}</div>
      </label>
    );
  },
);

export interface SegmentedCheckboxBoxProps {
  boxClassName?: string;
  children: React.ReactNode;
}

export const SegmentedCheckboxBox = React.forwardRef<
  HTMLDivElement,
  SegmentedCheckboxBoxProps
>(({ children, boxClassName }, ref) => {
  return (
    <div ref={ref} className={cn(css.wrapper, boxClassName)}>
      {children}
    </div>
  );
});
