import React, { HTMLProps, useRef } from 'react';
import nanoid from 'nanoid';
import { Type } from '../../Type';
import { Spacer } from '../../Spacer';
import { Flex } from '../../Flex';

interface FormLabelProps extends Omit<HTMLProps<HTMLLabelElement>, 'action'> {
  children: (bind: { id: string }) => React.ReactNode;
  text: React.ReactNode;
  action?: React.ReactNode;
  required?: boolean;
}

export const FormLabel = React.forwardRef<HTMLLabelElement, FormLabelProps>(
  ({ children, text, required, action }: FormLabelProps, ref) => {
    const idRef = useRef<string>(nanoid());
    return (
      <label ref={ref} htmlFor={idRef.current}>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <Type size="12px" color="baseTertiary">
              {text}
            </Type>
            {required && (
              <>
                {' '}
                <Type size="12px" color="accent4Normal">
                  *
                </Type>
              </>
            )}
          </div>
          {action}
        </Flex>
        <Spacer factor={2} />
        <div>{children({ id: idRef.current })}</div>
      </label>
    );
  },
);
