import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as CarriageReturn } from '../../_deprecated/Icon/icons/carriage-return.svg';
import * as s from './RichPlaceholder.css';

interface RichPlaceholderProps {
  exampleWords?: [string, string];
}

export const RichPlaceholder: React.FC<RichPlaceholderProps> = ({
  exampleWords,
}) => {
  const { t } = useSafeTranslation();
  const [firstWord, secondWord] = exampleWords ?? [
    t('common.BubbleEditor.placeholder.hi'),
    t('common.BubbleEditor.placeholder.hello'),
  ];

  return (
    <span className={s.placeholder}>
      <span className={s.bubble}>{firstWord}</span>{' '}
      <span style={{ position: 'relative', bottom: -2 }}>
        <CarriageReturn />
      </span>
      <span>&nbsp; {t('common.BubbleEditor.placeholder.Enter')} &nbsp;</span>
      <span className={s.bubble}>{secondWord}</span>
      <span>
        &nbsp;{' '}
        {t('common.BubbleEditor.placeholder.PressEnterToSeparatePhrases')}
      </span>
    </span>
  );
};

export const RichPlaceholderWrapper: React.FC = ({ children }) => {
  return <span className={s.placeholder}>{children}</span>;
};

export const RichPlaceholderBubble: React.FC = ({ children }) => {
  return <span className={s.bubble}>{children}</span>;
};

export const UrlsPlaceholderBubble: React.FC = () => {
  return (
    <RichPlaceholderWrapper>
      <span>Try&nbsp;&nbsp;</span>
      <RichPlaceholderBubble>/blog</RichPlaceholderBubble>
      <span>&nbsp;&nbsp;</span>
      <span style={{ position: 'relative', bottom: -2 }}>
        <CarriageReturn />
      </span>
      <span>&nbsp;&nbsp;enter&nbsp;&nbsp;</span>
      <RichPlaceholderBubble>/login</RichPlaceholderBubble>
    </RichPlaceholderWrapper>
  );
};
