import React from 'react';
import { ReactComponent as WarningIcon } from '../_deprecated/Icon/icons/warning2.svg';
import * as s from './WarningText.css';

interface IProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
}

export const WarningText: React.SFC<IProps> = (props) => {
  const { text, ...htmlProps } = props;
  return (
    <div {...htmlProps} className={s.error}>
      <WarningIcon className={s.icon} />
      &nbsp;
      <span className={s.text}>{props.text}</span>
    </div>
  );
};
