import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';

export enum BotActionType {
  connectPage = 'connectPage',
  connectPhone = 'connectPhone',
  unpause = 'unpause',
  updatePayments = 'updatePayments',
  upgradeToPro = 'upgradeToPro',
  none = 'none',
}

export interface BotActionProps {
  actionType: keyof typeof BotActionType;
  onActionClick: VoidFunction;
  className?: string;
}

export const BotAction: React.FC<BotActionProps> = ({
  actionType,
  onActionClick,
  className,
}) => {
  const { t } = useSafeTranslation();

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onActionClick();
  };

  const actionButtonProps: {
    [key in BotActionType]: React.ReactElement | null;
  } = {
    [BotActionType.connectPage]: (
      <Button
        className={className}
        intent="secondary"
        onClick={handleClick}
        data-testid="bot_action_button__connect_page"
      >
        {t('modernUi.BotListItem.BotAction.connectPage')}
      </Button>
    ),
    [BotActionType.connectPhone]: (
      <Button
        className={className}
        intent="secondary"
        onClick={handleClick}
        data-testid="bot_action_button__connect_phone"
      >
        {t('modernUi.BotListItem.BotAction.connectPhone')}
      </Button>
    ),
    [BotActionType.unpause]: (
      <Button
        className={className}
        intent="secondary"
        onClick={handleClick}
        data-testid="bot_action_button__unpause"
      >
        {t('BotActionButton-string-1384-unpause')}
      </Button>
    ),
    [BotActionType.updatePayments]: (
      <Button
        className={className}
        intent="red"
        onClick={handleClick}
        data-testid="bot_action_button__update_payments"
      >
        {t('BotActionButton-string--104-update-payment')}
      </Button>
    ),
    [BotActionType.upgradeToPro]: (
      <Button
        className={className}
        intent="secondary"
        onClick={handleClick}
        data-testid="bot_action_button__upgrade_to_pro"
      >
        {t('BotActionButton-string--165-upgrade-to-pro')}
      </Button>
    ),
    [BotActionType.none]: null,
  };

  return actionButtonProps[actionType];
};
