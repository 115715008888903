import React from 'react';
import cn from 'classnames';
import * as css from './LoadingPlaceholder.css';
import { Color, ColorKey } from '../_common/colors';

export interface LoadingPlaceholderProps
  extends React.HTMLProps<HTMLDivElement> {
  animated?: boolean;
  color?: ColorKey;
  fullWidth?: boolean;
  width?: number | string;
  height?: number;
}

export const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({
  animated = true,
  className,
  color = 'greyLight20',
  style,
  fullWidth,
  width,
  height,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      css.LoadingPlaceholder,
      { [css.LoadingPlaceholderAnimated]: animated },
      className,
    )}
    style={{
      backgroundColor: Color[color],
      width: width || (fullWidth ? '100%' : 'auto'),
      height: height ? `${height}px` : undefined,
      ...style,
    }}
  />
);
