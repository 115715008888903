import React, { useState, useMemo } from 'react';
import { Portal } from 'react-portal';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Manager, Reference, Popper } from 'react-popper';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { normalizedIncludes } from '@utils/normalizedIncludes';
import { Menubox } from '../Menu';
import { Flex } from '../Flex';
import { FocusWithin } from '../FocusWithin';
import { CheckBox } from '../CheckBox';
import { useCheckboxGroup } from './useCheckboxGroup';
import * as s from './MultiComboboxAction.css';

const Divider: React.FC<React.SVGProps<SVGElement>> = ({ style }) => (
  <svg
    viewBox="0 0 1 1"
    style={Object.assign({ display: 'block', height: 1, width: '100%' }, style)}
    preserveAspectRatio="none"
  >
    <rect width="1" height="1" fill="var(--greyLight20)" />
  </svg>
);

type Option = { id: string; displayName: string; type: string; name: string };

interface Props {
  options: Option[];
  onSubmit: (values: string[]) => void;
  disabled?: boolean;
  renderToggleButton: (btnProps: any) => React.ReactNode;
  onSelectAll?(): void;
  onClearAll?(): void;
}

export const MultiComboboxAction: React.FC<Props> = ({
  onSubmit,
  options,
  disabled,
  renderToggleButton,
  onSelectAll,
  onClearAll,
}) => {
  const { t } = useSafeTranslation();
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(
    () =>
      options.filter(
        query
          ? (option) => {
              return (
                normalizedIncludes(option.displayName.toLowerCase(), query) ||
                normalizedIncludes(option.id, query)
              );
            }
          : Boolean,
      ),
    [options, query],
  );

  const optionValues = useMemo(
    () => filteredOptions.map((option) => option.id),
    [filteredOptions],
  );
  const { values, selectAll, deselectAll, handleClick } =
    useCheckboxGroup(optionValues);

  const [isOpen, setIsOpen] = useState(false);
  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          renderToggleButton({ ref, onClick: () => setIsOpen(!isOpen) })
        }
      </Reference>
      {isOpen ? (
        <Portal>
          <Popper placement="bottom-end">
            {({ ref, style }) => (
              <div
                ref={ref}
                style={Object.assign({ width: 300, zIndex: 1 }, style)}
                data-testid="multi-combobox__dialog"
              >
                <FocusWithin
                  onFocusOut={() => {
                    setIsOpen(false);
                  }}
                  render={({ bind: bindFocusWithin }) => (
                    <div
                      className={s.interactiveBox}
                      tabIndex={-1}
                      {...bindFocusWithin}
                    >
                      <Menubox style={{ padding: 17 }}>
                        <Input
                          autoFocus
                          data-testid="multi-combobox__input"
                          renderIcon={() => (
                            <Icon icon="search" color="greyDark" />
                          )}
                          placeholder={t(
                            'modernUi.MultiComboboxAction.placeholder',
                          )}
                          disabled={disabled}
                          name="query"
                          value={query}
                          onChange={(e) => {
                            setQuery(e.target.value.toLowerCase());
                          }}
                        />
                        <Spacer factor={3} />
                        <Flex>
                          <Button
                            data-testid="multi-combobox__select-all-button"
                            type="button"
                            intent="secondary"
                            onClick={() => {
                              if (onSelectAll) {
                                onSelectAll();
                              }
                              selectAll();
                            }}
                            disabled={disabled}
                          >
                            {t('modernUi.MultiComboboxAction.selectAll')}
                          </Button>
                          <Spacer horizontalFactor={3} />
                          <Button
                            data-testid="multi-combobox__clear-all-button"
                            type="button"
                            intent="secondary"
                            style={{ marginLeft: 4 }}
                            onClick={() => {
                              if (onClearAll) {
                                onClearAll();
                              }
                              deselectAll();
                            }}
                            disabled={disabled}
                          >
                            {t('modernUi.MultiComboboxAction.clearAll')}
                          </Button>
                        </Flex>
                        <Spacer factor={3} />
                        <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            onSubmit(
                              Object.keys(values).filter((key) => values[key]),
                            );
                          }}
                        >
                          <fieldset disabled={disabled} className={s.fieldset}>
                            <div
                              className={s.scrollBox}
                              data-testid="multi-combobox__dialog_items-list"
                            >
                              {filteredOptions.map((option, _, options) => {
                                const isOptionWithSameNameExist = options.find(
                                  (opt) =>
                                    opt.name === option.name &&
                                    opt.type !== option.type,
                                );
                                const renderCustomLabel =
                                  option.type === 'custom' &&
                                  isOptionWithSameNameExist;

                                return (
                                  <label
                                    data-testid="multi-combobox__item"
                                    htmlFor={option.id}
                                    key={option.id}
                                    className={s.label}
                                  >
                                    <Flex>
                                      <div>
                                        <CheckBox
                                          data-testid="multi-combobox__item__checkbox"
                                          id={option.id}
                                          type="checkbox"
                                          name="field"
                                          value={option.id}
                                          checked={Boolean(values[option.id])}
                                          onClick={handleClick}
                                          className={s.checkbox}
                                        />
                                      </div>
                                      <span
                                        data-testid="multi-combobox__item__name"
                                        className={s.checkboxLabelText}
                                      >
                                        {option.displayName}
                                      </span>
                                      {renderCustomLabel && (
                                        <span
                                          className={
                                            s.checkboxLabelTextSecondary
                                          }
                                        >
                                          {t(
                                            'modernUi.MultiComboboxAction.customLabel',
                                          )}
                                        </span>
                                      )}
                                    </Flex>
                                  </label>
                                );
                              })}
                            </div>
                            <Divider style={{ marginBottom: 17 }} />
                            <Flex justifyContent="flex-end">
                              <Button
                                data-testid="multi-combobox__download-button"
                                intent="primary"
                                disabled={disabled}
                                type="submit"
                              >
                                {t('modernUi.MultiComboboxAction.download')}
                              </Button>
                            </Flex>
                          </fieldset>
                        </form>
                      </Menubox>
                    </div>
                  )}
                />
              </div>
            )}
          </Popper>
        </Portal>
      ) : null}
    </Manager>
  );
};
