import React from 'react';
import cn from 'classnames';
import unknownUserSvg from '../../ui/avatar/images/unknown-user.svg';
import * as css from './Avatar.css';

interface IAvatarProps extends Omit<React.HTMLProps<HTMLDivElement>, 'src'> {
  src: string | null | undefined;
  size?: AvatarSize;
  customSize?: number;
  hideCoverImage?: boolean;
}

export enum AvatarSize {
  small = 16,
  medium = 26,
  xMedium = 28,
  big = 40,
  large = 64,
}

export const Avatar: React.FC<IAvatarProps> = ({
  src,
  size = AvatarSize.medium,
  className,
  customSize,
  style,
  hideCoverImage,
  ...props
}) => {
  const mainImage = src ? `url("${src}")` : '';
  const coverImage = hideCoverImage ? '' : `url("${unknownUserSvg}")`;

  const backgroundImage = [mainImage, coverImage].filter((it) => it).join(', ');

  return (
    <div
      className={cn(css.avatar, className)}
      style={{
        // method to create default user pic in case first one has load errors
        backgroundImage,
        width: customSize || size,
        height: customSize || size,
        ...style,
      }}
      {...props}
    />
  );
};
