import React from 'react';
import cn from 'classnames';
import * as css from './TagItem.css';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { IconButton } from '../IconButton';

interface ITagItem {
  children: string;
  isActive?: boolean;
  onClick?: React.EventHandler<React.MouseEvent>;
  onFocus?: React.EventHandler<React.FocusEvent>;
  onDoubleClick?: React.MouseEventHandler;
  onRemoveRequest?: () => void;
}

export class TagItem extends React.Component<ITagItem> {
  padRef: HTMLDivElement | null = null;

  componentDidUpdate(prevProps: ITagItem): void {
    if (this.props.isActive && !prevProps.isActive) {
      if (this.padRef) {
        this.padRef.focus();
      }
    }
  }

  setRef = (padRef: HTMLDivElement | null) => {
    this.padRef = padRef;
  };

  render() {
    const { children, isActive, onRemoveRequest, ...props }: ITagItem =
      this.props;
    const isMobile = isMobileDevice();
    return (
      <div
        className={cn(css.tag, isActive && css['tag-active'])}
        ref={this.setRef}
        // TODO:
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={1}
        {...props}
      >
        {children}
        {isMobile && (
          <IconButton
            icon="close"
            noBackground
            onClick={() => {
              onRemoveRequest?.();
            }}
            color={isActive ? 'white' : 'black'}
            size="24px"
            className={css.removeButton}
          />
        )}
      </div>
    );
  }
}
