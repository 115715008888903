import React, { useRef } from 'react';
import cn from 'classnames';
import { Input } from './Input';
import * as css from './Input.module.css';
import * as cssPostfix from './InputWithPostfix.css';
import AutosizeInput from 'react-input-autosize';
import { Flex } from '../Flex';
import { combineRefs } from '@utils/combineRefs';

interface RenderPostfixArgs {
  error?: boolean;
}

export interface InputWithPostfixProps
  extends React.HTMLProps<HTMLInputElement> {
  renderIcon?: () => React.ReactNode;
  renderPostfix?: (args: RenderPostfixArgs) => React.ReactNode;
  renderErrorText?: () => React.ReactNode;
  containerClassName?: string;
  error?: boolean;
}

export const InputWithPostfix = React.forwardRef<
  HTMLInputElement,
  InputWithPostfixProps
>(
  (
    {
      renderPostfix,
      error,
      className,
      containerClassName,
      renderIcon,
      renderErrorText,
      ...htmlProps
    },
    ref,
  ) => {
    const inputFocusRef = useRef<HTMLInputElement | null>(null);

    return (
      <Input
        error={error}
        renderErrorText={renderErrorText}
        containerClassName={cn(cssPostfix.box, containerClassName)}
        renderIcon={renderIcon}
        render={({ getInputProps }) => {
          return (
            <Flex
              data-testid="input-with-postfix__input-field"
              gapFactor={1}
              alignItems="center"
              onClick={() => {
                inputFocusRef.current?.focus();
              }}
            >
              <AutosizeInput
                {...getInputProps({
                  ...htmlProps,
                })}
                data-testid="input-with-postfix__input-box"
                className={cssPostfix.inputBox}
                inputClassName={cn(css.input, cssPostfix.input, className, {
                  [css.padStart]: !renderIcon?.(),
                })}
                inputRef={combineRefs([ref, inputFocusRef])}
              />
              {renderPostfix && (
                <div
                  className={cssPostfix.postfix}
                  data-testid="input-with-postfix__input-postfix"
                >
                  {renderPostfix({ error })}
                </div>
              )}
            </Flex>
          );
        }}
      />
    );
  },
);
