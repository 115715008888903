import React from 'react';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import { Manager, Reference, Popper } from 'react-popper';
import { isNil, test, curry, compose } from 'ramda';
import cn from 'classnames';
import escapeStringRegexp from 'escape-string-regexp';
import ScrollBox from '../ScrollBox/ScrollBox';
import { Input } from '../Input';
import {
  HighlightOccurrenceType,
  HighlightedString,
} from '../HighlightedString';
import { MenuItem } from './MenuItem';
import * as css from './Suggest.css';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface ISuggestProps<T>
  extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
  items: T[];
  selectedItem?: T;
  placeholder?: string;
  onChange: (item: T) => void;
  onInputChange?: (value: string) => void;
  itemToString?: (item?: T) => string;
  itemsFilter?: (value: string, itemString: string) => boolean;
  highlightOccurrence?: HighlightOccurrenceType;
  dropdownPositionFixed?: boolean;
  inputRef?: (ref: HTMLDivElement | null) => void;
  error?: boolean;
}

function defaultItemToString<T extends {}>(item: T | undefined) {
  return (item && item.toString()) || '';
}

const defaultItemsFilter = (value: string, itemString: string) =>
  test(new RegExp(escapeStringRegexp(value), 'i'), itemString);

export class Suggest<T> extends React.PureComponent<ISuggestProps<T>, {}> {
  width: number | null = null;

  // rewrite default behove of downshift
  stateReducer = (
    _state: DownshiftState<T>,
    changes: StateChangeOptions<T>,
  ) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: false,
          highlightedIndex: 0,
        };
      case Downshift.stateChangeTypes.changeInput:
        return {
          ...changes,
          isOpen: true,
          highlightedIndex: 0,
        };
      default:
        return changes;
    }
  };

  render() {
    const {
      items,
      selectedItem,
      onChange,
      onInputChange,
      placeholder = '',
      itemsFilter = defaultItemsFilter,
      itemToString = defaultItemToString,
      highlightOccurrence,
      onFocus,
      onBlur,
      onKeyDown,
      maxLength,
      dropdownPositionFixed,
      inputRef,
      error,
      disabled,
      ...props
    } = this.props;

    const curryItemsFilter = curry(itemsFilter);
    const selectedItemString: string = itemToString(selectedItem);
    const filteredItems = items.filter(
      compose(curryItemsFilter(selectedItemString), itemToString),
    );

    return (
      <div
        {...props}
        ref={(el) => {
          this.width = el && el.clientWidth;
        }}
      >
        <Manager>
          <Downshift
            selectedItem={selectedItem}
            onChange={onChange}
            itemToString={itemToString}
            stateReducer={this.stateReducer}
          >
            {({
              isOpen,
              getInputProps,
              getItemProps,
              getMenuProps,
              openMenu,
              highlightedIndex,
              selectedItem: dsSelectedItem,
            }) => {
              return (
                <div>
                  <Reference>
                    {({ ref }) => (
                      <div ref={ref}>
                        <Input
                          disabled={disabled}
                          error={error}
                          data-testid="suggest-input"
                          {...getInputProps({
                            maxLength,
                            placeholder,
                            value: selectedItemString,
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              if (onInputChange) {
                                onInputChange(
                                  (event.target as HTMLInputElement).value,
                                );
                              }
                            },
                            onFocus: (event) => {
                              openMenu();

                              if (onFocus) {
                                (onFocus as React.FocusEventHandler)(event);
                              }
                            },
                            onBlur: onBlur as React.FocusEventHandler,
                            onKeyDown: onKeyDown as React.KeyboardEventHandler,
                          })}
                          ref={(ref) => {
                            if (inputRef) {
                              inputRef(ref);
                            }
                          }}
                        />
                      </div>
                    )}
                  </Reference>
                  {isOpen && !!filteredItems.length && (
                    <Popper
                      placement="bottom-start"
                      positionFixed={dropdownPositionFixed}
                      modifiers={{
                        flip: {
                          enabled: true,
                        },
                        preventOverflow: {
                          enabled: true,
                        },
                        hide: {
                          enabled: true,
                        },
                      }}
                    >
                      {({ ref, style, outOfBoundaries }) => {
                        const extStyle = {
                          ...style,
                          width: this.width,
                        };

                        return (
                          <div
                            {...getMenuProps({
                              ref,
                            })}
                            style={extStyle}
                            className={cn(css.suggestDropdown, {
                              [css.hide]: outOfBoundaries,
                            })}
                          >
                            <ScrollBox className={css.scrollBoxHeight}>
                              <div className={css.scrollBoxSpacing}>
                                {filteredItems.map((item: T, index: number) => {
                                  const stringItem = itemToString(item);
                                  return (
                                    <MenuItem
                                      {...getItemProps({
                                        item,
                                      })}
                                      key={stringItem}
                                      selected={
                                        isNil(highlightedIndex)
                                          ? item === dsSelectedItem
                                          : highlightedIndex === index
                                      }
                                      title={stringItem}
                                    >
                                      <div className={css.menuItemEllipsis}>
                                        <HighlightedString
                                          str={stringItem}
                                          matchValue={selectedItemString}
                                          highlightOccurrence={
                                            highlightOccurrence
                                          }
                                        />
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </div>
                            </ScrollBox>
                          </div>
                        );
                      }}
                    </Popper>
                  )}
                </div>
              );
            }}
          </Downshift>
        </Manager>
      </div>
    );
  }
}

export const SuggestStrings = Suggest as new () => Suggest<string>;
