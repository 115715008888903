import React from 'react';
import cn from 'classnames';
import {
  HighlightBorderBox,
  HighlightBorderBoxProps,
} from './HighlightBorderBox';
import * as css from './HighlightBorderBoxHideable.css';
import { getComponentWithRefAndName } from '@utils/withRef';

interface HighlightBorderBoxHideableProps extends HighlightBorderBoxProps {
  hide?: boolean;
}

export const HighlightBorderBoxHideable = getComponentWithRefAndName<
  HTMLDivElement,
  HighlightBorderBoxHideableProps
>(
  'HighlightBorderBoxHideable',
  ({ hide, className, innerClassName, ...props }, ref) => (
    <HighlightBorderBox
      innerClassName={cn(innerClassName, hide && css.hide)}
      className={cn(className, hide && css.hide)}
      {...props}
      ref={ref}
    />
  ),
);
