import React, { useEffect, useState } from 'react';
import * as css from './LineLoader.css';

export interface LineLoaderProps {
  percent?: number;
}

export const LineLoader: React.FC<LineLoaderProps> = ({ percent = 0 }) => {
  const [innerPercent, setInnerPercent] = useState(0);

  useEffect(() => {
    if (innerPercent !== percent) {
      if (innerPercent === 0) {
        // we need give css transition time to register 0px state
        setTimeout(() => setInnerPercent(percent), 10);
      } else {
        setInnerPercent(percent);
      }
    }
  }, [percent, innerPercent]);

  return (
    <div>
      <div className={css.lineLoaderContainer}>
        <div className={css.lineLoader} style={{ width: `${innerPercent}%` }} />
      </div>
    </div>
  );
};
