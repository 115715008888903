import React from 'react';
import cn from 'classnames';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogFooter,
  DialogCloseButton,
} from './Dialog';
import css from './Dialog.css';

export interface DefaultDialogProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onDismiss?(): void;
  children: React.ReactNode;
  contentStyle?: React.CSSProperties;
  dialogStyle?: React.CSSProperties;
  closeButtonStyle?: React.CSSProperties;
  className?: string;
  contentClassName?: string;
  mobileAdaptive?: boolean;
  closeButtonClassName?: string;
  closeButtonDisabled?: boolean;
  dialogId?: string;
  small?: boolean;
}

export const DefaultDialog: React.FC<DefaultDialogProps> = ({
  header: Header,
  footer: Footer,
  onDismiss,
  children,
  contentStyle,
  dialogStyle,
  closeButtonStyle,
  closeButtonClassName,
  closeButtonDisabled,
  className,
  contentClassName,
  mobileAdaptive,
  dialogId,
  small,
}) => (
  <Dialog
    id={dialogId}
    style={dialogStyle}
    className={cn(className, mobileAdaptive && css.dialog_mobile)}
  >
    <DialogContent
      style={{ lineHeight: 'initial', ...contentStyle }}
      className={cn(contentClassName, small && css.dialog_small)}
    >
      {Header && (
        <DialogHeading>
          {typeof Header === 'function' ? <Header /> : Header}
        </DialogHeading>
      )}
      {!mobileAdaptive && onDismiss ? (
        <DialogCloseButton
          data-testid="default-dialog__close-button"
          aria-label="close"
          type="button"
          onClick={onDismiss}
          style={closeButtonStyle}
          className={cn(closeButtonClassName, small && css.dialog_small_button)}
          disabled={closeButtonDisabled}
        />
      ) : null}
      {children}
    </DialogContent>
    {Footer && (
      <DialogFooter isAdaptive={Boolean(mobileAdaptive)}>
        {typeof Footer === 'function' ? <Footer /> : Footer}
      </DialogFooter>
    )}
  </Dialog>
);

export const WideDialog: React.FC<DefaultDialogProps> = (props) => (
  <DefaultDialog
    {...props}
    dialogStyle={{
      ...props.dialogStyle,
      width: props.dialogStyle?.width ?? '100%',
    }}
  />
);
