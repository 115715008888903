import { Type, TypeProps, AsType } from '@ui/Type';
import { DateFormat } from '@utils/DateTime';
import React, { CSSProperties, HTMLProps } from 'react';
import * as css from './ListItemWithPicture.css';
import defaultImage from './pictures/empty-picture.svg';

interface ListItemProps extends HTMLProps<HTMLDivElement> {
  picture?: string | null;
  message?: React.ReactNode;
  messageProps?: TypeProps<AsType>;
  messageStyles?: CSSProperties;
}

interface TimeLabelProps {
  time: number | null;
}

export const TimeLabel: React.FC<TimeLabelProps> = ({ time }) =>
  time ? (
    <Type size="12px" color="greyDark">
      {DateFormat.DDMMMMHHmm(time)}
    </Type>
  ) : null;

export const ListItemWithPicture = React.forwardRef<
  HTMLDivElement,
  ListItemProps
>(
  (
    { picture, message, children, messageProps, messageStyles, ...props },
    ref,
  ) => (
    <div className={css.wrapper} ref={ref} {...props}>
      <div className={css.postPictureBox}>
        <div
          className={css.postPicture}
          style={{ backgroundImage: `url(${picture || defaultImage})` }}
        />
      </div>

      <div>
        <div style={{ ...messageStyles }} className={css.title}>
          {message ? (
            <Type size="15px_DEPRECATED" {...messageProps}>
              {message}
            </Type>
          ) : (
            <Type size="15px_DEPRECATED" color="grey">
              No text
            </Type>
          )}
        </div>
        {children}
      </div>
    </div>
  ),
);
