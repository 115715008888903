import React from 'react';
import cx from 'classnames';
import { getComponentWithRefAndName } from '@utils/withRef';
import * as css from './Menubox.css';

type Props = React.HTMLProps<HTMLDivElement>;
type Ref = HTMLDivElement;

export const Menubox = getComponentWithRefAndName<Ref, Props>(
  'Menubox',
  (props, ref) => (
    <div {...props} ref={ref} className={cx(css.box, props.className)} />
  ),
);
