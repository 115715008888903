import React from 'react';
import * as css from './SectionHeader.css';

export interface ISectionHeaderProps extends React.HTMLProps<HTMLDivElement> {
  intent: string;
}

// Component to avoid duplication of styles between angular and react parts in broadcasting

export const SectionHeader: React.SFC<ISectionHeaderProps> = ({
  intent = 'section',
  children,
  ...props
}) => {
  return (
    <div className={css[intent]} {...props}>
      {children}
    </div>
  );
};
