/* eslint-disable react/sort-comp */
import React from 'react';
import detector from 'detector';
import cn from 'classnames';
import { Portal } from 'react-portal';
import debounce from 'lodash-es/debounce';
import { prop, defaultTo, compose, flip, pathOr } from 'ramda';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import * as css from './UndoSimple.css';
import { ReactComponent as UndoSvg } from './images/undo.svg';

export interface INamed {
  name: string;
}

interface IUndoSimpleProps {
  deletedData: INamed | null;
  deletedDataIndex?: number;
  onUndoRequest?: (data: INamed, index: number | void) => void;
}

interface IUndoSimpleState {
  show: boolean;
  restored: boolean;
  updateAnimate: boolean;
  name: string;
  showNameInRestore: boolean;
}

const APPROVE_TIME = 4 * 1000;
const RESTORED_TIME = 1.8 * 1000;
const CTRL_SYMBOLS = {
  macosx: '⌘',
  ios: '⌘',
  default: 'Ctrl',
};
const KEY_DOWN = 'keydown';
const ctrlByOs = flip(prop)(CTRL_SYMBOLS) as any;
const ctrl = compose(
  defaultTo(CTRL_SYMBOLS.default),
  ctrlByOs,
  pathOr<string>('', ['os', 'name']),
)(detector as string);

export class UndoSimple extends React.Component<
  IUndoSimpleProps,
  IUndoSimpleState
> {
  state = {
    show: false,
    restored: false,
    updateAnimate: false,
    name: '',
    showNameInRestore: false,
  };

  hide = () => {
    this.setState({
      show: false,
    });
  };

  debounceHideApprove = debounce(this.hide, APPROVE_TIME);

  debounceHideRestored = debounce(this.hide, RESTORED_TIME);

  undo() {
    const { onUndoRequest, deletedData, deletedDataIndex } = this.props;
    const { show } = this.state;

    if (deletedData) {
      this.setState({
        restored: true,
        show: true,
        showNameInRestore: !show,
      });
      this.debounceHideApprove.cancel();
      this.debounceHideRestored();

      if (onUndoRequest) {
        onUndoRequest(deletedData, deletedDataIndex);
      }
    }
  }

  handleUndoClick = () => {
    this.undo();
  };

  handleGlobalKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 90 && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      this.undo();
    }
  };

  componentDidUpdate(prevProps: IUndoSimpleProps) {
    const { deletedData } = this.props;
    if (deletedData && prevProps.deletedData !== deletedData) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        show: true,
        restored: false,
        updateAnimate: true,
      });
      setTimeout(
        () => {
          // for animate update
          this.setState({
            updateAnimate: false,
            name: deletedData.name,
          });
        },
        prevProps.deletedData ? 100 : 0,
      );

      this.debounceHideRestored.cancel();
      this.debounceHideApprove();
    }
  }

  componentDidMount() {
    window.addEventListener(KEY_DOWN, this.handleGlobalKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener(KEY_DOWN, this.handleGlobalKeyDown);
    this.debounceHideApprove.cancel();
    this.debounceHideRestored.cancel();
  }

  render() {
    const { show } = this.state;
    return (
      <Portal>
        <TransitionGroup>{show && this.createUndoPopup()}</TransitionGroup>
      </Portal>
    );
  }

  private createUndoPopup() {
    const { restored } = this.state;
    return (
      <CSSTransition
        timeout={200}
        classNames={{
          enter: css.undoEnter,
          enterActive: css.undoEnterActive,
          exit: css.undoExit,
          exitActive: css.undoExitActive,
        }}
      >
        {() => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            className={cn(
              css.layout,
              css.makeup,
              {
                [css.restoredBehove]: restored,
                [css.restoredWidth]: restored,
              },
              'test-undo-panel',
            )}
            onClick={this.handleUndoClick}
          >
            {this.createUndoState()}
            {this.createRestoreState()}
          </div>
        )}
      </CSSTransition>
    );
  }

  private createRestoreState() {
    const { restored } = this.state;
    return (
      <div
        className={cn(css.wrapper, css.typo, css.animate, {
          [css.show]: restored,
        })}
      >
        {this.createRestoreString()}
      </div>
    );
  }

  private createRestoreString() {
    const { name, showNameInRestore } = this.state;

    if (showNameInRestore) {
      return (
        <div className={css.caption}>
          <span>‘</span>
          <span className={css.ellipsis}>{name}</span>
          <span>‘ restored</span>
        </div>
      );
    }
    return <div className={css.caption}>Attribute restored</div>;
  }

  private createUndoState() {
    const { restored, updateAnimate, name } = this.state;
    return (
      <div
        className={cn(css.wrapper, css.typo, css.animate, {
          [css.show]: !restored && !updateAnimate,
        })}
      >
        <div className={css.caption}>
          <span>Attribute deleted ‘</span>
          <span className={css.ellipsis}>{name}</span>
          <span>’</span>
        </div>
        <div className={css.shortcut}>{ctrl} + Z</div>
        <div className={css.btnPic}>
          <UndoSvg />
        </div>
      </div>
    );
  }
}
