import React from 'react';
import cn from 'classnames';
import escapeStringRegexp from 'escape-string-regexp';
import { isEmpty } from 'ramda';
import * as css from './HighlightedString.css';

type HighlightedEntity = {
  text: string;
  highlight: boolean;
};

export type HighlightOccurrenceType = (
  str: string,
  matchValue: string,
) => HighlightedEntity[];

export interface IHighlightedStringProps {
  str: string;
  matchValue: string;
  highlightOccurrence?: HighlightOccurrenceType;
}

const createRegExp = (matchValue: string) =>
  new RegExp(escapeStringRegexp(matchValue), 'ig');

const getRealDividerValueByPartIndex = (
  matchValue: string,
  str: string,
  index: number,
) =>
  str.substr(
    str
      .split(createRegExp(matchValue))
      .slice(0, index)
      .join(matchValue).length,
    matchValue.length,
  );

export const defaultHighlightOccurrence: HighlightOccurrenceType = (
  str,
  matchValue,
) =>
  isEmpty(matchValue)
    ? [{ text: str, highlight: false }]
    : str
        .split(createRegExp(matchValue))
        .reduce((result: HighlightedEntity[], part, i) => {
          if (i > 0) {
            result.push({
              text: getRealDividerValueByPartIndex(matchValue, str, i),
              highlight: true,
            });
          }

          result.push({
            text: part,
            highlight: false,
          });
          return result;
        }, [] as HighlightedEntity[]);

export const HighlightedString: React.SFC<IHighlightedStringProps> = ({
  str,
  matchValue,
  highlightOccurrence = defaultHighlightOccurrence,
}) => (
  <React.Fragment>
    {highlightOccurrence(str, matchValue).map(({ text, highlight }, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={i} className={cn({ [css.textHighlight]: highlight })}>
        {text}
      </span>
    ))}
  </React.Fragment>
);
