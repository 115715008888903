import React from 'react';
import cx from 'classnames';
import { HoverDisclosure } from '../HoverDisclosure';
import * as s from './ListItem.css';

interface Props extends TestLocator {
  interactive?: boolean;
  onClick?: () => void;
  drawDivider?: boolean;
  boxClassName?: string;
  itemClassName?: string;
}

export const ListItem: React.FC<Props> = ({
  interactive,
  onClick,
  drawDivider = true,
  children,
  boxClassName,
  itemClassName,
  'data-testid': dataTestId,
}) => {
  if (onClick && !interactive) {
    throw new Error(
      '"interactive" prop must be passed if you want ' +
        'the ListItem to receive clicks',
    );
  }
  return (
    <HoverDisclosure
      render={({ isVisible, bind }) => (
        <div
          {...bind}
          className={cx(s.itemBox, boxClassName, {
            [s.interactiveHover]: interactive && isVisible,
          })}
          tabIndex={interactive ? 0 : undefined}
          onClick={onClick}
          onKeyDown={
            onClick
              ? (event) => {
                  if (event.key === 'Enter') {
                    onClick();
                  }
                }
              : undefined
          }
          data-testid={dataTestId}
        >
          <div
            className={cx(s.item, itemClassName, {
              [s.itemNoDivider]: !drawDivider,
            })}
          >
            <div className={s.itemChild}>{children}</div>
          </div>
        </div>
      )}
    />
  );
};
