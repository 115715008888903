import React from 'react';
import AutosizeInput from 'react-input-autosize';

interface IRenameInputProps extends TestLocator {
  onChange: React.EventHandler<React.FormEvent<HTMLInputElement>>;
  onSubmit: () => void;
  onCancel?: () => void;
  value: string;
}

export class RenameInput extends React.Component<IRenameInputProps> {
  inputRef: HTMLInputElement | null = null;

  componentDidMount() {
    if (this.inputRef) {
      this.inputRef.select();
    }
  }

  setRef = (ref: HTMLInputElement | null) => {
    this.inputRef = ref;
  };

  keyDownHandler = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      this.props.onSubmit();
    }
  };

  render() {
    return (
      <AutosizeInput
        data-testid={this.props['data-testid']}
        onKeyDown={this.keyDownHandler}
        inputRef={this.setRef}
        value={this.props.value}
        inputStyle={{ border: 'none', padding: 0 }}
        onBlur={this.props.onSubmit}
        onChange={this.props.onChange}
      />
    );
  }
}
