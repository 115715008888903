import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Callout, CalloutProps } from './Callout';
import * as css from './Callout.css';

interface FadeInCalloutProps extends CalloutProps {
  open?: boolean;
}

export const FadeInCallout: React.FC<FadeInCalloutProps> = ({
  open = true,
  ...props
}) => {
  const [firstOpen, setFirstOpen] = useState(false);

  useEffect(() => {
    setFirstOpen(true);
  }, []);

  return (
    <CSSTransition
      in={open && firstOpen}
      timeout={300}
      classNames={{
        enter: css.calloutEnter,
        enterActive: css.calloutEnterActive,
        exit: css.calloutExit,
        exitActive: css.calloutExitActive,
      }}
      unmountOnExit
    >
      <Callout {...props} />
    </CSSTransition>
  );
};
