import React from 'react';
import cn from 'classnames';

import { logDeprecatedComponent } from '@utils/logDeprecated';
import { getComponentWithRefAndName } from '@utils/withRef';

import { IconColor, IconProps, IconSize } from './iconTypes';
import * as styles from './module.css';

const componentName = 'Icon';
export const Icon = logDeprecatedComponent(
  componentName,
  getComponentWithRefAndName<HTMLDivElement, IconProps>(
    componentName,
    (
      {
        color = IconColor.white,
        svg: SvgComponent,
        className,
        size = IconSize.medium,
        url,
        rounded,
        ...props
      },
      ref,
    ) => (
      <div
        {...props}
        className={cn([styles.container, className || '', styles[size]])}
        ref={ref}
      >
        {SvgComponent && <SvgComponent className={styles[color]} />}
        {url && (
          <div
            style={{ backgroundImage: `url("${url}")` }}
            className={cn(styles.imgIcon, { [styles.rounded]: rounded })}
          />
        )}
      </div>
    ),
  ),
);
