import React from 'react';

import { logDeprecatedComponent } from '@utils/logDeprecated';
import { ReactComponent as crossIcon } from '../Icon/icons/cross.svg';
import { ReactComponent as searchIcon } from '../Icon/icons/ic_search.svg';
import { Input, InputProps } from './Input';

export type FakeEvent = {
  target: {
    value: string;
  };
};

interface SearchInputProps extends InputProps {
  placeholderIcon?: never;
  buttonIcon?: never;
  onChange: (event: FakeEvent) => void | InputProps['onChange']; // TODO: how it can do better?
}

function handleIconClick(props: SearchInputProps) {
  if (props.onIconClick) {
    props.onIconClick();
  }

  const event: FakeEvent = {
    target: {
      value: '',
    },
  };
  props.onChange(event);
}

export const SearchInput: React.FC<SearchInputProps> = logDeprecatedComponent(
  'SearchInput',
  (props) => {
    const onIconClick = () => handleIconClick(props);
    return (
      <Input
        {...props}
        onIconClick={onIconClick}
        placeholderIcon={searchIcon}
        buttonIcon={crossIcon}
      />
    );
  },
);
