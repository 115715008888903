import React from 'react';

export class PreventAngularGlobalClickEvent extends React.Component<{}, {}> {
  jqClickEventsBackup: any[] = [];

  // eslint-disable-next-line no-underscore-dangle
  jqClickEventsLink: any[] = window.jQuery._data(document, 'events').click;

  componentDidMount() {
    if (Array.isArray(this.jqClickEventsLink)) {
      this.jqClickEventsBackup = [...this.jqClickEventsLink];
      this.jqClickEventsLink.length = 0;
    }
  }

  componentWillUnmount() {
    if (Array.isArray(this.jqClickEventsLink)) {
      this.jqClickEventsLink.push(...this.jqClickEventsBackup);
    }
  }

  render() {
    return this.props.children;
  }
}
