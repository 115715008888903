import React from 'react';
import { isIOS, isOSX } from './Emoji.helpers';
import { EmojisPaths } from './Emoji.constants';
import { Emojis } from './Emoji.types';

export interface EmojiProps {
  emoji: Emojis;
  label?: string;
  size: number | string;
  className?: string;
  style?: React.CSSProperties;
  forceImage?: boolean; // for testing purposes (to check image display on apple devices)
}

export const Emoji: React.FC<EmojiProps> = ({
  emoji,
  label,
  size,
  style,
  className,
  forceImage,
}) =>
  (isIOS || isOSX) && !forceImage ? (
    <span
      className={className}
      style={size ? { ...style, fontSize: size } : style}
      aria-label={label ?? emoji}
    >
      {emoji}
    </span>
  ) : (
    <img
      className={className}
      style={size ? { ...style, width: size, height: size } : style}
      alt={label ?? emoji}
      src={EmojisPaths[emoji]}
    />
  );
