import React from 'react';
import { Platform } from '@globals';
import cn from 'classnames';
import { Avatar, AvatarSize } from './Avatar';
import { ReactComponent as MessengerPlatform } from './assets/messenger.svg';
import { ReactComponent as InstagramPlatform } from './assets/InstagramDirectLogo.svg';
import { ReactComponent as WhatsappPlatform } from './assets/Whatsapp.svg';
import * as css from './Avatar.css';

const PlatformIcon: React.FC<{ platform: Platform }> = ({ platform }) => {
  return {
    [Platform.facebook]: <MessengerPlatform className={css.platformIcon} />,
    [Platform.instagram]: <InstagramPlatform className={css.platformIcon} />,
    [Platform.whatsapp]: <WhatsappPlatform className={css.platformIcon} />,
  }[platform];
};

export const AvatarWithPlatform: React.FC<{
  src: string | null;
  platform: Platform;
  size?: AvatarSize;
  className?: string;
  containerClassName?: string;
}> = ({
  src,
  platform,
  size = AvatarSize.big,
  className,
  containerClassName,
}) => (
  <div className={cn(css.avatarContainer, containerClassName)}>
    <Avatar src={src} size={size} className={className} />
    <PlatformIcon platform={platform} />
  </div>
);
