import React, {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import { propOr } from 'ramda';
import memoizeOne from 'memoize-one';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftProps,
  GetItemPropsOptions,
} from 'downshift';
import { Manager, Popper, Reference } from 'react-popper';
import { Boundary, Modifiers, Placement } from 'popper.js';
import { combineRefs } from '@utils/combineRefs';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Menubox, MenuItem } from '../Menu';
import { ScrollBox } from '../ScrollBox';
import { normalizedIncludes } from '@utils/normalizedIncludes';
import { usePreventWheel } from '@utils/Event/usePreventWheel';
import * as css from './SimpleCombobox.css';

type DefaultItemPayload = {
  title: string;
};

export type Item<T extends {} = DefaultItemPayload> = T & {
  id: string;
  disabled?: boolean;
  fixedToFooter?: boolean;
};

export type ComboboxPosition = 'bottom-start' | 'bottom-end' | 'right-start';

export const filterItemsToShowWithoutCurrentItem = memoizeOne(
  (
    itemToString: (item: Item) => string,
    items: Item[],
    selectedItem: Item,
    inputValue: string | null,
  ) => {
    const selectedItemString = itemToString(selectedItem).toLowerCase();
    const inputValueString = (inputValue || '').toLowerCase();

    return items.filter((item) => {
      const itemString = itemToString(item).toLowerCase();
      if (itemString === selectedItemString) {
        return false;
      }
      if (inputValueString === selectedItemString) {
        return true;
      }
      return normalizedIncludes(itemString, inputValueString);
    });
  },
);

export interface VirtualScrollComboboxProps {
  virtualScroll?: boolean;
  autoSizerStyles?: CSSProperties;
  fixedSizeListProps?: (props: {
    height: number;
  }) => Omit<FixedSizeListProps, 'children' | 'itemCount' | 'width'>;
  mapFixedSizeListHeight?: (height: number) => number;
}

export type ComboboxFilterType<T> = (
  itemToString: (item: T) => string,
  items: T[],
  selectedItem: T,
  inputValue: string | null,
) => T[];

export interface SimpleComboboxProps<T>
  extends DownshiftProps<Item<T> | null>,
    VirtualScrollComboboxProps {
  renderInput: (props: any) => React.ReactNode;
  renderItem?: (props: RenderItemProps<T>) => React.ReactNode;
  renderMenuHead?: (props: any) => React.ReactNode;
  items: Item<T>[];
  autoSelectFirstItem?: boolean;
  unsaveSelectedOption?: boolean; // Do not save selection, just choose (for dropdown menus)
  className?: string;
  downshiftContainerClassName?: string;
  downshiftContainerStyle?: React.CSSProperties;
  menuBoxClassName?: string;
  menuboxStyle?: React.CSSProperties;
  scrollboxStyle?: React.CSSProperties;
  initialIsOpen?: boolean;
  menuItemStyle?: React.CSSProperties;
  itemToString?: (item: Item<T> | null) => string;
  filter?: ComboboxFilterType<Item<T>>;
  position?: Placement;
  modifiers?: Modifiers;
  ignoreParentsOverflow?: boolean; // ignore parents boundaries and create position based only on viewport
  boundariesElement?: Boundary | Element; // element to keep menu in (using for avoiding menus under the dashboard header)
  hideFooterSeparator?: boolean;
  disableFixedMode?: boolean;
  renderEmptyState?(
    currentValue: string,
    config: { closeMenu: () => void },
  ): React.ReactNode;
  downshiftMenuRef?: React.MutableRefObject<HTMLDivElement | null>;
  dataTestId?: string;
  menuBoxDataTestId?: string;
}

interface RenderItemProps<T> {
  getItemProps(options: GetItemPropsOptions<T>): any;
  item: Item<T>;
  index: number;
  highlightedIndex: number | null;
  closeMenu(cb?: () => void): void;
}

export function defaultFilterItemsToShow<T>(
  itemToString: (item: T) => string,
  items: T[],
  selectedItem: T,
  inputValue: string | null,
) {
  if (inputValue === itemToString(selectedItem)) {
    // display all items
    return items;
  }
  return items.filter((item) =>
    normalizedIncludes(
      itemToString(item).toLowerCase(),
      (inputValue || '').toLowerCase(),
    ),
  );
}

export const defaultItemToString = propOr<string>('', 'title');

/**
 * @deprecated this component is deprecated use one of Comboboxes defined in SimpleCombobox/Combobox folder
 */
export function SimpleCombobox<T>({
  onChange,
  renderInput,
  renderItem,
  renderMenuHead,
  items,
  menuboxStyle,
  menuBoxClassName,
  scrollboxStyle,
  initialIsOpen,
  menuItemStyle,
  autoSelectFirstItem,
  unsaveSelectedOption,
  itemToString = defaultItemToString,
  filter = defaultFilterItemsToShow,
  position = 'bottom-start',
  modifiers,
  ignoreParentsOverflow = false,
  boundariesElement,
  disableFixedMode = false,
  renderEmptyState,
  downshiftMenuRef,
  virtualScroll,
  autoSizerStyles,
  fixedSizeListProps,
  mapFixedSizeListHeight,
  hideFooterSeparator,
  dataTestId,
  menuBoxDataTestId,
  ...props
}: SimpleComboboxProps<T>) {
  const { t } = useSafeTranslation();
  const scheduleUpdateRef = useRef<VoidFunction>();
  const inputRef = useRef<HTMLInputElement>(
    null,
  ) as MutableRefObject<HTMLInputElement>;
  const selectInputValue = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [inputRef]);

  const filterMemoized = useCallback(memoizeOne(filter), []);
  const preventBackgroundScrollRef = usePreventWheel();

  useEffect(() => {
    scheduleUpdateRef.current?.();
  }, [items]);

  return (
    <Downshift
      itemToString={itemToString}
      onChange={(selectedItem, controllerStateAndHelpers) => {
        if (onChange) {
          onChange(
            selectedItem,
            controllerStateAndHelpers as ControllerStateAndHelpers<Item<T> | null>,
          );
        }
        if (unsaveSelectedOption) {
          controllerStateAndHelpers.clearSelection();
        }
      }}
      initialIsOpen={initialIsOpen}
      {...props}
    >
      {({
        isOpen,
        getItemProps,
        inputValue,
        highlightedIndex,
        selectedItem,
        setHighlightedIndex,
        ...otherDownshiftStateAndHelpers
      }) => {
        if (autoSelectFirstItem && isOpen && highlightedIndex === null) {
          setHighlightedIndex(0);
        }

        // fix bad focus behaviour in safari (focus passed to parent)
        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
          otherDownshiftStateAndHelpers.getToggleButtonProps().onClick(e);
        };

        const currentItems = filterMemoized(
          itemToString,
          items,
          selectedItem,
          inputValue,
        );

        const footerItem = currentItems.find(
          ({ fixedToFooter }) => !!fixedToFooter,
        );

        const renderItems = currentItems.filter(
          ({ fixedToFooter }) => !fixedToFooter,
        );

        return (
          <div
            className={props.downshiftContainerClassName}
            style={props.downshiftContainerStyle}
            data-test-id={dataTestId}
          >
            <Manager>
              <Reference>
                {({ ref }) => (
                  <div
                    ref={ref}
                    className={props.className}
                    style={{ position: 'relative' }}
                  >
                    {renderInput({
                      ...otherDownshiftStateAndHelpers,
                      getToggleButtonProps: () => ({
                        ...otherDownshiftStateAndHelpers.getToggleButtonProps(),
                        onClick,
                      }),
                      selectedItem,
                      isOpen,
                      selectInputValue,
                      ref: (el: HTMLInputElement) => {
                        inputRef.current = el;
                      },
                    })}
                  </div>
                )}
              </Reference>
              {isOpen ? (
                <Popper
                  placement={position}
                  modifiers={
                    modifiers || {
                      offset: {
                        offset: '0, 4',
                      },
                      preventOverflow:
                        boundariesElement || ignoreParentsOverflow
                          ? {
                              enabled: true,
                              boundariesElement:
                                boundariesElement ?? 'viewport',
                              escapeWithReference: false,
                            }
                          : undefined,
                    }
                  }
                  positionFixed={!disableFixedMode}
                >
                  {({ ref, style, scheduleUpdate }) => {
                    scheduleUpdateRef.current = scheduleUpdate;
                    return (
                      <Menubox
                        ref={combineRefs([ref, downshiftMenuRef])}
                        className={cn(css.comboboxMenu, menuBoxClassName)}
                        style={{ ...style, ...menuboxStyle }}
                        data-testid={menuBoxDataTestId}
                      >
                        {renderMenuHead?.({
                          getToggleButtonProps:
                            otherDownshiftStateAndHelpers.getToggleButtonProps,
                          getInputProps:
                            otherDownshiftStateAndHelpers.getInputProps,
                        })}

                        {virtualScroll ? (
                          <AutoSizer disableWidth style={autoSizerStyles}>
                            {({ height }) => (
                              <FixedSizeList
                                {...fixedSizeListProps!({ height })}
                                width="100%"
                                layout="vertical"
                                itemCount={renderItems.length}
                              >
                                {({ index, style }) => (
                                  <div style={style}>
                                    {renderItem?.({
                                      getItemProps,
                                      item: renderItems[index],
                                      index,
                                      highlightedIndex,
                                      closeMenu:
                                        otherDownshiftStateAndHelpers.closeMenu,
                                    })}
                                  </div>
                                )}
                              </FixedSizeList>
                            )}
                          </AutoSizer>
                        ) : (
                          <ScrollBox
                            style={scrollboxStyle}
                            innerRef={preventBackgroundScrollRef}
                          >
                            {currentItems.length === 0
                              ? renderEmptyState?.(inputValue ?? '', {
                                  closeMenu:
                                    otherDownshiftStateAndHelpers.closeMenu,
                                }) ?? (
                                  <MenuItem
                                    disabled
                                    title={t(
                                      'SimpleCombobox-string--959-no-items-match-your-search',
                                    )}
                                  />
                                )
                              : renderItems.map((item, index) =>
                                  renderItem ? (
                                    renderItem({
                                      getItemProps,
                                      item,
                                      index,
                                      highlightedIndex,
                                      closeMenu:
                                        otherDownshiftStateAndHelpers.closeMenu,
                                    })
                                  ) : (
                                    <MenuItem
                                      disabled={item.disabled}
                                      key={item.id}
                                      style={menuItemStyle}
                                      {...getItemProps({ item })}
                                      active={index === highlightedIndex}
                                      title={itemToString(item)}
                                    />
                                  ),
                                )}
                          </ScrollBox>
                        )}

                        {footerItem && (
                          <>
                            {!hideFooterSeparator && (
                              <div className={css.separator} />
                            )}
                            {renderItem ? (
                              renderItem({
                                getItemProps,
                                item: footerItem,
                                index: currentItems.length - 1,
                                highlightedIndex,
                                closeMenu:
                                  otherDownshiftStateAndHelpers.closeMenu,
                              })
                            ) : (
                              <MenuItem
                                key={footerItem.id}
                                disabled={footerItem.disabled}
                                style={menuItemStyle}
                                {...getItemProps({ item: footerItem })}
                                active={
                                  currentItems.length - 1 === highlightedIndex
                                }
                                title={itemToString(footerItem)}
                              />
                            )}
                          </>
                        )}
                      </Menubox>
                    );
                  }}
                </Popper>
              ) : null}
            </Manager>
          </div>
        );
      }}
    </Downshift>
  );
}
