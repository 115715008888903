import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { LONG_DASH } from './consts';

export interface StatisticsProps {
  caption?: string;
  className?: string;
}

export const Statistics: React.FC<StatisticsProps> = ({
  children,
  caption,
  className,
}) => (
  <Flex flexDirection="column" className={className}>
    {children}
    {caption && (
      <>
        <Spacer factor={1} />
        <Type size="15px">{caption}</Type>
      </>
    )}
  </Flex>
);

interface StatisticsNullableProps extends StatisticsProps {
  defaultWith?: React.ReactNode;
}

export const StatisticsNullable: React.FC<StatisticsNullableProps> = ({
  defaultWith = LONG_DASH,
  children,
  ...props
}) => (
  <Statistics {...props}>
    {children == null ? defaultWith : children}
  </Statistics>
);
