import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React, { useState } from 'react';
import moment from 'moment';
import {
  DayPickerSingleDateController,
  DateRangePickerShape,
} from 'react-dates';
import './styles/react-dates.global.css';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Type } from '../Type';
import * as css from './DatePicker.css';
import { Icon } from '../Icon';
import { DateFormat, DateUtils } from '@utils/DateTime';

export type Moment = moment.Moment;

export interface DatePickerProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    'onChange' | 'defaultValue'
  > {
  onChange: (date: Moment) => void;
  value?: Moment;
  showBorder?: boolean;
  numberOfMonths?: number;
  autofocus?: boolean;
  isDayBlocked?: DateRangePickerShape['isDayBlocked'];
}

export const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  value,
  autofocus = false,
  numberOfMonths = 1,
  showBorder,
  isDayBlocked,
  ...props
}) => {
  const [focused, setFocused] = useState<boolean>(autofocus);

  const onFocusChange = ({ focused }: { focused: boolean | null }) => {
    setFocused(!!focused);
  };

  return (
    <div {...props}>
      <DayPickerSingleDateController
        date={value as any}
        isDayBlocked={isDayBlocked}
        onDateChange={(d: any) => {
          onChange(d as Moment);
        }}
        onFocusChange={onFocusChange}
        focused={focused}
        initialVisibleMonth={value ? () => value as any : undefined}
        numberOfMonths={numberOfMonths}
        noBorder={!showBorder}
        hideKeyboardShortcutsPanel
        transitionDuration={0} // TODO update in couple of weeks: https://github.com/airbnb/react-dates/issues/2050
        navPrev={
          <Button
            intent="text"
            icon={<Icon icon="triangle" className={css.leftArrow} />}
            className={css.monthLeftButton}
          />
        }
        navNext={
          <Button
            intent="text"
            icon={<Icon icon="triangle" className={css.rightArrow} />}
            className={css.monthRightButton}
          />
        }
        renderMonthElement={({ month }) => (
          <Flex justifyContent="center">
            <Type weight="medium" size="15px_DEPRECATED">
              {DateFormat.MMMMYYYY(month)}
            </Type>
          </Flex>
        )}
        // add ignore cause library types are bad for this prop
        // @ts-ignore
        renderWeekHeaderElement={(day) => (
          <Type size="12px-all_caps" color="grey">
            {DateUtils.mapDDtoDDD(day)}
          </Type>
        )}
      />
    </div>
  );
};
