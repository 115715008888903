import ng from 'angular';

import { ngDateRangePickerField } from '@ui/DatePicker/DateRangePickerField/ngDateRangePickerField';
import { ngExportChartButton } from '../pages/BotPage/AnalyzeTab/ExportChartButton/ngExportChartButton';
import { ngDropdownUi } from './_deprecated/Dropdown/ngDropdown';
import { ngAvatarWithPlatform } from './Avatar/ngAvatar';
import { ngSuggestStringsUi } from './Suggest/ngSuggest';
import { ngTimeoutInput } from './TimeoutInput/ngTimeoutInput';
import { ngSectionHeader } from './SectionHeader/ngSectionHeader';
import { ngButtonAdapter } from './_deprecated/buttonAdapter';
import { ngWhiteLabelLogo } from '../modern-components/PageHeader/WhiteLabelLogo/ngWhiteLabelLogo';
import { ngEmojiPicker } from './EmojiPicker/ngEmojiPicker';

import './_common/fonts.css';
import './_common/colors/colors.css';
import './_common/sizes.css';
import './_common/z-index.css';

export default ng.module('app.modernUi', [
  ngDropdownUi,
  ngSuggestStringsUi,
  ngTimeoutInput,
  ngSectionHeader,
  ngButtonAdapter,
  ngWhiteLabelLogo,
  ngEmojiPicker,
  ngAvatarWithPlatform,
  ngDateRangePickerField,
  ngExportChartButton,
]).name;
