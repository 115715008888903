import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as PopperJS from 'popper.js';
import { BaseEmoji } from 'emoji-mart';
import OutsideClick from 'react-outsideclick';
import { Popper } from 'react-popper';
import { Portal } from 'react-portal';
import * as css from './EmojiPopup.css';
import { Loader } from '../Loader';
import { Flex } from '../Flex';

const Picker = React.lazy(async () => {
  const [EmojiMart] = await Promise.all([
    import('emoji-mart'),
    import('emoji-mart/css/emoji-mart.css'),
  ]);
  return { default: (EmojiMart as typeof import('emoji-mart')).Picker };
});

export interface EmojiPopupProps {
  onSelect: (emoji: BaseEmoji) => void;
  onRequestClose: () => void;
  referenceElement: Element | undefined;
  offset?: string;
  placement?: PopperJS.Placement;
}

const stopPropagationEvent = (
  // todo : remove me after remove Angular
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  event.nativeEvent.stopImmediatePropagation();
  if ((event.target as HTMLElement).tagName !== 'INPUT') {
    event.nativeEvent.preventDefault();
  }
};

export const EmojiPopup = React.forwardRef<HTMLDivElement, EmojiPopupProps>(
  (
    {
      onSelect,
      onRequestClose,
      referenceElement,
      offset = '0px, 10px',
      placement = 'bottom-end',
    },
    ref,
  ) => {
    const { t } = useSafeTranslation();

    return (
      <Portal>
        <Popper
          referenceElement={referenceElement}
          placement={placement}
          modifiers={{
            offset: {
              offset,
            },
          }}
        >
          {({ ref: popperRef, style }) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              style={style}
              ref={(el) => {
                if (typeof popperRef === 'function') {
                  popperRef(el);
                } else if (popperRef !== null) {
                  // eslint-disable-next-line no-param-reassign
                  (popperRef as any).current = el;
                }
                if (el && ref) {
                  // eslint-disable-next-line no-param-reassign
                  (ref as React.MutableRefObject<HTMLDivElement>).current = el;
                }
              }}
              className={css.popup}
              onClick={stopPropagationEvent}
              onMouseDown={stopPropagationEvent}
            >
              <OutsideClick
                onClickOutside={() => {
                  if (onRequestClose) {
                    onRequestClose();
                  }
                }}
              >
                <React.Suspense
                  fallback={
                    <Flex
                      className={css.loaderBox}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Loader />
                    </Flex>
                  }
                >
                  <Picker
                    emoji="point_up"
                    title={t('modernUi.EmojiPicker.title')}
                    i18n={{
                      search: t('modernUi.EmojiPicker.search'),
                      categories: {
                        search: t('modernUi.EmojiPicker.categories.search'),
                        recent: t('modernUi.EmojiPicker.categories.recent'),
                        people: t('modernUi.EmojiPicker.categories.people'),
                        nature: t('modernUi.EmojiPicker.categories.nature'),
                        foods: t('modernUi.EmojiPicker.categories.foods'),
                        activity: t('modernUi.EmojiPicker.categories.activity'),
                        places: t('modernUi.EmojiPicker.categories.places'),
                        objects: t('modernUi.EmojiPicker.categories.objects'),
                        symbols: t('modernUi.EmojiPicker.categories.symbols'),
                        flags: t('modernUi.EmojiPicker.categories.flags'),
                        custom: t('modernUi.EmojiPicker.categories.custom'),
                      },
                      notfound: t('modernUi.EmojiPicker.notfound'),
                    }}
                    onSelect={onSelect}
                  />
                </React.Suspense>
              </OutsideClick>
            </div>
          )}
        </Popper>
      </Portal>
    );
  },
);
