import React, { useMemo } from 'react';
import {
  CardElementComponent,
  CardNumberElementComponent,
  CardCvcElementComponent,
  CardExpiryElementComponent,
} from '@stripe/react-stripe-js';
import { StripeElementBase, StripeElementChangeEvent } from '@stripe/stripe-js';
import { Input } from '@ui/Input';
import { HEXColors } from '@ui/_common/colors';

import * as css from './StripeInput.css';

type StripeElementType =
  | CardElementComponent
  | CardNumberElementComponent
  | CardCvcElementComponent
  | CardExpiryElementComponent;

export interface StripeInputProps {
  as: StripeElementType;
  disabled: boolean;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  onReady?: (cardElement: StripeElementBase) => void;
  onChange?: (event: StripeElementChangeEvent) => void;
}

export const StripeInput: React.FC<StripeInputProps> = ({
  as,
  disabled,
  placeholder,
  error,
  errorText,
  onReady,
  onChange,
}) => {
  const CardElement = as;

  const elementOptions = useMemo(
    () => ({
      placeholder,
      disabled,
      style: {
        base: {
          '::placeholder': {
            color: HEXColors.grey,
          },
        },
      },
    }),
    [placeholder, disabled],
  );

  const renderErrorText = errorText ? () => errorText : undefined;

  return (
    <Input
      error={error}
      renderErrorText={renderErrorText}
      render={({ getInputProps }) => {
        const { onFocus, onBlur } = getInputProps({});
        return (
          <CardElement
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            className={css.inputHolder}
            options={elementOptions}
            onReady={onReady}
          />
        );
      }}
    />
  );
};
