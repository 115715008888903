import React, { useCallback, useState } from 'react';
import { StripeInput, StripeInputProps } from './StripeInput';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

type LegacyPremiumStripeInputProps = Omit<
  StripeInputProps,
  'onChange' | 'error'
>;

export const LegacyPremiumStripeInput: React.FC<LegacyPremiumStripeInputProps> =
  (props) => {
    const [cardIsInvalid, setCardInvalid] = useState(false);

    const handleChange = useCallback(
      (event: StripeElementChangeEvent) => {
        setCardInvalid(!!event.error);
      },
      [setCardInvalid],
    );

    return (
      <StripeInput {...props} error={cardIsInvalid} onChange={handleChange} />
    );
  };
