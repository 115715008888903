import React from 'react';
import Downshift, {
  GetToggleButtonPropsOptions,
  GetItemPropsOptions,
} from 'downshift';
import { Manager, Reference, Popper, PopperProps } from 'react-popper';
import { isNil } from 'ramda';
import cn from 'classnames';

import { logDeprecatedComponent } from '@utils/logDeprecated';

import { MenuItem } from '@ui/Menu';
import ScrollBox from '@ui/ScrollBox/ScrollBox';

import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import {
  Button,
  ButtonSize,
  ButtonIntent,
  ButtonColorWay,
} from '@ui/_deprecated/Button';

import * as css from './Dropdown.css';

export interface IDropdownProps {
  items: string[];
  selectedItem?: string;
  onChange: (item: string) => void;
  className?: string;
  buttonsFactory?: (
    props: GetToggleButtonPropsOptions,
    ref: React.Ref<any>,
  ) => JSX.Element;
  menuItemFactory?: (
    props: GetItemPropsOptions<string>,
    item: string,
    customItemProps: any,
  ) => JSX.Element;
  placement?: PopperProps['placement'];
  fullWidth?: boolean;
  inline?: boolean;
  boxClasses?: string;
  buttonColorWay?: ButtonColorWay;
  itemClassName?: string;
  id?: string;
  disabled?: boolean;
  onFocus?: () => void;
}

const defaultPlacement = 'bottom-start';

const defaultItemSizes = {
  height: 32,
  sidePadding: 16,
  lineHeight: 28,
};

const DropdownUi = logDeprecatedComponent(
  'Dropdown',
  ({
    items,
    selectedItem,
    onChange,
    buttonsFactory,
    menuItemFactory,
    placement = defaultPlacement,
    fullWidth,
    inline,
    className,
    boxClasses,
    itemClassName,
    buttonColorWay,
    id,
    disabled,
    onFocus,
  }: IDropdownProps) => {
    const selectedItemIndex = items.indexOf(selectedItem || '');
    const offset =
      inline && selectedItemIndex !== -1
        ? `-${defaultItemSizes.sidePadding}px, -${
            selectedItemIndex * defaultItemSizes.height +
            defaultItemSizes.lineHeight
          }px`
        : '';

    return (
      <Manager>
        <Downshift selectedItem={selectedItem} onChange={onChange}>
          {({
            isOpen,
            getToggleButtonProps,
            getItemProps,
            highlightedIndex,
          }) => (
            <div
              data-testid="dropdown"
              style={fullWidth ? { width: '100%' } : undefined}
              className={className}
            >
              <Reference>
                {({ ref }) =>
                  buttonsFactory ? (
                    buttonsFactory(getToggleButtonProps(), ref)
                  ) : (
                    <Button
                      innerRef={ref}
                      intent={ButtonIntent.primary}
                      colorWay={buttonColorWay}
                      size={ButtonSize.m}
                      renderIconRight={() => <DropdownIcon />}
                      style={fullWidth && { width: '100%' }}
                      {...getToggleButtonProps()}
                      id={id}
                      disabled={disabled}
                      onFocus={onFocus}
                    >
                      {selectedItem || 'Select an item ...'}
                    </Button>
                  )
                }
              </Reference>
              {isOpen && (
                <Popper
                  positionFixed
                  placement={inline ? defaultPlacement : placement}
                  modifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                    flip: {
                      enabled: !inline,
                    },
                    offset: {
                      offset,
                      enabled: inline,
                    },
                  }}
                >
                  {({ ref, style, placement }) => (
                    <div
                      ref={ref}
                      style={fullWidth ? { ...style, width: '100%' } : style}
                      data-placement={placement}
                      className={cn(css.box_makeup, boxClasses)}
                    >
                      <ScrollBox>
                        {items.map((item, index) => {
                          const itemProps = getItemProps({
                            item,
                          });
                          const customItemProps = {
                            active: isNil(highlightedIndex)
                              ? item === selectedItem
                              : highlightedIndex === index,
                            title: item,
                          };
                          return menuItemFactory ? (
                            menuItemFactory(itemProps, item, customItemProps)
                          ) : (
                            <MenuItem
                              {...itemProps}
                              {...customItemProps}
                              className={itemClassName}
                              key={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </ScrollBox>
                    </div>
                  )}
                </Popper>
              )}
            </div>
          )}
        </Downshift>
      </Manager>
    );
  },
);

export default DropdownUi;

export const Dropdown = DropdownUi;
