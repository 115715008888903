import React from 'react';
import cn from 'classnames';
import { logDeprecatedComponent } from '@utils/logDeprecated';
import * as css from './Button.module.css';

export const TextButton: React.FC<React.ButtonHTMLAttributes<
  HTMLButtonElement
>> = logDeprecatedComponent(
  'TextButton',
  ({ children, className, ...props }) => (
    <button className={cn(className, css.textButton)} {...props}>
      {children}
    </button>
  ),
);
