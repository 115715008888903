import OutsideClick from 'react-outsideclick';
import { NestedMenuProps, NestedMenu } from '@ui/NestedMenu/NestedMenu';
import { NestedMenuItem } from '@ui/NestedMenu/types';
import React, { useState } from 'react';
import { Popper, Reference, Manager, PopperProps } from 'react-popper';
import { ButtonUnstyled } from '@ui/Button';
import { CenteredLoader } from '@ui/Loader';
import { Menubox } from '@ui/Menu';

export interface PopperMenuProps<
  MenuItemType extends NestedMenuItem = NestedMenuItem,
> extends Pick<NestedMenuProps<MenuItemType>, 'items'> {
  onItemSelect: NestedMenuProps<MenuItemType>['onChange'];
  placement?: PopperProps['placement'];
  modifiers?: PopperProps['modifiers'];
  className?: string;
  children(
    ref: any,
    payload: { setOpen: (open: boolean) => void; open: boolean },
  ): React.ReactNode;
  menuStopPropagation?: boolean;
  menuLoading?: boolean;
  loadingMenuBoxClassName?: string;
}

export const PopperMenu = <
  MenuItemType extends NestedMenuItem = NestedMenuItem,
>({
  items,
  onItemSelect,
  children,
  placement,
  modifiers,
  className,
  menuStopPropagation,
  menuLoading,
  loadingMenuBoxClassName,
}: PopperMenuProps<MenuItemType>) => {
  const [open, setOpen] = useState(false);

  const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

  return (
    <Manager>
      <Reference>{({ ref }) => children(ref, { setOpen, open })}</Reference>
      {open && (
        <Popper placement={placement} positionFixed modifiers={modifiers}>
          {({ ref, style }) => (
            <ButtonUnstyled
              ref={ref}
              style={style}
              onClick={menuStopPropagation ? stopPropagation : undefined}
              className={className}
            >
              <OutsideClick onClickOutside={() => setOpen(false)}>
                {menuLoading ? (
                  <Menubox className={loadingMenuBoxClassName}>
                    <CenteredLoader />
                  </Menubox>
                ) : (
                  <NestedMenu<MenuItemType>
                    items={items}
                    onChange={(item) => {
                      setOpen(false);
                      onItemSelect(item);
                    }}
                  />
                )}
              </OutsideClick>
            </ButtonUnstyled>
          )}
        </Popper>
      )}
    </Manager>
  );
};
