import { MutableRefObject, useState } from 'react';
import { useEventListener } from 'cf-common/src/hooks/useEventListener';

export function useFocusWithin<T extends MutableRefObject<HTMLElement | null>>(
  containerRef: T,
) {
  const [focusedWithin, setFocusedWithin] = useState(false);

  const config = {
    htmlElementRef: containerRef,
  };

  useEventListener('focusin', () => setFocusedWithin(true), config);
  useEventListener('focusout', () => setFocusedWithin(false), config);

  return { focusedWithin };
}
