import React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Modifiers, Placement } from 'popper.js';
import cn from 'classnames';
import { Portal } from 'react-portal';
import * as css from './Tooltip.css';

export enum TooltipMode {
  classic = 'classic',
  modern = 'modern',
}

interface ITooltipProps {
  children: (ref: React.Ref<any>) => React.ReactNode;
  content: React.ReactNode;
  placement?: Placement;
  show?: boolean;
  className?: string;
  mode?: TooltipMode;
  modifiers?: Modifiers;
}

export const Tooltip = ({
  children,
  placement = 'auto',
  content,
  show,
  className = '',
  modifiers,
  mode = TooltipMode.modern,
}: ITooltipProps) => {
  let renderTooltip: (
    ref: React.Ref<any>,
    style: React.CSSProperties,
  ) => React.ReactNode;

  switch (mode) {
    case TooltipMode.modern:
      renderTooltip = (ref: React.Ref<any>, style: React.CSSProperties) => (
        <div
          ref={ref}
          style={style}
          className={cn(css['tooltip-popover'], className)}
        >
          {content}
        </div>
      );
      break;
    case TooltipMode.classic:
      renderTooltip = (ref: React.Ref<any>, style: React.CSSProperties) => (
        <div ref={ref} style={style} className={cn(css.wrapper, className)}>
          <div className={css.classicTooltip}>{content}</div>
          <div className={css.classicTooltipNib} />
        </div>
      );
      break;
    default:
      renderTooltip = (ref: React.Ref<any>, style: React.CSSProperties) => (
        <div
          ref={ref}
          style={style}
          className={cn(css['tooltip-popover'], className)}
        >
          {content}
        </div>
      );
      break;
  }

  return (
    <Manager>
      <Reference>{({ ref }) => children(ref)}</Reference>
      {show ? (
        <Portal>
          <Popper placement={placement} modifiers={modifiers}>
            {({ ref, style }) => renderTooltip(ref, style)}
          </Popper>
        </Portal>
      ) : null}
    </Manager>
  );
};
