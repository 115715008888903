import React from 'react';
import Downshift, { DownshiftProps as DownshiftPropsRaw } from 'downshift';
import {
  Manager,
  Popper,
  Reference,
  PopperProps as PopperPropsRaw,
} from 'react-popper';
import { ComboboxChildrenProps, RenderInput } from './types';

type DownshiftProps<T> = Omit<DownshiftPropsRaw<T>, 'children'>;
type PopperProps = Omit<PopperPropsRaw, 'children'>;

export interface ComboboxBaseProps<T>
  extends DownshiftProps<T>,
    PopperProps,
    RenderInput<T>,
    TestLocator {
  children: (props: ComboboxChildrenProps<T>) => React.ReactNode;
  containerClassName?: string;
}

export function ComboboxBase<T>({
  renderInput,
  children,
  containerClassName,
  ...props
}: ComboboxBaseProps<T>) {
  return (
    <Downshift {...props}>
      {(downshift) => (
        <div className={containerClassName} data-testid={props['data-testid']}>
          <Manager>
            <Reference>
              {(popperReference) => renderInput({ popperReference, downshift })}
            </Reference>
            {downshift.isOpen ? (
              <Popper {...props}>
                {(popper) => children({ popper, downshift })}
              </Popper>
            ) : null}
          </Manager>
        </div>
      )}
    </Downshift>
  );
}
