import React, {
  CSSProperties,
  DOMAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { Editor } from 'slate-react';
import { ShopifyDiscounts } from '@components/ShopifyDiscounts';
import { usePreventWheelOnRef } from '@utils/Event/usePreventWheel';
import { InputLikeElement } from '@utils/documentExecCommand';
import { ButtonUnstyled } from '../Button/Unstyled';
import { EmojiPopup } from '../EmojiPicker/EmojiPopup';
import { ReactComponent as FaceIcon } from '../_deprecated/Icon/icons/face.svg';
import { ReactComponent as AttributeIcon } from '../_deprecated/Icon/icons/attribute.svg';
import { ReactComponent as PercentageIcon } from '../_deprecated/Icon/icons/percentage.svg';
import * as css from './TextOutsideControls.css';
import { Flex } from '../Flex';
import {
  FlowBuilderOverlayEvent,
  overlayEventEmitter,
} from '../../modern-components/FlowBuilder/FlowBuilderOverlay/events';
import { Type } from '../Type';
import { ATTR_SIGN } from '../TextWithAttributesEditor';
import { ShopifyDiscountsCodeType } from '@globals';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';

interface TextOutsideControlsProps {
  children: (bind: {
    ref: React.MutableRefObject<any>;
    getInputProps<E = HTMLInputElement, T = Partial<DOMAttributes<E>>>(
      props?: T,
    ): T & {
      onBlur: (event: Event) => void;
      onFocus: (event: Event) => void;
    };
  }) => React.ReactNode;
  onInsertRequest: (text: string, el: InputLikeElement | Editor) => void;
  currentTextLimit?: number;
  shouldShowOutsideControls: ShouldShowOutsideControls;
  tight?: boolean;
  initialShow?: boolean;
  externalInputRef?: React.MutableRefObject<any>;
  controlsBoxStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  className?: string;
}

export interface ShouldShowOutsideControls {
  emoji?: boolean;
  attributes?: boolean;
  symbolsLimit?: boolean;
  discount?: boolean;
}

const TEXT_RED_LIMIT = 6;

const getShouldShowSomeControls = (
  shouldShowOutsideControls: ShouldShowOutsideControls,
) => Object.values(shouldShowOutsideControls || {}).some(Boolean);

export const TextOutsideControls: React.FC<TextOutsideControlsProps> = ({
  children,
  onInsertRequest,
  currentTextLimit = 0,
  shouldShowOutsideControls,
  tight,
  initialShow,
  externalInputRef,
  controlsBoxStyle,
  boxStyle,
  className,
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const innerInputRef = useRef<HTMLElement | null>(null);
  const [show, setShow] = useState(initialShow ?? true);
  const [showPicker, setShowPicker] = useState(false);
  const [discountPicker, setDiscountPicker] = useState(false);
  const preventWheelRef = useRef<HTMLDivElement | null>(null);
  const inputRef = externalInputRef || innerInputRef;
  usePreventWheelOnRef(preventWheelRef);

  const shouldShowOutsideControlsCurrent = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(shouldShowOutsideControls).filter(
          ([feature]) => !isMobileDevice() || feature !== 'emoji',
        ),
      ),
    [shouldShowOutsideControls],
  );

  useEffect(() => {
    const unsubscribers = [
      overlayEventEmitter.on(FlowBuilderOverlayEvent.hide, () => {
        setShowPicker(false);
      }),
    ];
    return () => {
      unsubscribers.forEach((fn) => fn());
    };
  }, []);

  const closePopup = useCallback(() => {
    setShowPicker(false);
  }, []);

  return (
    <>
      <div className={cn(css.box, className)} style={boxStyle}>
        {children({
          ref: inputRef,
          getInputProps: (props: any = {}) => {
            return {
              ...props,
              onBlur: (event) => {
                setShow(false);
                props.onBlur?.(event);
              },
              onFocus: (event) => {
                setShow(true);
                props.onFocus?.(event);
              },
            };
          },
        })}
        {show && getShouldShowSomeControls(shouldShowOutsideControlsCurrent) && (
          <Flex
            ref={boxRef}
            className={cn(css.buttonBox, { [css.tight]: tight })}
            alignItems="center"
            style={controlsBoxStyle}
          >
            {shouldShowOutsideControlsCurrent.discount && (
              <ButtonUnstyled
                onClick={(event) => {
                  event.preventDefault();
                  setDiscountPicker(true);
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                className={css.item}
              >
                <PercentageIcon />
              </ButtonUnstyled>
            )}
            {shouldShowOutsideControlsCurrent.attributes && (
              <ButtonUnstyled
                onClick={(event) => {
                  event.preventDefault();
                  if (inputRef.current) {
                    onInsertRequest(ATTR_SIGN.start, inputRef.current);
                  }
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                className={css.item}
              >
                <AttributeIcon />
              </ButtonUnstyled>
            )}
            {shouldShowOutsideControlsCurrent.emoji && (
              <ButtonUnstyled
                onClick={(event) => {
                  event.preventDefault();
                  setShowPicker(true);
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                className={css.item}
              >
                <FaceIcon />
              </ButtonUnstyled>
            )}
            {shouldShowOutsideControlsCurrent.symbolsLimit && (
              <Flex
                alignItems="center"
                className={cn(css.item, css.currentTextLimit)}
              >
                <Type
                  size="11px"
                  color={currentTextLimit > TEXT_RED_LIMIT ? 'green' : 'red'}
                >
                  {currentTextLimit}
                </Type>
              </Flex>
            )}
          </Flex>
        )}
      </div>
      {showPicker && (
        <EmojiPopup
          onSelect={(emoji) => {
            closePopup();
            if (inputRef.current) {
              onInsertRequest(emoji.native, inputRef.current);
            }
          }}
          onRequestClose={closePopup}
          referenceElement={boxRef.current as Element}
          offset="0px, 0px"
          placement="bottom-end"
          ref={preventWheelRef}
        />
      )}
      {discountPicker && (
        <ShopifyDiscounts
          referenceElement={boxRef.current as Element}
          offset="0px, 0px"
          placement="bottom-end"
          onSelect={(discountCode) => {
            setDiscountPicker(false);
            if (discountCode) {
              onInsertRequest(discountCode.code, inputRef.current);
            }
          }}
          onClose={() => setDiscountPicker(false)}
          discountTypes={[ShopifyDiscountsCodeType.common]}
        />
      )}
    </>
  );
};
