import React from 'react';
import { Menubox } from '@ui/Menu';
import { Menu } from './Menu';
import {
  ExtendStyles,
  Group,
  Items,
  NestedMenuItem,
  RenderContent,
} from './types';
import { useNestedMenu } from './useNestedMenu';
import { isGroup } from './utils';

export interface NestedMenuProps<T> extends ExtendStyles<T>, RenderContent<T> {
  items: Items<T> | Group<T>;
  onChange: (item: T) => void;
  /**
   * handled inside useNestedMenu
   * event.codes: ArrowDown, ArrowRight, ArrowUp, ArrowLeft, Escape, Enter
   */
  onKeyDown?: (event: KeyboardEvent) => void;
}

export function NestedMenu<T extends NestedMenuItem>({
  items,
  onChange,
  onKeyDown,
  getGroupStyles,
  getItemStyles,
  getTitleStyles,
  renderTooltip,
  renderGroupView,
}: NestedMenuProps<T>) {
  const allItems = (isGroup(items) ? items.flat() : items) as Array<T>;
  const nestedMenuProps = useNestedMenu({
    isRoot: true,
    allItems,
    onKeyDown,
    onChange,
  });

  return (
    <Menubox>
      <Menu
        items={items}
        renderTooltip={renderTooltip}
        renderGroupView={renderGroupView}
        getGroupStyles={getGroupStyles}
        getItemStyles={getItemStyles}
        getTitleStyles={getTitleStyles}
        {...nestedMenuProps}
      />
    </Menubox>
  );
}
