interface SizeValue {
  fontSize: React.CSSProperties['fontSize'];
  lineHeight: React.CSSProperties['lineHeight'];
  letterSpacing?: React.CSSProperties['letterSpacing'];
  textTransform?: React.CSSProperties['textTransform'];
}

export enum FontSize {
  '44px' = '44px',
  '40px' = '40px',
  '24px' = '24px',
  '30px' = '30px',
  '32px' = '32px',
  '18px' = '18px',
  '15px' = '15px',
  '15px_DEPRECATED' = '15px_DEPRECATED',
  '12px' = '12px',
  '11px' = '11px',
  '9px' = '9px', // for badges only
  '12px-all_caps' = '12px',
  '18px-all-caps' = '18px',
}

export type FontSizeKey = keyof typeof FontSize;

export const SIZES_CONFIG: Record<FontSizeKey, SizeValue> = {
  '44px': {
    fontSize: '44px',
    lineHeight: '58px',
  },
  '40px': {
    fontSize: '40px',
    lineHeight: '48px',
  },
  '32px': {
    fontSize: '32px',
    lineHeight: '40px',
  },
  '30px': {
    fontSize: '30px',
    lineHeight: '36px',
  },
  '24px': {
    fontSize: '24px',
    lineHeight: '32px',
  },
  '18px': {
    fontSize: '18px',
    lineHeight: '24px',
  },
  '15px_DEPRECATED': {
    fontSize: '15px',
    lineHeight: '22px',
  },
  '15px': {
    fontSize: '15px',
    lineHeight: '20px',
  },
  '12px': {
    fontSize: '12px',
    lineHeight: '16px',
  },
  '11px': {
    fontSize: '11px',
    lineHeight: '14px',
  },
  '9px': {
    fontSize: '9px',
    lineHeight: '12px',
  },
  '18px-all-caps': {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  '12px-all_caps': {
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
};

export enum FontName {
  accent = 'var(--accent)',
}

export type FontNameKey = keyof typeof FontName;
