import React from 'react';
import { Box, BoxProps } from './Box';
import cn from 'classnames';
import * as css from './HighlightBorderBox.css';
import { BASE_UNIT } from '../Spacer/Spacer';
import { getComponentWithRefAndName } from '@utils/withRef';

export interface HighlightBorderBoxProps extends BoxProps {
  highlightEnabled: boolean;
  innerClassName?: string;
}

export const HighlightBorderBox = getComponentWithRefAndName<
  HTMLDivElement,
  HighlightBorderBoxProps
>(
  'HighlightBorderBox',
  (
    {
      highlightEnabled,
      borderRadiusFactor = 1,
      style,
      className,
      innerClassName,
      ...boxPops
    },
    ref,
  ) => (
    <div
      ref={ref}
      style={{
        borderRadius: borderRadiusFactor * BASE_UNIT + 3,
        ...style,
      }}
      className={cn(
        css.highlightBorderBox,
        highlightEnabled && css.enabled,
        className,
      )}
    >
      <Box
        borderRadiusFactor={borderRadiusFactor}
        className={innerClassName}
        {...boxPops}
      />
    </div>
  ),
);
