import React from 'react';
import cn from 'classnames';
import * as css from './MenuItem.css';

interface BotHeaderMenuItemProps extends React.HTMLProps<HTMLDivElement> {
  leftElement: React.ReactNode;
  titleStyle: React.CSSProperties;
  makeupStyles: React.CSSProperties;
  titleElement: React.ReactNode;
  rightElement: React.ReactNode;
  active: boolean;
}

export const BotHeaderMenuItem = React.memo<BotHeaderMenuItemProps>(
  ({
    leftElement,
    titleStyle,
    titleElement,
    rightElement,
    active,
    makeupStyles,
    className,
    ...props
  }) => (
    <div
      {...props}
      data-testid="menu-item"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '6px 16px',
      }}
      className={cn(
        {
          [css.active]: active,
        },
        className,
        css.wrapper,
        'test-menu-item',
      )}
    >
      <div className={css.makeup} style={makeupStyles}>
        {leftElement && (
          <div className={cn(css.leftElement)}>{leftElement}</div>
        )}
        <div className={cn(css.title)} style={titleStyle}>
          {titleElement}
        </div>
      </div>
      {rightElement && <div>{rightElement}</div>}
    </div>
  ),
);
