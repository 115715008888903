import React, { CSSProperties, MutableRefObject } from 'react';
import { Value, Editor as CoreEditor, Point, Text, Range } from 'slate';
import { Editor, EventHook, RenderMarkProps } from 'slate-react';
import memoize from 'lodash-es/memoize';
import debounce from 'lodash-es/debounce';
import PlainSerializer from 'slate-plain-serializer';
import cn from 'classnames';
import { once } from 'ramda';
import { filterXSS } from 'xss';
import { Attribute, AttributeProps } from './Attribute';
import {
  ATTR_MARK_TYPE,
  ATTR_SIGN,
  EditorModeEnum,
} from './TextWithAttributesEditorConsts';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';
import * as css from './TextWithAttributesEditor.css';

import {
  cutTextWithoutAttributes,
  findIndexOfActiveFullAttribute,
  getActiveAttributeBoundaries,
  getFullAttributesBoundaries,
  getLengthWithoutAttributes,
} from './attributesBoundariesUtils';
import { removeBrokenEmojiChars } from 'cf-common/src/utils/DOM/removeBrokenEmojiChars';
import { getSlateFixClassName } from '@utils/Contenteditable/getSlateFixClassName';
import { isWebkitBrowser } from 'cf-common/src/utils/isWebkitBrowser';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType } from '@globals';
import { PREVENT_CHROME_TRANSLATOR_CLASS } from 'cf-common/src/utils/ChromeTranslator/consts';

export function serialize(value: Value) {
  return PlainSerializer.serialize(value);
}

export function deserialize(value: string) {
  return PlainSerializer.deserialize(value);
}

export type Attributes = AttributesQuery_bot_variableSuggest[];

export interface ITextWithAttributesEditorProps extends TestLocator {
  containerId?: string;
  defaultValue: Value;
  /**
   * При использовани `onChange` (как показанно ниже) в звязке с `maxLength`, может произойти баг
   * что при вводе символа в начало строки, последний символ удалится на ui, но не удалится
   * при сереализации value, поэтому maxLength может быть превышен
   * ```
   * onChange={(value) => onChange(serialize(value))}
   * ```
   *
   * В этом случае лучше использовать {@link onStringChange}
   */
  onChange?: (value: Value) => void;
  onStringChange?: (value: string) => void;
  onFocus?: Function;
  onBlur?(event: Event, value: string): void;
  onKeyDown?: (e: Event) => undefined | false;
  placeholder?: string;
  multiLinePlaceholder?: boolean;
  className?: string;
  containerClassName?: string;
  autoFocus?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  attributes: Attributes;
  id?: string;
  singleLine?: boolean;
  trimOnBlur?: boolean;
  style?: CSSProperties;
  shouldMoveCursorToEndOnFocus?: boolean;
  highlightFullAttributesOnly?: boolean;
  onManageAttributesClick?: AttributeProps['onManageAttributesClick'];
  fixedEditorMode?: EditorModeEnum;
  /**
   * При использовани `onChange` (как показанно ниже) в звязке с `maxLength`, может произойти баг
   * что при вводе символа в начало строки, последний символ удалится на ui, но не удалится
   * при сереализации value, поэтому maxLength может быть превышен
   * ```
   * onChange={(value) => onChange(serialize(value))}
   * ```
   *
   * В этом случае лучше использовать {@link onStringChange}
   */
  maxLength?: number;
  editorRef?: React.Ref<Editor | null>;
  editorContainerRef?: MutableRefObject<HTMLDivElement | null>;
  placeholderContainerClassName?: string;
  hasManageAttributes?: boolean;
  error?: boolean;
}

interface ITextWithAttributesEditorState {
  value: Value;
}

interface SlateEditorChangeEventPayload {
  value: Value;
}

let editorMode: EditorModeEnum = EditorModeEnum.view;

export class TextWithAttributesEditor extends React.Component<
  ITextWithAttributesEditorProps,
  ITextWithAttributesEditorState
> {
  editorInstance: Editor | null = null;

  state = {
    value: this.props.defaultValue,
  };

  // eslint-disable-next-line react/sort-comp
  decorateNodes = (_: Event, editor: CoreEditor, next: () => any) => {
    editor.withoutSaving(() => {
      const decorations = this.prepareDecorated(
        editor.value.document.getTexts().toArray(),
        editor,
      );
      editor.setDecorations(decorations);
    });
    return next();
  };

  decorateNodesOnce = once(debounce(this.decorateNodes)); // debounce (in next tic) call of decorateNodes for wait full init slate editor for correct call renderMark

  prepareDecorated = (texts: Text[], editor: CoreEditor) => {
    const { document, selection } = editor.value;
    const caretPoint =
      selection && selection.isCollapsed ? selection.start : undefined;
    const getPath = memoize((key: string) => (document as any).assertPath(key));
    return texts.reduce((result, textNode) => {
      const fullAttributesBoundaries = getFullAttributesBoundaries(
        textNode.text,
      );
      const activeAttributeBoundaries =
        caretPoint &&
        getActiveAttributeBoundaries(caretPoint, textNode, editorMode);
      const { key } = textNode;
      const path = getPath(key);

      if (activeAttributeBoundaries) {
        const indexOfActiveFullAttribute = findIndexOfActiveFullAttribute(
          fullAttributesBoundaries,
          activeAttributeBoundaries,
        );
        if (indexOfActiveFullAttribute > -1) {
          fullAttributesBoundaries[indexOfActiveFullAttribute].caretOffset =
            activeAttributeBoundaries.caretOffset;
        } else {
          fullAttributesBoundaries.push(activeAttributeBoundaries);
        }
      }

      fullAttributesBoundaries.forEach(
        ({ startOffset, endOffset, caretOffset }, index) =>
          result.push({
            anchor: {
              key,
              path,
              offset: startOffset,
            },
            focus: {
              key,
              path,
              offset: endOffset,
            },
            mark: {
              type: `${ATTR_MARK_TYPE}-${index}`, // add index for prevent merge mark
              data: {
                caretOffset,
                editorMode,
              },
            },
          }),
      );

      return result;
    }, [] as any[]);
  };

  renderMark = (
    renderMarkProps: RenderMarkProps,
    editor: CoreEditor,
    next: () => any,
  ) => {
    const {
      children,
      mark,
      attributes: domAttributes,
      offset,
      node: { key },
    } = renderMarkProps;
    const {
      attributes,
      highlightFullAttributesOnly,
      hasManageAttributes,
      onManageAttributesClick,
    } = this.props;

    if (mark.type.indexOf(ATTR_MARK_TYPE) === 0) {
      return (
        <Attribute
          {...domAttributes}
          mode={mark.data.get('editorMode')}
          caretOffset={mark.data.get('caretOffset')}
          start={offset}
          onSuggestSelect={(value, start, innerOffset) => {
            editor.insertTextAtRange(
              Range.create({
                anchor: Point.create({
                  key,
                  offset: start,
                }),
                focus: Point.create({
                  key,
                  offset: start + innerOffset,
                }),
              }),
              `${ATTR_SIGN.start}${value}${ATTR_SIGN.end}`,
            );
          }}
          onManageAttributesClick={(modal) => {
            onManageAttributesClick?.(modal);
            editor.deselect();
          }}
          attributes={attributes}
          highlightFullAttributesOnly={highlightFullAttributesOnly}
          hasManageAttributes={hasManageAttributes}
        >
          {children as React.ReactElement}
        </Attribute>
      );
    }
    return next();
  };

  onceSetState = once(this.setState);

  handleChange = ({ value }: SlateEditorChangeEventPayload) => {
    const { value: stateValue } = this.state;
    const { onStringChange, onChange, readonly, maxLength, containerId } =
      this.props;
    let stringValue = serialize(value);
    const clearStringValueLength = getLengthWithoutAttributes(stringValue);

    if (readonly) {
      this.onceSetState({
        value,
      });
      return;
    }

    if (typeof maxLength === 'number' && clearStringValueLength > maxLength) {
      stringValue = removeBrokenEmojiChars(
        cutTextWithoutAttributes(stringValue, maxLength),
      );
      this.setState({
        value: deserialize(stringValue),
      });
      setTimeout(() => {
        this.editorInstance?.focus();
        this.editorInstance?.moveToEndOfDocument();
      });
    } else {
      this.setState({
        value,
      });
    }

    if (stringValue === serialize(stateValue)) {
      return;
    }

    if (containerId) {
      OnboardingEmitter.emit(OnboardingTourEventType.change, {
        element: containerId,
        value: stringValue,
      });
    }

    if (onStringChange) {
      onStringChange(stringValue);
    }

    if (onChange) {
      onChange(value);
    }
  };

  handleFocus: EventHook = (event, editor) => {
    const { onFocus, shouldMoveCursorToEndOnFocus, fixedEditorMode } =
      this.props;
    setTimeout(() => onFocus && onFocus(event));
    editorMode = fixedEditorMode || EditorModeEnum.edit;
    if (shouldMoveCursorToEndOnFocus) {
      editor.moveToEndOfDocument();
    }
    editor.focus();
  };

  handleBlurSafe: EventHook = (...args) => {
    const stateValue = serialize(this.state.value);
    const safeValue = filterXSS(stateValue, {
      escapeHtml: (html) => html, // ignore < and > escaping
    });
    if (stateValue !== safeValue) {
      this.setState({ value: deserialize(safeValue) }, () =>
        this.handleBlur(...args),
      );
    } else {
      this.handleBlur(...args);
    }
  };

  handleBlur: EventHook = (event, editor, next) => {
    const { onBlur, fixedEditorMode, trimOnBlur } = this.props;
    const stateValue = serialize(this.state.value);
    const trimmedValue = stateValue.trim();
    if (trimOnBlur) {
      if (stateValue !== trimmedValue) {
        this.setState({ value: deserialize(trimmedValue) });
      }
    }
    setTimeout(
      () => onBlur && onBlur(event, trimOnBlur ? trimmedValue : stateValue),
    );
    editorMode = fixedEditorMode || EditorModeEnum.view;
    return this.decorateNodes(event, editor, next);
  };

  handleKeyDown: EventHook = (event, _, next) => {
    const { onKeyDown } = this.props;
    const res = onKeyDown && onKeyDown(event);
    return res === false ? false : next(); // https://github.com/ianstormtaylor/slate/issues/1345
  };

  handlePaste: EventHook = (event, editor) => {
    const { singleLine, maxLength } = this.props;
    const { clipboardData } = event as ClipboardEvent;
    let text = clipboardData?.getData('text/plain') || '';
    if (singleLine) {
      text = text.replace(/(\r\n|\n|\r)+/gm, ' ');
    }
    if (maxLength) {
      text = text.substr(0, maxLength);
    }
    editor.insertText(text);
  };

  componentDidMount() {
    const { fixedEditorMode, autoFocus } = this.props;
    if (fixedEditorMode) {
      editorMode = fixedEditorMode;
    }
    if (autoFocus) {
      // emulate auto focus
      setTimeout(() => {
        this.editorInstance?.focus();
      });
    }
  }

  render() {
    const {
      placeholder,
      multiLinePlaceholder,
      className,
      containerClassName,
      autoFocus,
      id,
      singleLine,
      disabled,
      style,
      editorRef,
      editorContainerRef,
      placeholderContainerClassName,
      error,
      containerId,
    } = this.props;
    const { value } = this.state;
    const isEmpty = !value.document.text;

    return (
      <div
        className={cn(className, {
          [css.error]: error,
        })}
      >
        <div
          id={containerId}
          data-testid={this.props['data-testid']}
          className={cn(css.editorContainer, containerClassName)}
          style={style}
          ref={editorContainerRef}
        >
          {isEmpty && placeholder ? (
            <div
              className={cn(
                css.placeholderContainer,
                placeholderContainerClassName,
                {
                  [css.placeholderContainerMultiLine]: multiLinePlaceholder,
                },
              )}
            >
              {placeholder}
            </div>
          ) : null}
          <Editor
            ref={(el) => {
              if (!editorRef || !el) {
                return;
              }
              this.editorInstance = el;
              if (typeof editorRef === 'function') {
                editorRef(el);
              } else {
                (editorRef as React.MutableRefObject<Editor>).current = el;
              }
            }}
            value={value}
            onCompositionEnd={(event, editor, next) => {
              if (isWebkitBrowser()) {
                const text = (event as CompositionEvent).data;
                editor.insertText(text);
              }
              next();
            }}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlurSafe}
            renderMark={this.renderMark}
            decorateNode={this.decorateNodesOnce}
            autoFocus={autoFocus}
            onSelect={this.decorateNodes}
            onClick={this.decorateNodes}
            onKeyDown={this.handleKeyDown}
            onPaste={this.handlePaste}
            className={cn(
              css.editor,
              {
                [css.singleLine]: singleLine,
                [css.disabled]: disabled,
              },
              getSlateFixClassName(),
              PREVENT_CHROME_TRANSLATOR_CLASS,
            )}
            readOnly={disabled}
            {...{ id }} // not complete @types in Slate :((
          />
        </div>
        <div className={css.focusLocker}>
          {' '}
          {/* lock focus on click outside editor element */}
          &nbsp;
        </div>
      </div>
    );
  }
}
