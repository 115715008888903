import React, { useCallback, useContext, useState } from 'react';
import { Portal } from 'react-portal';
import nanoid from 'nanoid';
import { Toaster, ToasterConfig, ToasterProps } from './Toaster';
import * as css from './Toaster.css';

interface ToasterContextConfig {
  addToaster: (toaster: ToasterConfig) => string;
}

const ToasterContext = React.createContext<ToasterContextConfig>({
  addToaster: () => '0',
});

export const useToaster = () => {
  return useContext(ToasterContext);
};

export const ToasterProvider: React.FC = ({ children }) => {
  const [toasters, setToasters] = useState<ToasterProps[]>([]);

  const removeToaster = useCallback((id: string) => {
    // change state of removing toaster to { hiding: true }
    setToasters((currentToasters) =>
      currentToasters.map((toaster) =>
        toaster.id === id
          ? {
              ...toaster,
              hiding: true,
            }
          : toaster,
      ),
    );
    setTimeout(() => {
      setToasters((currentToasters) => {
        currentToasters.splice(
          currentToasters.findIndex((item) => item.id === id),
          1,
        );
        return [...currentToasters];
      });
    }, 300);
  }, []);

  const addToaster = useCallback(
    (toaster: ToasterConfig) => {
      const id = nanoid();
      setToasters((currentToasters) => [
        {
          ...toaster,
          id,
          hiding: false,
          onDismiss: () => {
            toaster.onDismiss?.();
            removeToaster(id);
          },
          onButtonClick: () => {
            toaster.onButtonClick?.();
            removeToaster(id);
          },
        },
        ...currentToasters,
      ]);

      // add remove toaster function in timout
      if (toaster.timeout) {
        setTimeout(() => {
          removeToaster(id);
        }, toaster.timeout);
      }
      return id;
    },
    [removeToaster],
  );

  return (
    <ToasterContext.Provider value={{ addToaster }}>
      {children}
      <Portal>
        <div className={css.container}>
          {toasters.map((toaster, index) => (
            <Toaster key={toaster.id} {...toaster} index={index} />
          ))}
        </div>
      </Portal>
    </ToasterContext.Provider>
  );
};
