import React from 'react';
import i18next from 'i18next';
import cn from 'classnames';
import OutsideClick from 'react-outsideclick';
import { GetToggleButtonPropsOptions } from 'downshift';
import { ReactComponent as Dots } from '../_deprecated/Icon/icons/ic_dots.svg';
import { Dropdown } from '../_deprecated/Dropdown';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
  ButtonProps,
} from '../_deprecated/Button';
import { RenameInput } from './RenameInput';
import * as css from './TabsSet.css';

const MAX_TITLE_LENGTH = 50;
const alwaysValid = () => true;

export interface ITab {
  id: string;
  title: string;
  faded?: boolean;
}

type HandlerMapItem = {
  menuTitle: string;
  handler: () => void;
};

type HandlerMap = {
  rename: HandlerMapItem;
  clone: HandlerMapItem;
  remove: HandlerMapItem;
  copyTo: HandlerMapItem;
};

export interface TabProps extends ButtonProps, TestLocator {
  tab: ITab;
  isActive: boolean;
  className?: string;
  isFaded?: boolean;
  onRename?: (editedName: string) => void;
  onCopyTo?: () => void;
  onClone?: () => void;
  onRemove?: () => void;
  onStartEditing?: () => void;
  validate?: (value: string) => boolean;
  getErrorMessage?: (value: string) => string | undefined;
  editMode: boolean;
  allowEdit: boolean;
  showDots: boolean;
}

export interface ITabState {
  menuVisible: boolean;
  mouseOverTab: boolean;
  mouseOverDots: boolean;
  editedTabName: string;
}

export class Tab extends React.Component<TabProps, ITabState> {
  static renderErrorTooltip(errorText: string) {
    return (
      <React.Fragment>
        <div className={css.errorTooltip}>{errorText}</div>
        <div className={css.errorTooltipNib} />
      </React.Fragment>
    );
  }

  state = {
    menuVisible: false,
    mouseOverTab: false,
    mouseOverDots: false,
    editedTabName: this.props.tab.title,
  };

  buttonRef: HTMLButtonElement | null = null;

  handlersMap: HandlerMap = {
    rename: {
      menuTitle: i18next.t('modernUi.Tabs.rename'),
      handler: () => {
        if (this.buttonRef) {
          this.buttonRef.click();
        }

        if (this.props.onStartEditing) {
          this.props.onStartEditing();
        }
      },
    },
    clone: {
      menuTitle: i18next.t('modernUi.Tabs.clone'),
      handler: () => {
        if (this.props.onClone) {
          this.props.onClone();
        }
      },
    },
    remove: {
      menuTitle: i18next.t('modernUi.Tabs.delete'),
      handler: () => {
        if (this.props.onRemove) {
          this.props.onRemove();
        }
      },
    },
    copyTo: {
      menuTitle: i18next.t('modernUi.Tabs.copyTo'),
      handler: () => {
        if (this.props.onCopyTo) {
          this.props.onCopyTo();
        }
      },
    },
  };

  setButtonRef = (b: HTMLButtonElement) => {
    this.buttonRef = b;
  };

  setMenuVisibility(value: boolean = false) {
    this.setState({
      menuVisible: value,
    });
  }

  setMouseOverDots(value: boolean = false) {
    this.setState({
      mouseOverDots: value,
    });
  }

  setMouseOverTab(value: boolean = false) {
    this.setState({
      mouseOverTab: value,
    });
  }

  handleMenuClick = (command: string) => {
    this.setMenuVisibility(false);
    switch (command) {
      case this.handlersMap.remove.menuTitle:
        this.handlersMap.remove.handler();
        break;

      case this.handlersMap.rename.menuTitle:
        this.handlersMap.rename.handler();
        break;

      case this.handlersMap.copyTo.menuTitle:
        this.handlersMap.copyTo.handler();
        break;

      case this.handlersMap.clone.menuTitle:
        this.handlersMap.clone.handler();
        break;
      default:
    }
  };

  renameTab = () => {
    const { onRename, validate = alwaysValid, tab } = this.props;
    const { editedTabName } = this.state;

    if (validate(editedTabName)) {
      if (onRename) {
        onRename(editedTabName);
      }
    } else {
      this.setState({
        editedTabName: tab.title,
      });

      if (onRename) {
        onRename(tab.title);
      }
    }
  };

  shouldShowDots() {
    const { menuVisible, mouseOverDots, mouseOverTab } = this.state;
    const { showDots } = this.props;
    return showDots && (menuVisible || mouseOverDots || mouseOverTab);
  }

  renderDotsButton = (
    { onClick, ...props }: GetToggleButtonPropsOptions,
    ref: any,
  ) => {
    return (
      <div className={css.menuDotsWrapper} ref={ref}>
        <button
          {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
          data-testid={`${this.props['data-testid']}__dots-button`}
          className={css.menuDots}
          onMouseEnter={() => this.setMouseOverDots(true)}
          onMouseLeave={() => this.setMouseOverDots(false)}
          onClick={(evt) => {
            if (onClick) {
              onClick(evt);
            }

            if (this.props.onClick) {
              this.props.onClick(evt);
            }

            this.setMenuVisibility(true);
          }}
        >
          <Dots />
        </button>
      </div>
    );
  };

  render() {
    const {
      isActive,
      isFaded,
      tab,
      className,
      onRename,
      onRemove,
      onCopyTo,
      onClone,
      onStartEditing,
      validate = alwaysValid,
      getErrorMessage,
      editMode,
      allowEdit,
      showDots,
      ...props
    } = this.props;
    const testId = this.props['data-testid'];
    const { editedTabName } = this.state;

    const items: string[] = [];

    if (this.props.onRename) {
      items.push(this.handlersMap.rename.menuTitle);
    }

    if (this.props.onClone) {
      items.push(this.handlersMap.clone.menuTitle);
    }

    if (this.props.onRemove) {
      items.push(this.handlersMap.remove.menuTitle);
    }

    if (this.props.onCopyTo) {
      items.push(this.handlersMap.copyTo.menuTitle);
    }

    let errorMessage;

    if (editMode && !validate(editedTabName)) {
      errorMessage = getErrorMessage && getErrorMessage(editedTabName);
    }
    return (
      <div data-testid={testId} className={css.tab}>
        {errorMessage && Tab.renderErrorTooltip(errorMessage)}

        {allowEdit && editMode ? (
          <div
            className={cn(css.tabButton, css.inputWrapper, className, {
              [css.invalid]: !validate(editedTabName),
            })}
          >
            <RenameInput
              data-testid={`${testId}__rename-input`}
              onChange={(evt: React.FormEvent<HTMLInputElement>) => {
                this.setState({
                  editedTabName: evt.currentTarget.value.substr(
                    0,
                    MAX_TITLE_LENGTH,
                  ),
                });
              }}
              onSubmit={this.renameTab}
              value={this.state.editedTabName}
            />
          </div>
        ) : (
          <Button
            {...props}
            data-testid={`${testId}__button`}
            intent={isActive ? ButtonIntent.highlighted : ButtonIntent.tab}
            size={ButtonSize.s}
            colorWay={ButtonColorWay.white}
            onMouseEnter={() => this.setMouseOverTab(true)}
            onMouseLeave={() => this.setMouseOverTab(false)}
            innerRef={this.setButtonRef}
            className={cn(css.tabButton, className, {
              [css.faded]: isFaded,
            })}
          >
            {tab.title}
          </Button>
        )}
        {this.shouldShowDots() && (
          <OutsideClick onClickOutside={() => this.setMenuVisibility(false)}>
            <Dropdown
              buttonsFactory={this.renderDotsButton}
              items={items}
              boxClasses={css.contextMenu}
              onChange={this.handleMenuClick}
              placement={tab.title.length > 19 ? 'bottom-end' : 'bottom-start'}
            />
          </OutsideClick>
        )}
      </div>
    );
  }
}
