import React, { useEffect } from 'react';
import cn from 'classnames';
import { IS_PRODUCTION_BUILD } from 'cf-common/src/environment';
import { logDeprecatedComponent } from '@utils/logDeprecated';
import {
  ButtonColorWay,
  ButtonIntent,
  ButtonProps,
  ButtonSize,
  ButtonTextColorWay,
} from './buttonTypes';
import * as css from './Button.module.css';

export const Button = logDeprecatedComponent(
  'Button',
  ({
    intent = ButtonIntent.default,
    colorWay,
    size = ButtonSize.m,
    tall,
    wide,
    innerRef,
    renderIcon,
    renderIconRight,
    children,
    className,
    textColorWay = ButtonTextColorWay.black,
    textNormalWeight,
    iconClassName,
    rightIconClassName,
    ...props
  }: ButtonProps) => {
    const isDisplayContent = intent === ButtonIntent.displayContent;
    const isPrimary =
      intent === ButtonIntent.primary ||
      intent === ButtonIntent.primaryLanding ||
      isDisplayContent;
    const hasLeftIcon = Boolean(renderIcon);
    const hasRightIcon = Boolean(renderIconRight);
    const onlyIcon = !children;
    const onlyText = !hasLeftIcon && !hasRightIcon;
    const hasText = !onlyIcon;

    useEffect(() => {
      if (!IS_PRODUCTION_BUILD && !props['data-testid']) {
        // eslint-disable-next-line no-console
        console.log('[TEST-ID] Consider adding data-testid for autotesting');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <button
        className={cn(css.button, className, {
          [css.primary]: isPrimary,
          [css.secondary]: intent === ButtonIntent.secondary,
          [css.toggle]: intent === ButtonIntent.toggle,
          [css.displayContent]: intent === ButtonIntent.displayContent,
          [css.defaultIntent]: intent === ButtonIntent.default,
          [css.highlighted]: intent === ButtonIntent.highlighted,
          [css.tab]: intent === ButtonIntent.tab,
          [css.primaryLanding]: intent === ButtonIntent.primaryLanding,
          [css.white]: colorWay
            ? colorWay === ButtonColorWay.white
            : !isPrimary,
          [css.toggleWhiteHover]: colorWay === ButtonColorWay.toggleWhiteHover,
          [css.toggleWhiteOnly]: colorWay === ButtonColorWay.toggleWhiteOnly,
          [css.toggleBlueOnly]: colorWay === ButtonColorWay.toggleBlueOnly,
          [css.red]: colorWay === ButtonColorWay.red,
          [css.smallButton]: size === ButtonSize.s,
          [css.buttonHasLeftIcon]: hasLeftIcon || onlyIcon,
          [css.buttonHasRightIcon]: hasRightIcon || onlyIcon,
          [css.tall]: tall,
          [css.wide]: wide,
          [css.textNormalWeight]: textNormalWeight,
          [css.fixedMinWidth]: onlyText,
        })}
        {...props}
        ref={innerRef}
      >
        <span
          className={cn(css.label, {
            [css.onlyIcon]: onlyIcon,
          })}
        >
          {renderIcon ? (
            <span
              className={cn(css.iconContainer, iconClassName, {
                [css.noRightMargin]: hasText,
              })}
            >
              {renderIcon()}
            </span>
          ) : null}
          {children && (
            <div
              className={cn(css.labelText, {
                [css.alignLeft]: !onlyIcon && hasRightIcon && !hasLeftIcon,
                [css.red]: textColorWay === 'red',
              })}
            >
              {children}
            </div>
          )}
          {renderIconRight ? (
            <span
              className={cn(css.iconContainer, rightIconClassName, {
                [css.noLeftMargin]: hasText,
              })}
            >
              {renderIconRight()}
            </span>
          ) : null}
        </span>
      </button>
    );
  },
);

export default Button;
