import { append, omit, last, difference } from 'ramda';
import {
  TreeType,
  Node,
  FlatTreeItem,
  FlatNode,
  FlatLeaf,
  Leaf,
  CheckTreeConfig,
} from './types';

type Tree<T> = TreeType<T> | TreeType<T>[];

/**
 * Check whether item node or not
 *
 * @param node {Object} node element. Node of Leaf
 */
export const isNode = <T>(node: TreeType<T>): node is Node<T> =>
  Object.prototype.hasOwnProperty.call(node, 'children');

/**
 * Creates flat tree from given tree
 *
 * @param tree {Array|Object} tree or an aray of trees
 * @param expanded {Array} array of expanded nodes by default
 * @param arr {Array} accumulator array which
 * @param depth {Number} represents how deep current node is
 * @param path {Array} array of parent ids
 */
export const createFlattenTree = <T>(
  tree: Tree<T>,
  expanded: string[],
  arr: Array<FlatTreeItem<T>> = [],
  depth = 0,
  path: string[] = [],
) => {
  if (Array.isArray(tree)) {
    tree.forEach((t) => createFlattenTree(t, expanded, arr, depth, path));
    return arr;
  }

  const commonProps = {
    depth,
    tree,
    path,
    id: tree.id,
    visible: depth === 0 ? true : expanded.includes(last(path)),
  };

  if (isNode(tree)) {
    arr.push(commonProps as FlatNode<T>);
    createFlattenTree(
      tree.children,
      expanded,
      arr,
      depth + 1,
      append(tree.id, path),
    );
    return arr;
  }

  arr.push(commonProps as FlatLeaf<T>);
  return arr;
};

/**
 * Returns children ids of a given tree
 *
 * @param tree {Array|Object} tree or an array of trees
 */
export const getChildrenIds = <T>(tree: Tree<T>) => {
  const result: string[] = [];

  const helper = <T>(tree: Tree<T>) => {
    if (Array.isArray(tree)) {
      tree.forEach(helper);
      return;
    }

    if (isNode(tree)) {
      result.push(tree.id);
      helper(tree.children);
      return;
    }

    result.push(tree.id);
  };

  helper(tree);

  return result;
};

/**
 * Returns leaf ids of a given tree
 *
 * @param tree {Array|Object} tree or an array of trees
 */
export const getLeafIds = <T>(tree: Tree<T>) => {
  const result: string[] = [];

  const helper = <T>(tree: Tree<T>) => {
    if (Array.isArray(tree)) {
      tree.forEach(helper);
      return;
    }

    if (isNode(tree)) {
      helper(tree.children);
      return;
    }

    result.push(tree.id);
  };

  helper(tree);

  return result;
};

/**
 * Returns nodes ids of a given tree
 *
 * @param tree {Array|Object} tree or an array of trees
 */
export const getNodesIds = <T>(tree: Tree<T>) => {
  const result: string[] = [];

  const helper = <T>(tree: Tree<T>, ids: string[] = []) => {
    if (Array.isArray(tree)) {
      tree.forEach((t) => helper(t, ids));
      return ids;
    }

    if (isNode(tree)) {
      ids.push(tree.id);
      helper(tree.children, ids);
      return ids;
    }

    return ids;
  };

  helper(tree, result);

  return result;
};

/**
 * Filters tree by given predicate with saving nodes structure.
 *
 * @param tree {Array|Object} tree or an array of trees
 * @param pred {Function} predicate function
 */
export const filterTreeBy = <T>(
  tree: Tree<T>,
  pred: (tree: TreeType<T>) => boolean,
): TreeType<T>[] | TreeType<T> | null => {
  if (Array.isArray(tree)) {
    return tree.map((t) => filterTreeBy(t, pred)).filter(Boolean) as TreeType<
      T
    >[];
  }

  if (isNode(tree)) {
    const children = filterTreeBy(tree.children, pred) as TreeType<T>[];
    const nonNullableChildren = children ? children.filter(Boolean) : [];

    if (pred(tree)) {
      return tree;
    }

    if (nonNullableChildren.length === 0) {
      return null;
    }

    return { ...tree, children };
  }

  if (pred(tree)) {
    return omit(['children'], tree) as Leaf<T>;
  }

  return null;
};

/**
 * Returns new array of checked ids
 *
 * @param tree {Array|Object} tree or an array of trees
 * @param checkedIds {Array} Array of checked items
 * @param clickedId {String} id of clicked id
 */
export const checkTree = <T>(
  tree: Tree<T>,
  checkedIds: string[],
  clickedId: string = '',
  config: CheckTreeConfig = { autoCheckParent: true },
) => {
  /**
   * Difference of ids below covers the case when your tree is dynamicly loaded, but you have
   * an array of checked ids (e.g from Entry Point) and some items have not been loaded yet,
   * so the algoritm should consider these ids as "not loaded yet ids" and keep then in the result.
   */
  const result: string[] = difference(checkedIds, getChildrenIds(tree));

  const helper = <T>(tree: Tree<T>) => {
    if (Array.isArray(tree)) {
      tree.forEach(helper);
      return;
    }

    if (isNode(tree)) {
      if (tree.id === clickedId) {
        /**
         * If checked ids does not include clicked id then it means that we are the node which wasn't
         * checked and we should check all children of that node and the node itself.
         * Otherwise it means that we are at the node which was checked, and we dont have to check
         * this node, so we dont add anything to result array
         */

        if (!checkedIds.includes(clickedId)) {
          result.push(...getChildrenIds(tree));
        }

        return;
      }

      helper(tree.children);

      /**
       * After returning from one level of recursion we have to check whether all children are checked
       * or not because we need to check do we have to check current node or not.
       */
      const isEveryChildrenChecked = tree.children.every(({ id }) =>
        result.includes(id),
      );

      if (isEveryChildrenChecked && config.autoCheckParent) {
        result.push(tree.id);
      }

      if (
        !config.autoCheckParent &&
        tree.id !== clickedId &&
        checkedIds.includes(tree.id) &&
        !result.includes(tree.id) &&
        !getChildrenIds(tree).includes(clickedId)
      ) {
        result.push(tree.id);
      }

      return;
    }

    /**
     * Check whether current node was checked or not
     */
    if (checkedIds.includes(tree.id)) {
      /**
       * If it was we have to check does this node id equals to clicked id and if it don't
       * we have to check this node
       */
      if (tree.id !== clickedId) {
        result.push(tree.id);
      }

      return;
    }

    /**
     * if this node wasn't checked we just have to check if clicked id equals current node id
     * and check this node if it does
     */
    if (clickedId === tree.id) {
      result.push(tree.id);
    }
  };

  helper(tree);

  return result;
};
