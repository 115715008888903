import moment from 'moment';

export type PickedDate = moment.Moment | null;

export type PickedRange = [PickedDate, PickedDate];

export enum DatePickerShortcut {
  today = 'today',
  yesterday = 'yesterday',
  thisWeek = 'thisWeek',
  thisMonth = 'thisMonth',
  last3Months = 'last3Months',
  custom = 'custom',
}
