import * as React from 'react';
import cn from 'classnames';
import { getComponentWithRefAndName } from '@utils/withRef';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { ButtonBaseProps, ButtonBase } from './Base';
import * as css from './Button.css';
import { ColorKey } from '@ui/_common/colors';

export enum ButtonIntent {
  primary = 'Primary',
  secondary = 'Secondary',
  text = 'Text',
  red = 'Red',
  error = 'Error',
  transparent = 'Transparent',
  orange = 'Orange',
}

export type ButtonIntentKey = keyof typeof ButtonIntent;

enum ButtonTextColor {
  black = 'Black',
  white = 'White',
  red = 'Red',
}

type ButtonBaseInheritProps =
  | 'id'
  | 'className'
  | 'children'
  | 'disabled'
  | 'icon'
  | 'iconRight'
  | 'iconClassName'
  | 'textClassName'
  | 'labelTextClassName'
  | 'onClick'
  | 'onMouseUp'
  | 'onMouseDown'
  | 'onBlur'
  | 'size'
  | 'type'
  | 'data-testid'
  | 'style'
  | 'fitToText'
  | 'fullWidth';

export interface ButtonProps
  extends Pick<ButtonBaseProps, ButtonBaseInheritProps> {
  intent?: ButtonIntentKey;
  loading?: boolean;
}

const loaderColorByIntent: Record<ButtonIntent, ColorKey> = {
  [ButtonIntent.primary]: 'blueLight',
  [ButtonIntent.secondary]: 'grey',
  [ButtonIntent.text]: 'grey',
  [ButtonIntent.red]: 'redLight',
  [ButtonIntent.orange]: 'redLight',
  [ButtonIntent.error]: 'blueLight',
  [ButtonIntent.transparent]: 'white',
} as const;

const textColorByIntent: Record<ButtonIntent, ButtonTextColor> = {
  [ButtonIntent.primary]: ButtonTextColor.white,
  [ButtonIntent.secondary]: ButtonTextColor.black,
  [ButtonIntent.text]: ButtonTextColor.black,
  [ButtonIntent.red]: ButtonTextColor.white,
  [ButtonIntent.error]: ButtonTextColor.red,
  [ButtonIntent.transparent]: ButtonTextColor.white,
  [ButtonIntent.orange]: ButtonTextColor.white,
} as const;

export const Button = getComponentWithRefAndName<
  HTMLButtonElement,
  ButtonProps
>(
  'Button',
  (
    {
      children,
      className,
      icon,
      iconRight,
      intent = 'primary',
      textClassName,
      size,
      loading,
      ...props
    },
    ref,
  ) => {
    const buttonIntent = ButtonIntent[intent];
    const textColor = textColorByIntent[buttonIntent];

    return (
      <ButtonBase
        {...props}
        ref={ref}
        icon={icon}
        iconRight={iconRight}
        size={size}
        className={cn(css[`buttonIntent${buttonIntent}`], className)}
        textClassName={cn(css[`textColor${textColor}`], textClassName)}
        loader={
          loading ? (
            <LoadingPlaceholder
              className={css.buttonLoadingPlaceholder}
              color={loaderColorByIntent[buttonIntent]}
              fullWidth
            />
          ) : null
        }
      >
        {children}
      </ButtonBase>
    );
  },
);
