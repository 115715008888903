import React from 'react';
import cn from 'classnames';
import * as css from './Box.css';
import { BASE_UNIT } from '../Spacer/Spacer';
import { Color, ColorKey } from '../_common/colors';

export interface BoxProps extends React.HTMLProps<HTMLDivElement> {
  borderRadiusFactor?: number;
  paddingFactor?: number;
  color?: ColorKey;
}

export const Box: React.FC<BoxProps> = ({
  className,
  borderRadiusFactor = 1,
  paddingFactor = 6,
  style,
  color,
  ...props
}) => (
  <div
    className={cn(css.Box, className)}
    style={{
      borderRadius: borderRadiusFactor * BASE_UNIT,
      backgroundColor: color && Color[color],
      padding: paddingFactor * BASE_UNIT,
      ...style,
    }}
    {...props}
  />
);
