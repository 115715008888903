import React, { useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import * as css from './DateInput.css';
import { ComboboxTriangleButton } from '../SimpleCombobox/components/ComboboxTriangleButton';
import cn from 'classnames';
import { Type } from '../Type';
import { DateFormat } from '@utils/DateTime';
import { DateType } from '@utils/DateTime/types';
import { DatePicker, Moment } from '../DatePicker';
import OutsideClick from 'react-outsideclick';
import { DateRangePickerShape } from 'react-dates';

interface DateInputProps {
  value: Moment;
  onChange(value: Moment): void;
  error?: boolean;
  disabled?: boolean;
  isDayBlocked?: DateRangePickerShape['isDayBlocked'];
  dateFormat?: (date: DateType) => string;
}

export const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  error,
  disabled,
  isDayBlocked,
  dateFormat = DateFormat.MMMMDoYYYY,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  return (
    <OutsideClick onClickOutside={() => setShowDatePicker(false)}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref}>
              <ComboboxTriangleButton
                onClick={() => setShowDatePicker((value) => !value)}
                className={cn(css.button, {
                  [css.buttonError]: error,
                })}
                disabled={disabled}
                data-testid="date-picker__button"
              >
                <Type size="15px" color={error ? 'accent4Secondary' : 'black'}>
                  {dateFormat(value as any as DateType)}
                </Type>
              </ComboboxTriangleButton>
            </div>
          )}
        </Reference>
        <Popper placement="bottom-end" positionFixed>
          {({ ref, style }) =>
            showDatePicker && (
              <div ref={ref} style={style} className={css.datePicker}>
                <DatePicker
                  isDayBlocked={isDayBlocked}
                  showBorder
                  value={value}
                  onChange={onChange}
                />
              </div>
            )
          }
        </Popper>
      </Manager>
    </OutsideClick>
  );
};
