import React from 'react';
import cn from 'classnames';
import { MobileDialog } from '@ui/MobileDialog';
import { MobileModalProps } from './types';
import css from './Modal.css';

export const MobileModal: React.FC<MobileModalProps> = ({
  onDismiss,
  isOpen = true,
  breaksFn,
  initialBreak,
  fitHeight,
  children,
  className,
  hideCloseButton,
}) => {
  return (
    <MobileDialog
      show={Boolean(isOpen)}
      onClose={onDismiss}
      breaksFn={breaksFn}
      initialBreak={initialBreak}
      fitHeight={fitHeight}
      backdropOpacity={0.4}
      backdrop
      className={cn(css.dialog, css.mobile, className)}
      hideCloseButton={hideCloseButton}
    >
      {children}
    </MobileDialog>
  );
};
