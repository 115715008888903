import React, { useMemo, useRef } from 'react';
import { ButtonUnstyled } from '../Button';
import { Icon } from '../Icon';
import { Flex } from '../Flex';
import { InputLikeElement } from '@utils/documentExecCommand';
import Maybe from 'graphql/tsutils/Maybe';
import { Editor } from 'slate-react';

type WrapRequest = (wrapEl: string, el: InputLikeElement | Editor) => void;

interface TextFormatControlsProps {
  children: (props: { ref: React.RefObject<any> }) => React.ReactNode;
  buttonsBoxClassName?: string;
  onWrapRequest: WrapRequest;
}

const createHandler =
  (wrapPart: string, onWrapRequest: WrapRequest, el: Maybe<InputLikeElement>) =>
  (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!el) {
      return;
    }
    onWrapRequest(wrapPart, el);
  };

export const TextFormatControls: React.FC<TextFormatControlsProps> = ({
  children,
  buttonsBoxClassName,
  onWrapRequest,
}) => {
  const ref = useRef<InputLikeElement>();
  const boldClickHandler = useMemo(
    () => createHandler('*', onWrapRequest, ref.current),
    [onWrapRequest],
  );
  const italicClickHandler = useMemo(
    () => createHandler('_', onWrapRequest, ref.current),
    [onWrapRequest],
  );
  const strikethroughClickHandler = useMemo(
    () => createHandler('~', onWrapRequest, ref.current),
    [onWrapRequest],
  );

  return (
    <div>
      {children({ ref })}
      <Flex className={buttonsBoxClassName}>
        <ButtonUnstyled data-testid="format-control__bold">
          <Icon icon="bold" color="baseSecondary" onClick={boldClickHandler} />
        </ButtonUnstyled>
        <ButtonUnstyled data-testid="format-control__italic">
          <Icon
            icon="italic"
            color="baseSecondary"
            onClick={italicClickHandler}
          />
        </ButtonUnstyled>
        <ButtonUnstyled data-testid="format-control__strike-through">
          <Icon
            icon="strikethrough"
            color="baseSecondary"
            onClick={strikethroughClickHandler}
          />
        </ButtonUnstyled>
      </Flex>
    </div>
  );
};
