import { Point, Text } from 'slate';
import { prop } from 'ramda';
import {
  ATTR_SIGN,
  ATTR_SIGN_REG,
  CLEAR_QUERY_REQ_EXP,
  EditorModeEnum,
} from './TextWithAttributesEditorConsts';
import { AttributesQuery_bot_variableSuggest } from '../../utils/AttributesUtils/@types/AttributesQuery';
import { HEXColors } from '@ui/_common/colors';

/**
 *  AttributeBoundaries is native Range lake
 *  ex.:
 *  "hello {{attr1}} world"
 *        ^--------^
 *      start     end
 */

type AttributeBoundaries = {
  startOffset: number;
  endOffset: number;
  text: string;
  caretOffset?: number;
};

export const getFullAttributesBoundaries = (text: string) => {
  const out = [] as AttributeBoundaries[];
  let match: RegExpExecArray | null = null;
  // eslint-disable-next-line no-cond-assign
  while ((match = ATTR_SIGN_REG.exec(text))) {
    out.push({
      startOffset: match.index,
      endOffset: match.index + match[0].length,
      text: match[0].replace(CLEAR_QUERY_REQ_EXP, ''),
    });
  }
  return out;
};

export const getActiveAttributeBoundaries = (
  caretPoint: Point,
  textNode: Text,
  editorMode: EditorModeEnum,
) => {
  if (editorMode === EditorModeEnum.view || caretPoint.key !== textNode.key) {
    return null;
  }
  const textPartByCaretPos = textNode.text.substring(0, caretPoint.offset);
  const startLeftOffset = textPartByCaretPos.lastIndexOf(ATTR_SIGN.start);
  const endLeftOffset = textPartByCaretPos.lastIndexOf(ATTR_SIGN.end);
  return startLeftOffset === -1 || startLeftOffset <= endLeftOffset
    ? null
    : ({
        startOffset: startLeftOffset,
        endOffset: caretPoint.offset,
        text: textNode.text
          .substring(startLeftOffset, caretPoint.offset)
          .replace(CLEAR_QUERY_REQ_EXP, ''),
        caretOffset: caretPoint.offset - startLeftOffset,
      } as AttributeBoundaries);
};

export const findIndexOfActiveFullAttribute = (
  attributesBoundaries: AttributeBoundaries[],
  activeAttributeBoundaries: AttributeBoundaries,
) =>
  attributesBoundaries.findIndex(
    ({ startOffset, endOffset }) =>
      startOffset === activeAttributeBoundaries.startOffset &&
      endOffset > activeAttributeBoundaries.endOffset,
  );

export const wrapAllAttributes = (
  text: string,
  wrapper: (attributeString: string) => string,
) => {
  const boundaries = getFullAttributesBoundaries(text);
  return boundaries.length
    ? boundaries.reduce(
        (out, { endOffset, startOffset }, index, boundaries) =>
          [
            out,
            text.substring(boundaries[index - 1]?.endOffset || 0, startOffset),
            wrapper(text.substring(startOffset, endOffset)),
            index === boundaries.length - 1 ? text.substring(endOffset) : '',
          ].join(''),
        '',
      )
    : text;
};

export const attributeWrapper =
  (
    singleLine: boolean,
    wrapEveryAttribute: boolean,
    predefinedAttributes?: AttributesQuery_bot_variableSuggest[],
  ) =>
  (attributeString: string, color: string = HEXColors.blue) => {
    const attributeView = (attribute: string) =>
      `<span style="color:${color};${
        singleLine ? '' : 'white-space: pre-wrap;'
      }">${attribute}</span>`;

    if (wrapEveryAttribute) {
      return attributeView(attributeString);
    }

    const isPredefinedAttribute = predefinedAttributes?.some(
      ({ name }) =>
        `${ATTR_SIGN.start}${name}${ATTR_SIGN.end}` === attributeString,
    );

    return !predefinedAttributes || isPredefinedAttribute
      ? attributeView(attributeString)
      : attributeString;
  };

export const getLengthWithoutAttributes = (text: string) =>
  wrapAllAttributes(text, () => '').length;

const incOutLength = (outLength: number, out: string[]) =>
  outLength + out[out.length - 1].length;

export const cutTextWithoutAttributes = (text: string, maxLength: number) => {
  const boundaries = getFullAttributesBoundaries(text);
  const clearText = wrapAllAttributes(text, () => '');
  if (!boundaries.length && clearText.length > maxLength) {
    return text.substr(0, maxLength);
  }
  if (boundaries.length && clearText.length <= maxLength) {
    return text;
  }
  if (boundaries.length) {
    let outLength = 0;
    const out = [''];
    for (let i = 0; i < boundaries.length; i++) {
      const { startOffset, endOffset } = boundaries[i];
      if (i === 0) {
        out.push(text.substring(0, Math.min(startOffset, maxLength)));
        outLength = incOutLength(outLength, out);
      }
      if (outLength < maxLength) {
        out.push(text.substring(startOffset, endOffset));
        out.push(
          text.substring(
            endOffset,
            Math.min(
              boundaries[i + 1]?.startOffset || text.length - 1,
              endOffset + (maxLength - outLength),
            ),
          ),
        );
        outLength = incOutLength(outLength, out);
      }
      if (outLength >= maxLength) {
        return out.join('');
      }
    }
    return out.join('');
  }
  return text;
};

export const extractAllUniqAttributes = (text: string) => [
  ...new Set(getFullAttributesBoundaries(text).map(prop('text'))),
];
