import React from 'react';
import cn from 'classnames';
import { MenuItemType } from '@components/FlowBuilder/views/Menu/types';
import { Avatar, AvatarSize } from '@ui/Avatar';
import { NestedMenuItem } from '../types';
import * as css from './ItemImage.css';

export const ItemImage = ({ type, img, imageUrl }: NestedMenuItem) => {
  if (img) {
    const className = cn(css.icon, {
      [css.circle]: [MenuItemType.plugin, MenuItemType.block].includes(
        type as MenuItemType,
      ),
      [css.rect]: [MenuItemType.node, MenuItemType.entrypoint].includes(
        type as MenuItemType,
      ),
    });

    // @ts-expect-error
    return React.createElement(img, { className });
  }
  if (imageUrl) {
    return <Avatar size={AvatarSize.small} src={imageUrl} />;
  }
  return null;
};
