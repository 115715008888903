import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { propEq } from 'ramda';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Translation } from '@translations';
import { Flex } from '../Flex';
import { Input } from '../Input';
import { SimpleCombobox } from '../SimpleCombobox';
import { Button, ButtonColorWay, ButtonIntent } from '../_deprecated/Button';
import { ReactComponent as DropdownIcon } from '../_deprecated/Icon/icons/ic_dropdown_arr.svg';
import * as css from './TimeoutInput.css';

export interface TimeoutInputOnChangeArgs {
  value: number | null;
  interval: TimeIntervalsIds;
}

export interface TimeoutInputClassNames {
  className?: string;
  inputClassName?: string;
  containerClassName?: string;
  timeIntervalClassName?: string;
  timeIntervalWrapperClassName?: string;
}

export interface TimeoutInputProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'>,
    TimeoutInputClassNames,
    TestLocator {
  value?: number;
  interval?: TimeIntervalsIds;
  intervalIds?: TimeIntervalsIds[];
  textNormalWeight?: boolean;
  customTimeIntervals?: TimeInterval[];
  onChange: (props: TimeoutInputOnChangeArgs) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  maxLength?: number;
}

export interface TimeInterval {
  id: TimeIntervalsIds;
  titleKey: Translation;
  divider: number;
}

export enum TimeIntervalsIds {
  seconds = 'seconds',
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  off = 'off',
  immediately = 'immediately',
}

const OFF_INTERVAL: TimeInterval = {
  id: TimeIntervalsIds.off,
  titleKey: 'modernUi.TimeoutInput.off',
  divider: -1,
};

export const TIME_INTERVALS: TimeInterval[] = [
  {
    id: TimeIntervalsIds.immediately,
    divider: 1,
    titleKey: 'modernUi.TimeoutInput.none',
  },
  {
    id: TimeIntervalsIds.seconds,
    titleKey: 'modernUi.TimeoutInput.seconds',
    divider: 1,
  },
  {
    id: TimeIntervalsIds.minutes,
    titleKey: 'modernUi.TimeoutInput.minutes',
    divider: 60,
  },
  {
    id: TimeIntervalsIds.hours,
    titleKey: 'modernUi.TimeoutInput.hours',
    divider: 60 * 60,
  },
  {
    id: TimeIntervalsIds.days,
    titleKey: 'modernUi.TimeoutInput.days',
    divider: 60 * 60 * 24,
  },
  OFF_INTERVAL,
];

export const getDividerByIntervalId = (id: TimeIntervalsIds) =>
  (TIME_INTERVALS.find(propEq('id', id)) || {}).divider || 1;

const NON_ENUMERATE_INTERVALS = [
  TimeIntervalsIds.off,
  TimeIntervalsIds.immediately,
];

export const timeoutIntervalIsNotEnumerated = (timeout: TimeIntervalsIds) => {
  return NON_ENUMERATE_INTERVALS.includes(timeout);
};

export const TimeoutInput: React.FC<TimeoutInputProps> = ({
  className,
  ref,
  onBlur,
  onFocus,
  onChange,
  value = 1,
  interval = TimeIntervalsIds.minutes,
  intervalIds,
  textNormalWeight,
  customTimeIntervals = [],
  disabled,
  maxLength = 2,
  inputClassName,
  containerClassName,
  timeIntervalClassName,
  timeIntervalWrapperClassName,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const timeIntervals = TIME_INTERVALS.map(
    (interval) =>
      customTimeIntervals.find(({ id }) => id === interval.id) || interval,
  );

  const intervals = useMemo(
    () =>
      intervalIds
        ? timeIntervals.filter(({ id }) => intervalIds.includes(id))
        : timeIntervals,
    [intervalIds, timeIntervals],
  );

  const [innerValue, setInnerValue] = useState(value.toString());
  const [innerInterval, setInnerInterval] = useState<
    TimeInterval | undefined | null
  >(timeIntervals.find((item) => item.id === interval));

  if (!innerInterval) {
    return null;
  }

  return (
    <Flex
      ref={ref as React.RefObject<HTMLDivElement>}
      className={cn(css.TimeoutInput, className)}
      alignItems="flex-start"
      {...props}
    >
      {!timeoutIntervalIsNotEnumerated(innerInterval.id) && (
        <div className={css.inputBox}>
          <Input
            className={cn(css.timeIntervalInput, inputClassName)}
            containerClassName={containerClassName}
            type="number"
            min={0}
            max={10 ** maxLength - 1}
            maxLength={maxLength}
            onBlur={() => {
              onBlur?.();
              if (innerValue === '') {
                setInnerValue('0');
              }
            }}
            onFocus={(e) => {
              e.target.select();
              onFocus?.();
            }}
            value={innerValue}
            data-testid={`${props['data-testid']}_input`}
            disabled={disabled}
            onKeyDown={(event) => {
              if (
                Number.isNaN(Number.parseInt(event.key, 10)) &&
                ![
                  'Enter',
                  'Backspace',
                  'Delete',
                  'ArrowLeft',
                  'ArrowRight',
                ].includes(event.key)
              ) {
                event.preventDefault();
              }
            }}
            onChange={({ currentTarget: { value } }) => {
              if (value.length === 0) {
                setInnerValue('');
                return;
              }
              const n = parseInt(value, 10);
              if (!Number.isNaN(n) && n < 100 && n >= 0) {
                setInnerValue(value);
                onChange({
                  value: n,
                  interval: innerInterval.id,
                });
              } else {
                setInnerValue(innerValue);
              }
            }}
          />
        </div>
      )}
      <div
        className={cn(
          css.timeIntervalComboboxBox,
          timeIntervalWrapperClassName,
        )}
      >
        <SimpleCombobox<TimeInterval>
          items={intervals}
          onChange={(selectedItem) => {
            const timeInterval = selectedItem || OFF_INTERVAL;
            setInnerInterval(timeInterval);
            onChange({
              value: NON_ENUMERATE_INTERVALS.includes(timeInterval.id)
                ? null
                : Number(innerValue || 1),
              interval: timeInterval.id,
            });
            onBlur?.();
          }}
          filter={(_, items) => items}
          selectedItem={innerInterval}
          itemToString={(item) =>
            item ? t(item.titleKey, { count: parseFloat(innerValue) }) : ''
          }
          menuboxStyle={{ width: 'auto' }}
          renderInput={({ getToggleButtonProps, selectedItem }) => (
            <Button
              intent={ButtonIntent.primary}
              colorWay={ButtonColorWay.white}
              {...getToggleButtonProps()}
              renderIconRight={() => <DropdownIcon />}
              className={cn(css.timeIntervalCombobox, timeIntervalClassName)}
              textNormalWeight={textNormalWeight}
              data-testid={`${props['data-testid']}_button`}
              disabled={disabled}
            >
              {selectedItem
                ? t(selectedItem.titleKey, { count: parseFloat(innerValue) })
                : ''}
            </Button>
          )}
        />
      </div>
    </Flex>
  );
};
