import React from 'react';
import cn from 'classnames';
import * as css from './ShadowBox.css';

export interface ShadowBoxProps extends TestLocator {
  active?: boolean;
}

export const ShadowBox: React.FC<ShadowBoxProps> = ({
  children,
  active,
  ...dataTestId
}) => (
  <div
    className={cn(css.container, {
      [css.active]: active,
    })}
    {...dataTestId}
  >
    {children}
  </div>
);
