import React, { CSSProperties, HTMLProps } from 'react';
import cn from 'classnames';
import { Color, ColorKey } from '../_common/colors';
import { Flex, FlexProps } from '../Flex';
import { Icon } from '../Icon';
import { Type, FontWeightKey } from '../Type';
import * as css from './Callout.css';
import useResizeObserver from 'use-resize-observer';
import { Spacer } from '../Spacer';
import { IconNames } from '@ui/Icon/icons';
import { DeviceMediaProps, useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export enum CalloutVariant {
  info = 'info',
  warning = 'warning',
  gradient = 'gradient',
  error = 'error',
  dark = 'dark',
  purple = 'purple',
}

export type CalloutVariantKey = keyof typeof CalloutVariant;

export interface CalloutProps
  extends HTMLProps<HTMLDivElement>,
    DeviceMediaProps {
  text: React.ReactNode;
  type: CalloutVariantKey;
  button?: React.ReactNode;
  iconDisabled?: boolean;
  icon?: IconNames;
  iconView?: React.ReactNode;
  buttonNewLine?: boolean;
  onClose?: VoidFunction;
  closeButtonClassName?: string;
  alignItems?: FlexProps['alignItems'];
  flexDirection?: FlexProps['flexDirection'];
  gapFactor?: FlexProps['gapFactor'];
  onCloseStyle?: CSSProperties;
}

interface CalloutColorConfig {
  backgroundColor: ColorKey;
  iconColor: ColorKey;
  textColor: ColorKey;
  borderColor?: ColorKey;
  textWeight?: FontWeightKey;
}

const CalloutColors: Record<CalloutVariant, CalloutColorConfig> = {
  [CalloutVariant.info]: {
    backgroundColor: 'blueLight30',
    iconColor: 'blue',
    textColor: 'black',
  },
  [CalloutVariant.warning]: {
    backgroundColor: 'antiqueWhite',
    iconColor: 'alloyOrange',
    textColor: 'alloyOrange',
  },
  [CalloutVariant.error]: {
    backgroundColor: 'red',
    iconColor: 'white',
    textColor: 'white',
    textWeight: 'medium',
  },
  [CalloutVariant.gradient]: {
    backgroundColor: 'chatfuelGradient',
    iconColor: 'white',
    textColor: 'white',
    textWeight: 'medium',
  },
  [CalloutVariant.dark]: {
    backgroundColor: 'baseNormal',
    iconColor: 'white',
    textColor: 'white',
    textWeight: 'medium',
  },
  [CalloutVariant.purple]: {
    backgroundColor: 'purpleLight',
    iconColor: 'white',
    textColor: 'purpleText',
  },
};

const MIN_CALLOUT_WIDTH = 350;

export const Callout: React.FC<CalloutProps> = ({
  className,
  text,
  button: baseButton,
  type,
  style,
  iconDisabled,
  icon,
  iconView,
  buttonNewLine,
  onClose,
  closeButtonClassName,
  alignItems,
  flexDirection,
  gapFactor,
  onCloseStyle,
  ...props
}) => {
  const { getValueDueToSize } = useDeviceMedia(props);
  const { width = 0, ref } = useResizeObserver();
  const narrow = baseButton && MIN_CALLOUT_WIDTH > width;
  const { backgroundColor, borderColor, iconColor, textColor, textWeight } =
    CalloutColors[CalloutVariant[type]];

  const button = baseButton && (
    <div
      className={cn(
        buttonNewLine
          ? css.buttonNewLine
          : getValueDueToSize(css.buttonSmall, css.button),
        narrow && css.narrow,
      )}
    >
      {baseButton}
    </div>
  );

  return (
    <div
      className={cn(css.container, className)}
      style={{
        position: 'relative',
        background: Color[backgroundColor],
        border: borderColor ? `1px solid ${Color[borderColor]}` : 'none',
        ...style,
      }}
      ref={ref}
      {...props}
    >
      <Flex
        flexDirection={flexDirection ?? (narrow ? 'column' : 'row')}
        alignItems={alignItems ?? 'center'}
        gapFactor={gapFactor}
        justifyContent="space-between"
      >
        {!iconDisabled &&
          (iconView || (
            <Icon
              size="24px"
              icon={icon || 'info'}
              color={iconColor}
              className={css.icon}
            />
          ))}
        <div className={getValueDueToSize(css.textSmall, css.text)}>
          <Type
            color={textColor}
            size="15px_DEPRECATED"
            weight={textWeight}
            whiteSpace="pre-wrap"
          >
            {text}
          </Type>
        </div>
        {narrow && <Spacer factor={3} />}
        {!buttonNewLine && button}
        {onClose && (
          <Icon
            size="24px"
            icon="close"
            className={cn(
              getValueDueToSize(css.closeIconSmall, css.closeIcon),
              closeButtonClassName,
            )}
            style={onCloseStyle}
            onClick={onClose}
          />
        )}
      </Flex>
      {buttonNewLine && button}
    </div>
  );
};
