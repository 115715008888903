import { useEffect, useState } from 'react';
import { createKeyboardEventHandler } from './utils';
import { NestedMenuItem, NestedMenuNodeDisplayMode } from './types';

export interface UseNestedMenuParams<T extends NestedMenuItem> {
  isRoot: boolean;
  allItems: Array<T>;
  onKeyDown?: (event: KeyboardEvent) => void;
  onChange: (item: T) => void;
  // rename or removee?
  parentsSetOpenedMenuIndex?: (index: number) => void;
}

export interface UseNestedMenuHandlers<T> {
  isRoot: boolean;
  onChange: (item: T) => void;
  highlightedIndex: number;
  setHighlightedIndex: (index: number) => void;
  openedMenuIndex: number;
  setOpenedMenuIndex: (index: number) => void;
}

export function useNestedMenu<T extends NestedMenuItem>({
  isRoot,
  allItems,
  parentsSetOpenedMenuIndex,
  onKeyDown,
  onChange,
}: UseNestedMenuParams<T>): UseNestedMenuHandlers<T> {
  const [highlightedIndex, setHighlightedIndex] = useState(isRoot ? -1 : 0);
  const [openedMenuIndex, setOpenedMenuIndex] = useState(-1);

  useEffect(() => {
    const isNode =
      Boolean(allItems[highlightedIndex]?.children) &&
      allItems[highlightedIndex]?.displayMode !==
        NestedMenuNodeDisplayMode.structure;
    const keyboardEventHandler = createKeyboardEventHandler({
      isRoot,
      isNode,
      openedMenuIndex,
      allItems,
      highlightedIndex,
      setHighlightedIndex,
      setOpenedMenuIndex,
      parentsSetOpenedMenuIndex,
      onKeyDown,
      onChange,
    });

    const listener = (event: KeyboardEvent) => {
      if (openedMenuIndex !== -1) {
        return;
      }

      keyboardEventHandler(event);
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [
    allItems,
    isRoot,
    openedMenuIndex,
    highlightedIndex,
    setHighlightedIndex,
    setOpenedMenuIndex,
    parentsSetOpenedMenuIndex,
    onKeyDown,
    onChange,
  ]);

  return {
    isRoot,
    onChange,
    highlightedIndex,
    setHighlightedIndex,
    openedMenuIndex,
    setOpenedMenuIndex,
  };
}
