import {
  getQuantId,
  getQuantValue,
  getRegularValue,
  QuantIds,
} from '@utils/DateTime/quant';
import { invertObj } from 'ramda';
import React from 'react';
import {
  TimeIntervalsIds,
  TimeInterval,
  TimeoutInput,
  TimeoutInputClassNames,
} from './TimeoutInput';

export type TimeoutInputOnChange = (
  value: number,
  rawValue: number,
  interval: TimeIntervalsIds,
) => void;

interface TimeoutInputByTimestampProps
  extends TimeoutInputClassNames,
    TestLocator {
  value: number;
  onChange: TimeoutInputOnChange;
  onBlur?: () => void;
  allowedTimeIntervalsIds?: TimeIntervalsIds[];
  customTimeIntervals?: TimeInterval[];
  maxLength?: number;
  disabled?: boolean;
}

export const QUANT_ID_TO_TIME_INTERVALS_ID: Record<QuantIds, TimeIntervalsIds> =
  {
    [QuantIds.days]: TimeIntervalsIds.days,
    [QuantIds.hours]: TimeIntervalsIds.hours,
    [QuantIds.instantly]: TimeIntervalsIds.immediately,
    [QuantIds.minutes]: TimeIntervalsIds.minutes,
    [QuantIds.seconds]: TimeIntervalsIds.seconds,
  };

export const TIME_INTERVALS_ID_TO_QUANT_ID = invertObj(
  QUANT_ID_TO_TIME_INTERVALS_ID,
) as Record<TimeIntervalsIds, QuantIds>;

export const TimeoutInputByTimestamp: React.FC<TimeoutInputByTimestampProps> =
  ({
    value,
    onChange,
    allowedTimeIntervalsIds,
    customTimeIntervals,
    onBlur,
    maxLength,
    disabled,
    'data-testid': dataTestId,
    ...props
  }) => {
    const quantId = getQuantId(value);
    return (
      <TimeoutInput
        maxLength={maxLength}
        value={
          quantId === QuantIds.instantly
            ? undefined
            : getRegularValue(value, quantId)
        }
        interval={QUANT_ID_TO_TIME_INTERVALS_ID[quantId]}
        intervalIds={allowedTimeIntervalsIds}
        customTimeIntervals={customTimeIntervals}
        onChange={({ interval, value }) => {
          onChange(
            getQuantValue(value || 0, TIME_INTERVALS_ID_TO_QUANT_ID[interval]),
            value || 0,
            interval,
          );
        }}
        disabled={disabled}
        onBlur={onBlur}
        data-testid={dataTestId}
        {...props}
      />
    );
  };
