import React from 'react';
import { Icon, IconNames } from '@ui/Icon';
import { Button, ButtonProps } from '@ui/Button';
import { getComponentWithRefAndName } from '@utils/withRef';
import { ColorKey } from '../../_common/colors';

export interface ComboboxTriangleButtonProps extends ButtonProps {
  icon?: IconNames;
  color?: ColorKey;
}

export const ComboboxTriangleButton = getComponentWithRefAndName<
  HTMLButtonElement,
  ComboboxTriangleButtonProps
>('ComboboxTriangleButton', ({ icon = 'triangle', color, ...props }, ref) => (
  <Button
    ref={ref}
    intent="text"
    iconRight={<Icon icon={icon} color={color} />}
    {...props}
  />
));
