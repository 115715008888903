import React from 'react';
import { MenuItemProps } from '../Menu';
import { Type } from '../Type';

import * as css from './MenuItemEmpty.css';

export function MenuItemEmpty({ title, attrTitle, ...props }: MenuItemProps) {
  return (
    <div className={css.empty} {...props}>
      <Type color="greyDark" size="15px_DEPRECATED">
        {title}
      </Type>
    </div>
  );
}
