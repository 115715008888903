import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { InputProps } from '@ui/Input/Input';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useState } from 'react';
import * as css from './ChangeTextInput.css';

type Event = React.MouseEvent<HTMLButtonElement, MouseEvent>;

interface ChangeTextInputProps extends Omit<InputProps, 'error'> {
  initialValue: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRenameClick: (event: Event, newValue: string) => void;
  error?: (value: string) => boolean;
  checkClassName?: string;
  closeClassName?: string;
  columnOrientation?: boolean;
}

export const ChangeTextInput: React.FC<ChangeTextInputProps> = ({
  onChange,
  onRenameClick,
  initialValue,
  checkClassName,
  closeClassName,
  error,
  columnOrientation,
  ...inputProps
}) => {
  const { t } = useSafeTranslation();
  const [value, setValue] = useState(initialValue);

  return (
    <Flex
      alignItems="center"
      flexDirection={columnOrientation ? 'column' : 'row'}
    >
      <Input
        autoFocus
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value);
          onChange?.(e);
        }}
        error={error?.(value)}
        data-testid="change-text__input"
        {...inputProps}
      />

      <Spacer
        factor={columnOrientation ? 2 : 0}
        horizontalFactor={columnOrientation ? 0 : 4}
      />

      <Flex style={{ width: '100%' }}>
        <Button
          onClick={(event) => onRenameClick(event, value)}
          className={css.button}
          data-testid="change-text__check-button"
        >
          {t('modernComponents.ChangeTextInput.rename')}
        </Button>
      </Flex>
    </Flex>
  );
};
