import { MutableRefObject } from 'react';
import { useFocusWithin } from '@ui/FocusWithin';
import { useHover } from '@ui/Hover';

export function useHoverDisclosure<
  T extends MutableRefObject<HTMLElement | null>,
>(containerRef: T) {
  const { focusedWithin } = useFocusWithin(containerRef);
  const { hovered } = useHover(containerRef);
  return {
    visible: hovered || focusedWithin,
  };
}
