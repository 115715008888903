import React from 'react';
import cn from 'classnames';
import { MenuItemProps } from '../Menu';
import * as css from './MenuItemCreateBlock.css';

const cutBlockTitle = (title: string): string => title.substr(0, 60);

export interface MenuItemCreateProps extends MenuItemProps {
  addItem: React.MouseEventHandler;
  text: string;
}

export const MenuItemCreate: React.FC<MenuItemCreateProps> = ({
  title = '',
  addItem,
  onClick,
  active,
  attrTitle,
  text,
  ...props
}) => (
  <div // eslint-disable-line jsx-a11y/click-events-have-key-events
    data-testid="menu-item-create__create-block"
    onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
      addItem(e);

      if (onClick) {
        onClick(e);
      }
    }}
    className={cn(css.create, { [css.active]: active })}
    {...props}
  >
    {`${text} ‘${cutBlockTitle(title)}‘`}
  </div>
);
