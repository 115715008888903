import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '@ui/_deprecated/Button';
import { SegmentedGroupTrackColorWay } from '@ui/SegmentedGroup/SegmentedGroupTrack';
import { SegmentedGroupTrack } from '@ui/SegmentedGroup';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface Tab {
  id: string;
  title: string;
}

interface PageNavigationTabsProps {
  tabs: Tab[];
  initialTabId: string;
  navParam: string;
  tabColor: keyof typeof ButtonColorWay;
  className?: string;
  containerClassName?: string;
}

export const PageNavigationTabs: React.FC<PageNavigationTabsProps> = ({
  tabs,
  initialTabId,
  navParam,
  tabColor,
  className,
  containerClassName,
}) => {
  const { pathname, search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();

  const { getValueDueToSize } = useDeviceMedia();

  useEffect(() => {
    if (query.get(navParam) === initialTabId) return;

    if (!query.has(navParam)) {
      query.append(navParam, initialTabId);
    } else if (!tabs.map((v) => v.id).includes(query.get(navParam) ?? '')) {
      query.set(navParam, initialTabId);
    }
    history.push(`${pathname}?${query}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SegmentedGroupTrack
      role="tablist"
      style={{
        width: getValueDueToSize('100%', 'auto'),
      }}
      className={containerClassName}
      colorWay={SegmentedGroupTrackColorWay.blueLight30}
    >
      {tabs.map((tab) => (
        <Button
          data-testid={`page-tabs__tab-${tab.id}`}
          className={className}
          key={tab.id}
          role="tab"
          intent={ButtonIntent.toggle}
          colorWay={ButtonColorWay[tabColor]}
          size={ButtonSize.s}
          aria-selected={query.get(navParam) === tab.id}
          textNormalWeight
          onClick={() => {
            history.push(`${pathname}?${navParam}=${tab.id}`);
          }}
        >
          {tab.title}
        </Button>
      ))}
    </SegmentedGroupTrack>
  );
};
