/**
 * This component tells you when you can display a hidden element
 * by detecting when its parent is either hovered of focused.
 */
import React from 'react';
import { Hover } from 'react-powerplug';
import { FocusWithin } from '../FocusWithin';

interface Props {
  render: (props: {
    isVisible: boolean;
    bind: {
      onMouseEnter: () => void;
      onMouseLeave: () => void;
      ref: (n: HTMLElement | null) => void;
    };
  }) => React.ReactNode;
}

interface State {
  focusedWithin: boolean;
}

class HoverDisclosure extends React.Component<Props, State> {
  render() {
    return (
      <Hover>
        {(hover) => (
          <FocusWithin
            render={(focus) =>
              this.props.render({
                isVisible: hover.hovered || focus.focusedWithin,
                bind: { ...hover.bind, ...focus.bind },
              })
            }
          />
        )}
      </Hover>
    );
  }
}

export { HoverDisclosure };
