import React from 'react';

export const BASE_UNIT = 4;

interface SpacerProps {
  factor?: number;
  horizontalFactor?: number;
}

export const Spacer: React.FC<SpacerProps> = ({
  factor = 6,
  horizontalFactor = 0,
}) => (
  <div
    style={{
      height: BASE_UNIT * factor,
      width: BASE_UNIT * horizontalFactor,
      flex: '0 0 auto',
    }}
  />
);
