import React from 'react';
import cn from 'classnames';
import { PageHeader } from '../../modern-components/PageHeader';
import { PageContent } from '../PageContent';
import { Flex } from '../Flex';
import * as css from './Page.css';
import { LogoSize } from '../../modern-components/PageHeader/WhiteLabelLogo';

export const Page: React.SFC<React.HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} className={cn(css.page, props.className)} />
);

interface AuthFlowPageProps {
  render: () => React.ReactNode;
  logoSize?: LogoSize;
  hideNav?: boolean;
  hideHeader?: boolean;
  disabledHeader?: boolean;
}

export const AuthFlowPage: React.SFC<AuthFlowPageProps> = ({
  render,
  logoSize,
  hideNav,
  hideHeader,
  disabledHeader,
}) => (
  <Page>
    {!hideHeader && (
      <PageHeader
        logoSize={logoSize}
        hideNav={hideNav}
        style={{ pointerEvents: disabledHeader ? 'none' : undefined }}
      />
    )}
    <PageContent>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={css.authFlowPageContainer}
      >
        {render()}
      </Flex>
    </PageContent>
  </Page>
);
