import React from 'react';
import cn from 'classnames';
import { DialogContent, DialogCloseButton } from '../Dialog';
import * as css from './ActionDialogParts.css';

export const FlexDialogContent: React.SFC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => (
  <DialogContent
    {...props}
    className={cn(css.flexDialogContent, props.className)}
  />
);

export const FormLine: React.SFC<React.HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} className={cn(css.formLine, props.className)} />
);

export const SubmitRow: React.SFC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => <div {...props} className={cn(css.submitRow, props.className)} />;

export const ConfirmRow: React.SFC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => <div {...props} className={cn(css.confirmRow, props.className)} />;

export const CloseButton: React.SFC<React.ButtonHTMLAttributes<
  HTMLButtonElement
>> = (props) => <DialogCloseButton className={css.closeButton} {...props} />;
