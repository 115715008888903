import React, { useEffect } from 'react';
import cn from 'classnames';
import { IS_PRODUCTION_BUILD } from 'cf-common/src/environment';
import { getComponentWithRefAndName } from '@utils/withRef';
import * as css from './ButtonBase.css';

export enum ButtonSize {
  xs = 'XS',
  s = 'S',
  m = 'M',
  l = 'L',
}

export type ButtonType = 'button' | 'submit' | 'reset' | undefined;

export type ButtonSizeKey = keyof typeof ButtonSize;

export interface ButtonBaseProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSizeKey;
  iconClassName?: string;
  textClassName?: string;
  labelTextClassName?: string;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  fitToText?: boolean;
  loader?: React.ReactNode;
  fullWidth?: boolean;
  // TODO: Make this field required when every button has data-testid
  'data-testid'?: string;
}

export const ButtonBase = getComponentWithRefAndName<
  HTMLButtonElement,
  ButtonBaseProps
>(
  'ButtonBase',
  (
    {
      icon,
      iconRight,
      children,
      className,
      textClassName,
      iconClassName,
      labelTextClassName,
      type = 'button',
      size = 'm',
      fitToText,
      fullWidth,
      loader,
      ...props
    },
    ref,
  ) => {
    const hasLeftIcon = Boolean(icon);
    const hasRightIcon = Boolean(iconRight);
    const hasText = !!children;
    const onlyIcon = !hasText;
    const onlyText = !hasLeftIcon && !hasRightIcon;

    useEffect(() => {
      if (!IS_PRODUCTION_BUILD && !props['data-testid']) {
        // eslint-disable-next-line
        console.log('[TEST-ID] Consider adding data-testid for autotesting');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <button
        {...props}
        ref={ref}
        type={type as ButtonType}
        className={cn(css.button, css[`size${ButtonSize[size]}`], className, {
          [css.buttonHasLeftIcon]: hasLeftIcon || onlyIcon,
          [css.buttonHasRightIcon]: hasRightIcon || onlyIcon,
          [css.fixedMinWidth]: onlyText && !fitToText,
          [css.fullWidth]: fullWidth,
        })}
      >
        <span
          className={cn(css.label, textClassName, {
            [css.onlyIcon]: onlyIcon,
          })}
        >
          {loader}
          {icon ? (
            <span
              className={cn(css.iconContainer, iconClassName, {
                [css.noRightMargin]: hasText,
                [css.hidden]: loader,
              })}
            >
              {icon}
            </span>
          ) : null}
          {children && (
            <span
              className={cn(labelTextClassName, css.labelText, {
                [css.alignLeft]: hasText && hasRightIcon && !hasLeftIcon,
                [css.hidden]: loader,
              })}
            >
              {children}
            </span>
          )}
          {iconRight ? (
            <span
              className={cn(css.iconContainer, {
                [css.noLeftMargin]: hasText,
                [css.hidden]: loader,
              })}
            >
              {iconRight}
            </span>
          ) : null}
        </span>
      </button>
    );
  },
);
