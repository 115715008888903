import React, { CSSProperties } from 'react';
import cn from 'classnames';
import { Level, log } from 'cf-common/src/logger';
import {
  NTN_GROUP_ID,
  OTN_GROUP_ID,
} from '@components/BroadcastingTags/TagsData';

import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogCloseButton,
} from '../Dialog';
import { ScrollBox } from '../ScrollBox';
import { Button, ButtonIntent } from '../_deprecated/Button';
import { HoverDisclosure } from '../HoverDisclosure';

import * as css from './ChooseFromListPopup.css';

export interface ListItem {
  id: string;
  order?: number;
  groupId: string;
  title?: string;
  text?: string;
  renderRightPart?: (
    selected?: boolean,
    disabled?: boolean,
    onSelect?: (id: string) => void,
  ) => React.ReactNode;
  picture?: string;
  leftPartTitle?: string;
  leftPartSubtitle?: string;
  disabled?: boolean;
}

export interface GroupItem {
  id: string;
  title?: string;
  render?: () => React.ReactNode;
}

type ListProps = {
  groupId: string;
  selectedId?: string;
  items: ListItem[];
  onSelect: (id: string) => void;
};
export const List: React.FC<ListProps> = ({
  groupId,
  selectedId,
  items,
  onSelect,
}) => {
  const groupItems = items.filter((item) => item.groupId === groupId);
  return (
    <>
      {groupItems.map(
        ({
          id,
          title,
          text,
          picture,
          leftPartTitle,
          leftPartSubtitle,
          renderRightPart,
          disabled,
        }) => {
          const selected = selectedId === id;
          if (leftPartTitle && picture) {
            log({
              level: Level.warn,
              msg: 'leftPartTitle and picture cannot be passed for item at the same time',
            });
            return null;
          }
          return (
            <HoverDisclosure
              key={id}
              render={({ isVisible, bind }) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  key={id}
                  className={cn(css.item, {
                    [css.selected]: selected,
                    [css.hover]: !disabled && isVisible && !selected,
                    [css.disabled]: disabled,
                  })}
                  onClick={() => !disabled && onSelect(id)}
                  {...bind}
                >
                  {picture && (
                    <div>
                      <img src={picture} className={css.leftPart} alt={title} />
                    </div>
                  )}
                  {leftPartTitle && (
                    <div>
                      <div className={cn(css.leftPart, css.leftPartText)}>
                        <div
                          className={cn(css.leftPartTitle, {
                            [css.leftPartTitleSelected]: selected,
                            [css.disabledText]: disabled,
                          })}
                        >
                          {leftPartTitle}
                        </div>
                        <div
                          className={cn(css.leftPartSubtitle, {
                            [css.leftPartSubtitleSelected]: selected,
                            [css.disabledText]: disabled,
                          })}
                        >
                          {leftPartSubtitle}
                        </div>
                      </div>
                    </div>
                  )}
                  {renderRightPart ? (
                    renderRightPart(selected, disabled, onSelect)
                  ) : (
                    <div>
                      {title && (
                        <div
                          className={cn(css.itemTitle, {
                            [css.itemTitleSelected]: selected,
                            [css.disabledText]: disabled,
                          })}
                        >
                          {title}
                        </div>
                      )}
                      <div
                        className={cn(css.itemText, {
                          [css.itemTextSelected]: selected,
                          [css.disabledText]: disabled,
                        })}
                      >
                        {text}
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
          );
        },
      )}
    </>
  );
};

type GroupsTypes = {
  groups: GroupItem[];
  renderGroup: ({ groupId }: { groupId: string }) => JSX.Element;
};
const Groups: React.FC<GroupsTypes> = ({ groups, renderGroup }) => {
  return (
    <>
      {groups.map(({ id, title, render }) => (
        <div className={css.itemsGroup} key={id}>
          <div className={css.groupTitle}>{render ? render() : title}</div>
          <div>{renderGroup({ groupId: id })}</div>
        </div>
      ))}
    </>
  );
};

type DialogWithActionsProps = {
  heading: string;
  renderSubHeading?: () => JSX.Element;
  renderActions: () => JSX.Element;
  onClose: () => void;
  scrollBoxStyle?: CSSProperties;
};
const DialogWithActions: React.FC<DialogWithActionsProps> = ({
  heading,
  renderSubHeading,
  renderActions,
  onClose,
  scrollBoxStyle,
  children,
}) => (
  <Dialog className={css.dialog}>
    <DialogContent>
      <DialogHeading>{heading}</DialogHeading>
      {renderSubHeading && renderSubHeading()}
      <div className={css.groupsBox}>
        <ScrollBox className={css.scrollBox} style={scrollBoxStyle}>
          {children}
          <div className={css.buttonBox}>{renderActions()}</div>
        </ScrollBox>
      </div>
    </DialogContent>
    <DialogCloseButton className={css.closeButton} onClick={onClose} />
  </Dialog>
);

export interface ChooseFromListProps {
  onConfirmSelection: (id?: string) => void;
  onRequestClose: () => void;
  selectedId?: string;
  items: ListItem[];
  groups: GroupItem[];
  sort?: (a: ListItem, b: ListItem) => boolean;
  buttonText: string;
  dialogHeading: string;
  renderSubHeading?: () => JSX.Element;
  onSelect?: (id: string) => void;
  isOtnSelected?: boolean;
  isNtnSelected?: boolean;
}

export const ChooseFromListPopup: React.FC<ChooseFromListProps> = ({
  onRequestClose,
  onConfirmSelection,
  buttonText,
  dialogHeading,
  renderSubHeading,
  items,
  groups,
  selectedId,
  onSelect,
  isOtnSelected,
  isNtnSelected,
}) => {
  const [currentId, setCurrentId] = React.useState(selectedId);

  const handleSelect = (id: string) => {
    if (id === OTN_GROUP_ID && !isOtnSelected) return;
    if (id === NTN_GROUP_ID && !isNtnSelected) return;
    if (onSelect) {
      onSelect(id);
    } else {
      setCurrentId(id);
    }
  };
  const id = onSelect ? selectedId : currentId;

  return (
    <DialogWithActions
      heading={dialogHeading}
      scrollBoxStyle={{
        position: 'static',
      }}
      renderActions={() => (
        <Button
          intent={ButtonIntent.primary}
          className={css.itemSelectButton}
          disabled={id === undefined}
          onClick={() => onConfirmSelection(id)}
        >
          {buttonText}
        </Button>
      )}
      onClose={onRequestClose}
      renderSubHeading={renderSubHeading}
    >
      <Groups
        groups={groups}
        renderGroup={({ groupId }) => (
          <List
            groupId={groupId}
            selectedId={id}
            items={items}
            onSelect={handleSelect}
          />
        )}
      />
    </DialogWithActions>
  );
};
