import React from 'react';
import cn from 'classnames';
import * as css from './TextEllipsis.css';

interface TextEllipsisProps extends React.HTMLProps<HTMLDivElement> {
  width?: number | string;
  lineClamp?: number;
  hypens?: boolean;
  inline?: boolean;
}

export const TextEllipsis: React.FC<TextEllipsisProps> = ({
  width: maxWidth = '100%',
  lineClamp,
  hypens,
  inline,
  className,
  ...divProps
}) => (
  <div
    style={{
      ...(lineClamp
        ? {
            WebkitLineClamp: lineClamp,
            WebkitBoxOrient: 'vertical',
          }
        : {}),
      maxWidth,
    }}
    lang={hypens ? 'en' : undefined}
    className={cn(
      {
        [css.hypens]: hypens,
      },
      lineClamp ? css.clampedTextEllipsis : css.TextEllipsis,
      inline && css.inline,
      className,
    )}
    {...divProps}
  />
);
