/**
 * т.к меню рисуется рикурсивно, то при разбиении на файлы, появляются цикл. зависимости
 * поэтому все расположено тут
 */
import React, { CSSProperties, useEffect, useRef } from 'react';
import * as PopperJS from 'popper.js';
import { usePreventWheel } from '@utils/Event/usePreventWheel';
import { ScrollBox } from '@ui/ScrollBox';
import { Popper } from 'react-popper';
import { Menubox, MenuItem as UIKITMenuItem } from '@ui/Menu';
import { Badge, Kind } from '@ui/Badge';
import { useTooltip } from '@ui/Tooltip2';
import { HtmlTextMemoized } from '@components/HtmlTextMemoized';
import { isGroup, isStructureNode, mapPlacement } from './utils';
import { ItemImage } from './components/ItemImage';
import * as menuItemCSS from './MenuItem.css';
import * as css from './Menu.css';
import { useNestedMenu, UseNestedMenuHandlers } from './useNestedMenu';
import {
  ExtendStyles,
  Group,
  MenuItems,
  NestedMenuItem,
  NestedMenuNodeDisplayMode,
  RenderContent,
  TooltipPlacement,
} from './types';
import { DEFAULT_POPPER_MODIFIERS } from './consts';

interface NestedMenuProps<T extends NestedMenuItem>
  extends ExtendStyles<T>,
    RenderContent<T> {
  item: T;
  items: Group<T>;
  style: CSSProperties;
  onChange: (item: T) => void;
  placement: TooltipPlacement;
  setOpenedMenuIndex: (index: number) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
}

const SubMenu = React.forwardRef<HTMLDivElement, NestedMenuProps<any>>(
  (
    {
      item,
      items,
      style,
      onChange,
      onKeyDown,
      placement,
      renderTooltip,
      renderGroupView,
      setOpenedMenuIndex,
      getItemStyles,
      getTitleStyles,
      getGroupStyles,
      getRightElement,
    },
    ref,
  ) => {
    const allItems = item?.children?.flat() || [];
    const props = useNestedMenu({
      isRoot: false,
      allItems,
      parentsSetOpenedMenuIndex: setOpenedMenuIndex,
      onKeyDown,
      onChange,
    });

    return (
      <div ref={ref} style={{ ...style, zIndex: 1 }}>
        <Menubox>
          {/* eslint-disable-next-line */}
          <Menu
            items={items}
            onKeyDown={onKeyDown}
            tooltipPlacement={placement}
            renderTooltip={renderTooltip}
            renderGroupView={renderGroupView}
            getItemStyles={getItemStyles}
            getGroupStyles={getGroupStyles}
            getTitleStyles={getTitleStyles}
            getRightElement={getRightElement}
            {...props}
          />
        </Menubox>
      </div>
    );
  },
);

interface MenuItemProps<T extends NestedMenuItem>
  extends UseNestedMenuHandlers<T>,
    ExtendStyles<T>,
    RenderContent<T> {
  item: T;
  index: number;
  onKeyDown?: (event: KeyboardEvent) => void;
  tooltipPlacement?: TooltipPlacement;
  getPopperModifiers?: PopperJS.Modifiers;
}

export function RenderMenuItem<T extends NestedMenuItem>({
  item,
  index,
  highlightedIndex,
  openedMenuIndex,
  tooltipPlacement,
  renderTooltip,
  renderGroupView,
  onChange,
  onKeyDown,
  getItemStyles,
  getTitleStyles,
  getGroupStyles,
  getRightElement,
  getPopperModifiers,
  setOpenedMenuIndex,
  setHighlightedIndex,
}: MenuItemProps<T>) {
  const listItemRef = useRef(null);
  const [hovered, setHovered] = useTooltip(50, 50);
  const active = highlightedIndex === index;
  const opened = openedMenuIndex === index;
  const { title, titleElement, tooltip, id, newLabel, children, displayMode } =
    item;
  const isNode = Boolean(children);
  const type = isNode ? 'node' : 'item';
  const hasAnyImage = Boolean(item.imageUrl || item.img);
  const placement = mapPlacement(tooltipPlacement);

  useEffect(() => {
    if (isNode && hovered) {
      setOpenedMenuIndex(index);
    }

    if (!isNode && hovered) {
      setOpenedMenuIndex(-1);
    }
  }, [hovered, isNode, setOpenedMenuIndex, index]);

  return (
    <li style={{ position: 'relative' }} ref={listItemRef}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        style={{ width: '100%' }}
        onMouseEnter={() => {
          setHighlightedIndex(index);
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHighlightedIndex(-1);
          setHovered(false);
        }}
      >
        <UIKITMenuItem
          style={getItemStyles?.(item, index)}
          placement={placement}
          tooltipType="small"
          tooltipBoundariesElement="viewport"
          type={type}
          className={menuItemCSS.overlayMenuItem}
          active={active}
          leftElement={hasAnyImage && <ItemImage {...item} />}
          titleElement={
            titleElement ?? <HtmlTextMemoized title={title as string} />
          }
          titleStyle={{
            flex: 'none',
            ...getTitleStyles?.(item, index),
          }}
          rightElement={
            newLabel ? (
              <Badge kind={Kind.primary}>New</Badge>
            ) : (
              getRightElement?.({ item, index, active })
            )
          }
          tooltip={tooltip && renderTooltip?.(tooltip, id)}
          onClick={() => !isNode && onChange(item)}
        />

        {opened &&
        isNode &&
        displayMode === NestedMenuNodeDisplayMode.nested ? (
          <Popper
            placement={tooltipPlacement || 'right-start'}
            referenceElement={listItemRef.current || undefined}
            modifiers={getPopperModifiers || DEFAULT_POPPER_MODIFIERS}
            positionFixed
          >
            {({ ref, style: popperStyles, placement }) => {
              const style = {
                ...popperStyles,
                top: '-4px',
                left: placement === 'right-start' ? '4px' : '-4px',
              };

              return (
                <SubMenu
                  ref={ref}
                  items={item.children as Group<T>}
                  item={item}
                  style={style}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  placement={placement}
                  renderTooltip={renderTooltip}
                  renderGroupView={renderGroupView}
                  setOpenedMenuIndex={setOpenedMenuIndex}
                  getItemStyles={getItemStyles}
                  getTitleStyles={getTitleStyles}
                  getGroupStyles={getGroupStyles}
                  getRightElement={getRightElement}
                />
              );
            }}
          </Popper>
        ) : null}
      </div>
    </li>
  );
}

interface MenuProps<T>
  extends UseNestedMenuHandlers<T>,
    ExtendStyles<T>,
    RenderContent<T> {
  items: MenuItems<T>;
  tooltipPlacement?: TooltipPlacement;
  getPopperModifiers?: PopperJS.Modifiers;
  indexDelta?: number;
  onKeyDown?: (event: KeyboardEvent) => void;
}

export function Menu<T extends NestedMenuItem>({
  items,
  onChange,
  onKeyDown,
  tooltipPlacement,
  renderTooltip,
  highlightedIndex,
  setHighlightedIndex,
  openedMenuIndex,
  setOpenedMenuIndex,
  getGroupStyles,
  getItemStyles,
  getTitleStyles,
  getRightElement,
  getPopperModifiers,
  indexDelta = 0,
  renderGroupView = (item: T) => <>{item.title}</>,
}: MenuProps<T>) {
  const boxRef = usePreventWheel();

  let delta = indexDelta;

  return (
    <ScrollBox innerRef={(el) => boxRef(el)} className={css.scrollBox}>
      {isGroup(items) ? (
        items.map((group, index) => {
          if (index !== 0) {
            delta += items[index - 1].length;
          }

          return (
            <ul className={css.ul} style={getGroupStyles?.(group, index)}>
              {group.map((item, index) => (
                <RenderMenuItem
                  key={`${item.id}:${item.title}`}
                  isRoot={false}
                  item={item}
                  index={index + delta}
                  getItemStyles={getItemStyles}
                  getGroupStyles={getGroupStyles}
                  getTitleStyles={getTitleStyles}
                  getRightElement={getRightElement}
                  getPopperModifiers={getPopperModifiers}
                  highlightedIndex={highlightedIndex}
                  openedMenuIndex={openedMenuIndex}
                  tooltipPlacement={tooltipPlacement}
                  renderTooltip={renderTooltip}
                  renderGroupView={renderGroupView}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  setOpenedMenuIndex={setOpenedMenuIndex}
                  setHighlightedIndex={setHighlightedIndex}
                />
              ))}
            </ul>
          );
        })
      ) : (
        <ul className={css.ul}>
          {items.map((item, index) => {
            if (index !== 0 && isStructureNode(items[index - 1])) {
              delta += items[index - 1]?.children?.length || 0;
            }

            if (!isStructureNode(item)) {
              return (
                <RenderMenuItem
                  key={`${item.id}:${item.title}`}
                  isRoot={false}
                  item={item}
                  index={index + delta}
                  getItemStyles={getItemStyles}
                  getGroupStyles={getGroupStyles}
                  getTitleStyles={getTitleStyles}
                  getRightElement={getRightElement}
                  getPopperModifiers={getPopperModifiers}
                  highlightedIndex={highlightedIndex}
                  openedMenuIndex={openedMenuIndex}
                  tooltipPlacement={tooltipPlacement}
                  renderTooltip={renderTooltip}
                  renderGroupView={renderGroupView}
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  setOpenedMenuIndex={setOpenedMenuIndex}
                  setHighlightedIndex={setHighlightedIndex}
                />
              );
            }

            const isGroupVisible =
              item.displayMode !== NestedMenuNodeDisplayMode.noTitlteStructure;

            return (
              <React.Fragment key={`${item.id}:${item.title}`}>
                {isGroupVisible && <li>{renderGroupView(item)}</li>}
                {(item.children as T[]).map((child, index) => (
                  <RenderMenuItem
                    key={`${child.id}:${child.title}`}
                    isRoot={false}
                    item={child}
                    index={index + delta}
                    getItemStyles={getItemStyles}
                    getGroupStyles={getGroupStyles}
                    getTitleStyles={getTitleStyles}
                    getRightElement={getRightElement}
                    getPopperModifiers={getPopperModifiers}
                    highlightedIndex={highlightedIndex}
                    openedMenuIndex={openedMenuIndex}
                    tooltipPlacement={tooltipPlacement}
                    renderTooltip={renderTooltip}
                    renderGroupView={renderGroupView}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    setOpenedMenuIndex={setOpenedMenuIndex}
                    setHighlightedIndex={setHighlightedIndex}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </ScrollBox>
  );
}
