import React from 'react';
import { Translation } from '@translations';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TOptions } from 'i18next';
import { Type, TypeProps } from './Type';

interface TypeTranslationProps extends TypeProps {
  i18nKey: Translation;
  options?: TOptions;
}

export const TypeTranslation: React.FC<TypeTranslationProps> = ({
  children,
  i18nKey,
  options,
  ...props
}) => {
  const { t } = useSafeTranslation();

  return <Type {...props}>{t(i18nKey, options)}</Type>;
};
