import React from 'react';
import cn from 'classnames';

import { getComponentWithRefAndName } from '@utils/withRef';

import * as css from './Badge.css';

export enum Kind {
  success = 'success',
  primary = 'primary',
  default = 'default',
  alert = 'alert',
  blue = 'blue',
  gray = 'gray',
  transparent = 'transparent',
  white = 'white',
  instagram = 'instagram',
  fuchsia = 'fuchsia',
}

export type KindKey = keyof typeof Kind;

export enum BadgeSize {
  s = 's',
  m = 'm',
  l = 'l',
}

export type BadgeSizeKey = keyof typeof BadgeSize;

export interface BadgeProps
  extends Omit<React.HTMLProps<HTMLSpanElement>, 'kind' | 'size'> {
  kind: KindKey;
  big?: boolean; // deprecated, use size='L'
  size?: BadgeSizeKey;
  ellipsis?: boolean;
  circle?: boolean;
}

export const Badge = getComponentWithRefAndName<HTMLSpanElement, BadgeProps>(
  'Badge',
  ({ className, kind, big, ellipsis, circle, size = 'm', ...props }, ref) => (
    <span
      className={cn(
        css.badge,
        className,
        css[`size_${BadgeSize[size]}`],
        css[kind],
        {
          [css.big]: big,
          [css.ellipsis]: ellipsis,
          [css.circle]: circle,
        },
      )}
      ref={ref}
      {...props}
    />
  ),
);
