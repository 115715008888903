import React from 'react';
import { ScrollBox } from '@ui/ScrollBox';
import { MenuItem } from '@ui/Menu';
import {
  AdditionalProps,
  BaseComboboxItem,
  ComboboxChildrenProps,
} from './types';
import { MenuComboboxBaseProps, MenuComboboxBase } from './MenuComboboxBase';

type ChildrenProps<T> = ComboboxChildrenProps<T> & {
  props: AdditionalProps<T>;
};

export const DefaultMenuComboboxChildern = <T extends BaseComboboxItem>({
  downshift: { getItemProps, highlightedIndex },
  props: { item, index },
}: ChildrenProps<T>) => (
  <MenuItem
    title={item.title}
    active={index === highlightedIndex}
    key={item.id}
    {...getItemProps({ item })}
  />
);

export interface MenuComboboxProps<T>
  extends Omit<MenuComboboxBaseProps<T>, 'children'> {
  items: Array<T>;
  children?: (props: ChildrenProps<T>) => React.ReactNode;
}

export function MenuCombobox<T extends BaseComboboxItem>({
  children = DefaultMenuComboboxChildern,
  ...props
}: MenuComboboxProps<T>) {
  return (
    <MenuComboboxBase {...props}>
      {({ popper, downshift }) => {
        return (
          <ScrollBox>
            {props.items.map((item, index) =>
              children({
                popper,
                downshift,
                props: { item, index, currentItems: props.items },
              }),
            )}
          </ScrollBox>
        );
      }}
    </MenuComboboxBase>
  );
}
