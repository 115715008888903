import React, { useCallback } from 'react';
import { useToaster } from '../Toaster/ToasterProvider';

export const useCopiedToaster = () => {
  const { addToaster } = useToaster();
  return useCallback(() => {
    addToaster({
      type: 'info',
      content: <div style={{ whiteSpace: 'nowrap' }}>Code copied!</div>,
      timeout: 2000,
    });
  }, [addToaster]);
};
