import { Type } from '@ui/Type';
import cn from 'classnames';
import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperInterface from 'swiper/types/swiper-class';
import { ButtonUnstyled } from '../Button';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Spacer } from '../Spacer';
import { SwiperSliderWrapper } from '../SwiperSliderWrapper/SwiperSliderWrapper';
import { Bullets } from './Bullets';
import * as css from './Gallery.css';

interface GalleryItemRenderFooterProps {
  onNext(): void;
  onPrev(): void;
}
export interface GalleryItem {
  imageSrc: string;
  title?: React.ReactNode;
  text?: React.ReactNode;
  footer?: React.ReactNode;
  renderFooter?: (props: GalleryItemRenderFooterProps) => React.ReactNode;
  onFooterAction?(): void;
}

interface GalleryProps {
  width?: number;
  height?: number;
  items: GalleryItem[];
  defaultFooter?: React.ReactNode;
  onChangeIndex?(index: number): void;
}

export const Gallery: React.FC<GalleryProps> = ({
  width = 400,
  height = 400,
  items,
  onChangeIndex,
  defaultFooter,
}) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface | null>(
    null,
  );
  const [activeIndex, setActiveIndex] = useState(0);

  const clickNext = () => {
    swiperInstance?.slideNext();
  };

  const clickPrev = () => {
    swiperInstance?.slidePrev();
  };

  return (
    <div
      style={{ width: `${width}px`, height: `${height}px` }}
      className={css.gallery}
    >
      <SwiperSliderWrapper
        slidesPerView={1}
        spaceBetween={16}
        onSwiper={(swiper) => {
          setSwiperInstance(swiper);
        }}
        onActiveIndexChange={(swiper) => {
          const currentIndex = swiper.activeIndex ?? 0;
          setActiveIndex(currentIndex);
          onChangeIndex?.(currentIndex);
        }}
      >
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={index} className={css.item}>
            <img
              alt=""
              src={item.imageSrc}
              className={css.image}
              style={{ width: `${width}px` }}
            />
            <Spacer factor={5} />
            {item.title && (
              <>
                <Type weight="semibold" size="15px_DEPRECATED">
                  {item.title}
                </Type>
                <Spacer factor={2} />
              </>
            )}
            <Type size="15px_DEPRECATED">{item.text}</Type>
          </SwiperSlide>
        ))}
      </SwiperSliderWrapper>
      <Spacer factor={6} />
      <Flex alignItems="center" justifyContent="space-between">
        {items.length > 1 ? (
          <Flex alignItems="center">
            <ButtonUnstyled
              className={cn(css.navigationButton, css.prevButton)}
              onClick={clickPrev}
            >
              <Icon icon="sort" noColor />
            </ButtonUnstyled>
            <Spacer horizontalFactor={3} />
            <Bullets
              itemsNumber={items.length}
              activeIndex={activeIndex}
              onIndexChange={(index) => swiperInstance?.slideTo(index)}
            />
            <Spacer horizontalFactor={3} />
            <ButtonUnstyled
              className={cn(css.navigationButton, css.nextButton)}
              onClick={clickNext}
            >
              <Icon icon="sort" noColor />
            </ButtonUnstyled>
          </Flex>
        ) : (
          <div />
        )}
        {items[activeIndex]?.footer ??
          items[activeIndex]?.renderFooter?.({
            onNext: clickNext,
            onPrev: clickPrev,
          }) ??
          defaultFooter ?? <div />}
      </Flex>
    </div>
  );
};
